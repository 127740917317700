import React from 'react';

import { Box } from '@allergan-data-labs/alle-elements-layout';

import { ConsumerFooterLinkType } from './consumerFooterHelpers';
import { useEnvironmentContext } from '../environment-context/environmentContext';

type FooterLinkProps = {
  link: ConsumerFooterLinkType;
  onClick: (e: React.SyntheticEvent) => void;
  external?: boolean;
};

export function ConsumerFooterLink({
  link,
  external,
  onClick,
}: FooterLinkProps) {
  const { Link } = useEnvironmentContext();

  const { url, displayName, forceOpenSameTab } = link;

  return (
    <Link
      data-testid={`footer-${displayName?.replaceAll(' ', '-').toLowerCase()}`}
      href={url as string}
      onClick={onClick}
      target={external && !forceOpenSameTab ? '_blank' : '_self'}
      sx={{
        textDecoration: 'none',
      }}
      variant="action"
      colorMode="dark"
    >
      <Box
        textStyle="Body/Small/Regular"
        color={`dark.Text/Neutral/Interactive/Enable`}
        textAlign="center"
      >
        {displayName}
      </Box>
    </Link>
  );
}
