import React, { MouseEvent } from 'react';
import styled from 'styled-components';

import { alleColors } from '@allergan-data-labs/component-library/src/colorPalette';
import {
  HtmlDialog,
  HtmlDialogProps,
} from '@allergan-data-labs/component-library/src/components/htmlDialog';
import { Link as AlleLink } from '@allergan-data-labs/component-library/src/core/link';
import { OpenUrlIcon } from '@allergan-data-labs/component-library/src/icons/openUrlIcon';
import { useSegment } from '@allergan-data-labs/consumer-component-library/src/segment/segmentContext';
import { ABBVIE_URLS } from '@allergan-data-labs/universal-component-library/src/constants/urls';
import { Typography } from '@allergan-data-labs/universal-component-library/src/atoms/typography';

const Title = styled(Typography).attrs({ preset: 'headline-M-petersburg' })`
  color: ${alleColors.white};
  text-align: left;
`;

const Body = styled(Typography)`
  color: ${alleColors.white};
  text-transform: none;
  letter-spacing: -0.2px;
`;

const Link = styled(AlleLink)`
  text-decoration: underline;
  color: ${alleColors.white};
`;

const LinkButton = styled.button`
  all: unset;
  cursor: pointer;
  text-decoration: underline;
  color: ${alleColors.white};
`;

interface PrivacyDialogProps extends Pick<HtmlDialogProps, 'open' | 'onClose'> {
  isLoggedIn?: boolean;
  loginRoute: string;
  external?: boolean;
  openCookieSettings: (event: MouseEvent<HTMLButtonElement>) => void;
}

const PrivacyDialog = ({
  open,
  isLoggedIn,
  loginRoute,
  external = false,
  onClose,
  openCookieSettings,
}: PrivacyDialogProps) => {
  const { trackConsumer } = useSegment();
  const handleOpenCookieSettings = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    openCookieSettings(event);

    trackConsumer()?.siteNavigated({
      event: {
        action_source: 'consumer',
        activator: 'here link click',
        explainer: 'Tracking user engagement with privacy choices modal',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: isLoggedIn ? 'logged_in' : 'logged_out',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'footer navigation',
      event_source_url: window.location.href,
      url: 'One Trust Modal',
      text: 'cookie settings here',
    });

    onClose?.();
  };

  const handleSignInClick = () => {
    trackConsumer()?.siteNavigated({
      event: {
        action_source: 'consumer',
        activator: 'Sign In Link',
        explainer: 'Tracking user engagement with privacy choices modal',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: isLoggedIn ? 'logged_in' : 'logged_out',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'footer navigation',
      event_source_url: window.location.href,
      url: loginRoute,
      text: 'Sign In',
    });

    onClose?.();
  };

  const handleAbbVieLinkClick = () => {
    trackConsumer()?.siteNavigated({
      event: {
        action_source: 'consumer web',
        activator: "AbbVie's Data Subject Rights Tool Link",
        explainer: 'Tracking user engagement with modal',
        user_type: 'consumer',
        user_status: isLoggedIn ? 'logged_in' : 'logged_out',
        event_type: 'engagement',
        invoked_by: 'consumer',
      },
      site_type: 'consumer web',
      navigation_type: 'footer navigation',
      event_source_url: window.location.href,
      url: ABBVIE_URLS.dataSubjectRightsRequestForm,
      text: 'Abbvie Data Subject Rights Tool',
    });

    onClose?.();
  };

  return (
    <HtmlDialog
      open={open}
      title={<Title>Your Privacy Choices</Title>}
      content={
        <>
          <Body preset="body-M-regular">
            Your Privacy Choices are available for logged in Allē Members
            only.&nbsp;
            <Link
              data-testid="privacy-dialog-sign-in"
              to={loginRoute}
              external={external}
              onClick={handleSignInClick}
            >
              Sign in
            </Link>
            .
          </Body>
          <br />
          <Body preset="body-M-regular">
            You can also adjust your logged out&nbsp;
            <LinkButton
              data-testid="privacy-dialog-cookie-settings"
              onClick={handleOpenCookieSettings}
            >
              cookie settings here
            </LinkButton>
            , or otherwise exercise your privacy choices through&nbsp;
            <Link
              data-testid="privacy-dialog-data-subject-rights"
              to={ABBVIE_URLS.dataSubjectRightsRequestForm}
              onClick={handleAbbVieLinkClick}
              external
            >
              AbbVie&lsquo;s Data Subject Rights Tool
            </Link>
            .&nbsp;
            <Link
              to={ABBVIE_URLS.dataSubjectRightsRequestForm}
              onClick={handleAbbVieLinkClick}
              external
            >
              <OpenUrlIcon size={12} fill="white" />
            </Link>
          </Body>
        </>
      }
      onClose={onClose}
    />
  );
};

export { PrivacyDialog };
