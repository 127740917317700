import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// universal component library
import { Typography } from '@allergan-data-labs/universal-component-library/src/atoms/typography';
import { above } from '@allergan-data-labs/universal-component-library/src/utils/mediaTemplate';
import { ALLERGAN_URLS } from '@allergan-data-labs/universal-component-library/src/constants/urls';

// component library
import { alleColors } from '@allergan-data-labs/component-library/src/colorPalette';
import {
  DrawerButtonContainer,
  DrawerContentContainer,
  DrawerNavButtonContainer,
} from '@allergan-data-labs/component-library/src/components/drawerStyledComponents';
import { Link } from '@allergan-data-labs/component-library/src/core/link';
import { SimpleIconButton } from '@allergan-data-labs/component-library/src/core/simpleIconButton';
import { SlideHeaderContainer } from '@allergan-data-labs/component-library/src/header/slideHeaderContainer';
import { AlleLogo } from '@allergan-data-labs/component-library/src/icons/alleLogo';
import { Close } from '@allergan-data-labs/component-library/src/icons/close';

// segment
import { SiteNavigated } from '@allergan-data-labs/consumer-component-library/src/segment-tracking-plan/generated';
import { useSegment } from '@allergan-data-labs/consumer-component-library/src/segment/segmentContext';

// constants
import { Routes } from '../../constants/routes';

const Drawer = React.lazy(() =>
  import(
    /* webpackChunkName: 'Drawer' */ '@allergan-data-labs/component-library/src/core/drawer'
  ).then((mod) => ({
    default: mod.Drawer,
  }))
);

const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoginLink = styled(Link)`
  text-decoration: none;
  height: 100%;
  margin-right: 24px;
  p {
    color: #8f6446;
  }
  &:hover {
    p {
      opacity: 0.75;
    }
  }
  ${above.tablet`
    margin-right: 32px;
  `};
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const JoinLink = styled(Link)`
  box-sizing: border-box;
  max-width: 168px;
  border: 1px solid ${alleColors.black};
  background-color: ${alleColors.black};
  border-radius: 4px;
  padding: 8px 16px;
  p {
    color: ${alleColors.white};
  }
  text-align: center;
  &:hover {
    background-color: ${alleColors.white};
    p {
      color: ${alleColors.black};
    }
  }
`;

const StyledSimpleIconButton = styled(SimpleIconButton)`
  &&:hover .MuiSvgIcon-root path {
    fill: ${alleColors.white};
  }
`;

const DrawerFallback: React.FunctionComponent = () => {
  // Drawer is rendered off screen until visible, no need to display a fallback.
  return null;
};

interface DrawerLinkProps {
  testId: string;
  to: string;
  label: string;
  onClick: () => void;
}

const DrawerLink = (props: DrawerLinkProps) => {
  return (
    <DrawerNavButtonContainer>
      <Link to={props.to} data-testid={props.testId} onClick={props.onClick}>
        <Typography
          css={`
            color: ${alleColors.white};
          `}
          preset={'headline-L-graphik'}
        >
          {props.label}
        </Typography>
      </Link>
    </DrawerNavButtonContainer>
  );
};

const LoggedOutNavigator: React.FunctionComponent<{
  variant: 'light' | 'transparent' | 'transparentBlackText';
  keepPathOnLogin?: boolean;
  jumpLink?: string;
}> = ({ variant, keepPathOnLogin, jumpLink }) => {
  const { trackConsumer } = useSegment();
  const history = useHistory();
  const location = history.location;
  const [open, setOpen] = useState(false);

  const getLoginRoute = () => {
    if (keepPathOnLogin) {
      return { pathname: Routes.login, state: { from: location.pathname } };
    }
    return Routes.login;
  };

  const getJoinRoute = () => {
    if (jumpLink) {
      return `${location.pathname}${jumpLink}`;
    } else {
      return Routes.registrationPhoneNumber;
    }
  };

  const handleAlleLinkClick = () => {
    const linkClickSegment: SiteNavigated = {
      event: {
        action_source: 'consumer web',
        activator: 'Logged Out - Allē Logo',
        explainer: 'Tracking user engagement with top navigation',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: 'logged_out',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'top navigation',
      event_source_url: `${window.location.href}`,
      text: 'Alle',
      url: `https://alle.com/`,
    };

    trackConsumer()?.siteNavigated(linkClickSegment);
  };

  const handleLoginLinkClick = () => {
    const linkClickSegment: SiteNavigated = {
      event: {
        action_source: 'consumer web',
        activator: 'Log in Button',
        explainer: 'Tracking user engagement with top navigation',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: 'logged_out',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'top navigation',
      event_source_url: `${window.location.href}`,
      text: 'Log in',
      url: `https://alle.com/login`,
    };

    trackConsumer()?.siteNavigated(linkClickSegment);
  };

  const handleJoinAlleClick = () => {
    const linkClickSegment: SiteNavigated = {
      event: {
        action_source: 'consumer web',
        activator: 'Join Allē Button',
        explainer: 'Tracking user engagement with top navigation',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: 'logged_out',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'top navigation',
      event_source_url: `${window.location.href}`,
      text: 'Join Allē',
      url: `https://alle.com/registration`,
    };

    trackConsumer()?.siteNavigated(linkClickSegment);
  };

  return (
    <>
      <SlideHeaderContainer variant={variant} data-testid="logged-out-header">
        <LogoLink
          aria-label="ALLE"
          to={Routes.home}
          onClick={handleAlleLinkClick}
        >
          <AlleLogo />
        </LogoLink>

        <LinksContainer className="mui-fixed">
          <LoginLink
            data-testid="header-login-link"
            to={getLoginRoute()}
            onClick={handleLoginLinkClick}
          >
            <Typography
              preset={{
                s: 'body-S-regular',
                m: 'body-M-regular',
                l: 'body-M-regular',
              }}
            >
              Log in
            </Typography>
          </LoginLink>
          <JoinLink
            data-testid="header-join-link"
            to={getJoinRoute()}
            onClick={handleJoinAlleClick}
          >
            <Typography
              preset={{
                s: 'body-S-regular',
                m: 'body-M-regular',
                l: 'body-M-regular',
              }}
            >
              Join Allē
            </Typography>
          </JoinLink>
        </LinksContainer>
      </SlideHeaderContainer>
      <React.Suspense fallback={<DrawerFallback />}>
        <Drawer open={open} onClose={() => setOpen(false)}>
          <DrawerButtonContainer>
            <StyledSimpleIconButton
              onClick={() => setOpen(false)}
              color={alleColors.white}
            >
              <Close />
            </StyledSimpleIconButton>
          </DrawerButtonContainer>
          <DrawerContentContainer>
            <DrawerLink
              to={Routes.about}
              testId={`drawer-link-about`}
              label={'About Allē'}
              onClick={() => setOpen(false)}
            />
            <DrawerLink
              to={Routes.howItWorks}
              testId={`drawer-link-how-it-works`}
              label={'How it works'}
              onClick={() => setOpen(false)}
            />
            <DrawerLink
              to={Routes.ourBrands}
              testId={`drawer-link-our-brands`}
              label={'Our Brands'}
              onClick={() => setOpen(false)}
            />
            <DrawerLink
              to={Routes.articles}
              testId={`drawer-link-discover`}
              label={'Browse Articles'}
              onClick={() => setOpen(false)}
            />
            <DrawerNavButtonContainer>
              <a
                css={`
                  text-decoration: none;
                `}
                href={ALLERGAN_URLS.alleSupport}
                rel="noopener noreferrer"
                target="_blank"
                data-testid={`drawer-link-help`}
                onClick={() => setOpen(false)}
              >
                <Typography
                  css={`
                    color: ${alleColors.white};
                  `}
                  preset={'headline-L-graphik'}
                >
                  Help
                </Typography>
              </a>
            </DrawerNavButtonContainer>
          </DrawerContentContainer>
        </Drawer>
      </React.Suspense>
    </>
  );
};

export { LoggedOutNavigator };
