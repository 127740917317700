import React from 'react';
import styled from 'styled-components';
import { Flex } from '@allergan-data-labs/alle-elements-layout';
import { Text } from '@chakra-ui/react';

import { Button, ButtonGroup } from '@allergan-data-labs/alle-elements-button';
import { getTypographyToken } from '@allergan-data-labs/alle-elements-core';
import {
  above,
  screenSizes,
} from '@allergan-data-labs/universal-component-library/src/utils/mediaTemplate';
import { ABBVIE_URLS } from '@allergan-data-labs/universal-component-library/src/constants/urls';
import { Container, showAnimation } from './shared';
import { useMediaQuery } from '@material-ui/core';
import { ButtonLink } from '../chakra-components/buttonlink';
import { useEnvironmentContext } from '../environment-context/environmentContext';

const DefaultOptedOutContainer = styled(Container)`
  max-height: 90vh;
  padding: 12px 16px 16px 16px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.12),
    0px 13px 23px 0px rgba(0, 0, 0, 0.1), 0px 30px 18px 0px rgba(0, 0, 0, 0.06),
    0px 54px 22px 0px rgba(0, 0, 0, 0.02);

  ${above.tablet`
    animation: ${showAnimation('0', '100px')} linear 0.5s;
  `}
`;

export const TextAndButtonsContainer = styled(Flex).attrs({
  direction: 'column',
})`
  max-width: 904px;
  margin: 0 auto;
  overflow: hidden;
`;

export const TextContainer = styled(Flex)`
  padding-right: 8px; // adjust padding and margin to align the scrollbar, should add up to 20 to leave room for the 'X'
  margin-right: 12px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    margin-top: 20px; // Don't want it to slide into the 'X'
  }
  ::-webkit-scrollbar-thumb {
    background: #cfcbca;
    border-radius: 4px;
  }
`;

const ButtonGroupContainer = styled(ButtonGroup)`
  width: 100%;
  margin-top: 8px;
  gap: 8px;

  @media (max-width: 350px) {
    flex-direction: column-reverse;
  }
`;

const ButtonContainer = styled.span`
  flex: 1;
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
  && {
    margin-left: 0;
  }

  &.accept-and-reject {
    flex: 2;
  }
`;

const DefaultOptedOutSnippet = ({
  onShowMoreClick,
  text,
}: {
  onShowMoreClick: () => void;
  text: string;
}) => {
  return (
    <>
      <Text {...getTypographyToken('Body/Small/Regular')} css={{ margin: 0 }}>
        {text}{' '}
        <ButtonLink
          onClick={onShowMoreClick}
          variant="action"
          colorMode="dark"
          {...getTypographyToken('Body/Small/Regular')}
        >
          read more
        </ButtonLink>
      </Text>
    </>
  );
};

const DefaultOptedOutFullBanner = ({
  onShowLessClick,
  onPrivacyPolicyClick,
}: {
  onShowLessClick: () => void;
  onPrivacyPolicyClick: () => void;
}) => {
  const { Link } = useEnvironmentContext();
  return (
    <Text {...getTypographyToken('Body/Small/Regular')} sx={{ margin: 0 }}>
      AbbVie may use and disclose online usage data about you collected
      automatically through online tracking technologies such as cookies,
      pixels, and web beacons (collectively, “cookies”). We use this data for
      multiple purposes, including for online targeted advertising
      (advertisements based on your interests inferred from your activity across
      other unaffiliated sites and services) and website analytics purposes, as
      well as to personalize content, save your preferences, provide social
      media features, and track the site’s performance, as further described in
      the “
      <Link
        href={ABBVIE_URLS.cookiesAndTracking}
        target={'_blank'}
        onClick={onPrivacyPolicyClick}
        variant="action"
        colorMode="dark"
        {...getTypographyToken('Body/Small/Bold')}
      >
        Cookies and similar tracking and data collection technologies
      </Link>
      ” section of our Privacy Notice. We retain this data for as long as
      necessary to fulfill these purposes or as needed to comply with our record
      retention obligations. We do not sell your data, but we may disclose it to
      our marketing and advertising partners for purposes of online targeted
      advertising or for website analytics purposes. To opt out of the use or
      disclosure of your cookie-based personal data for online targeted
      advertising or to otherwise manage your preferences, please click on
      Cookie Settings. For additional information on the categories of data we
      collect, the purposes for their collection, disclosures to third parties,
      and data retention, please visit our{' '}
      <Link
        href={ABBVIE_URLS.privacyPolicy}
        target={'_blank'}
        onClick={onPrivacyPolicyClick}
        variant="action"
        colorMode="dark"
        {...getTypographyToken('Body/Small/Bold')}
      >
        Privacy Notice
      </Link>
      .{' '}
      <ButtonLink
        onClick={onShowLessClick}
        variant="action"
        colorMode="dark"
        {...getTypographyToken('Body/Small/Regular')}
      >
        read less
      </ButtonLink>
    </Text>
  );
};

const DefaultOptedOutButtons = ({
  onCookieSettingsClick,
  onRejectAllClick,
  onAcceptAllClick,
}: {
  onCookieSettingsClick: () => void;
  onRejectAllClick: () => void;
  onAcceptAllClick: () => void;
}) => {
  const isTabletOrAbove = useMediaQuery(`(min-width:${screenSizes.tablet}px)`);
  return (
    <ButtonGroupContainer
      colorScheme="action"
      variant="solid"
      size={isTabletOrAbove ? 'sm' : 'xs'}
    >
      <ButtonContainer>
        <Button
          variant="outline"
          colorMode="dark"
          sx={{ width: '100%' }}
          {...getTypographyToken('CONSUMER/Caption/Medium/Regular')}
          onClick={onCookieSettingsClick}
        >
          Manage Choices
        </Button>
      </ButtonContainer>
      <ButtonContainer className="accept-and-reject">
        <Button
          colorMode="dark"
          sx={{ width: '100%' }}
          {...getTypographyToken('CONSUMER/Caption/Medium/Regular')}
          onClick={onRejectAllClick}
        >
          Reject All
        </Button>
        <Button
          colorMode="dark"
          sx={{ width: '100%' }}
          {...getTypographyToken('CONSUMER/Caption/Medium/Regular')}
          onClick={onAcceptAllClick}
        >
          Accept All
        </Button>
      </ButtonContainer>
    </ButtonGroupContainer>
  );
};

export {
  DefaultOptedOutContainer,
  DefaultOptedOutSnippet,
  DefaultOptedOutFullBanner,
  DefaultOptedOutButtons,
};
