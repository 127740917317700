import React from 'react';

import { Box } from '@allergan-data-labs/alle-elements-layout';
import { TextButton } from '@allergan-data-labs/alle-elements-button';

type ConsumerFooterCookieSettingsProps = {
  onClick: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => void;
};

export function ConsumerFooterCookieSettings({
  onClick,
}: ConsumerFooterCookieSettingsProps) {
  return (
    <TextButton
      id="ot-sdk-btn"
      onClick={onClick}
      border="unset"
      padding="unset"
      backgroundColor="transparent"
      cursor="pointer"
      colorMode="dark"
      _hover={{
        div: {
          textDecoration: 'underline',
        },
      }}
    >
      <Box
        textStyle="Body/Small/Regular"
        color="dark.Text/Neutral/Interactive/Enable"
      >
        Cookie Settings
      </Box>
    </TextButton>
  );
}
