import React from 'react';

import { Box } from '@allergan-data-labs/alle-elements-layout';
import { TextButton } from '@allergan-data-labs/alle-elements-button';
import { CCPAOptOutIcon } from '@allergan-data-labs/component-library/src/icons/CCPAOptOut';
import { useEnvironmentContext } from '../environment-context/environmentContext';

type ConsumerFooterPrivacyChoicesProps = {
  redirectTo?: string | null;
  onClick: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => void;
};

export function ConsumerFooterPrivacyChoices({
  redirectTo,
  onClick,
}: ConsumerFooterPrivacyChoicesProps) {
  const { Link } = useEnvironmentContext();

  const Content = (
    <Box
      justifyContent="left"
      color="dark.Text/Neutral/Interactive/Enable"
      textStyle="Body/Small/Regular"
      display="flex"
      alignItems="center"
      gap={4}
    >
      Your Privacy Choices
      <Box display="inline-flex" flexWrap="wrap" alignItems="center">
        <CCPAOptOutIcon />
      </Box>
    </Box>
  );

  if (redirectTo) {
    return (
      <Link
        data-testid="footer-privacy-choices"
        href={redirectTo}
        onClick={onClick}
        sx={{
          textDecoration: 'none',
        }}
        variant="action"
        colorMode="dark"
      >
        {Content}
      </Link>
    );
  }

  return (
    <TextButton
      data-testid="footer-privacy-choices"
      as="button"
      onClick={onClick}
      border="unset"
      padding="unset"
      backgroundColor="transparent"
      cursor="pointer"
      colorMode="dark"
      _hover={{
        div: {
          textDecoration: 'underline',
        },
      }}
    >
      {Content}
    </TextButton>
  );
}
