export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CurrencyCode: any;
  /** String input format MM-DD-YYYY */
  DateInput: any;
  /**
   * https://tools.ietf.org/html/rfc3339 compliant date-time string
   * such as 2007-12-03T10:15:30Z.
   */
  DateTimeInput: any;
  /**
   * https://www.w3.org/Protocols/rfc822/ compliant email address.
   * such as john.doe@acme.org
   */
  EmailAddress: any;
  HTML: any;
  Markdown: any;
  /** HH:MM:SS -HH:MM */
  Time: any;
  /**
   * https://www.ietf.org/rfc/rfc3986.txt compiant URL
   * such as https://www.acme.org/path?query=value
   */
  URL: any;
  join__FieldSet: any;
  link__Import: any;
};

export enum Account_Status {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  NewEntity = 'NEW_ENTITY',
  PendingBusinessOwnerRegistration = 'PENDING_BUSINESS_OWNER_REGISTRATION',
  RequiresAction = 'REQUIRES_ACTION',
  UserArchived = 'USER_ARCHIVED',
}

export enum Actor_Type {
  Consumer = 'CONSUMER',
  PracticeEmployee = 'PRACTICE_EMPLOYEE',
  Admin = 'ADMIN',
}

export enum Advertisement_Management_Status {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export enum Advertising_Audience_Source_Type {
  S3Object = 'S3_OBJECT',
}

export enum Advertising_Audience_Status {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
}

export enum Advertising_Audience_Target_Type {
  Custom = 'CUSTOM',
  Lookalike = 'LOOKALIKE',
}

export enum Advertising_Auto_Call_Phone_Number_Verification_Method {
  Call = 'CALL',
  Sms = 'SMS',
}

export enum Advertising_Auto_Call_Verification_Status {
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export enum Advertising_Contact_Automated_Lead_Notification_Method {
  Call = 'CALL',
  Sms = 'SMS',
}

export enum Advertising_Lead_Notification_Method_Verification_Status {
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export enum Advertising_Lead_Notification_Phone_Number_Verification_Method {
  Call = 'CALL',
  Sms = 'SMS',
}

export enum Advertising_Lead_Notification_Phone_Number_Verification_Status {
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export enum Advertising_Profile_Enrollment_Status {
  AwaitingTerms = 'AWAITING_TERMS',
  Enrolled = 'ENROLLED',
  NotEnrolled = 'NOT_ENROLLED',
  ReadOnly = 'READ_ONLY',
}

export enum Advertising_Targeting_Audience_Type {
  AdvertisingAudience = 'ADVERTISING_AUDIENCE',
  Interests = 'INTERESTS',
}

export enum Ad_Audience_Gender {
  All = 'ALL',
  Men = 'MEN',
  Women = 'WOMEN',
}

export enum Allergan_Product_Search_Offering_Results_Sort_Column {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Price = 'PRICE',
  Relevance = 'RELEVANCE',
}

export enum Allergan_Product_Search_Offering_Results_Sort_Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum Allergan_Product_Search_Results_Sort_Column {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Price = 'PRICE',
  Relevance = 'RELEVANCE',
}

export enum Allergan_Product_Search_Results_Sort_Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum Allergan_Store_Invoice_Column {
  CreatedAt = 'CREATED_AT',
  OrderNumber = 'ORDER_NUMBER',
}

export enum Allergan_Store_Invoice_Memo {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum Allergan_Store_Invoice_Memo_Column {
  DueDate = 'DUE_DATE',
}

export enum Allergan_Store_Invoice_Sort_Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum Allergan_Store_Invoice_Status {
  Paid = 'PAID',
  Unpaid = 'UNPAID',
  Partial = 'PARTIAL',
}

export enum Allergan_Store_Order_Column {
  CreatedAt = 'CREATED_AT',
  OrderNumber = 'ORDER_NUMBER',
}

export enum Allergan_Store_Order_Shipping_Status {
  Ordered = 'ORDERED',
  Processing = 'PROCESSING',
  Shipped = 'SHIPPED',
  Delivered = 'DELIVERED',
}

export enum Allergan_Store_Order_Sort_Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum Allergan_Store_Order_Status {
  Pending = 'PENDING',
  PartialDelivered = 'PARTIAL_DELIVERED',
  Delivered = 'DELIVERED',
  Completed = 'COMPLETED',
  Errored = 'ERRORED',
}

export enum Allergan_Store_Payment_Option {
  CreditCard = 'CREDIT_CARD',
  BillMeLater = 'BILL_ME_LATER',
}

export enum Allergan_Store_Price_Adjustment_Type {
  PercentageOff = 'PERCENTAGE_OFF',
}

export enum Allergan_Store_Pricing_Duration {
  Week = 'WEEK',
  Month = 'MONTH',
  Quarter = 'QUARTER',
}

export enum Allergan_Store_Pricing_Threshold_Unit {
  Cent = 'CENT',
  Percentage = 'PERCENTAGE',
}

export enum Allergan_Store_Pricing_Tier_Upgrade_Reason_Type {
  Retention = 'RETENTION',
  Volume = 'VOLUME',
}

export enum Allergan_Store_Shopping_Cart_Processing_Status {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
}

export enum Alle_Payout_Summary_Status {
  Pending = 'PENDING',
  Paid = 'PAID',
  Failed = 'FAILED',
}

export enum Audit_Event_Actor_Type {
  Employee = 'EMPLOYEE',
  Consumer = 'CONSUMER',
  Provider = 'PROVIDER',
  ScheduledService = 'SCHEDULED_SERVICE',
  Service = 'SERVICE',
}

export enum Audit_Event_Sort_Column {
  ActorType = 'ACTOR_TYPE',
  Action = 'ACTION',
  TargetType = 'TARGET_TYPE',
}

export enum Audit_Event_Sort_Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum Audit_Event_Status {
  Success = 'SUCCESS',
  Fail = 'FAIL',
  Attempt = 'ATTEMPT',
}

export type ActivePromotionsReport = {
  __typename?: 'ActivePromotionsReport';
  rowsConnection: ActivePromotionsReportRowsConnection;
};

export type ActivePromotionsReportCreateInput = {
  sort: Array<ActivePromotionsReportSort>;
  reportExportFormat?: InputMaybe<Report_Export_Format>;
};

export type ActivePromotionsReportPageInput = {
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort: Array<ActivePromotionsReportSort>;
};

export type ActivePromotionsReportRow = {
  __typename?: 'ActivePromotionsReportRow';
  consumerId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  phoneNumber: Scalars['String'];
  promotionDisplayName: Scalars['String'];
  promotionExpiresAt: DateTime;
  expiringPointsAsDollars: Scalars['Float'];
  pointsExpiresAt?: Maybe<DateTime>;
  redeemedAt?: Maybe<DateTime>;
};

export type ActivePromotionsReportRowsConnection = {
  __typename?: 'ActivePromotionsReportRowsConnection';
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  nodes: Array<ActivePromotionsReportRow>;
};

export type ActivePromotionsReportSort = {
  column?: ActivePromotionsReportSortColumn;
  order?: ReportSortOrder;
};

export enum ActivePromotionsReportSortColumn {
  ExpiringDollars = 'EXPIRING_DOLLARS',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  PointsExpirationDate = 'POINTS_EXPIRATION_DATE',
  PromotionExpirationDate = 'PROMOTION_EXPIRATION_DATE',
  RedemptionDate = 'REDEMPTION_DATE',
}

export type Actor = {
  __typename?: 'Actor';
  id: Scalars['ID'];
  type: Actor_Type;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type ActorV2 = {
  id: Scalars['ID'];
};

export type AdParameters = {
  __typename?: 'AdParameters';
  budgetInCents: Scalars['Int'];
  durationInDays: Scalars['Int'];
};

export type Address = {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AdminReferringUserProfile = {
  __typename?: 'AdminReferringUserProfile';
  id: Scalars['ID'];
  referredBy?: Maybe<Patient>;
  referralProgramData?: Maybe<ReferralProgramData>;
};

export type AdminUserActor = ActorV2 &
  HumanActor & {
    __typename?: 'AdminUserActor';
    id: Scalars['ID'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
  };

export type AdminUserProfile = ViewerProfile & {
  __typename?: 'AdminUserProfile';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
};

/** Error data returned upon failed campaign budget validation. */
export type AdsCampaignBudgetValidateError = {
  __typename?: 'AdsCampaignBudgetValidateError';
  error: Error;
};

export type AdsCampaignBudgetValidateInput = {
  spendAmountInCents?: InputMaybe<Scalars['Int']>;
  spendLimitInCents?: InputMaybe<Scalars['Int']>;
  spendLimitMode: Spend_Limit_Mode;
  startDate: Scalars['DateTimeInput'];
  endDate: Scalars['DateTimeInput'];
};

export type AdsCampaignBudgetValidatePayload =
  | AdsCampaignBudgetValidateSuccess
  | AdsCampaignBudgetValidateError;

/** Response returned upon successful campaign budget validation. */
export type AdsCampaignBudgetValidateSuccess = {
  __typename?: 'AdsCampaignBudgetValidateSuccess';
  /** @deprecated Please use spendLimitInCents */
  spendAmountInCents?: Maybe<Scalars['Int']>;
  spendLimitInCents: Scalars['Int'];
  spendLimitMode: Spend_Limit_Mode;
  startDate: DateTime;
  endDate: DateTime;
};

export type AdvertisingAddress = {
  __typename?: 'AdvertisingAddress';
  /** @deprecated Please use the rest of address attributes here to build full address. For example, {address1}, {city}, {state}, {zipCode} */
  fullAddress: Scalars['String'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type AdvertisingAddressInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type AdvertisingAgeRange = {
  __typename?: 'AdvertisingAgeRange';
  minimum: Scalars['Int'];
  maximum: Scalars['Int'];
};

export type AdvertisingAudience = {
  __typename?: 'AdvertisingAudience';
  id: Scalars['ID'];
  name: Scalars['String'];
  status: Advertising_Audience_Status;
};

export type AdvertisingAudienceCreateFailure = {
  __typename?: 'AdvertisingAudienceCreateFailure';
  error: Error;
};

export type AdvertisingAudienceCreateInput = {
  name: Scalars['String'];
  sourceId: Scalars['ID'];
  sourceAudienceType: Advertising_Audience_Source_Type;
  sourceAudienceURL?: InputMaybe<Scalars['String']>;
  targetType: Advertising_Audience_Target_Type;
  completionNotificationURL?: InputMaybe<Scalars['String']>;
  /** only required when needing to upload audience to specific practice(provider) */
  practiceId?: InputMaybe<Scalars['ID']>;
  /** only will be used when targetType is LOOKALIKE */
  lookalikeExpansionPercentage?: InputMaybe<Scalars['Int']>;
};

export type AdvertisingAudienceCreatePayload =
  | AdvertisingAudienceCreateSuccess
  | AdvertisingAudienceCreateFailure;

export type AdvertisingAudienceCreateSuccess = {
  __typename?: 'AdvertisingAudienceCreateSuccess';
  audience: AdvertisingAudience;
};

/** Error data returned upon failed form creation. */
export type AdvertisingAudienceEmailsUploadFormCreateError = {
  __typename?: 'AdvertisingAudienceEmailsUploadFormCreateError';
  error: Error;
};

export type AdvertisingAudienceEmailsUploadFormCreateInput = {
  filename: Scalars['String'];
};

export type AdvertisingAudienceEmailsUploadFormCreatePayload =
  | AdvertisingAudienceEmailsUploadFormCreateSuccess
  | AdvertisingAudienceEmailsUploadFormCreateError;

/**
 * Response returned upon successful form creation.
 * Example usage:
 *
 * const form = new FormData()
 *
 * uploadForm.fields.forEach(({name,value}) => form.append(name, value))
 * form.append('file', fileToUpload)
 *
 * await fetch(uploadForm.url, { method: 'POST', body: form })
 */
export type AdvertisingAudienceEmailsUploadFormCreateSuccess = {
  __typename?: 'AdvertisingAudienceEmailsUploadFormCreateSuccess';
  uploadForm: SignedUploadForm;
};

export type AdvertisingAudienceReachEstimateAudienceInput = {
  type: Advertising_Targeting_Audience_Type;
  id?: InputMaybe<Scalars['ID']>;
  interests?: InputMaybe<Array<Scalars['String']>>;
};

export type AdvertisingAudienceReachEstimateFailure = {
  __typename?: 'AdvertisingAudienceReachEstimateFailure';
  error: Error;
};

export type AdvertisingAudienceReachEstimateInput = {
  targeting: AdvertisingAudienceReachEstimateTargetingInput;
  practiceId?: InputMaybe<Scalars['ID']>;
};

export type AdvertisingAudienceReachEstimatePayload =
  | AdvertisingAudienceReachEstimateSuccess
  | AdvertisingAudienceReachEstimateFailure;

export type AdvertisingAudienceReachEstimateSuccess = {
  __typename?: 'AdvertisingAudienceReachEstimateSuccess';
  lowerBound: Scalars['Int'];
  upperBound: Scalars['Int'];
};

export type AdvertisingAudienceReachEstimateTargetingGeoLocationsInput = {
  zipCodes?: InputMaybe<Array<Scalars['String']>>;
  locationFullAddress?: InputMaybe<Scalars['String']>;
  radiusInMiles?: InputMaybe<Scalars['Int']>;
};

export type AdvertisingAudienceReachEstimateTargetingInput = {
  /** default to provider address when not provided (or US if no practiceId was given) */
  geoLocations?: InputMaybe<AdvertisingAudienceReachEstimateTargetingGeoLocationsInput>;
  /** default to 25 to 64 */
  ageRange?: InputMaybe<NumberRangeInput>;
  /** default to [WOMEN] */
  genders?: InputMaybe<Array<InputMaybe<Ad_Audience_Gender>>>;
  audiences: Array<AdvertisingAudienceReachEstimateAudienceInput>;
  excludedAudiences?: InputMaybe<
    Array<AdvertisingAudienceReachEstimateAudienceInput>
  >;
};

export type AdvertisingAutoCallPhoneNumberVerification = {
  __typename?: 'AdvertisingAutoCallPhoneNumberVerification';
  id: Scalars['ID'];
  phoneNumber: Scalars['String'];
  verificationCode: Scalars['String'];
  status: Advertising_Auto_Call_Verification_Status;
};

export type AdvertisingAutoCallProfile = {
  __typename?: 'AdvertisingAutoCallProfile';
  id: Scalars['ID'];
  shouldReceiveCall: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  preferredNotificationMethod?: Maybe<Advertising_Contact_Automated_Lead_Notification_Method>;
  verificationStatus: Advertising_Auto_Call_Verification_Status;
};

export type AdvertisingBusinessHours = {
  __typename?: 'AdvertisingBusinessHours';
  day: Day_Of_The_Week;
  open?: Maybe<Scalars['Time']>;
  close?: Maybe<Scalars['Time']>;
  closed?: Maybe<Scalars['Boolean']>;
};

export type AdvertisingContactAutoCallProfileUpdateError = {
  __typename?: 'AdvertisingContactAutoCallProfileUpdateError';
  error: Error;
};

export type AdvertisingContactAutoCallProfileUpdateInput = {
  shouldReceiveCall: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  preferredNotificationMethod?: InputMaybe<Advertising_Contact_Automated_Lead_Notification_Method>;
};

export type AdvertisingContactAutoCallProfileUpdatePayload =
  | AdvertisingContactAutoCallProfileUpdateSuccess
  | AdvertisingContactAutoCallProfileUpdateError;

export type AdvertisingContactAutoCallProfileUpdateSuccess = {
  __typename?: 'AdvertisingContactAutoCallProfileUpdateSuccess';
  contact: AdvertisingPublicContact;
};

export type AdvertisingContactUpdateEmailAddressFailure = {
  __typename?: 'AdvertisingContactUpdateEmailAddressFailure';
  error: Error;
};

export type AdvertisingContactUpdateEmailAddressInput = {
  emailAddress?: InputMaybe<Scalars['EmailAddress']>;
};

export type AdvertisingContactUpdateEmailAddressPayload =
  | AdvertisingContactUpdateEmailAddressSuccess
  | AdvertisingContactUpdateEmailAddressFailure;

export type AdvertisingContactUpdateEmailAddressSuccess = {
  __typename?: 'AdvertisingContactUpdateEmailAddressSuccess';
  contact: AdvertisingPublicContact;
};

export type AdvertisingLandingPageMetadata = {
  __typename?: 'AdvertisingLandingPageMetadata';
  id: Scalars['ID'];
  claimableUntil?: Maybe<DateTime>;
  contact: AdvertisingPublicContact;
  curatedPost: SocialMediaCuratedPost;
  promotion?: Maybe<PromotionConstruct>;
  campaignStatus: Provider_Marketing_Campaign_Status;
};

export type AdvertisingLeadNotificationEmailAddress =
  AdvertisingLeadNotificationMethod & {
    __typename?: 'AdvertisingLeadNotificationEmailAddress';
    id: Scalars['ID'];
    isEnabled: Scalars['Boolean'];
    emailAddress: Scalars['String'];
    verificationStatus: Advertising_Lead_Notification_Method_Verification_Status;
  };

export type AdvertisingLeadNotificationMethod = {
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
  verificationStatus: Advertising_Lead_Notification_Method_Verification_Status;
};

export type AdvertisingLeadNotificationMethodRemoveFailure = {
  __typename?: 'AdvertisingLeadNotificationMethodRemoveFailure';
  error: Error;
};

export type AdvertisingLeadNotificationMethodRemovePayload =
  | AdvertisingLeadNotificationMethodRemoveSuccess
  | AdvertisingLeadNotificationMethodRemoveFailure;

export type AdvertisingLeadNotificationMethodRemoveSuccess = {
  __typename?: 'AdvertisingLeadNotificationMethodRemoveSuccess';
  notificationMethods: AdvertisingLeadNotificationMethods;
};

export type AdvertisingLeadNotificationMethodSetEnableFailure = {
  __typename?: 'AdvertisingLeadNotificationMethodSetEnableFailure';
  error: Error;
};

export type AdvertisingLeadNotificationMethodSetEnableInput = {
  id: Scalars['ID'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type AdvertisingLeadNotificationMethodSetEnablePayload =
  | AdvertisingLeadNotificationMethodSetEnableSuccess
  | AdvertisingLeadNotificationMethodSetEnableFailure;

export type AdvertisingLeadNotificationMethodSetEnableSuccess = {
  __typename?: 'AdvertisingLeadNotificationMethodSetEnableSuccess';
  notificationMethod: AdvertisingLeadNotificationMethod;
};

export type AdvertisingLeadNotificationMethods = {
  __typename?: 'AdvertisingLeadNotificationMethods';
  id: Scalars['ID'];
  voiceCall?: Maybe<AdvertisingLeadNotificationVoiceCallPhoneNumber>;
  textMessage?: Maybe<AdvertisingLeadNotificationSmsPhoneNumber>;
  emailAddress?: Maybe<AdvertisingLeadNotificationEmailAddress>;
};

export type AdvertisingLeadNotificationPhoneNumberRemoveFailure = {
  __typename?: 'AdvertisingLeadNotificationPhoneNumberRemoveFailure';
  error: Error;
};

export type AdvertisingLeadNotificationPhoneNumberRemoveInput = {
  id: Scalars['ID'];
};

export type AdvertisingLeadNotificationPhoneNumberRemovePayload =
  | AdvertisingLeadNotificationPhoneNumberRemoveSuccess
  | AdvertisingLeadNotificationPhoneNumberRemoveFailure;

export type AdvertisingLeadNotificationPhoneNumberRemoveSuccess = {
  __typename?: 'AdvertisingLeadNotificationPhoneNumberRemoveSuccess';
  id: Scalars['ID'];
};

export type AdvertisingLeadNotificationPhoneNumberVerification = {
  __typename?: 'AdvertisingLeadNotificationPhoneNumberVerification';
  id: Scalars['ID'];
  phoneNumber: Scalars['String'];
  phoneNumberExtension?: Maybe<Scalars['String']>;
  verificationCode?: Maybe<Scalars['String']>;
  status: Advertising_Lead_Notification_Phone_Number_Verification_Status;
  verificationMethod: Advertising_Lead_Notification_Phone_Number_Verification_Method;
};

export type AdvertisingLeadNotificationSmsPhoneNumber =
  AdvertisingLeadNotificationMethod & {
    __typename?: 'AdvertisingLeadNotificationSMSPhoneNumber';
    id: Scalars['ID'];
    isEnabled: Scalars['Boolean'];
    phoneNumber: Scalars['String'];
    verificationStatus: Advertising_Lead_Notification_Method_Verification_Status;
  };

export type AdvertisingLeadNotificationSmsPhoneNumberAddFailure = {
  __typename?: 'AdvertisingLeadNotificationSMSPhoneNumberAddFailure';
  error: Error;
};

export type AdvertisingLeadNotificationSmsPhoneNumberAddInput = {
  /** Accepted format: +11234567890, 1234567890 */
  phoneNumber: Scalars['String'];
};

export type AdvertisingLeadNotificationSmsPhoneNumberAddPayload =
  | AdvertisingLeadNotificationSmsPhoneNumberAddSuccess
  | AdvertisingLeadNotificationSmsPhoneNumberAddFailure;

export type AdvertisingLeadNotificationSmsPhoneNumberAddSuccess = {
  __typename?: 'AdvertisingLeadNotificationSMSPhoneNumberAddSuccess';
  phoneNumber: AdvertisingLeadNotificationSmsPhoneNumber;
};

export type AdvertisingLeadNotificationSmsPhoneNumberVerifyFailure = {
  __typename?: 'AdvertisingLeadNotificationSMSPhoneNumberVerifyFailure';
  error: Error;
};

export type AdvertisingLeadNotificationSmsPhoneNumberVerifyInput = {
  phoneNumber: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type AdvertisingLeadNotificationSmsPhoneNumberVerifyPayload =
  | AdvertisingLeadNotificationSmsPhoneNumberVerifySuccess
  | AdvertisingLeadNotificationSmsPhoneNumberVerifyFailure;

export type AdvertisingLeadNotificationSmsPhoneNumberVerifySuccess = {
  __typename?: 'AdvertisingLeadNotificationSMSPhoneNumberVerifySuccess';
  phoneNumber: AdvertisingLeadNotificationSmsPhoneNumber;
};

export type AdvertisingLeadNotificationVoiceCallPhoneNumber =
  AdvertisingLeadNotificationMethod & {
    __typename?: 'AdvertisingLeadNotificationVoiceCallPhoneNumber';
    id: Scalars['ID'];
    isEnabled: Scalars['Boolean'];
    phoneNumber: Scalars['String'];
    /** phoneNumberExtension can include w as a short delay for bypassing IVR system (e.g. www1www3) */
    phoneNumberExtension?: Maybe<Scalars['String']>;
    verificationCode: Scalars['String'];
    verificationStatus: Advertising_Lead_Notification_Method_Verification_Status;
  };

export type AdvertisingLeadNotificationVoiceCallPhoneNumberAddFailure = {
  __typename?: 'AdvertisingLeadNotificationVoiceCallPhoneNumberAddFailure';
  error: Error;
};

export type AdvertisingLeadNotificationVoiceCallPhoneNumberAddInput = {
  /** Accepted format: +11234567890, 1234567890 */
  phoneNumber: Scalars['String'];
  phoneNumberExtension?: InputMaybe<Scalars['String']>;
};

export type AdvertisingLeadNotificationVoiceCallPhoneNumberAddPayload =
  | AdvertisingLeadNotificationVoiceCallPhoneNumberAddSuccess
  | AdvertisingLeadNotificationVoiceCallPhoneNumberAddFailure;

export type AdvertisingLeadNotificationVoiceCallPhoneNumberAddSuccess = {
  __typename?: 'AdvertisingLeadNotificationVoiceCallPhoneNumberAddSuccess';
  phoneNumber: AdvertisingLeadNotificationVoiceCallPhoneNumber;
};

export type AdvertisingLeadNotificationVoiceCallPhoneNumberRemoveFailure = {
  __typename?: 'AdvertisingLeadNotificationVoiceCallPhoneNumberRemoveFailure';
  error: Error;
};

export type AdvertisingLeadNotificationVoiceCallPhoneNumberRemovePayload =
  | AdvertisingLeadNotificationVoiceCallPhoneNumberRemoveSuccess
  | AdvertisingLeadNotificationVoiceCallPhoneNumberRemoveFailure;

export type AdvertisingLeadNotificationVoiceCallPhoneNumberRemoveSuccess = {
  __typename?: 'AdvertisingLeadNotificationVoiceCallPhoneNumberRemoveSuccess';
  phoneNumber: AdvertisingLeadNotificationVoiceCallPhoneNumber;
};

export type AdvertisingLeadNotificationVoiceCallPhoneNumberUpdateFailure = {
  __typename?: 'AdvertisingLeadNotificationVoiceCallPhoneNumberUpdateFailure';
  error: Error;
};

export type AdvertisingLeadNotificationVoiceCallPhoneNumberUpdateInput = {
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
};

export type AdvertisingLeadNotificationVoiceCallPhoneNumberUpdatePayload =
  | AdvertisingLeadNotificationVoiceCallPhoneNumberUpdateSuccess
  | AdvertisingLeadNotificationVoiceCallPhoneNumberUpdateFailure;

export type AdvertisingLeadNotificationVoiceCallPhoneNumberUpdateSuccess = {
  __typename?: 'AdvertisingLeadNotificationVoiceCallPhoneNumberUpdateSuccess';
  phoneNumber: AdvertisingLeadNotificationVoiceCallPhoneNumber;
};

export type AdvertisingLeadNotificationsAddEmailAddressFailure = {
  __typename?: 'AdvertisingLeadNotificationsAddEmailAddressFailure';
  error: Error;
};

export type AdvertisingLeadNotificationsAddEmailAddressInput = {
  emailAddress: Scalars['String'];
};

export type AdvertisingLeadNotificationsAddEmailAddressPayload =
  | AdvertisingLeadNotificationsAddEmailAddressSuccess
  | AdvertisingLeadNotificationsAddEmailAddressFailure;

export type AdvertisingLeadNotificationsAddEmailAddressSuccess = {
  __typename?: 'AdvertisingLeadNotificationsAddEmailAddressSuccess';
  advertisingLeadNotificationMethods: AdvertisingLeadNotificationMethods;
};

export type AdvertisingLeadNotificationsAddSmsPhoneNumberFailure = {
  __typename?: 'AdvertisingLeadNotificationsAddSMSPhoneNumberFailure';
  error: Error;
};

export type AdvertisingLeadNotificationsAddSmsPhoneNumberInput = {
  phoneNumber: Scalars['String'];
};

export type AdvertisingLeadNotificationsAddSmsPhoneNumberPayload =
  | AdvertisingLeadNotificationsAddSmsPhoneNumberSuccess
  | AdvertisingLeadNotificationsAddSmsPhoneNumberFailure;

export type AdvertisingLeadNotificationsAddSmsPhoneNumberSuccess = {
  __typename?: 'AdvertisingLeadNotificationsAddSMSPhoneNumberSuccess';
  advertisingLeadNotificationMethods: AdvertisingLeadNotificationMethods;
};

export type AdvertisingLeadNotificationsAddVoiceCallPhoneNumberFailure = {
  __typename?: 'AdvertisingLeadNotificationsAddVoiceCallPhoneNumberFailure';
  error: Error;
};

export type AdvertisingLeadNotificationsAddVoiceCallPhoneNumberInput = {
  phoneNumber: Scalars['String'];
  phoneNumberExtension?: InputMaybe<Scalars['String']>;
};

export type AdvertisingLeadNotificationsAddVoiceCallPhoneNumberPayload =
  | AdvertisingLeadNotificationsAddVoiceCallPhoneNumberSuccess
  | AdvertisingLeadNotificationsAddVoiceCallPhoneNumberFailure;

export type AdvertisingLeadNotificationsAddVoiceCallPhoneNumberSuccess = {
  __typename?: 'AdvertisingLeadNotificationsAddVoiceCallPhoneNumberSuccess';
  advertisingLeadNotificationMethods: AdvertisingLeadNotificationMethods;
};

export type AdvertisingLeadNotificationsVerifyEmailAddressFailure = {
  __typename?: 'AdvertisingLeadNotificationsVerifyEmailAddressFailure';
  error: Error;
};

export type AdvertisingLeadNotificationsVerifyEmailAddressInput = {
  emailAddress: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type AdvertisingLeadNotificationsVerifyEmailAddressPayload =
  | AdvertisingLeadNotificationsVerifyEmailAddressSuccess
  | AdvertisingLeadNotificationsVerifyEmailAddressFailure;

export type AdvertisingLeadNotificationsVerifyEmailAddressSuccess = {
  __typename?: 'AdvertisingLeadNotificationsVerifyEmailAddressSuccess';
  advertisingLeadNotificationMethods: AdvertisingLeadNotificationMethods;
};

export type AdvertisingLeadNotificationsVerifySmsPhoneNumberFailure = {
  __typename?: 'AdvertisingLeadNotificationsVerifySMSPhoneNumberFailure';
  error: Error;
};

export type AdvertisingLeadNotificationsVerifySmsPhoneNumberInput = {
  phoneNumber: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type AdvertisingLeadNotificationsVerifySmsPhoneNumberPayload =
  | AdvertisingLeadNotificationsVerifySmsPhoneNumberSuccess
  | AdvertisingLeadNotificationsVerifySmsPhoneNumberFailure;

export type AdvertisingLeadNotificationsVerifySmsPhoneNumberSuccess = {
  __typename?: 'AdvertisingLeadNotificationsVerifySMSPhoneNumberSuccess';
  advertisingLeadNotificationMethods: AdvertisingLeadNotificationMethods;
};

export type AdvertisingLeadSource = LeadSource & {
  __typename?: 'AdvertisingLeadSource';
  id: Scalars['ID'];
  name: Scalars['String'];
  url?: Maybe<Scalars['URL']>;
  campaign: ProviderMarketingCampaign;
};

export type AdvertisingNotificationMethodVerifyFailure = {
  __typename?: 'AdvertisingNotificationMethodVerifyFailure';
  error: Error;
};

export type AdvertisingNotificationMethodVerifyInput = {
  id: Scalars['ID'];
  verificationCode: Scalars['String'];
};

export type AdvertisingNotificationMethodVerifyPayload =
  | AdvertisingNotificationMethodVerifySuccess
  | AdvertisingNotificationMethodVerifyFailure;

export type AdvertisingNotificationMethodVerifySuccess = {
  __typename?: 'AdvertisingNotificationMethodVerifySuccess';
  advertisingLeadNotificationMethods: AdvertisingLeadNotificationMethods;
};

export type AdvertisingOffer = {
  __typename?: 'AdvertisingOffer';
  id: Scalars['ID'];
  claimableUntil: DateTime;
  publicContact: AdvertisingPublicContact;
  patientPromotion?: Maybe<PatientPromotion>;
};

export type AdvertisingOfferActivateFailure = {
  __typename?: 'AdvertisingOfferActivateFailure';
  error: Error;
};

export type AdvertisingOfferActivateInput = {
  token: Scalars['String'];
};

export type AdvertisingOfferActivatePayload =
  | AdvertisingOfferActivateSuccess
  | AdvertisingOfferActivateFailure;

export type AdvertisingOfferActivateSuccess = {
  __typename?: 'AdvertisingOfferActivateSuccess';
  offer: AdvertisingOffer;
};

export type AdvertisingPatientListFileMetadata = {
  __typename?: 'AdvertisingPatientListFileMetadata';
  filename: Scalars['String'];
  filesizeInBytes: Scalars['Int'];
};

export type AdvertisingPerformanceInfo = {
  __typename?: 'AdvertisingPerformanceInfo';
  /** Total spent up to date */
  spendToDateInCents: Scalars['Int'];
  /** Number of clicks tracked. */
  clickCount: Scalars['Int'];
  /** Number of impression of the ad(s). */
  impressionCount: Scalars['Int'];
  /** Number of leads generated. */
  leadCount: Scalars['Int'];
  /** Number of patients getting a treatment after seeing the ad. */
  patientCount: Scalars['Int'];
};

export type AdvertisingPost = {
  __typename?: 'AdvertisingPost';
  id: Scalars['ID'];
  practice: Practice;
  audience: AdvertisingPostAudience;
  imageUrl: Scalars['URL'];
  name: Scalars['String'];
  /**
   * Ads are bound to an active offer. The purpose of this field is to reflect
   * the expiration time of the related offer.
   */
  offerExpiresAt: DateTime;
  status: Social_Media_Post_Status;
  startAt: DateTime;
  endAt?: Maybe<DateTime>;
  performance: AdvertisingPerformanceInfo;
  spendToDateInCents: Scalars['Int'];
  spendLimitInCents: Scalars['Int'];
  curatedPost?: Maybe<SocialMediaCuratedPost>;
  /** List of Leads under the advertisement */
  leads: AdvertisingPostLeadsConnection;
};

export type AdvertisingPostLeadsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** Advertising audience settings */
export type AdvertisingPostAudience = {
  __typename?: 'AdvertisingPostAudience';
  /** the originally uploaded file for patient emails */
  patientEmailsFileMetadata?: Maybe<AdvertisingPatientListFileMetadata>;
  ageRange: AdvertisingAgeRange;
  gender: Ad_Audience_Gender;
  radiusInMiles: Scalars['Int'];
};

export type AdvertisingPostLeadsConnection = {
  __typename?: 'AdvertisingPostLeadsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<Lead>;
};

export type AdvertisingPostsConnection = {
  __typename?: 'AdvertisingPostsConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<AdvertisingPostsEdge>;
};

export type AdvertisingPostsEdge = {
  __typename?: 'AdvertisingPostsEdge';
  node: AdvertisingPost;
};

export type AdvertisingProfile = {
  __typename?: 'AdvertisingProfile';
  id: Scalars['ID'];
  /** @deprecated use enrollmentStatus instead */
  isEnabled: Scalars['Boolean'];
  enrollmentStatus: Advertising_Profile_Enrollment_Status;
  advertisementManagementStatus: Advertisement_Management_Status;
  termsAcceptance?: Maybe<AdvertisingProfileTermsAcceptance>;
  campaigns: ProviderMarketingCampaignsConnection;
  contact?: Maybe<AdvertisingPublicContact>;
  /** Global Ads Performance. */
  performance: AdvertisingPerformanceInfo;
  /** List of ad posts. */
  posts: AdvertisingPostsConnection;
  wallet: AdvertisingWallet;
  advertisingLeadNotificationMethods: AdvertisingLeadNotificationMethods;
  emailCampaignsPerformance: ProviderEmailMarketingCampaignOverallPerformance;
};

export type AdvertisingProfileCampaignsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ProviderMarketingCampaignsFilter>;
  sort?: InputMaybe<Array<ProviderMarketingCampaignsSort>>;
};

export type AdvertisingProfilePerformanceArgs = {
  status?: InputMaybe<Campaign_Status_Filter>;
};

export type AdvertisingProfilePostsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type AdvertisingProfileAcceptTermsAndConditionsFailure = {
  __typename?: 'AdvertisingProfileAcceptTermsAndConditionsFailure';
  error: Error;
};

export type AdvertisingProfileAcceptTermsAndConditionsInput = {
  termsAndConditionsId: Scalars['ID'];
};

export type AdvertisingProfileAcceptTermsAndConditionsPayload =
  | AdvertisingProfileAcceptTermsAndConditionsSuccess
  | AdvertisingProfileAcceptTermsAndConditionsFailure;

export type AdvertisingProfileAcceptTermsAndConditionsSuccess = {
  __typename?: 'AdvertisingProfileAcceptTermsAndConditionsSuccess';
  advertisingProfile: AdvertisingProfile;
};

export type AdvertisingProfileSetPhoneNotificationMethodFailure = {
  __typename?: 'AdvertisingProfileSetPhoneNotificationMethodFailure';
  error: Error;
};

export type AdvertisingProfileSetPhoneNotificationMethodInput = {
  id: Scalars['ID'];
  phoneNumber: Scalars['String'];
  extension?: InputMaybe<Scalars['String']>;
  method: Advertising_Lead_Notification_Phone_Number_Verification_Method;
};

export type AdvertisingProfileSetPhoneNotificationMethodPayload =
  | AdvertisingProfileSetPhoneNotificationMethodSuccess
  | AdvertisingProfileSetPhoneNotificationMethodFailure;

export type AdvertisingProfileSetPhoneNotificationMethodSuccess = {
  __typename?: 'AdvertisingProfileSetPhoneNotificationMethodSuccess';
  advertisingLeadNotificationMethods: AdvertisingLeadNotificationMethods;
};

export type AdvertisingProfileTermsAcceptance = {
  __typename?: 'AdvertisingProfileTermsAcceptance';
  terms: AdvertisingProfileTermsAndConditions;
  acceptedAt: DateTime;
  actor: ActorV2;
};

export type AdvertisingProfileTermsAndConditions = {
  __typename?: 'AdvertisingProfileTermsAndConditions';
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type AdvertisingPublicContact = {
  __typename?: 'AdvertisingPublicContact';
  /** @deprecated Use displayName from public provider profile */
  displayName: Scalars['String'];
  /** @deprecated Use displayName from public provider profile */
  emailDisplayName: Scalars['String'];
  emailAddress?: Maybe<Scalars['String']>;
  /** @deprecated Use address from public provider profile */
  address: AdvertisingAddress;
  avatarUrl: Scalars['URL'];
  locationImage: Image;
  /** @deprecated Use phoneNumber from public provider profile */
  phoneNumber: Scalars['String'];
  /** @deprecated Use businessHours from public provider profile */
  officeHours?: Maybe<Array<AdvertisingBusinessHours>>;
  /** @deprecated Use AdvertisingProfile.advertisingLeadNotificationMethods */
  autoCallProfile?: Maybe<AdvertisingAutoCallProfile>;
  practice: Practice;
  /** @deprecated Use bookingUrl from public provider profile */
  bookingUrl?: Maybe<Scalars['URL']>;
};

export type AdvertisingWallet = {
  __typename?: 'AdvertisingWallet';
  availableCreditInCents: Scalars['Int'];
};

export type AllAllerganStorePricingTiersConnection = {
  __typename?: 'AllAllerganStorePricingTiersConnection';
  nodes: Array<AllerganStorePricingTier>;
};

export type AllPointPurchasablePromotionsConnection = {
  __typename?: 'AllPointPurchasablePromotionsConnection';
  claimLimitReached?: Maybe<Scalars['Boolean']>;
  edges: Array<AllPointPurchasablePromotionsEdge>;
};

export type AllPointPurchasablePromotionsEdge = {
  __typename?: 'AllPointPurchasablePromotionsEdge';
  node: PointPurchasablePromotion;
};

export type AllProviderMarketingCamapignPackagesTagsEdge = {
  __typename?: 'AllProviderMarketingCamapignPackagesTagsEdge';
  node: Tag;
  /** number of campaign packages under the tag(node) */
  quantity: Scalars['Int'];
};

export type AllProviderMarketingCampaignPackagesConnection = {
  __typename?: 'AllProviderMarketingCampaignPackagesConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<ProviderMarketingCampaignPackage>;
  allTagsEdges?: Maybe<Array<AllProviderMarketingCamapignPackagesTagsEdge>>;
};

export type AllProviderProfileLanguagesConnection = {
  __typename?: 'AllProviderProfileLanguagesConnection';
  nodes: Array<ProfileLanguage>;
};

export type AllProviderProfileSlugsConnection = {
  __typename?: 'AllProviderProfileSlugsConnection';
  nodes: Array<ProviderProfileSlug>;
  offsetPageInfo: OffsetPageInfo;
};

export type AllProviderStatesConnection = {
  __typename?: 'AllProviderStatesConnection';
  nodes: Array<ProviderSearchState>;
};

export type AllePayout = {
  __typename?: 'AllePayout';
  id: Scalars['ID'];
  amountInCents?: Maybe<Scalars['Int']>;
  destinationAccount?: Maybe<AllePayoutDestinationAccount>;
  summary?: Maybe<AllePayoutSummary>;
  details?: Maybe<AllePayoutDetailConnection>;
};

export type AllePayoutDetailsArgs = {
  filter?: InputMaybe<AllePayoutDetailFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type AllePayoutDestinationAccount = {
  __typename?: 'AllePayoutDestinationAccount';
  last4?: Maybe<Scalars['String']>;
};

export type AllePayoutDetail = {
  __typename?: 'AllePayoutDetail';
  id: Scalars['ID'];
  payout: AllePayout;
  grossAmountInCents?: Maybe<Scalars['Int']>;
  netAmountInCents: Scalars['Int'];
  feesAmountInCents?: Maybe<Scalars['Int']>;
  refundsAmountInCents?: Maybe<Scalars['Int']>;
  referenceNumber?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
};

export type AllePayoutDetailConnection = {
  __typename?: 'AllePayoutDetailConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<AllePayoutDetail>;
};

export type AllePayoutDetailFilter = {
  range?: InputMaybe<DateTimeRangeInput>;
};

export type AllePayoutSummary = {
  __typename?: 'AllePayoutSummary';
  id: Scalars['ID'];
  payout: AllePayout;
  payoutSlug: Scalars['String'];
  paidAt: DateTime;
  status: Alle_Payout_Summary_Status;
  grossAmountInCents?: Maybe<Scalars['Int']>;
  netAmountInCents: Scalars['Int'];
  refundsAmountInCents?: Maybe<Scalars['Int']>;
  feesAmountInCents?: Maybe<Scalars['Int']>;
};

export type AllerganCustomerPurchaseAccess = {
  __typename?: 'AllerganCustomerPurchaseAccess';
  license?: Maybe<Scalars['Boolean']>;
  training?: Maybe<Scalars['Boolean']>;
  certification?: Maybe<Scalars['Boolean']>;
};

export type AllerganProduct = {
  __typename?: 'AllerganProduct';
  id: Scalars['ID'];
  description: Scalars['String'];
  images: AllerganProductImagesConnection;
  name: Scalars['String'];
  customerPrice?: Maybe<Money>;
  price: Money;
  purchasableQuantity: AllerganProductPurchasableQuantity;
  weightInPounds: Scalars['Float'];
  sku: Scalars['String'];
  isiBrandShortCodeId?: Maybe<Scalars['String']>;
  isiBrandLongCodeId?: Maybe<Scalars['String']>;
  variationName?: Maybe<Scalars['String']>;
  customerPurchaseAccess?: Maybe<AllerganCustomerPurchaseAccess>;
  bundles?: Maybe<Array<AllerganStoreProductBundle>>;
};

export type AllerganProductCustomerPriceArgs = {
  providerOrganizationId: Scalars['ID'];
};

export type AllerganProductCustomerPurchaseAccessArgs = {
  providerOrganizationId: Scalars['ID'];
};

export type AllerganProductCost = {
  __typename?: 'AllerganProductCost';
  product: AllerganProduct;
  cost: AllerganStoreCost;
};

export type AllerganProductFacet = {
  __typename?: 'AllerganProductFacet';
  id: Scalars['ID'];
  name: Scalars['String'];
  values: Array<AllerganProductFacetValue>;
};

export type AllerganProductFacetValue = {
  __typename?: 'AllerganProductFacetValue';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AllerganProductImagesConnection = {
  __typename?: 'AllerganProductImagesConnection';
  nodes: Array<Image>;
};

export type AllerganProductOffering =
  | AllerganProduct
  | AllerganStoreProductBundle;

export type AllerganProductOfferingSearchFilter = {
  facetValueIds?: InputMaybe<Array<Scalars['ID']>>;
  keyword?: InputMaybe<Scalars['String']>;
};

export type AllerganProductOfferingSearchResultsConnection = {
  __typename?: 'AllerganProductOfferingSearchResultsConnection';
  allFacets: AllerganProductOfferingSearchResultsFacetsConnection;
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<AllerganProductOffering>;
};

export type AllerganProductOfferingSearchResultsFacetsConnection = {
  __typename?: 'AllerganProductOfferingSearchResultsFacetsConnection';
  nodes: Array<AllerganProductFacet>;
};

export type AllerganProductOfferingSearchSort = {
  column?: InputMaybe<Allergan_Product_Search_Offering_Results_Sort_Column>;
  order?: InputMaybe<Allergan_Product_Search_Offering_Results_Sort_Order>;
};

export type AllerganProductPurchasableQuantity = {
  __typename?: 'AllerganProductPurchasableQuantity';
  minimum: Scalars['Int'];
  maximum?: Maybe<Scalars['Int']>;
  step: Scalars['Int'];
};

export type AllerganProductSearchFilter = {
  facetValueIds?: InputMaybe<Array<Scalars['ID']>>;
  keyword?: InputMaybe<Scalars['String']>;
};

export type AllerganProductSearchResultsConnection = {
  __typename?: 'AllerganProductSearchResultsConnection';
  allFacets: AllerganProductSearchResultsFacetsConnection;
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<AllerganProduct>;
};

export type AllerganProductSearchResultsFacetsConnection = {
  __typename?: 'AllerganProductSearchResultsFacetsConnection';
  nodes: Array<AllerganProductFacet>;
};

export type AllerganProductSearchSort = {
  column?: InputMaybe<Allergan_Product_Search_Results_Sort_Column>;
  order?: InputMaybe<Allergan_Product_Search_Results_Sort_Order>;
};

export type AllerganStoreBalance = {
  __typename?: 'AllerganStoreBalance';
  current?: Maybe<Money>;
  month?: Maybe<Money>;
  prevMonth?: Maybe<Money>;
  older?: Maybe<Money>;
};

export type AllerganStoreBalanceDetails = {
  __typename?: 'AllerganStoreBalanceDetails';
  accountBalance?: Maybe<Money>;
  pastDueBalance?: Maybe<Money>;
  pastDueDebits?: Maybe<Money>;
  pastDueCredits?: Maybe<Money>;
  balance?: Maybe<AllerganStoreBalance>;
};

export type AllerganStoreBillMeLater = {
  __typename?: 'AllerganStoreBillMeLater';
  id: Scalars['ID'];
  termDescription?: Maybe<Scalars['String']>;
};

export type AllerganStoreCost = {
  __typename?: 'AllerganStoreCost';
  discount: Money;
  discountPercentage: Scalars['Float'];
  rebate: Money;
  rebatePercentage: Scalars['Float'];
  estimatedTax: Money;
  subtotal: Money;
  total: Money;
};

export type AllerganStoreCreditCard = {
  __typename?: 'AllerganStoreCreditCard';
  id: Scalars['ID'];
  cardHolderName: Scalars['String'];
  providerName: Scalars['String'];
  lastFour: Scalars['String'];
  expiresAt: Date;
};

export type AllerganStoreCreditCardAddFailure = {
  __typename?: 'AllerganStoreCreditCardAddFailure';
  error: Error;
};

export type AllerganStoreCreditCardAddInput = {
  setupIntentId: Scalars['ID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
};

export type AllerganStoreCreditCardAddPayload =
  | AllerganStoreCreditCardAddSuccess
  | AllerganStoreCreditCardAddFailure;

export type AllerganStoreCreditCardAddSuccess = {
  __typename?: 'AllerganStoreCreditCardAddSuccess';
  creditCard: AllerganStoreCreditCard;
};

export type AllerganStoreCreditCardDeleteFailure = {
  __typename?: 'AllerganStoreCreditCardDeleteFailure';
  error: Error;
};

export type AllerganStoreCreditCardDeleteInput = {
  id: Scalars['ID'];
};

export type AllerganStoreCreditCardDeletePayload =
  | AllerganStoreCreditCardDeleteSuccess
  | AllerganStoreCreditCardDeleteFailure;

export type AllerganStoreCreditCardDeleteSuccess = {
  __typename?: 'AllerganStoreCreditCardDeleteSuccess';
  id: Scalars['ID'];
};

export type AllerganStoreCreditCardFormCreateFailure = {
  __typename?: 'AllerganStoreCreditCardFormCreateFailure';
  error: Error;
};

export type AllerganStoreCreditCardFormCreateInput = {
  providerOrganizationId: Scalars['ID'];
};

export type AllerganStoreCreditCardFormCreatePayload =
  | AllerganStoreCreditCardFormCreateSuccess
  | AllerganStoreCreditCardFormCreateFailure;

export type AllerganStoreCreditCardFormCreateSuccess = {
  __typename?: 'AllerganStoreCreditCardFormCreateSuccess';
  setupIntentId: Scalars['ID'];
};

export type AllerganStoreCreditCardInput = {
  id: Scalars['ID'];
  providerName: Scalars['String'];
};

export type AllerganStoreCreditCardSetIsDefaultFailure = {
  __typename?: 'AllerganStoreCreditCardSetIsDefaultFailure';
  error: Error;
};

export type AllerganStoreCreditCardSetIsDefaultInput = {
  id: Scalars['ID'];
};

export type AllerganStoreCreditCardSetIsDefaultPayload =
  | AllerganStoreCreditCardSetIsDefaultSuccess
  | AllerganStoreCreditCardSetIsDefaultFailure;

export type AllerganStoreCreditCardSetIsDefaultSuccess = {
  __typename?: 'AllerganStoreCreditCardSetIsDefaultSuccess';
  providerOrganization: ProviderOrganization;
};

export type AllerganStoreCreditDetails = {
  __typename?: 'AllerganStoreCreditDetails';
  creditLimit: AllerganStoreCreditLimit;
  lastPayment?: Maybe<AllerganStoreLastPayment>;
};

export type AllerganStoreCreditLimit = {
  __typename?: 'AllerganStoreCreditLimit';
  totalCreditLimit?: Maybe<Money>;
  availableCredit?: Maybe<Money>;
};

export type AllerganStoreCreditTermsDetails = {
  __typename?: 'AllerganStoreCreditTermsDetails';
  creditDetails?: Maybe<AllerganStoreCreditDetails>;
  balanceDetails?: Maybe<AllerganStoreBalanceDetails>;
};

export type AllerganStoreInvoice = {
  __typename?: 'AllerganStoreInvoice';
  id: Scalars['ID'];
  accountingDocumentNumber: Scalars['Int'];
  status: Allergan_Store_Invoice_Status;
  purchaseOrderName: Scalars['String'];
  /** @deprecated Orders will no longer be bound to invoices. */
  order?: Maybe<AllerganStoreOrder>;
  dueAmount: Money;
  paymentAmount: Money;
  dueDate: Date;
};

export type AllerganStoreInvoiceGenerateReceiptFileFailure = {
  __typename?: 'AllerganStoreInvoiceGenerateReceiptFileFailure';
  error: Error;
};

export type AllerganStoreInvoiceGenerateReceiptFilePayload =
  | AllerganStoreInvoiceGenerateReceiptFileSuccess
  | AllerganStoreInvoiceGenerateReceiptFileFailure;

export type AllerganStoreInvoiceGenerateReceiptFileSuccess = {
  __typename?: 'AllerganStoreInvoiceGenerateReceiptFileSuccess';
  url: Scalars['String'];
};

export type AllerganStoreInvoiceMemo = {
  __typename?: 'AllerganStoreInvoiceMemo';
  id: Scalars['ID'];
  accountingDocumentNumber: Scalars['Int'];
  amount: Money;
  dueDate: Date;
  description: Scalars['String'];
};

export type AllerganStoreInvoiceMemoFilter = {
  dueDate?: InputMaybe<Scalars['DateInput']>;
  amount?: InputMaybe<MoneyInput>;
};

export type AllerganStoreInvoiceMemosSort = {
  column?: InputMaybe<Allergan_Store_Invoice_Memo_Column>;
  order?: InputMaybe<Allergan_Store_Invoice_Memo>;
};

export type AllerganStoreInvoicePay = {
  __typename?: 'AllerganStoreInvoicePay';
  id: Scalars['ID'];
  paymentAmount: Money;
  openAmount: Money;
  dueDate: Date;
};

export type AllerganStoreInvoicePaymentDataInput = {
  id: Scalars['ID'];
  paymentAmount: MoneyInput;
};

export type AllerganStoreInvoicesFilter = {
  status?: InputMaybe<Scalars['String']>;
};

export type AllerganStoreInvoicesPayFailure = {
  __typename?: 'AllerganStoreInvoicesPayFailure';
  error: Error;
};

export type AllerganStoreInvoicesPayInput = {
  providerOrganizationId: Scalars['ID'];
  creditCardPayment?: InputMaybe<AllerganStoreCreditCardInput>;
  invoices: Array<AllerganStoreInvoicePaymentDataInput>;
  creditMemoIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type AllerganStoreInvoicesPayPayload =
  | AllerganStoreInvoicesPaySuccess
  | AllerganStoreInvoicesPayFailure;

export type AllerganStoreInvoicesPaySuccess = {
  __typename?: 'AllerganStoreInvoicesPaySuccess';
  allerganStoreInvoices?: Maybe<Array<AllerganStoreInvoicePay>>;
};

export type AllerganStoreInvoicesSort = {
  column?: InputMaybe<Allergan_Store_Invoice_Column>;
  order?: InputMaybe<Allergan_Store_Invoice_Sort_Order>;
};

export type AllerganStoreLastPayment = {
  __typename?: 'AllerganStoreLastPayment';
  paymentAmount?: Maybe<Money>;
  paymentDate?: Maybe<Date>;
  paymentMethod?: Maybe<Scalars['String']>;
};

export type AllerganStoreOrder = {
  __typename?: 'AllerganStoreOrder';
  id: Scalars['ID'];
  providerOrganization: ProviderOrganization;
  createdBy: ActorV2;
  createdDate: Date;
  orderNumber?: Maybe<Scalars['String']>;
  cost: AllerganStoreOrderCost;
  paymentMethod: AllerganStoreOrderPaymentMethod;
  products: AllerganStoreOrderProductsConnection;
  shippingGroups: AllerganStoreOrderShippingGroupsConnection;
  purchaseOrderName?: Maybe<Scalars['String']>;
  status: Allergan_Store_Order_Status;
};

export type AllerganStoreOrderCost = {
  __typename?: 'AllerganStoreOrderCost';
  shipping: Money;
  discount: Money;
  discountPercentage: Scalars['Float'];
  rebate: Money;
  rebatePercentage: Scalars['Float'];
  estimatedTax: Money;
  subtotal: Money;
  total: Money;
};

export type AllerganStoreOrderCreateFailure = {
  __typename?: 'AllerganStoreOrderCreateFailure';
  error: Error;
};

export type AllerganStoreOrderCreateInput = {
  cartId: Scalars['ID'];
};

export type AllerganStoreOrderCreatePayload =
  | AllerganStoreOrderCreateSuccess
  | AllerganStoreOrderCreateFailure;

export type AllerganStoreOrderCreateSuccess = {
  __typename?: 'AllerganStoreOrderCreateSuccess';
  order: AllerganStoreOrder;
};

export type AllerganStoreOrderGenerateInvoiceFileFailure = {
  __typename?: 'AllerganStoreOrderGenerateInvoiceFileFailure';
  error: Error;
};

export type AllerganStoreOrderGenerateInvoiceFilePayload =
  | AllerganStoreOrderGenerateInvoiceFileSuccess
  | AllerganStoreOrderGenerateInvoiceFileFailure;

export type AllerganStoreOrderGenerateInvoiceFileSuccess = {
  __typename?: 'AllerganStoreOrderGenerateInvoiceFileSuccess';
  url: Scalars['String'];
};

export type AllerganStoreOrderPaymentMethod =
  | AllerganStoreCreditCard
  | AllerganStoreUnknownPaymentMethod
  | AllerganStoreBillMeLater;

export type AllerganStoreOrderProductsConnection = {
  __typename?: 'AllerganStoreOrderProductsConnection';
  edges: Array<AllerganStoreOrderProductsEdge>;
};

export type AllerganStoreOrderProductsEdge = {
  __typename?: 'AllerganStoreOrderProductsEdge';
  quantity: Scalars['Int'];
  totalCost: AllerganStoreCost;
  node: AllerganProduct;
};

export type AllerganStoreOrderShippingGroup = {
  __typename?: 'AllerganStoreOrderShippingGroup';
  id: Scalars['ID'];
  products: AllerganStoreShippingGroupProductsConnection;
  shippingMethod: AllerganStoreShippingMethod;
};

export type AllerganStoreOrderShippingGroupsConnection = {
  __typename?: 'AllerganStoreOrderShippingGroupsConnection';
  edges: Array<AllerganStoreOrderShippingGroupsEdge>;
};

export type AllerganStoreOrderShippingGroupsEdge = {
  __typename?: 'AllerganStoreOrderShippingGroupsEdge';
  status: Allergan_Store_Order_Shipping_Status;
  trackingNumber?: Maybe<Scalars['String']>;
  node: AllerganStoreOrderShippingGroup;
  isInvoiceGenerated?: Maybe<Scalars['Boolean']>;
  invoiceNumber?: Maybe<Scalars['String']>;
};

export type AllerganStoreOrderSimulate = {
  __typename?: 'AllerganStoreOrderSimulate';
  shoppingCart?: Maybe<AllerganStoreShoppingCart>;
  cost: AllerganStoreCost;
  newProducts: AllerganStoreOrderSimulateNewProductsConnection;
  allProducts: AllerganStoreOrderSimulateAllProductsConnection;
};

export type AllerganStoreOrderSimulateAllProductsConnection = {
  __typename?: 'AllerganStoreOrderSimulateAllProductsConnection';
  edges: Array<AllerganStoreOrderSimulateAllProductsEdge>;
};

export type AllerganStoreOrderSimulateAllProductsEdge = {
  __typename?: 'AllerganStoreOrderSimulateAllProductsEdge';
  quantity: Scalars['Int'];
  totalCost: AllerganStoreCost;
  node: AllerganProduct;
};

export type AllerganStoreOrderSimulateNewProductsConnection = {
  __typename?: 'AllerganStoreOrderSimulateNewProductsConnection';
  edges: Array<AllerganStoreOrderSimulateNewProductsEdge>;
};

export type AllerganStoreOrderSimulateNewProductsEdge = {
  __typename?: 'AllerganStoreOrderSimulateNewProductsEdge';
  quantity: Scalars['Int'];
  warehouseId: Scalars['ID'];
  stockAvailableDate: Date;
  totalCost: AllerganStoreCost;
  node: AllerganProduct;
};

export type AllerganStoreOrderSimulateProductInput = {
  id: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type AllerganStoreOrdersFilter = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type AllerganStoreOrdersSort = {
  column?: InputMaybe<Allergan_Store_Order_Column>;
  order?: InputMaybe<Allergan_Store_Order_Sort_Order>;
};

export type AllerganStorePriceAdjustment = {
  __typename?: 'AllerganStorePriceAdjustment';
  id: Scalars['ID'];
  type: Allergan_Store_Price_Adjustment_Type;
  value: Scalars['Float'];
};

export type AllerganStorePricingBenefit = {
  __typename?: 'AllerganStorePricingBenefit';
  id: Scalars['ID'];
  description: Scalars['String'];
};

export type AllerganStorePricingBenefitCategory = {
  __typename?: 'AllerganStorePricingBenefitCategory';
  id: Scalars['ID'];
  description: Scalars['String'];
  benefits: AllerganStorePricingBenefitCategoryBenefitsConnection;
};

export type AllerganStorePricingBenefitCategoryBenefitsConnection = {
  __typename?: 'AllerganStorePricingBenefitCategoryBenefitsConnection';
  nodes: Array<AllerganStorePricingBenefit>;
};

export type AllerganStorePricingInvite = {
  __typename?: 'AllerganStorePricingInvite';
  invitedAt: Date;
  wave: Scalars['String'];
};

export type AllerganStorePricingRetentionRate = {
  __typename?: 'AllerganStorePricingRetentionRate';
  isEligible: Scalars['Boolean'];
  retentionPercentage: Scalars['Float'];
};

export type AllerganStorePricingRetentionRateDetail = {
  __typename?: 'AllerganStorePricingRetentionRateDetail';
  isEligible: Scalars['Boolean'];
  actualRetentionNumberOfPatients: Scalars['Int'];
  lastRetentionNumberOfPatients: Scalars['Int'];
};

export type AllerganStorePricingRetentionSnapshot = {
  __typename?: 'AllerganStorePricingRetentionSnapshot';
  createdDate: Date;
  actual: AllerganStorePricingRetentionRate;
  projection: AllerganStorePricingRetentionRate;
};

export type AllerganStorePricingSalesVolumeFigure = {
  __typename?: 'AllerganStorePricingSalesVolumeFigure';
  isEligible: Scalars['Boolean'];
  amountInCents: Scalars['Int'];
};

export type AllerganStorePricingTermsAcceptance = {
  __typename?: 'AllerganStorePricingTermsAcceptance';
  termsAcceptedAt: Date;
  termsAcceptedVersion: Scalars['String'];
};

export type AllerganStorePricingTermsAndConditions = {
  __typename?: 'AllerganStorePricingTermsAndConditions';
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type AllerganStorePricingThreshold = {
  __typename?: 'AllerganStorePricingThreshold';
  id: Scalars['ID'];
  type: Scalars['String'];
  unit: Allergan_Store_Pricing_Threshold_Unit;
  value: Scalars['Float'];
};

export type AllerganStorePricingTier = {
  __typename?: 'AllerganStorePricingTier';
  id: Scalars['ID'];
  level: Scalars['String'];
  thresholds: AllerganStorePricingTierThresholdsConnection;
  discountPercentage: Scalars['Float'];
  benefitCategories: AllerganStorePricingTierBenefitCategoriesConnection;
};

export type AllerganStorePricingTierBenefitCategoriesConnection = {
  __typename?: 'AllerganStorePricingTierBenefitCategoriesConnection';
  nodes: Array<AllerganStorePricingBenefitCategory>;
};

export type AllerganStorePricingTierThresholdsConnection = {
  __typename?: 'AllerganStorePricingTierThresholdsConnection';
  nodes: Array<AllerganStorePricingThreshold>;
};

export type AllerganStorePricingTierUpgradeReason = {
  __typename?: 'AllerganStorePricingTierUpgradeReason';
  id: Scalars['ID'];
  type: Allergan_Store_Pricing_Tier_Upgrade_Reason_Type;
  threshold: AllerganStorePricingThreshold;
  volumeThreshold?: Maybe<AllerganStorePricingThreshold>;
  retentionThreshold?: Maybe<AllerganStorePricingThreshold>;
  upgradedAt: DateTime;
};

export type AllerganStorePricingVolumeSnapshot = {
  __typename?: 'AllerganStorePricingVolumeSnapshot';
  createdDate: Date;
  actual: AllerganStorePricingSalesVolumeFigure;
  projection: AllerganStorePricingSalesVolumeFigure;
};

export type AllerganStoreProductBundle = {
  __typename?: 'AllerganStoreProductBundle';
  id: Scalars['ID'];
  name: Scalars['String'];
  shortDescription?: Maybe<Scalars['String']>;
  fullDescription: Scalars['String'];
  images: AllerganStoreProductBundleImagesConnection;
  price: Money;
  customerPrice?: Maybe<Money>;
  purchasableQuantity: AllerganStoreProductBundlePurchasableQuantity;
  products: Array<AllerganProduct>;
  priceAdjustment: AllerganStorePriceAdjustment;
};

export type AllerganStoreProductBundleCustomerPriceArgs = {
  providerOrganizationId: Scalars['ID'];
};

export type AllerganStoreProductBundleImagesConnection = {
  __typename?: 'AllerganStoreProductBundleImagesConnection';
  nodes: Array<Image>;
};

export type AllerganStoreProductBundlePurchasableQuantity = {
  __typename?: 'AllerganStoreProductBundlePurchasableQuantity';
  minimum: Scalars['Int'];
  maximum?: Maybe<Scalars['Int']>;
  step: Scalars['Int'];
};

export type AllerganStoreSavings = {
  __typename?: 'AllerganStoreSavings';
  amountInCents: Scalars['Int'];
};

export type AllerganStoreShippingGroup = {
  __typename?: 'AllerganStoreShippingGroup';
  id: Scalars['ID'];
  products: AllerganStoreShippingGroupProductsConnection;
  shippingMethods: AllerganStoreShippingGroupShippingMethodsConnection;
};

export type AllerganStoreShippingGroupProductEdge = {
  __typename?: 'AllerganStoreShippingGroupProductEdge';
  quantity: Scalars['Int'];
  totalCost: AllerganStoreCost;
  node: AllerganProduct;
};

export type AllerganStoreShippingGroupProductsConnection = {
  __typename?: 'AllerganStoreShippingGroupProductsConnection';
  /** @deprecated use edges instead */
  nodes: Array<AllerganProduct>;
  edges: Array<AllerganStoreShippingGroupProductEdge>;
};

export type AllerganStoreShippingGroupShippingMethodEdge = {
  __typename?: 'AllerganStoreShippingGroupShippingMethodEdge';
  node: AllerganStoreShippingMethod;
  cost: AllerganStoreShoppingCartCost;
  isSelected: Scalars['Boolean'];
};

export type AllerganStoreShippingGroupShippingMethodsConnection = {
  __typename?: 'AllerganStoreShippingGroupShippingMethodsConnection';
  edges: Array<AllerganStoreShippingGroupShippingMethodEdge>;
};

export type AllerganStoreShippingMethod = {
  __typename?: 'AllerganStoreShippingMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Money;
  provider: AllerganStoreShippingProvider;
  estimatedDeliveryDate: Date;
  estimatedCutOffTime?: Maybe<Scalars['String']>;
};

export type AllerganStoreShippingProvider = {
  __typename?: 'AllerganStoreShippingProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AllerganStoreShoppingCart = {
  __typename?: 'AllerganStoreShoppingCart';
  id: Scalars['ID'];
  providerOrganization: ProviderOrganization;
  cost: AllerganStoreShoppingCartCost;
  shippingTotalCost: AllerganStoreShoppingCartCost;
  products: AllerganStoreShoppingCartProductsConnection;
  shippingGroups: AllerganStoreShoppingCartShippingGroupsConnection;
  /** @deprecated Use payment method instead */
  creditCard?: Maybe<AllerganStoreCreditCard>;
  paymentMethod?: Maybe<AllerganStoreShoppingCartPaymentMethod>;
  purchaseOrderName?: Maybe<Scalars['String']>;
  processingStatus: Allergan_Store_Shopping_Cart_Processing_Status;
};

export type AllerganStoreShoppingCartAddProductFailure = {
  __typename?: 'AllerganStoreShoppingCartAddProductFailure';
  error: Error;
};

export type AllerganStoreShoppingCartAddProductInput = {
  id: Scalars['ID'];
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type AllerganStoreShoppingCartAddProductPayload =
  | AllerganStoreShoppingCartAddProductSuccess
  | AllerganStoreShoppingCartAddProductFailure;

export type AllerganStoreShoppingCartAddProductSuccess = {
  __typename?: 'AllerganStoreShoppingCartAddProductSuccess';
  cart: AllerganStoreShoppingCart;
};

export type AllerganStoreShoppingCartAddProductsFromOrderFailure = {
  __typename?: 'AllerganStoreShoppingCartAddProductsFromOrderFailure';
  error: Error;
};

export type AllerganStoreShoppingCartAddProductsFromOrderInput = {
  orderId: Scalars['ID'];
  cartId?: InputMaybe<Scalars['ID']>;
};

export type AllerganStoreShoppingCartAddProductsFromOrderPayload =
  | AllerganStoreShoppingCartAddProductsFromOrderSuccess
  | AllerganStoreShoppingCartAddProductsFromOrderFailure;

export type AllerganStoreShoppingCartAddProductsFromOrderSuccess = {
  __typename?: 'AllerganStoreShoppingCartAddProductsFromOrderSuccess';
  cart: AllerganStoreShoppingCart;
};

export type AllerganStoreShoppingCartCost = {
  __typename?: 'AllerganStoreShoppingCartCost';
  discount: Money;
  discountPercentage: Scalars['Float'];
  rebate: Money;
  rebatePercentage: Scalars['Float'];
  estimatedTax: Money;
  subtotal: Money;
  total: Money;
};

export type AllerganStoreShoppingCartCreateFailure = {
  __typename?: 'AllerganStoreShoppingCartCreateFailure';
  error: Error;
};

export type AllerganStoreShoppingCartCreateInput = {
  providerOrganizationId: Scalars['ID'];
  providerUserId: Scalars['ID'];
};

export type AllerganStoreShoppingCartCreatePayload =
  | AllerganStoreShoppingCartCreateSuccess
  | AllerganStoreShoppingCartCreateFailure;

export type AllerganStoreShoppingCartCreateSuccess = {
  __typename?: 'AllerganStoreShoppingCartCreateSuccess';
  cart: AllerganStoreShoppingCart;
};

export type AllerganStoreShoppingCartDeleteFailure = {
  __typename?: 'AllerganStoreShoppingCartDeleteFailure';
  error: Error;
};

export type AllerganStoreShoppingCartDeletePayload =
  | AllerganStoreShoppingCartDeleteSuccess
  | AllerganStoreShoppingCartDeleteFailure;

export type AllerganStoreShoppingCartDeleteSuccess = {
  __typename?: 'AllerganStoreShoppingCartDeleteSuccess';
  id: Scalars['ID'];
};

export type AllerganStoreShoppingCartPaymentMethod =
  | AllerganStoreCreditCard
  | AllerganStoreBillMeLater;

export type AllerganStoreShoppingCartProductsConnection = {
  __typename?: 'AllerganStoreShoppingCartProductsConnection';
  edges?: Maybe<Array<Maybe<AllerganStoreShoppingCartProductsEdge>>>;
};

export type AllerganStoreShoppingCartProductsEdge = {
  __typename?: 'AllerganStoreShoppingCartProductsEdge';
  node: AllerganProduct;
  cost: AllerganStoreShoppingCartCost;
  quantity: Scalars['Int'];
};

export type AllerganStoreShoppingCartReplaceProductsFromOrderFailure = {
  __typename?: 'AllerganStoreShoppingCartReplaceProductsFromOrderFailure';
  error: Error;
};

export type AllerganStoreShoppingCartReplaceProductsFromOrderInput = {
  orderId: Scalars['ID'];
  cartId?: InputMaybe<Scalars['ID']>;
};

export type AllerganStoreShoppingCartReplaceProductsFromOrderPayload =
  | AllerganStoreShoppingCartReplaceProductsFromOrderSuccess
  | AllerganStoreShoppingCartReplaceProductsFromOrderFailure;

export type AllerganStoreShoppingCartReplaceProductsFromOrderSuccess = {
  __typename?: 'AllerganStoreShoppingCartReplaceProductsFromOrderSuccess';
  cart: AllerganStoreShoppingCart;
};

export type AllerganStoreShoppingCartSetPaymentMethodToBillMeLaterFailure = {
  __typename?: 'AllerganStoreShoppingCartSetPaymentMethodToBillMeLaterFailure';
  error: Error;
};

export type AllerganStoreShoppingCartSetPaymentMethodToBillMeLaterPayload =
  | AllerganStoreShoppingCartSetPaymentMethodToBillMeLaterSuccess
  | AllerganStoreShoppingCartSetPaymentMethodToBillMeLaterFailure;

export type AllerganStoreShoppingCartSetPaymentMethodToBillMeLaterSuccess = {
  __typename?: 'AllerganStoreShoppingCartSetPaymentMethodToBillMeLaterSuccess';
  cart: AllerganStoreShoppingCart;
};

export type AllerganStoreShoppingCartShippingGroupsConnection = {
  __typename?: 'AllerganStoreShoppingCartShippingGroupsConnection';
  nodes: Array<AllerganStoreShippingGroup>;
};

export type AllerganStoreShoppingCartUpdateCreditCardFailure = {
  __typename?: 'AllerganStoreShoppingCartUpdateCreditCardFailure';
  error: Error;
};

export type AllerganStoreShoppingCartUpdateCreditCardInput = {
  id: Scalars['ID'];
  creditCardId: Scalars['ID'];
};

export type AllerganStoreShoppingCartUpdateCreditCardPayload =
  | AllerganStoreShoppingCartUpdateCreditCardSuccess
  | AllerganStoreShoppingCartUpdateCreditCardFailure;

export type AllerganStoreShoppingCartUpdateCreditCardSuccess = {
  __typename?: 'AllerganStoreShoppingCartUpdateCreditCardSuccess';
  cart: AllerganStoreShoppingCart;
};

export type AllerganStoreShoppingCartUpdateProductQuantityFailure = {
  __typename?: 'AllerganStoreShoppingCartUpdateProductQuantityFailure';
  error: Error;
};

export type AllerganStoreShoppingCartUpdateProductQuantityInput = {
  id: Scalars['ID'];
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type AllerganStoreShoppingCartUpdateProductQuantityPayload =
  | AllerganStoreShoppingCartUpdateProductQuantitySuccess
  | AllerganStoreShoppingCartUpdateProductQuantityFailure;

export type AllerganStoreShoppingCartUpdateProductQuantitySuccess = {
  __typename?: 'AllerganStoreShoppingCartUpdateProductQuantitySuccess';
  cart: AllerganStoreShoppingCart;
};

export type AllerganStoreShoppingCartUpdatePurchaseOrderNameFailure = {
  __typename?: 'AllerganStoreShoppingCartUpdatePurchaseOrderNameFailure';
  error: Error;
};

export type AllerganStoreShoppingCartUpdatePurchaseOrderNameInput = {
  id: Scalars['ID'];
  purchaseOrderName: Scalars['String'];
};

export type AllerganStoreShoppingCartUpdatePurchaseOrderNamePayload =
  | AllerganStoreShoppingCartUpdatePurchaseOrderNameSuccess
  | AllerganStoreShoppingCartUpdatePurchaseOrderNameFailure;

export type AllerganStoreShoppingCartUpdatePurchaseOrderNameSuccess = {
  __typename?: 'AllerganStoreShoppingCartUpdatePurchaseOrderNameSuccess';
  cart: AllerganStoreShoppingCart;
};

export type AllerganStoreShoppingCartUpdateShippingMethodFailure = {
  __typename?: 'AllerganStoreShoppingCartUpdateShippingMethodFailure';
  error: Error;
};

export type AllerganStoreShoppingCartUpdateShippingMethodInput = {
  id: Scalars['ID'];
  shippingGroupId: Scalars['ID'];
  shippingMethodId: Scalars['ID'];
};

export type AllerganStoreShoppingCartUpdateShippingMethodPayload =
  | AllerganStoreShoppingCartUpdateShippingMethodSuccess
  | AllerganStoreShoppingCartUpdateShippingMethodFailure;

export type AllerganStoreShoppingCartUpdateShippingMethodSuccess = {
  __typename?: 'AllerganStoreShoppingCartUpdateShippingMethodSuccess';
  cart: AllerganStoreShoppingCart;
};

export type AllerganStoreTermsAndConditions = {
  __typename?: 'AllerganStoreTermsAndConditions';
  id: Scalars['ID'];
  version: Scalars['String'];
};

export type AllerganStoreUnknownPaymentMethod = {
  __typename?: 'AllerganStoreUnknownPaymentMethod';
  id: Scalars['ID'];
};

export type AppreciationOffer = {
  __typename?: 'AppreciationOffer';
  id: Scalars['ID'];
  metadataOptions?: Maybe<AppreciationOfferMetadataOptions>;
  metadataType?: Maybe<AppreciationOfferType>;
  name: Scalars['String'];
  valueRange: NumberRange;
};

export type AppreciationOfferCaseNumber = {
  __typename?: 'AppreciationOfferCaseNumber';
  label?: Maybe<Scalars['String']>;
};

export type AppreciationOfferDatePicker = {
  __typename?: 'AppreciationOfferDatePicker';
  label?: Maybe<Scalars['String']>;
};

export type AppreciationOfferFreeformNumber = {
  __typename?: 'AppreciationOfferFreeformNumber';
  label?: Maybe<Scalars['String']>;
};

export type AppreciationOfferFreeformText = {
  __typename?: 'AppreciationOfferFreeformText';
  label?: Maybe<Scalars['String']>;
};

export type AppreciationOfferMetadataOptions = {
  __typename?: 'AppreciationOfferMetadataOptions';
  caseNumber?: Maybe<AppreciationOfferCaseNumber>;
  datePicker?: Maybe<AppreciationOfferDatePicker>;
  freeformText?: Maybe<AppreciationOfferFreeformText>;
  freeformNumber?: Maybe<AppreciationOfferFreeformNumber>;
  selector?: Maybe<AppreciationOfferSelector>;
};

export type AppreciationOfferSelector = {
  __typename?: 'AppreciationOfferSelector';
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<AppreciationOfferSelectorOption>>>;
};

export type AppreciationOfferSelectorOption = {
  __typename?: 'AppreciationOfferSelectorOption';
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueRange?: Maybe<NumberRange>;
  freeformText?: Maybe<AppreciationOfferFreeformText>;
};

export enum AppreciationOfferType {
  Date = 'DATE',
  Select = 'SELECT',
  String = 'STRING',
}

export type AtLeastOneBrandRedemptionRule = {
  __typename?: 'AtLeastOneBrandRedemptionRule';
  brandIds: Array<Scalars['ID']>;
};

export type AtLeastOneProductRedemptionRule = {
  __typename?: 'AtLeastOneProductRedemptionRule';
  productIds: Array<Scalars['ID']>;
};

export type AuditEvent = {
  __typename?: 'AuditEvent';
  actor?: Maybe<AuditEventActor>;
  source?: Maybe<AuditEventTrailSource>;
  action?: Maybe<Scalars['String']>;
  status?: Maybe<Audit_Event_Status>;
  target?: Maybe<AuditEventTarget>;
  timestamp?: Maybe<DateTime>;
  verion?: Maybe<Scalars['String']>;
};

export type AuditEventActor = {
  __typename?: 'AuditEventActor';
  id: Scalars['String'];
  type: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type AuditEventConnection = {
  __typename?: 'AuditEventConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<AuditEvent>;
};

export type AuditEventDateRangeInput = {
  start?: InputMaybe<Scalars['DateInput']>;
  end?: InputMaybe<Scalars['DateInput']>;
};

export type AuditEventFilter = {
  auditEventActorTypes?: InputMaybe<Array<Audit_Event_Actor_Type>>;
  action?: InputMaybe<Scalars['String']>;
  auditEventTargetTypes?: InputMaybe<Array<Scalars['String']>>;
  date?: InputMaybe<AuditEventDateRangeInput>;
  traceIds?: InputMaybe<Array<Scalars['String']>>;
};

export type AuditEventSort = {
  field: Audit_Event_Sort_Column;
  order: Audit_Event_Sort_Order;
};

export type AuditEventTarget = {
  __typename?: 'AuditEventTarget';
  id: Scalars['String'];
  type: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type AuditEventTrailSource = {
  __typename?: 'AuditEventTrailSource';
  userAgent?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  traceId?: Maybe<Scalars['String']>;
};

export enum Bank_Account_Type {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
}

export enum Bank_Information_Source {
  Alle = 'ALLE',
  BrilliantDistinctions = 'BRILLIANT_DISTINCTIONS',
  Dwolla = 'DWOLLA',
  None = 'NONE',
}

export type BdActivitiesConnection = {
  __typename?: 'BDActivitiesConnection';
  migrationActivity?: Maybe<BdMigrationActivity>;
};

export type BdMigrationActivity = {
  __typename?: 'BDMigrationActivity';
  id: Scalars['ID'];
  createdAt?: Maybe<Date>;
  migrationDetails?: Maybe<BdMigrationDetails>;
};

export type BdMigrationDetails = {
  __typename?: 'BDMigrationDetails';
  bdPoints: Scalars['Int'];
  bdCouponDollars: Scalars['Int'];
  bdCouponPoints: Scalars['Int'];
  trackerDollars: Scalars['Int'];
  trackerPoints: Scalars['Int'];
  hasOYLOffer?: Maybe<Scalars['Boolean']>;
};

export enum Billing_Cycle {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY',
}

export type BaseCatalog = {
  __typename?: 'BaseCatalog';
  id: Scalars['ID'];
  items?: Maybe<BaseCatalogItemsConnection>;
  /** a list of all possible unit types that can be assigned to a catalog item */
  unitTypes: BaseCatalogItemUnitTypesConnection;
};

export type BaseCatalogBrand = {
  __typename?: 'BaseCatalogBrand';
  id: Scalars['ID'];
  name: Scalars['String'];
  nameTransactional: Scalars['String'];
  namePromotional: Scalars['String'];
  displayName: Scalars['String'];
};

export type BaseCatalogCategory = {
  __typename?: 'BaseCatalogCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BaseCatalogItem = {
  __typename?: 'BaseCatalogItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  brand?: Maybe<BaseCatalogBrand>;
  category?: Maybe<BaseCatalogCategory>;
  manufacturer?: Maybe<BaseCatalogManufacturer>;
};

export type BaseCatalogItemUnitType = {
  __typename?: 'BaseCatalogItemUnitType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BaseCatalogItemUnitTypesConnection = {
  __typename?: 'BaseCatalogItemUnitTypesConnection';
  nodes: Array<BaseCatalogItemUnitType>;
};

export type BaseCatalogItemsConnection = {
  __typename?: 'BaseCatalogItemsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<BaseCatalogItem>;
};

export type BaseCatalogManufacturer = {
  __typename?: 'BaseCatalogManufacturer';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BecomeReferralEligibility = {
  __typename?: 'BecomeReferralEligibility';
  isEligible: Scalars['Boolean'];
  reason?: Maybe<Scalars['String']>;
};

export type Birthday = {
  __typename?: 'Birthday';
  day: Scalars['Int'];
  month: Month;
};

export type BirthdayInput = {
  day: Scalars['Int'];
  month: Month;
};

export type BotoxFiftyOffPromotionReport = {
  __typename?: 'BotoxFiftyOffPromotionReport';
  rowsConnection: BotoxFiftyOffPromotionReportRowsConnection;
};

export type BotoxFiftyOffPromotionReportCreateInput = {
  sort: Array<BotoxFiftyOffPromotionReportSort>;
};

export type BotoxFiftyOffPromotionReportPageInput = {
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort: Array<BotoxFiftyOffPromotionReportSort>;
};

export type BotoxFiftyOffPromotionReportRow = {
  __typename?: 'BotoxFiftyOffPromotionReportRow';
  consumerId: Scalars['ID'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastBotoxTransactionAt?: Maybe<DateTime>;
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  pointTotal: Scalars['Int'];
  pointValueInCents: Scalars['Int'];
  promotionName: Scalars['String'];
  promotionRedeemedAt?: Maybe<DateTime>;
  promotionType: Scalars['String'];
  selectedStaffMemberName?: Maybe<Scalars['String']>;
};

export type BotoxFiftyOffPromotionReportRowsConnection = {
  __typename?: 'BotoxFiftyOffPromotionReportRowsConnection';
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  nodes: Array<BotoxFiftyOffPromotionReportRow>;
};

export type BotoxFiftyOffPromotionReportSort = {
  column?: BotoxFiftyOffPromotionReportSortColumn;
  order?: ReportSortOrder;
};

export enum BotoxFiftyOffPromotionReportSortColumn {
  FirstName = 'FIRST_NAME',
  LastBotoxTransactionAt = 'LAST_BOTOX_TRANSACTION_AT',
  LastName = 'LAST_NAME',
  PointTotal = 'POINT_TOTAL',
  PointValueInCents = 'POINT_VALUE_IN_CENTS',
}

export type BotoxJuvedermLastTransaction = {
  __typename?: 'BotoxJuvedermLastTransaction';
  consumerId: Scalars['ID'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastBotoxTransactionDate?: Maybe<DateTime>;
  lastJuvedermTransactionDate?: Maybe<DateTime>;
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type BotoxJuvedermLastTransactionConnection = {
  __typename?: 'BotoxJuvedermLastTransactionConnection';
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  nodes: Array<BotoxJuvedermLastTransaction>;
};

export type BotoxJuvedermLastTransactionReport = {
  __typename?: 'BotoxJuvedermLastTransactionReport';
  botoxJuvedermLastTransactionReportRows: BotoxJuvedermLastTransactionConnection;
};

export type BotoxJuvedermLastTransactionReportCreateInput = {
  range?: InputMaybe<DateRangeInput>;
  sort: Array<BotoxJuvedermLastTransactionReportSort>;
  reportExportFormat?: InputMaybe<Report_Export_Format>;
};

export type BotoxJuvedermLastTransactionReportSort = {
  column?: BotoxJuvedermLastTransactionSortColumn;
  order?: ReportSortOrder;
};

export type BotoxJuvedermLastTransactionSimplePageInput = {
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort: Array<BotoxJuvedermLastTransactionReportSort>;
};

export enum BotoxJuvedermLastTransactionSortColumn {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  LastBotoxTransactionDate = 'LAST_BOTOX_TRANSACTION_DATE',
  LastJuvedermTransactionDate = 'LAST_JUVEDERM_TRANSACTION_DATE',
}

export type BoundingBoxGeoPointsInput = {
  topRight: GeoPointInput;
  bottomLeft: GeoPointInput;
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  isAllerganBrand: Scalars['Boolean'];
  name: Scalars['String'];
  products?: Maybe<Array<Maybe<Product>>>;
  isi?: Maybe<BrandIsi>;
};

export type BrandIsi = {
  __typename?: 'BrandISI';
  id: Scalars['ID'];
  name: Scalars['String'];
  isBoxedWarning: Scalars['Boolean'];
  prescribingInformation?: Maybe<IsiPrescribingInformation>;
  medicationGuideUrl?: Maybe<Scalars['String']>;
  /** @deprecated Use consumerContent and providerContent */
  content?: Maybe<IsiContent>;
  consumerContent?: Maybe<CmsLegalContent>;
  providerContent?: Maybe<CmsLegalContent>;
};

export type BusinessOnboardByInvitationFailure = {
  __typename?: 'BusinessOnboardByInvitationFailure';
  error: Error;
};

export type BusinessOnboardByInvitationInput = {
  id: Scalars['ID'];
  token: Scalars['String'];
  termsAccepted: Scalars['Boolean'];
  termsAndConditionsVersion?: InputMaybe<Scalars['String']>;
  idpAccessToken: Scalars['String'];
};

export type BusinessOnboardByInvitationPayload =
  | BusinessOnboardByInvitationSuccess
  | BusinessOnboardByInvitationFailure;

export type BusinessOnboardByInvitationSuccess = {
  __typename?: 'BusinessOnboardByInvitationSuccess';
  providerOrganization: ProviderOrganization;
};

export enum Campaign_Status_Filter {
  Active = 'ACTIVE',
  All = 'ALL',
}

export enum Catalog_Item_Status {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Deleted = 'DELETED',
}

export enum Checkout_Cart_Order_Status {
  Submitted = 'SUBMITTED',
  Processing = 'PROCESSING',
  Processed = 'PROCESSED',
  Failed = 'FAILED',
}

export enum Checkout_Cart_Payment_Status {
  RequiresPayment = 'REQUIRES_PAYMENT',
  Paid = 'PAID',
}

export enum Checkout_Cart_Rewards_Applicability_Status {
  InCart = 'IN_CART',
  Applicable = 'APPLICABLE',
  NotApplicable = 'NOT_APPLICABLE',
}

export enum Checkout_Cart_Terminal_Reader_Payment_Purchase_Intent_Status {
  Processing = 'PROCESSING',
  PreAuthorized = 'PRE_AUTHORIZED',
  Cancelled = 'CANCELLED',
  Captured = 'CAPTURED',
  Refunded = 'REFUNDED',
  Failed = 'FAILED',
}

export enum Checkout_Credit_Reward_Status {
  NotRedeemed = 'NOT_REDEEMED',
  Redeemed = 'REDEEMED',
  Unredeemed = 'UNREDEEMED',
}

export enum Checkout_Discount_Reward_Status {
  NotRedeemed = 'NOT_REDEEMED',
  Redeemed = 'REDEEMED',
  Unredeemed = 'UNREDEEMED',
}

export enum Checkout_Order_Notification_Type {
  PurchaseEmailReceipt = 'PURCHASE_EMAIL_RECEIPT',
  RefundEmailReceipt = 'REFUND_EMAIL_RECEIPT',
}

export enum Checkout_Order_Payment_Line_Item_Card_Brand {
  Amex = 'AMEX',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Mastercard = 'MASTERCARD',
  Visa = 'VISA',
  Unknown = 'UNKNOWN',
}

export enum Checkout_Order_Payment_Line_Item_Status {
  PreAuthorized = 'PRE_AUTHORIZED',
  Cancelled = 'CANCELLED',
  Captured = 'CAPTURED',
  Refunded = 'REFUNDED',
  Failed = 'FAILED',
}

export enum Checkout_Order_Payment_Purchase_Intent_Type {
  TerminalReader = 'TERMINAL_READER',
}

export enum Checkout_Order_Payment_Status {
  PreAuthorized = 'PRE_AUTHORIZED',
  PartiallyPaid = 'PARTIALLY_PAID',
  Paid = 'PAID',
  Refunded = 'REFUNDED',
}

export enum Checkout_Order_Refund_Status {
  Processing = 'PROCESSING',
  Refunded = 'REFUNDED',
  Failed = 'FAILED',
}

export enum Checkout_Order_Revision_Event {
  Submitted = 'SUBMITTED',
  PaymentCaptured = 'PAYMENT_CAPTURED',
}

export enum Checkout_Order_Submission_Status {
  Submitted = 'SUBMITTED',
  Processing = 'PROCESSING',
  Processed = 'PROCESSED',
  Failed = 'FAILED',
}

export enum Checkout_Order_Transaction_Card_Payment_Details_Card_Brand {
  Amex = 'AMEX',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Mastercard = 'MASTERCARD',
  Visa = 'VISA',
  Unknown = 'UNKNOWN',
}

export enum Checkout_Order_Transaction_Status {
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export enum Checkout_Order_Transaction_Type {
  Authorization = 'AUTHORIZATION',
  Capture = 'CAPTURE',
  Refund = 'REFUND',
}

export enum Checkout_Sales_Report_Export_Format {
  Csv = 'CSV',
}

export enum Checkout_Sales_Report_Status {
  Pending = 'PENDING',
  Ready = 'READY',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
}

export enum Checkout_Sales_Report_Type {
  SalesSummary = 'SALES_SUMMARY',
  SalesItemized = 'SALES_ITEMIZED',
}

export enum Check_In_Reward_Status {
  AlreadyClaimed = 'ALREADY_CLAIMED',
  AlreadyRedeemed = 'ALREADY_REDEEMED',
  Claimed = 'CLAIMED',
}

export type CmsArticle = {
  __typename?: 'CMSArticle';
  content: CmsArticleContent;
  meta: CmsMetadata;
};

export type CmsArticleBodySection =
  | CmsArticleChecklistComparisonSection
  | CmsArticleHeroSection
  | CmsContentSection
  | CmsFootnoteSection
  | CmsImageSection
  | CmsJoinBannerSection
  | CmsListSection
  | CmsVideoSection
  | FactSheetContent
  | QuestionSheetSectionContentFaq;

export type CmsArticleCardContent = {
  __typename?: 'CMSArticleCardContent';
  image: CmsImage;
  title: CmsContent;
  breadcrumb: Scalars['String'];
  description?: Maybe<CmsContent>;
  imageIsVideoThumbnail: Scalars['Boolean'];
};

export type CmsArticleChecklist = {
  __typename?: 'CMSArticleChecklist';
  title: CmsContent;
  items: Array<CmsContent>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type CmsArticleChecklistComparisonSection = {
  __typename?: 'CMSArticleChecklistComparisonSection';
  primaryChecklist: CmsArticleChecklist;
  secondaryChecklist: CmsArticleChecklist;
};

export type CmsArticleContent = CmsArticleContentLongForm;

export type CmsArticleContentLongForm = {
  __typename?: 'CMSArticleContentLongForm';
  card: CmsArticleCardContent;
  body: Array<CmsArticleBodySection>;
  /** @deprecated Use isis instead */
  legal: CmsLegalContent;
  isis?: Maybe<CmsArticleIsIsConnection>;
};

export type CmsArticleHeroSection = {
  __typename?: 'CMSArticleHeroSection';
  breadcrumb: Scalars['String'];
  image: CmsImage;
  video?: Maybe<CmsVideo>;
  title: CmsContent;
};

export type CmsArticleIsIsConnection = {
  __typename?: 'CMSArticleISIsConnection';
  nodes: Array<CmsLegalContent>;
};

export type CmsArticlesConnection = {
  __typename?: 'CMSArticlesConnection';
  articles: Array<CmsArticle>;
  totalCount: Scalars['Int'];
};

export type CmsContent = {
  __typename?: 'CMSContent';
  format: Cms_Content_Format;
  source: Scalars['String'];
  render: Scalars['String'];
};

export type CmsContentRenderArgs = {
  format?: InputMaybe<Cms_Content_Format>;
};

export type CmsContentSection = {
  __typename?: 'CMSContentSection';
  content: CmsContent;
};

export type CmsFactContent = {
  __typename?: 'CMSFactContent';
  label: Scalars['String'];
  description: Scalars['String'];
};

export type CmsFeaturedItem = CmsArticle;

export type CmsFeaturedItemsConnection = {
  __typename?: 'CMSFeaturedItemsConnection';
  featuredItems: Array<CmsFeaturedItem>;
  totalCount: Scalars['Int'];
};

export type CmsFootnoteSection = {
  __typename?: 'CMSFootnoteSection';
  content: CmsContent;
};

export type CmsImage = {
  __typename?: 'CMSImage';
  src: Scalars['URL'];
  alt: Scalars['String'];
  /** An image title appears on web when hovering over the image */
  title?: Maybe<Scalars['String']>;
  /** A disclaimer will be shown on the bottom right corner of the image */
  disclaimer?: Maybe<Scalars['String']>;
};

export type CmsImageSection = {
  __typename?: 'CMSImageSection';
  image: CmsImage;
};

export type CmsJoinBannerSection = {
  __typename?: 'CMSJoinBannerSection';
  bannerTitle: CmsContent;
  message: CmsContent;
};

export type CmsLegalContent = {
  __typename?: 'CMSLegalContent';
  slug?: Maybe<Scalars['String']>;
  shortTitle: CmsContent;
  title: CmsContent;
  main: CmsContent;
  globalISIFilters?: Maybe<Array<Scalars['String']>>;
};

export type CmsLink = {
  __typename?: 'CMSLink';
  text: Scalars['String'];
  target: Scalars['String'];
};

export type CmsListItem = {
  __typename?: 'CMSListItem';
  label: Scalars['String'];
  items?: Maybe<Array<Scalars['String']>>;
};

export type CmsListSection = {
  __typename?: 'CMSListSection';
  list: Array<CmsListItem>;
  /** @deprecated Use list instead */
  content: Array<CmsListItem>;
};

export type CmsMetadata = {
  __typename?: 'CMSMetadata';
  /**
   * The slug will be used as an identifier for fetching a single Treatment Guide or Article.
   * For Treatment Guides, this will always be the product covered
   */
  slug: Scalars['String'];
  segmentMetadata: SegmentMetadata;
  seoMetadata: SeoMetadata;
};

export type CmsVideo = {
  __typename?: 'CMSVideo';
  /**
   * The "poster" is the image that has been chosen to show while the video is loading
   * See also "thumbnail"
   */
  poster?: Maybe<CmsImage>;
  src: Scalars['URL'];
  /**
   * The "thumbnail" is the image that should be shown in most situations
   * See also "poster"
   */
  thumbnail?: Maybe<CmsImage>;
};

export type CmsVideoSection = {
  __typename?: 'CMSVideoSection';
  video: CmsVideo;
};

export enum Cms_Content_Format {
  Markdown = 'MARKDOWN',
  Html = 'HTML',
  Plaintext = 'PLAINTEXT',
}

export enum Cms_Content_Type {
  TreatmentGuide = 'TREATMENT_GUIDE',
  Article = 'ARTICLE',
}

export enum Communication_Channel {
  Email = 'EMAIL',
  Sms = 'SMS',
}

export enum Communication_Method {
  MarketingEmail = 'MARKETING_EMAIL',
  MarketingPush = 'MARKETING_PUSH',
  MarketingSms = 'MARKETING_SMS',
  EarningEmail = 'EARNING_EMAIL',
  EarningPush = 'EARNING_PUSH',
  EarningSms = 'EARNING_SMS',
}

export enum Connect_Account_Redirect_Url_Type {
  Onboarding = 'ONBOARDING',
  AccountUpdate = 'ACCOUNT_UPDATE',
}

export enum Consumer_Refer_A_Friend_Profile_Limits_Reset_Cadence {
  Yearly = 'YEARLY',
  Quarterly = 'QUARTERLY',
  Monthly = 'MONTHLY',
}

export enum Consumer_Tier {
  AList = 'A_LIST',
  Member = 'MEMBER',
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export type CatalogCategoriesConnection = {
  __typename?: 'CatalogCategoriesConnection';
  nodes: Array<CatalogCategory>;
};

export type CatalogCategory = LocationCatalogCategory | BaseCatalogCategory;

export type ChangeRequestApproveError = {
  __typename?: 'ChangeRequestApproveError';
  error: Error;
};

export type ChangeRequestApproveInput = {
  id: Scalars['ID'];
};

export type ChangeRequestApprovePayload =
  | ChangeRequestApproveSuccess
  | ChangeRequestApproveError;

export type ChangeRequestApproveSuccess = {
  __typename?: 'ChangeRequestApproveSuccess';
  changeRequest: MarketingOfferChangeRequest;
};

export type ChangeRequestDeclineError = {
  __typename?: 'ChangeRequestDeclineError';
  error: Error;
};

export type ChangeRequestDeclineInput = {
  id: Scalars['ID'];
};

export type ChangeRequestDeclinePayload =
  | ChangeRequestDeclineSuccess
  | ChangeRequestDeclineError;

export type ChangeRequestDeclineSuccess = {
  __typename?: 'ChangeRequestDeclineSuccess';
  changeRequest: MarketingOfferChangeRequest;
};

export type ChangeRequestDeleteError = {
  __typename?: 'ChangeRequestDeleteError';
  error?: Maybe<Error>;
};

export type ChangeRequestDeleteInput = {
  id: Scalars['ID'];
};

export type ChangeRequestDeletePayload =
  | ChangeRequestDeleteSuccess
  | ChangeRequestDeleteError;

export type ChangeRequestDeleteSuccess = {
  __typename?: 'ChangeRequestDeleteSuccess';
  id: Scalars['ID'];
};

export type ChangeRequestIncreaseInventoryAllocation =
  MarketingOfferChangeRequest & {
    __typename?: 'ChangeRequestIncreaseInventoryAllocation';
    id: Scalars['ID'];
    state: Marketing_Offer_Change_Request_State;
    publishedAt?: Maybe<DateTime>;
    createdAt: DateTime;
    createdBy: Actor;
    previousInventoryAllocation: Scalars['Int'];
    additionalInventoryAllocation?: Maybe<Scalars['Int']>;
    updateToUnlimitedInventoryAllocation?: Maybe<Scalars['Boolean']>;
  };

export type ChangeRequestIncreaseInventoryAllocationUpdateError = {
  __typename?: 'ChangeRequestIncreaseInventoryAllocationUpdateError';
  error?: Maybe<Error>;
};

export type ChangeRequestIncreaseInventoryAllocationUpdateInput = {
  id: Scalars['ID'];
  additionalInventoryAllocation?: InputMaybe<Scalars['Int']>;
  /**
   * When updating to unlimited inventory, set this boolean to true and leave the allocation int null.
   * If adding a fixed inventory, this boolean should be null or false.
   */
  updateToUnlimitedInventoryAllocation?: InputMaybe<Scalars['Boolean']>;
};

export type ChangeRequestIncreaseInventoryAllocationUpdatePayload =
  | ChangeRequestIncreaseInventoryAllocationUpdateSuccess
  | ChangeRequestIncreaseInventoryAllocationUpdateError;

export type ChangeRequestIncreaseInventoryAllocationUpdateSuccess = {
  __typename?: 'ChangeRequestIncreaseInventoryAllocationUpdateSuccess';
  changeRequest: ChangeRequestIncreaseInventoryAllocation;
};

export type ChangeRequestModifyEndDate = MarketingOfferChangeRequest & {
  __typename?: 'ChangeRequestModifyEndDate';
  id: Scalars['ID'];
  state: Marketing_Offer_Change_Request_State;
  publishedAt?: Maybe<DateTime>;
  createdAt: DateTime;
  createdBy: Actor;
  previousEndDate?: Maybe<DateTime>;
  previousEndDateDisplayTimezone?: Maybe<Marketing_Offer_Timezone>;
  newEndDate?: Maybe<DateTime>;
  newEndDateDisplayTimezone?: Maybe<Marketing_Offer_Timezone>;
};

export type ChangeRequestModifyEndDateUpdateError = {
  __typename?: 'ChangeRequestModifyEndDateUpdateError';
  error?: Maybe<Error>;
};

export type ChangeRequestModifyEndDateUpdateInput = {
  id: Scalars['ID'];
  newEndDate?: InputMaybe<Scalars['DateTimeInput']>;
  newEndDateDisplayTimezone?: InputMaybe<Marketing_Offer_Timezone>;
};

export type ChangeRequestModifyEndDateUpdatePayload =
  | ChangeRequestModifyEndDateUpdateSuccess
  | ChangeRequestModifyEndDateUpdateError;

export type ChangeRequestModifyEndDateUpdateSuccess = {
  __typename?: 'ChangeRequestModifyEndDateUpdateSuccess';
  changeRequest?: Maybe<ChangeRequestModifyEndDate>;
};

export type CheckoutCart = {
  __typename?: 'CheckoutCart';
  id: Scalars['ID'];
  patient?: Maybe<Patient>;
  /** Location-type Provider Organization */
  location: ProviderOrganization;
  expireAt: DateTime;
  createdAt: DateTime;
  updatedAt: DateTime;
  alleSavings?: Maybe<Money>;
  totalQuantity: Scalars['Int'];
  balance: CheckoutCartBalance;
  cost: CheckoutCartCost;
  lineItems?: Maybe<CheckoutCartLineItemsConnection>;
  order?: Maybe<CheckoutCartOrder>;
  /** @deprecated Field no longer supported */
  paymentLineItems?: Maybe<CheckoutCartPaymentLineItemsConnection>;
  payments?: Maybe<CheckoutCartPayments>;
  rewards?: Maybe<CheckoutCartRewards>;
  rewardsSummary?: Maybe<CheckoutCartRewardsSummary>;
};

export type CheckoutCartAddLineItemFailure = {
  __typename?: 'CheckoutCartAddLineItemFailure';
  error: Error;
};

export type CheckoutCartAddLineItemInput = {
  id: Scalars['ID'];
  lineItem: CheckoutCartLineItemInput;
};

export type CheckoutCartAddLineItemPayload =
  | CheckoutCartAddLineItemSuccess
  | CheckoutCartAddLineItemFailure;

export type CheckoutCartAddLineItemSuccess = {
  __typename?: 'CheckoutCartAddLineItemSuccess';
  cart: CheckoutCart;
};

export type CheckoutCartBalance = {
  __typename?: 'CheckoutCartBalance';
  total: Money;
  credit: Money;
  payment: Money;
  remaining: Money;
};

export type CheckoutCartClearLineItemPriceAdjustmentFailure = {
  __typename?: 'CheckoutCartClearLineItemPriceAdjustmentFailure';
  error: Error;
};

export type CheckoutCartClearLineItemPriceAdjustmentInput = {
  id: Scalars['ID'];
  lineItemId: Scalars['ID'];
};

export type CheckoutCartClearLineItemPriceAdjustmentPayload =
  | CheckoutCartClearLineItemPriceAdjustmentSuccess
  | CheckoutCartClearLineItemPriceAdjustmentFailure;

export type CheckoutCartClearLineItemPriceAdjustmentSuccess = {
  __typename?: 'CheckoutCartClearLineItemPriceAdjustmentSuccess';
  cart: CheckoutCart;
};

export type CheckoutCartClearLineItemStaffMemberFailure = {
  __typename?: 'CheckoutCartClearLineItemStaffMemberFailure';
  error: Error;
};

export type CheckoutCartClearLineItemStaffMemberInput = {
  id: Scalars['ID'];
  lineItemId: Scalars['ID'];
};

export type CheckoutCartClearLineItemStaffMemberPayload =
  | CheckoutCartClearLineItemStaffMemberSuccess
  | CheckoutCartClearLineItemStaffMemberFailure;

export type CheckoutCartClearLineItemStaffMemberSuccess = {
  __typename?: 'CheckoutCartClearLineItemStaffMemberSuccess';
  cart: CheckoutCart;
};

export type CheckoutCartCost = {
  __typename?: 'CheckoutCartCost';
  subtotal: Money;
  tax: Money;
  discount: Money;
  total: Money;
};

export type CheckoutCartCreateFailure = {
  __typename?: 'CheckoutCartCreateFailure';
  error: Error;
};

export type CheckoutCartCreateInput = {
  lineItems: Array<CheckoutCartLineItemInput>;
  catalogId: Scalars['ID'];
  patientId?: InputMaybe<Scalars['ID']>;
};

export type CheckoutCartCreatePayload =
  | CheckoutCartCreateSuccess
  | CheckoutCartCreateFailure;

export type CheckoutCartCreateSuccess = {
  __typename?: 'CheckoutCartCreateSuccess';
  cart: CheckoutCart;
};

export type CheckoutCartCreateTerminalReaderPaymentLineItemFailure = {
  __typename?: 'CheckoutCartCreateTerminalReaderPaymentLineItemFailure';
  error: Error;
};

export type CheckoutCartCreateTerminalReaderPaymentLineItemInput = {
  id: Scalars['ID'];
};

export type CheckoutCartCreateTerminalReaderPaymentLineItemPayload =
  | CheckoutCartCreateTerminalReaderPaymentLineItemSuccess
  | CheckoutCartCreateTerminalReaderPaymentLineItemFailure;

export type CheckoutCartCreateTerminalReaderPaymentLineItemSuccess = {
  __typename?: 'CheckoutCartCreateTerminalReaderPaymentLineItemSuccess';
  cart: CheckoutCart;
};

export type CheckoutCartDeleteFailure = {
  __typename?: 'CheckoutCartDeleteFailure';
  error: Error;
};

export type CheckoutCartDeleteInput = {
  id: Scalars['ID'];
};

export type CheckoutCartDeletePayload =
  | CheckoutCartDeleteSuccess
  | CheckoutCartDeleteFailure;

export type CheckoutCartDeleteSuccess = {
  __typename?: 'CheckoutCartDeleteSuccess';
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
};

export type CheckoutCartLineItem = {
  __typename?: 'CheckoutCartLineItem';
  id: Scalars['ID'];
  catalogItem: LocationCatalogItem;
  quantity: Scalars['Int'];
  cost: CheckoutCartLineItemCost;
  priceAdjustment?: Maybe<CheckoutCartLineItemPriceAdjustment>;
  staffMember?: Maybe<PracticeStaffMember>;
};

export type CheckoutCartLineItemClearPriceAdjustmentInput = {
  id: Scalars['ID'];
};

export type CheckoutCartLineItemCost = {
  __typename?: 'CheckoutCartLineItemCost';
  /** The price per individual unit of the catalog item associated to the line item */
  unitPrice: Money;
  /** subtotal = unitPrice * line item quantity */
  subtotal: Money;
  /** The value of discounts applied to the line item */
  discount: Money;
  /** The adjusted price is set when a line item's price has been modified by Price Adjustment, superseding the subtotal. */
  adjustedPrice?: Maybe<Money>;
  /** tax = (subtotal or adjustedPrice) * catalog item tax rate */
  tax: Money;
  /** total = (subtotal or adjustedPrice) - discount + tax */
  total: Money;
};

export type CheckoutCartLineItemInput = {
  catalogItemId: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Int']>;
  practiceStaffMemberId?: InputMaybe<Scalars['ID']>;
};

export type CheckoutCartLineItemPriceAdjustment =
  | CheckoutCartLineItemPriceAdjustmentPriceOverride
  | CheckoutCartLineItemPriceAdjustmentDiscount;

export type CheckoutCartLineItemPriceAdjustmentDiscount = {
  __typename?: 'CheckoutCartLineItemPriceAdjustmentDiscount';
  discountType: Discount_Type;
  discountValue: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
};

export type CheckoutCartLineItemPriceAdjustmentPriceOverride = {
  __typename?: 'CheckoutCartLineItemPriceAdjustmentPriceOverride';
  override: Money;
  notes?: Maybe<Scalars['String']>;
};

export type CheckoutCartLineItemSetPriceAdjustmentDiscountInput = {
  id: Scalars['ID'];
  discountType: Discount_Type;
  discountValue: Scalars['Int'];
  notes: Scalars['String'];
};

export type CheckoutCartLineItemSetPriceAdjustmentPriceOverrideInput = {
  id: Scalars['ID'];
  override: MoneyInput;
  notes: Scalars['String'];
};

export type CheckoutCartLineItemUpdateInput = {
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  practiceStaffMemberId?: InputMaybe<Scalars['ID']>;
};

export type CheckoutCartLineItemsConnection = {
  __typename?: 'CheckoutCartLineItemsConnection';
  nodes: Array<CheckoutCartLineItem>;
};

export type CheckoutCartOrder = {
  __typename?: 'CheckoutCartOrder';
  id: Scalars['ID'];
  confirmationNumber: Scalars['String'];
  status: Checkout_Cart_Order_Status;
};

export type CheckoutCartPatientRewards = {
  __typename?: 'CheckoutCartPatientRewards';
  maxApplicablePoints: Scalars['Int'];
  giftCards?: Maybe<CheckoutCartPatientRewardsGiftCardsConnection>;
  patientPromotions?: Maybe<CheckoutCartPatientRewardsPromotionsConnection>;
};

export type CheckoutCartPatientRewardsGiftCard = {
  __typename?: 'CheckoutCartPatientRewardsGiftCard';
  giftCard: GiftCard;
  status: Checkout_Cart_Rewards_Applicability_Status;
};

export type CheckoutCartPatientRewardsGiftCardsConnection = {
  __typename?: 'CheckoutCartPatientRewardsGiftCardsConnection';
  nodes: Array<CheckoutCartPatientRewardsGiftCard>;
};

export type CheckoutCartPatientRewardsPatientPromotion = {
  __typename?: 'CheckoutCartPatientRewardsPatientPromotion';
  patientPromotion: PatientPromotion;
  status: Checkout_Cart_Rewards_Applicability_Status;
};

export type CheckoutCartPatientRewardsPromotionsConnection = {
  __typename?: 'CheckoutCartPatientRewardsPromotionsConnection';
  nodes: Array<CheckoutCartPatientRewardsPatientPromotion>;
};

export type CheckoutCartPaymentLineItem =
  CheckoutCartTerminalReaderPaymentLineItem;

export type CheckoutCartPaymentLineItemsConnection = {
  __typename?: 'CheckoutCartPaymentLineItemsConnection';
  nodes: Array<CheckoutCartPaymentLineItem>;
};

export type CheckoutCartPayments = {
  __typename?: 'CheckoutCartPayments';
  status?: Maybe<Checkout_Cart_Payment_Status>;
  lineItems?: Maybe<CheckoutCartPaymentLineItemsConnection>;
};

export type CheckoutCartRemoveLineItemFailure = {
  __typename?: 'CheckoutCartRemoveLineItemFailure';
  error: Error;
};

export type CheckoutCartRemoveLineItemInput = {
  id: Scalars['ID'];
  lineItemId: Scalars['ID'];
};

export type CheckoutCartRemoveLineItemPayload =
  | CheckoutCartRemoveLineItemSuccess
  | CheckoutCartRemoveLineItemFailure;

export type CheckoutCartRemoveLineItemSuccess = {
  __typename?: 'CheckoutCartRemoveLineItemSuccess';
  cart: CheckoutCart;
};

export type CheckoutCartRemoveRewardsLineItemFailure = {
  __typename?: 'CheckoutCartRemoveRewardsLineItemFailure';
  error: Error;
};

export type CheckoutCartRemoveRewardsLineItemInput = {
  id: Scalars['ID'];
  rewardsInventoryId: Scalars['ID'];
};

export type CheckoutCartRemoveRewardsLineItemPayload =
  | CheckoutCartRemoveRewardsLineItemSuccess
  | CheckoutCartRemoveRewardsLineItemFailure;

export type CheckoutCartRemoveRewardsLineItemSuccess = {
  __typename?: 'CheckoutCartRemoveRewardsLineItemSuccess';
  cart: CheckoutCart;
};

export type CheckoutCartRewards = {
  __typename?: 'CheckoutCartRewards';
  lineItems?: Maybe<CheckoutCartRewardsLineItemsConnection>;
  officePromotions?: Maybe<CheckoutCartRewardsOfficePromotionsConnection>;
  pointsApplied?: Maybe<Scalars['Int']>;
};

export type CheckoutCartRewardsLineItem = {
  __typename?: 'CheckoutCartRewardsLineItem';
  id: Scalars['ID'];
  rewardsInventoryId?: Maybe<Scalars['String']>;
  catalogItemId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  total: Money;
  paymentType: Rewards_Payment_Type;
  inventoryType: Rewards_Inventory_Type;
};

export type CheckoutCartRewardsLineItemsConnection = {
  __typename?: 'CheckoutCartRewardsLineItemsConnection';
  nodes: Array<CheckoutCartRewardsLineItem>;
};

export type CheckoutCartRewardsOfficePromotion = {
  __typename?: 'CheckoutCartRewardsOfficePromotion';
  officePromotion: OfficePromotion;
  status: Checkout_Cart_Rewards_Applicability_Status;
};

export type CheckoutCartRewardsOfficePromotionsConnection = {
  __typename?: 'CheckoutCartRewardsOfficePromotionsConnection';
  nodes: Array<CheckoutCartRewardsOfficePromotion>;
};

export type CheckoutCartRewardsSummary = {
  __typename?: 'CheckoutCartRewardsSummary';
  estimatedPointsEarned: Scalars['Int'];
  warnings?: Maybe<CheckoutCartRewardsSummaryWarningsConnection>;
  awards?: Maybe<CheckoutCartRewardsSummaryAwardsConnection>;
};

export type CheckoutCartRewardsSummaryAwardsConnection = {
  __typename?: 'CheckoutCartRewardsSummaryAwardsConnection';
  pointAwards?: Maybe<Array<PatientTransactionPointAward>>;
};

export type CheckoutCartRewardsSummaryWarningsConnection = {
  __typename?: 'CheckoutCartRewardsSummaryWarningsConnection';
  productErrors?: Maybe<Array<PatientTransactionProductError>>;
  pointsCapErrors?: Maybe<Array<PointsCapError>>;
  totalPointsWithheldByUniversalPointsCapPerTransaction?: Maybe<Scalars['Int']>;
};

export type CheckoutCartSetLineItemPriceAdjustmentDiscountFailure = {
  __typename?: 'CheckoutCartSetLineItemPriceAdjustmentDiscountFailure';
  error: Error;
};

export type CheckoutCartSetLineItemPriceAdjustmentDiscountPayload =
  | CheckoutCartSetLineItemPriceAdjustmentDiscountSuccess
  | CheckoutCartSetLineItemPriceAdjustmentDiscountFailure;

export type CheckoutCartSetLineItemPriceAdjustmentDiscountSuccess = {
  __typename?: 'CheckoutCartSetLineItemPriceAdjustmentDiscountSuccess';
  cart: CheckoutCart;
};

export type CheckoutCartSetLineItemPriceAdjustmentPriceOverrideFailure = {
  __typename?: 'CheckoutCartSetLineItemPriceAdjustmentPriceOverrideFailure';
  error: Error;
};

export type CheckoutCartSetLineItemPriceAdjustmentPriceOverridePayload =
  | CheckoutCartSetLineItemPriceAdjustmentPriceOverrideSuccess
  | CheckoutCartSetLineItemPriceAdjustmentPriceOverrideFailure;

export type CheckoutCartSetLineItemPriceAdjustmentPriceOverrideSuccess = {
  __typename?: 'CheckoutCartSetLineItemPriceAdjustmentPriceOverrideSuccess';
  cart: CheckoutCart;
};

export type CheckoutCartSetLineItemStaffMemberFailure = {
  __typename?: 'CheckoutCartSetLineItemStaffMemberFailure';
  error: Error;
};

export type CheckoutCartSetLineItemStaffMemberInput = {
  id: Scalars['ID'];
  lineItemId: Scalars['ID'];
  practiceStaffMemberId: Scalars['ID'];
};

export type CheckoutCartSetLineItemStaffMemberPayload =
  | CheckoutCartSetLineItemStaffMemberSuccess
  | CheckoutCartSetLineItemStaffMemberFailure;

export type CheckoutCartSetLineItemStaffMemberSuccess = {
  __typename?: 'CheckoutCartSetLineItemStaffMemberSuccess';
  cart: CheckoutCart;
};

export type CheckoutCartSetLineItemsPriceAdjustmentDiscountInput = {
  id: Scalars['ID'];
  lineItem: CheckoutCartLineItemSetPriceAdjustmentDiscountInput;
};

export type CheckoutCartSetLineItemsPriceAdjustmentPriceOverrideInput = {
  id: Scalars['ID'];
  lineItem: CheckoutCartLineItemSetPriceAdjustmentPriceOverrideInput;
};

export type CheckoutCartSubmitOrderFailure = {
  __typename?: 'CheckoutCartSubmitOrderFailure';
  error: Error;
};

export type CheckoutCartSubmitOrderInput = {
  id: Scalars['ID'];
};

export type CheckoutCartSubmitOrderPayload =
  | CheckoutCartSubmitOrderSuccess
  | CheckoutCartSubmitOrderFailure;

export type CheckoutCartSubmitOrderSuccess = {
  __typename?: 'CheckoutCartSubmitOrderSuccess';
  cart: CheckoutCart;
};

export type CheckoutCartTerminalReaderPaymentLineItem = {
  __typename?: 'CheckoutCartTerminalReaderPaymentLineItem';
  id: Scalars['ID'];
  status: Checkout_Cart_Terminal_Reader_Payment_Purchase_Intent_Status;
};

export type CheckoutCartUpdateLineItemFailure = {
  __typename?: 'CheckoutCartUpdateLineItemFailure';
  error: Error;
};

export type CheckoutCartUpdateLineItemInput = {
  id: Scalars['ID'];
  lineItem: CheckoutCartLineItemUpdateInput;
};

export type CheckoutCartUpdateLineItemPayload =
  | CheckoutCartUpdateLineItemSuccess
  | CheckoutCartUpdateLineItemFailure;

export type CheckoutCartUpdateLineItemSuccess = {
  __typename?: 'CheckoutCartUpdateLineItemSuccess';
  cart: CheckoutCart;
};

export type CheckoutCartUpdatePatientFailure = {
  __typename?: 'CheckoutCartUpdatePatientFailure';
  error: Error;
};

export type CheckoutCartUpdatePatientInput = {
  id: Scalars['ID'];
  patientId: Scalars['ID'];
};

export type CheckoutCartUpdatePatientPayload =
  | CheckoutCartUpdatePatientSuccess
  | CheckoutCartUpdatePatientFailure;

export type CheckoutCartUpdatePatientSuccess = {
  __typename?: 'CheckoutCartUpdatePatientSuccess';
  cart: CheckoutCart;
};

export type CheckoutCartUpdateRewardsFailure = {
  __typename?: 'CheckoutCartUpdateRewardsFailure';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type CheckoutCartUpdateRewardsInput = {
  id: Scalars['ID'];
  points?: InputMaybe<Scalars['Int']>;
  giftCardId?: InputMaybe<Scalars['String']>;
  promotionInventoryId?: InputMaybe<Scalars['String']>;
};

export type CheckoutCartUpdateRewardsPayload =
  | CheckoutCartUpdateRewardsSuccess
  | CheckoutCartUpdateRewardsFailure;

export type CheckoutCartUpdateRewardsSuccess = {
  __typename?: 'CheckoutCartUpdateRewardsSuccess';
  cart: CheckoutCart;
};

export type CheckoutCreditDiscountConnection = {
  __typename?: 'CheckoutCreditDiscountConnection';
  nodes: Array<CheckoutDiscountReward>;
};

export type CheckoutCreditReward = {
  __typename?: 'CheckoutCreditReward';
  id: Scalars['ID'];
  status: Checkout_Credit_Reward_Status;
  value: Money;
  description?: Maybe<Scalars['String']>;
};

export type CheckoutCreditRewardConnection = {
  __typename?: 'CheckoutCreditRewardConnection';
  nodes: Array<CheckoutCreditReward>;
};

export type CheckoutDiscountReward = {
  __typename?: 'CheckoutDiscountReward';
  id: Scalars['ID'];
  status: Checkout_Discount_Reward_Status;
  value: Money;
  description?: Maybe<Scalars['String']>;
};

export type CheckoutOrder = {
  __typename?: 'CheckoutOrder';
  id: Scalars['ID'];
  location: ProviderOrganization;
  confirmationNumber: Scalars['String'];
  createdAt: DateTime;
  createdBy: ActorV2;
  updatedAt: DateTime;
  alleSavings?: Maybe<Money>;
  patient?: Maybe<Patient>;
  cost: CheckoutOrderCost;
  balance: CheckoutOrderBalance;
  payments: CheckoutOrderPayments;
  canRefund: Scalars['Boolean'];
  refunds?: Maybe<CheckoutOrderRefundsConnection>;
  lineItems?: Maybe<CheckoutOrderLineItemsConnection>;
  revisions?: Maybe<CheckoutOrderRevisionsConnection>;
  notifications?: Maybe<CheckoutOrderNotificationsConnection>;
  transactions?: Maybe<CheckoutOrderTransactionsConnection>;
  creditRewards?: Maybe<CheckoutCreditRewardConnection>;
  discountRewards?: Maybe<CheckoutCreditDiscountConnection>;
  pointsEarned: Scalars['Int'];
  patientTransaction?: Maybe<PatientTransaction>;
  submissionStatus: Checkout_Order_Submission_Status;
  canRerun: Scalars['Boolean'];
};

export type CheckoutOrderBalance = {
  __typename?: 'CheckoutOrderBalance';
  total: Money;
  credit: Money;
  paymentPending: Money;
  paymentCharged: Money;
  remaining: Money;
};

export type CheckoutOrderCost = {
  __typename?: 'CheckoutOrderCost';
  subtotal: Money;
  tax: Money;
  discount: Money;
  total: Money;
};

export type CheckoutOrderCreateRefundFailure = {
  __typename?: 'CheckoutOrderCreateRefundFailure';
  error: Error;
};

export type CheckoutOrderCreateRefundInput = {
  id: Scalars['ID'];
  reasons: Array<Refund_Reason>;
};

export type CheckoutOrderCreateRefundPayload =
  | CheckoutOrderCreateRefundSuccess
  | CheckoutOrderCreateRefundFailure;

export type CheckoutOrderCreateRefundSuccess = {
  __typename?: 'CheckoutOrderCreateRefundSuccess';
  order: CheckoutOrder;
  refund: CheckoutOrderRefund;
};

export type CheckoutOrderLineItem = {
  __typename?: 'CheckoutOrderLineItem';
  id: Scalars['ID'];
  catalogItem: LocationCatalogItem;
  quantity: Scalars['Int'];
  cost: CheckoutOrderLineItemCost;
  priceAdjustment?: Maybe<CheckoutOrderLineItemPriceAdjustment>;
  staffMember?: Maybe<PracticeStaffMember>;
};

export type CheckoutOrderLineItemCost = {
  __typename?: 'CheckoutOrderLineItemCost';
  /** The price per individual unit of the catalog item associated to the line item */
  unitPrice: Money;
  /** subtotal = unitPrice * line item quantity */
  subtotal: Money;
  /** The value of discounts applied to the line item */
  discount: Money;
  /** The adjusted price is set when a line item's price has been modified by Price Adjustment, superseding the subtotal. */
  adjustedPrice?: Maybe<Money>;
  /** tax = (subtotal or adjustedPrice) * catalog item tax rate */
  tax: Money;
  /** total = (subtotal or adjustedPrice) - discount + tax */
  total: Money;
};

export type CheckoutOrderLineItemPriceAdjustment =
  | CheckoutOrderLineItemPriceAdjustmentPriceOverride
  | CheckoutOrderLineItemPriceAdjustmentDiscount;

export type CheckoutOrderLineItemPriceAdjustmentDiscount = {
  __typename?: 'CheckoutOrderLineItemPriceAdjustmentDiscount';
  discountType: Discount_Type;
  discountValue: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
};

export type CheckoutOrderLineItemPriceAdjustmentPriceOverride = {
  __typename?: 'CheckoutOrderLineItemPriceAdjustmentPriceOverride';
  override: Money;
  notes?: Maybe<Scalars['String']>;
};

export type CheckoutOrderLineItemsConnection = {
  __typename?: 'CheckoutOrderLineItemsConnection';
  nodes: Array<CheckoutOrderLineItem>;
};

export type CheckoutOrderNotification = {
  __typename?: 'CheckoutOrderNotification';
  id: Scalars['ID'];
  type: Checkout_Order_Notification_Type;
  createdBy: ActorV2;
  createdAt: DateTime;
};

export type CheckoutOrderNotificationsConnection = {
  __typename?: 'CheckoutOrderNotificationsConnection';
  nodes: Array<CheckoutOrderNotification>;
};

export type CheckoutOrderPaymentLineItem = {
  __typename?: 'CheckoutOrderPaymentLineItem';
  id: Scalars['ID'];
  transaction?: Maybe<CheckoutOrderTransaction>;
  charge: Money;
  /** @deprecated Field no longer supported */
  status: Checkout_Order_Payment_Line_Item_Status;
  /** @deprecated Field no longer supported */
  paymentPurchaseIntent: CheckoutOrderPaymentLineItemPaymentPurchaseIntent;
  /** @deprecated Field no longer supported */
  totalFee: Money;
  /** @deprecated Field no longer supported */
  card?: Maybe<CheckoutOrderPaymentLineItemCard>;
  /** @deprecated Field no longer supported */
  chargeTransaction?: Maybe<PointOfSaleTransaction>;
};

export type CheckoutOrderPaymentLineItemCard = {
  __typename?: 'CheckoutOrderPaymentLineItemCard';
  last4?: Maybe<Scalars['String']>;
  brand?: Maybe<Checkout_Order_Payment_Line_Item_Card_Brand>;
};

export type CheckoutOrderPaymentLineItemPaymentPurchaseIntent = {
  __typename?: 'CheckoutOrderPaymentLineItemPaymentPurchaseIntent';
  id: Scalars['ID'];
  type: Checkout_Order_Payment_Purchase_Intent_Type;
};

export type CheckoutOrderPaymentLineItemsConnection = {
  __typename?: 'CheckoutOrderPaymentLineItemsConnection';
  nodes: Array<CheckoutOrderPaymentLineItem>;
};

export type CheckoutOrderPayments = {
  __typename?: 'CheckoutOrderPayments';
  status: Checkout_Order_Payment_Status;
  lineItems?: Maybe<CheckoutOrderPaymentLineItemsConnection>;
};

export type CheckoutOrderRefund = {
  __typename?: 'CheckoutOrderRefund';
  id: Scalars['ID'];
  status: Checkout_Order_Refund_Status;
  createdBy: ActorV2;
  createdAt: DateTime;
  updatedAt: DateTime;
  reasons: Array<Refund_Reason>;
  total: Money;
  lineItems?: Maybe<CheckoutOrderRefundLineItemsConnection>;
  transactions?: Maybe<CheckoutOrderTransactionsConnection>;
  creditRewards?: Maybe<CheckoutCreditRewardConnection>;
  discountRewards?: Maybe<CheckoutCreditDiscountConnection>;
};

export type CheckoutOrderRefundLineItem = {
  __typename?: 'CheckoutOrderRefundLineItem';
  id: Scalars['ID'];
  orderLineItem: CheckoutOrderLineItem;
  quantity: Scalars['Int'];
};

export type CheckoutOrderRefundLineItemsConnection = {
  __typename?: 'CheckoutOrderRefundLineItemsConnection';
  nodes: Array<CheckoutOrderRefundLineItem>;
};

export type CheckoutOrderRefundsConnection = {
  __typename?: 'CheckoutOrderRefundsConnection';
  nodes: Array<CheckoutOrderRefund>;
};

export type CheckoutOrderRerunFailure = {
  __typename?: 'CheckoutOrderRerunFailure';
  error: Error;
};

export type CheckoutOrderRerunInput = {
  id: Scalars['ID'];
};

export type CheckoutOrderRerunPayload =
  | CheckoutOrderRerunSuccess
  | CheckoutOrderRerunFailure;

export type CheckoutOrderRerunSuccess = {
  __typename?: 'CheckoutOrderRerunSuccess';
  order: CheckoutOrder;
};

export type CheckoutOrderRevision = {
  __typename?: 'CheckoutOrderRevision';
  id: Scalars['ID'];
  number: Scalars['Int'];
  event?: Maybe<Checkout_Order_Revision_Event>;
  createdBy: ActorV2;
  createdAt: DateTime;
  cost: CheckoutOrderCost;
  balance: CheckoutOrderBalance;
};

export type CheckoutOrderRevisionsConnection = {
  __typename?: 'CheckoutOrderRevisionsConnection';
  nodes: Array<CheckoutOrderRevision>;
};

export type CheckoutOrderSendEmailReceiptFailure = {
  __typename?: 'CheckoutOrderSendEmailReceiptFailure';
  error: Error;
};

export type CheckoutOrderSendEmailReceiptInput = {
  id: Scalars['ID'];
  /** If not set, it defaults to PURCHASE */
  kind?: InputMaybe<Receipt_Kind>;
  /** If not set, the email will be sent to the associated patient */
  email?: InputMaybe<Scalars['EmailAddress']>;
};

export type CheckoutOrderSendEmailReceiptPayload =
  | CheckoutOrderSendEmailReceiptSuccess
  | CheckoutOrderSendEmailReceiptFailure;

export type CheckoutOrderSendEmailReceiptSuccess = {
  __typename?: 'CheckoutOrderSendEmailReceiptSuccess';
  order: CheckoutOrder;
  notification: CheckoutOrderNotification;
};

export type CheckoutOrderTransaction = {
  __typename?: 'CheckoutOrderTransaction';
  id: Scalars['ID'];
  type: Checkout_Order_Transaction_Type;
  createdBy: ActorV2;
  createdAt: DateTime;
  updatedAt: DateTime;
  processedAt: DateTime;
  status: Checkout_Order_Transaction_Status;
  parent?: Maybe<CheckoutOrderTransaction>;
  total: Money;
  cardPaymentDetails?: Maybe<CheckoutOrderTransactionCardPaymentDetails>;
  totalFee?: Maybe<Money>;
  paymentPurchaseIntent?: Maybe<CheckoutOrderTransactionPurchaseIntent>;
  pointOfSaleTransaction?: Maybe<PointOfSaleTransaction>;
};

export type CheckoutOrderTransactionCardPaymentDetails = {
  __typename?: 'CheckoutOrderTransactionCardPaymentDetails';
  last4?: Maybe<Scalars['String']>;
  brand?: Maybe<Checkout_Order_Transaction_Card_Payment_Details_Card_Brand>;
};

export type CheckoutOrderTransactionPurchaseIntent = {
  __typename?: 'CheckoutOrderTransactionPurchaseIntent';
  id: Scalars['ID'];
  type: Checkout_Order_Payment_Purchase_Intent_Type;
};

export type CheckoutOrderTransactionsConnection = {
  __typename?: 'CheckoutOrderTransactionsConnection';
  nodes: Array<CheckoutOrderTransaction>;
};

export type CheckoutSalesReport = {
  __typename?: 'CheckoutSalesReport';
  id: Scalars['ID'];
  type: Checkout_Sales_Report_Type;
  exportFormat: Checkout_Sales_Report_Export_Format;
  status: Checkout_Sales_Report_Status;
  url?: Maybe<Scalars['URL']>;
};

export type CheckoutSalesReportCreateFailure = {
  __typename?: 'CheckoutSalesReportCreateFailure';
  error: Error;
};

export type CheckoutSalesReportCreateInput = {
  providerOrganizationId: Scalars['ID'];
  type: Checkout_Sales_Report_Type;
  exportFormat: Checkout_Sales_Report_Export_Format;
  range?: InputMaybe<DateRangeInput>;
};

export type CheckoutSalesReportCreatePayload =
  | CheckoutSalesReportCreateSuccess
  | CheckoutSalesReportCreateFailure;

export type CheckoutSalesReportCreateSuccess = {
  __typename?: 'CheckoutSalesReportCreateSuccess';
  report?: Maybe<CheckoutSalesReport>;
};

export type CherryFinancingApplicationInitiateFailure = {
  __typename?: 'CherryFinancingApplicationInitiateFailure';
  error: Error;
};

export type CherryFinancingApplicationInitiateInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  patientId: Scalars['ID'];
  phoneNumber: Scalars['String'];
};

export type CherryFinancingApplicationInitiatePayload =
  | CherryFinancingApplicationInitiateSuccess
  | CherryFinancingApplicationInitiateSuccessWithExisting
  | CherryFinancingApplicationInitiateFailure;

export type CherryFinancingApplicationInitiateSuccess = {
  __typename?: 'CherryFinancingApplicationInitiateSuccess';
  applicationUrl: Scalars['URL'];
};

export type CherryFinancingApplicationInitiateSuccessWithExisting = {
  __typename?: 'CherryFinancingApplicationInitiateSuccessWithExisting';
  application: PatientFinancingCherryApplication;
};

export type CherryFinancingApplicationRefreshAdditionalInformation = {
  __typename?: 'CherryFinancingApplicationRefreshAdditionalInformation';
  applicationUrl: Scalars['URL'];
};

export type CherryFinancingApplicationRefreshFailure = {
  __typename?: 'CherryFinancingApplicationRefreshFailure';
  error: Error;
};

export type CherryFinancingApplicationRefreshInput = {
  applicationId: Scalars['ID'];
};

export type CherryFinancingApplicationRefreshPayload =
  | CherryFinancingApplicationRefreshAdditionalInformation
  | CherryFinancingApplicationRefreshSuccess
  | CherryFinancingApplicationRefreshFailure;

export type CherryFinancingApplicationRefreshSuccess = {
  __typename?: 'CherryFinancingApplicationRefreshSuccess';
  application: PatientFinancingCherryApplication;
};

export type CherryPatientFinancingProfileEnrollmentDetails = {
  __typename?: 'CherryPatientFinancingProfileEnrollmentDetails';
  status: Patient_Financing_Cherry_Enrollment_Status;
  completionStatus: Patient_Financing_Cherry_Enrollment_Completion_Status;
};

export type CherryPaymentApprovalRequestCancelFailure = {
  __typename?: 'CherryPaymentApprovalRequestCancelFailure';
  error: Error;
};

export type CherryPaymentApprovalRequestCancelInput = {
  id: Scalars['ID'];
};

export type CherryPaymentApprovalRequestCancelPayload =
  | CherryPaymentApprovalRequestCancelSuccess
  | CherryPaymentApprovalRequestCancelFailure;

export type CherryPaymentApprovalRequestCancelSuccess = {
  __typename?: 'CherryPaymentApprovalRequestCancelSuccess';
  paymentApprovalRequest: PatientFinancingCherryPaymentApprovalRequest;
};

export type CherryPaymentApprovalRequestCreateFailure = {
  __typename?: 'CherryPaymentApprovalRequestCreateFailure';
  error: Error;
};

export type CherryPaymentApprovalRequestCreateInput = {
  providerOrganizationId: Scalars['ID'];
  applicationId: Scalars['ID'];
  requestedAmountInCents: Scalars['Int'];
  requestedAmountInCentsForAllerganProducts?: InputMaybe<Scalars['Int']>;
  requestedAmountInCentsForOtherProducts?: InputMaybe<Scalars['Int']>;
};

export type CherryPaymentApprovalRequestCreatePayload =
  | CherryPaymentApprovalRequestCreateSuccess
  | CherryPaymentApprovalRequestCreateFailure;

export type CherryPaymentApprovalRequestCreateSuccess = {
  __typename?: 'CherryPaymentApprovalRequestCreateSuccess';
  transactionFinancingRequest: PatientTransactionFinancingRequest;
};

export type CherryPaymentApprovalRequestFilter = {
  id?: InputMaybe<Scalars['ID']>;
  transactionId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Patient_Financing_Cherry_Payment_Approval_Request_Status>;
};

export type CherryPaymentPlanEstimate = {
  __typename?: 'CherryPaymentPlanEstimate';
  termInMonths: Scalars['Int'];
  apr: Scalars['Float'];
  monthlyPaymentInCents: Scalars['Int'];
  downPaymentInCents: Scalars['Int'];
  totalFinanceChargeInCents: Scalars['Int'];
  totalCostInCents: Scalars['Int'];
};

export type CherryPaymentPlanEstimateByRiskCategory = {
  __typename?: 'CherryPaymentPlanEstimateByRiskCategory';
  riskCategoryName: Payment_Plan_Risk_Category;
  minimumScore?: Maybe<Scalars['Int']>;
  maximumScore?: Maybe<Scalars['Int']>;
  estimates: CherryPaymentPlanRiskCategoryEstimatesConnection;
};

export type CherryPaymentPlanEstimateSearchFilter = {
  purchaseAmountInCents: Scalars['Int'];
};

export type CherryPaymentPlanEstimateSearchResultsConnection = {
  __typename?: 'CherryPaymentPlanEstimateSearchResultsConnection';
  nodes: Array<CherryPaymentPlanEstimateByRiskCategory>;
};

export type CherryPaymentPlanRiskCategoryEstimatesConnection = {
  __typename?: 'CherryPaymentPlanRiskCategoryEstimatesConnection';
  nodes: Array<CherryPaymentPlanEstimate>;
};

export type CherryProviderOrganizationOnboardCompleteFailure = {
  __typename?: 'CherryProviderOrganizationOnboardCompleteFailure';
  error: Error;
};

export type CherryProviderOrganizationOnboardCompleteInput = {
  providerOrganizationId: Scalars['ID'];
};

export type CherryProviderOrganizationOnboardCompletePayload =
  | CherryProviderOrganizationOnboardCompleteSuccess
  | CherryProviderOrganizationOnboardCompleteFailure;

export type CherryProviderOrganizationOnboardCompleteSuccess = {
  __typename?: 'CherryProviderOrganizationOnboardCompleteSuccess';
  patientFinancingProfile: PatientFinancingProfile;
};

export type CherryProviderOrganizationOnboardInitiateFailure = {
  __typename?: 'CherryProviderOrganizationOnboardInitiateFailure';
  error: Error;
};

export type CherryProviderOrganizationOnboardInitiateInput = {
  providerOrganizationId: Scalars['ID'];
};

export type CherryProviderOrganizationOnboardInitiatePayload =
  | CherryProviderOrganizationOnboardInitiateSuccess
  | CherryProviderOrganizationOnboardInitiateFailure;

export type CherryProviderOrganizationOnboardInitiateSuccess = {
  __typename?: 'CherryProviderOrganizationOnboardInitiateSuccess';
  onboardUrl: Scalars['URL'];
};

export type CherrySendSmsInvitationFailure = {
  __typename?: 'CherrySendSMSInvitationFailure';
  error: Error;
};

export type CherrySendSmsInvitationInput = {
  phoneNumber: Scalars['String'];
};

export type CherrySendSmsInvitationPayload =
  | CherrySendSmsInvitationSuccess
  | CherrySendSmsInvitationFailure;

export type CherrySendSmsInvitationSuccess = {
  __typename?: 'CherrySendSMSInvitationSuccess';
  status: Scalars['String'];
};

export type CityProductTreatmentAreasConnection = {
  __typename?: 'CityProductTreatmentAreasConnection';
  nodes: Array<ProviderProfileTreatmentArea>;
};

export type CityProviderProductsConnection = {
  __typename?: 'CityProviderProductsConnection';
  nodes: Array<ProviderProfileProduct>;
};

export type CommunicationSubscriptionGroup = {
  __typename?: 'CommunicationSubscriptionGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  channel: Communication_Channel;
};

export type CommunicationSubscriptionGroupConnection = {
  __typename?: 'CommunicationSubscriptionGroupConnection';
  nodes: Array<CommunicationSubscriptionGroup>;
};

export type ConnectAccountRedirectUrlCreateFailure = {
  __typename?: 'ConnectAccountRedirectUrlCreateFailure';
  error: Error;
};

export type ConnectAccountRedirectUrlCreateInput = {
  providerOrganizationId: Scalars['ID'];
  urlType: Connect_Account_Redirect_Url_Type;
};

export type ConnectAccountRedirectUrlCreatePayload =
  | ConnectAccountRedirectUrlCreateSuccess
  | ConnectAccountRedirectUrlCreateFailure;

export type ConnectAccountRedirectUrlCreateSuccess = {
  __typename?: 'ConnectAccountRedirectUrlCreateSuccess';
  connectAccountRedirectUrl: Scalars['URL'];
};

export type ConsultationRequest = {
  __typename?: 'ConsultationRequest';
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ConsultationRequestCreateError = {
  __typename?: 'ConsultationRequestCreateError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type ConsultationRequestCreateInput = {
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  providerId: Scalars['ID'];
};

export type ConsultationRequestCreatePayload =
  | ConsultationRequestCreateError
  | ConsultationRequestCreateSuccess;

export type ConsultationRequestCreateSuccess = {
  __typename?: 'ConsultationRequestCreateSuccess';
  consultationRequest: ConsultationRequest;
};

export type ConsultationRequestLeadSource = LeadSource & {
  __typename?: 'ConsultationRequestLeadSource';
  id: Scalars['ID'];
  url?: Maybe<Scalars['URL']>;
};

export type Consumer = {
  __typename?: 'Consumer';
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  verifiedEmail?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type ConsumerByNameClause = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type ConsumerClause = {
  id?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

export type ConsumerCommunicationPreferences = {
  __typename?: 'ConsumerCommunicationPreferences';
  optInMarketingEmail: Scalars['Boolean'];
  optInMarketingPush: Scalars['Boolean'];
  optInMarketingSMS: Scalars['Boolean'];
  optInEarningEmail: Scalars['Boolean'];
  optInEarningPush: Scalars['Boolean'];
  optInEarningSMS: Scalars['Boolean'];
};

export type ConsumerConsentCommunicationPreferencesUpdateFailure = {
  __typename?: 'ConsumerConsentCommunicationPreferencesUpdateFailure';
  error: Error;
};

export type ConsumerConsentCommunicationPreferencesUpdateInput = {
  id: Scalars['ID'];
  consumerId?: InputMaybe<Scalars['String']>;
  optIn: Scalars['Boolean'];
};

export type ConsumerConsentCommunicationPreferencesUpdatePayload =
  | ConsumerConsentCommunicationPreferencesUpdateSuccess
  | ConsumerConsentCommunicationPreferencesUpdateFailure;

export type ConsumerConsentCommunicationPreferencesUpdateSuccess = {
  __typename?: 'ConsumerConsentCommunicationPreferencesUpdateSuccess';
  consumerPreferences?: Maybe<Array<ConsumerPreference>>;
};

export type ConsumerConsentCommunicationPreferencesUpdateUnsubscribeFromAllFailure =
  {
    __typename?: 'ConsumerConsentCommunicationPreferencesUpdateUnsubscribeFromAllFailure';
    error: Error;
  };

export type ConsumerConsentCommunicationPreferencesUpdateUnsubscribeFromAllInput =
  {
    consumerId?: InputMaybe<Scalars['String']>;
  };

export type ConsumerConsentCommunicationPreferencesUpdateUnsubscribeFromAllPayload =

    | ConsumerConsentCommunicationPreferencesUpdateUnsubscribeFromAllSuccess
    | ConsumerConsentCommunicationPreferencesUpdateUnsubscribeFromAllFailure;

export type ConsumerConsentCommunicationPreferencesUpdateUnsubscribeFromAllSuccess =
  {
    __typename?: 'ConsumerConsentCommunicationPreferencesUpdateUnsubscribeFromAllSuccess';
    consumerPreferences?: Maybe<Array<ConsumerPreference>>;
  };

export type ConsumerConsentPrivacySettingsLimitSensitiveDataUseFailure = {
  __typename?: 'ConsumerConsentPrivacySettingsLimitSensitiveDataUseFailure';
  error: Error;
};

export type ConsumerConsentPrivacySettingsLimitSensitiveDataUseInput = {
  consumerId: Scalars['ID'];
};

export type ConsumerConsentPrivacySettingsLimitSensitiveDataUsePayload =
  | ConsumerConsentPrivacySettingsLimitSensitiveDataUseSuccess
  | ConsumerConsentPrivacySettingsLimitSensitiveDataUseFailure;

export type ConsumerConsentPrivacySettingsLimitSensitiveDataUseSuccess = {
  __typename?: 'ConsumerConsentPrivacySettingsLimitSensitiveDataUseSuccess';
  consumerPreferences?: Maybe<Array<ConsumerPreference>>;
};

export type ConsumerConsentPrivacySettingsUpdateFailure = {
  __typename?: 'ConsumerConsentPrivacySettingsUpdateFailure';
  error: Error;
};

export type ConsumerConsentPrivacySettingsUpdateInput = {
  id: Scalars['ID'];
  consumerId?: InputMaybe<Scalars['String']>;
  optIn: Scalars['Boolean'];
};

export type ConsumerConsentPrivacySettingsUpdatePayload =
  | ConsumerConsentPrivacySettingsUpdateSuccess
  | ConsumerConsentPrivacySettingsUpdateFailure;

export type ConsumerConsentPrivacySettingsUpdateSuccess = {
  __typename?: 'ConsumerConsentPrivacySettingsUpdateSuccess';
  consumerPreferences?: Maybe<Array<ConsumerPreference>>;
};

export type ConsumerConsentPrivacyTermsAcceptUpdatedTermsFailure = {
  __typename?: 'ConsumerConsentPrivacyTermsAcceptUpdatedTermsFailure';
  error: Error;
};

export type ConsumerConsentPrivacyTermsAcceptUpdatedTermsInput = {
  consumerId: Scalars['ID'];
  metadata?: InputMaybe<Array<GenericMetadataInput>>;
};

export type ConsumerConsentPrivacyTermsAcceptUpdatedTermsPayload =
  | ConsumerConsentPrivacyTermsAcceptUpdatedTermsSuccess
  | ConsumerConsentPrivacyTermsAcceptUpdatedTermsFailure;

export type ConsumerConsentPrivacyTermsAcceptUpdatedTermsSuccess = {
  __typename?: 'ConsumerConsentPrivacyTermsAcceptUpdatedTermsSuccess';
  acceptedAt: DateTime;
};

export type ConsumerDeactivateFailure = {
  __typename?: 'ConsumerDeactivateFailure';
  error: Error;
};

export type ConsumerDeactivateInput = {
  /**
   * This process is not currently reversible, so you must confirm that you want to DEACTIVATE the consumer.
   * You must send the value "DEACTIVATE" to confirm that you want to deactivate the consumer.
   */
  confidenceCheck: Scalars['String'];
  consumerId: Scalars['ID'];
  emailAddress: Scalars['String'];
  caseNumber: Scalars['String'];
};

export type ConsumerDeactivatePayload =
  | ConsumerDeactivateSuccess
  | ConsumerDeactivateFailure;

export type ConsumerDeactivateSuccess = {
  __typename?: 'ConsumerDeactivateSuccess';
  success: Scalars['Boolean'];
};

export type ConsumerDeleteFailure = {
  __typename?: 'ConsumerDeleteFailure';
  error: Error;
};

export type ConsumerDeleteInput = {
  /**
   * This process is not currently reversible, so you must confirm that you want to DELETE the consumer.
   * You must send the value "DELETE" to confirm that you want to delete the consumer.
   */
  confidenceCheck: Scalars['String'];
  consumerId: Scalars['ID'];
  emailAddress: Scalars['String'];
  caseNumber: Scalars['String'];
};

export type ConsumerDeletePayload =
  | ConsumerDeleteSuccess
  | ConsumerDeleteFailure;

export type ConsumerDeleteSuccess = {
  __typename?: 'ConsumerDeleteSuccess';
  success: Scalars['Boolean'];
};

export type ConsumerPointActivity = {
  __typename?: 'ConsumerPointActivity';
  transaction?: Maybe<PatientTransaction>;
  promotion?: Maybe<PatientPromotion>;
  points?: Maybe<Scalars['Int']>;
  type?: Maybe<Points_Type>;
  updatedAt: DateTime;
};

export type ConsumerPreference = {
  __typename?: 'ConsumerPreference';
  id: Scalars['ID'];
  /** slug maps to codeId in contentful */
  slug: Scalars['String'];
  decision?: Maybe<ConsumerPreferenceDecision>;
};

export type ConsumerPreferenceDecision = {
  __typename?: 'ConsumerPreferenceDecision';
  id: Scalars['ID'];
  /** optIn may return as undefined/null when consumer has never opted in nor opted out */
  optIn?: Maybe<Scalars['Boolean']>;
};

export type ConsumerProductLastTransactionData = {
  __typename?: 'ConsumerProductLastTransactionData';
  productId: Scalars['ID'];
  productName: Scalars['String'];
  lastTransactedAt?: Maybe<DateTime>;
};

export type ConsumerProfile = ViewerProfile & {
  __typename?: 'ConsumerProfile';
  id?: Maybe<Scalars['ID']>;
  hipaaAcceptedAt?: Maybe<DateTime>;
  privacyTermsAcceptedAt?: Maybe<DateTime>;
  /** @deprecated Use referAFriendProfile.referringUserProfile */
  referringUserProfile?: Maybe<ReferringUserProfile>;
  /** @deprecated Used only during refer a friend pilot period */
  referAFriendPilotAudience?: Maybe<Scalars['String']>;
  referAFriendProfile: ConsumerReferAFriendProfile;
  internalId: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  phoneNumber: Scalars['String'];
  pendingPhoneNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  gender?: Maybe<Gender>;
  birthDate?: Maybe<Date>;
  birthday?: Maybe<Birthday>;
  loyalty?: Maybe<LoyaltyProfile>;
  communicationPreferences?: Maybe<ConsumerCommunicationPreferences>;
  communicationSubscriptionGroups?: Maybe<CommunicationSubscriptionGroupConnection>;
  verifiedEmail?: Maybe<Scalars['Boolean']>;
};

export type ConsumerReferAFriendProfile = {
  __typename?: 'ConsumerReferAFriendProfile';
  referringUserProfile?: Maybe<ReferringUserProfile>;
  becomeReferralEligibility: BecomeReferralEligibility;
  referringUserPromotionToApply: PromotionConstruct;
  referringUserOffersLimit: Scalars['Int'];
  referralPromotionToApply: PromotionConstruct;
  referralOffersLimit: Scalars['Int'];
  referralTreatmentDeadlineInDays: Scalars['Int'];
  limitsResetCadence: Consumer_Refer_A_Friend_Profile_Limits_Reset_Cadence;
};

export type ConsumerResendPhoneVerificationInput = {
  baseUrl: Scalars['String'];
  consumerId: Scalars['ID'];
};

export type ConsumerResendVerifyEmailInput = {
  baseUrl: Scalars['String'];
  consumerId: Scalars['ID'];
};

export type ConsumerUpdateCommunicationMethodInput = {
  consumerId: Scalars['ID'];
  preferences?: InputMaybe<ConsumerUpdateCommunicationMethodPreferenceInput>;
};

export type ConsumerUpdateCommunicationMethodPreferenceInput = {
  optInMarketingEmail: Scalars['Boolean'];
  optInMarketingPush: Scalars['Boolean'];
  optInMarketingSMS: Scalars['Boolean'];
  optInEarningEmail: Scalars['Boolean'];
  optInEarningPush: Scalars['Boolean'];
  optInEarningSMS: Scalars['Boolean'];
};

export type ConsumerUpdateEmailAddressError = {
  __typename?: 'ConsumerUpdateEmailAddressError';
  error?: Maybe<Error>;
};

export type ConsumerUpdateEmailAddressInput = {
  baseUrl: Scalars['String'];
  emailAddress: Scalars['String'];
  consumerId: Scalars['ID'];
};

export type ConsumerUpdateEmailAddressResponse =
  | ConsumerUpdateEmailAddressSuccess
  | ConsumerUpdateEmailAddressError;

export type ConsumerUpdateEmailAddressSuccess = {
  __typename?: 'ConsumerUpdateEmailAddressSuccess';
  profile?: Maybe<ConsumerProfile>;
};

export type ConsumerUpdatePhoneNumberInput = {
  baseUrl: Scalars['String'];
  consumerId: Scalars['ID'];
  phoneNumber: Scalars['String'];
};

export type ConsumerUpdateProfileError = {
  __typename?: 'ConsumerUpdateProfileError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type ConsumerUpdateProfileInput = {
  consumerId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  birthDate?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<BirthdayInput>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type ConsumerUpdateProfileResponse =
  | ConsumerUpdateProfileSuccess
  | ConsumerUpdateProfileError;

export type ConsumerUpdateProfileSuccess = {
  __typename?: 'ConsumerUpdateProfileSuccess';
  profile?: Maybe<ConsumerProfile>;
};

export type ConsumerUserActor = ActorV2 &
  HumanActor & {
    __typename?: 'ConsumerUserActor';
    id: Scalars['ID'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
  };

/** Error data returned upon failed validation of contacts file (csv/xls[x]). */
export type ContactsFileValidateError = {
  __typename?: 'ContactsFileValidateError';
  error: Error;
};

export type ContactsFileValidateInput = {
  /** Url of the file (csv/xls[x]) containing contacts */
  url: Scalars['URL'];
};

export type ContactsFileValidatePayload =
  | ContactsFileValidateSuccess
  | ContactsFileValidateError;

/** Response returned upon successful file (csv/xls[x]) validation. */
export type ContactsFileValidateSuccess = {
  __typename?: 'ContactsFileValidateSuccess';
  url: Scalars['URL'];
};

/** Error data returned upon failed url creation. */
export type ContactsUploadUrlCreateError = {
  __typename?: 'ContactsUploadUrlCreateError';
  error: Error;
};

export type ContactsUploadUrlCreatePayload =
  | ContactsUploadUrlCreateSuccess
  | ContactsUploadUrlCreateError;

/** Response returned upon successful url creation. */
export type ContactsUploadUrlCreateSuccess = {
  __typename?: 'ContactsUploadUrlCreateSuccess';
  url: Scalars['URL'];
};

/** DAY_OF_THE_WEEK enum used for BusinessHours */
export enum Day_Of_The_Week {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
}

export enum Discount_Type {
  FixedAmount = 'FIXED_AMOUNT',
  Percent = 'PERCENT',
}

export type Date = {
  __typename?: 'Date';
  format?: Maybe<Scalars['String']>;
};

export type DateFormatArgs = {
  layout?: InputMaybe<Scalars['String']>;
};

export type DateRange = {
  __typename?: 'DateRange';
  start?: Maybe<Date>;
  end?: Maybe<Date>;
};

export type DateRangeInput = {
  start?: InputMaybe<Scalars['DateInput']>;
  end?: InputMaybe<Scalars['DateInput']>;
};

export type DateTime = {
  __typename?: 'DateTime';
  format?: Maybe<Scalars['String']>;
};

export type DateTimeFormatArgs = {
  layout?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type DateTimeRangeInput = {
  start?: InputMaybe<Scalars['DateTimeInput']>;
  end?: InputMaybe<Scalars['DateTimeInput']>;
};

export type DefaultStatus = {
  __typename?: 'DefaultStatus';
  status: Scalars['String'];
  message: Scalars['String'];
};

export type DeviceLocation = {
  __typename?: 'DeviceLocation';
  country?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  geoPoint?: Maybe<GeoPoint>;
  city?: Maybe<Scalars['String']>;
};

export type DirectoryConsultationRequest = {
  __typename?: 'DirectoryConsultationRequest';
  id: Scalars['ID'];
  profileSlug: Scalars['ID'];
  providerOrganizationId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  phoneNumber: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type DirectoryConsultationRequestCreateError = {
  __typename?: 'DirectoryConsultationRequestCreateError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type DirectoryConsultationRequestCreateInput = {
  profileSlug: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type DirectoryConsultationRequestCreatePayload =
  | DirectoryConsultationRequestCreateSuccess
  | DirectoryConsultationRequestCreateError;

export type DirectoryConsultationRequestCreateSuccess = {
  __typename?: 'DirectoryConsultationRequestCreateSuccess';
  consultationRequest: DirectoryConsultationRequest;
};

export type DollarsOffFlashDistributionChannel = {
  __typename?: 'DollarsOffFlashDistributionChannel';
  duration?: Maybe<MarketingOfferDuration>;
  inventory?: Maybe<MarketingOfferInventory>;
};

export type DollarsOffGivenAudienceConnection = {
  __typename?: 'DollarsOffGivenAudienceConnection';
  nodes: Array<MarketingOfferAudience>;
};

export type DollarsOffGivenDistributionChannel = {
  __typename?: 'DollarsOffGivenDistributionChannel';
  audienceConnection?: Maybe<DollarsOffGivenAudienceConnection>;
  inventory?: Maybe<MarketingOfferInventory>;
};

export type DollarsOffOfferReward = {
  __typename?: 'DollarsOffOfferReward';
  cannotRedeemWithSameBrandSpecificOffers?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['Markdown']>;
  expiration?: Maybe<MarketingOfferExpiration>;
  image?: Maybe<MarketingOfferRewardWalletImage>;
  redeemableBrandsConnection?: Maybe<MarketingOfferBrandsConnection>;
  redeemableProductsConnection?: Maybe<MarketingOfferProductsConnection>;
  terms?: Maybe<Scalars['Markdown']>;
  valueInCents?: Maybe<Scalars['Int']>;
};

export type DollarsOffOptInDistributionChannel = {
  __typename?: 'DollarsOffOptInDistributionChannel';
  duration?: Maybe<MarketingOfferDuration>;
  inventory?: Maybe<MarketingOfferInventory>;
  /**
   * A consumer must be included in at least one of the audiences to be eligible
   * for the offer. An empty list represents that all consumers are eligible.
   */
  eligibleAudienceNames?: Maybe<Array<Scalars['String']>>;
  /**
   * When this is true, newly registered users will be able to claim the offer even
   * if they are not included in one of the eligible audiences.
   */
  newUsersAlwaysEligible?: Maybe<Scalars['Boolean']>;
};

export type DollarsOffPracticeGivenAudienceConnection = {
  __typename?: 'DollarsOffPracticeGivenAudienceConnection';
  nodes: Array<MarketingOfferAudience>;
};

export type DollarsOffPracticeGivenDistributionChannel = {
  __typename?: 'DollarsOffPracticeGivenDistributionChannel';
  audienceConnection?: Maybe<DollarsOffPracticeGivenAudienceConnection>;
  inventory?: Maybe<MarketingOfferPracticeInventory>;
};

export type DraftPublicProfile = {
  __typename?: 'DraftPublicProfile';
  about?: Maybe<Scalars['String']>;
  address?: Maybe<ProviderAddress>;
  avatarUrl?: Maybe<Scalars['URL']>;
  businessHours?: Maybe<ProviderBusinessHoursConnection>;
  completionPercentage?: Maybe<Scalars['Int']>;
  consultationRequestSettings?: Maybe<ProviderConsultationRequestSettings>;
  contactInformation?: Maybe<ProviderContactInformation>;
  displayName?: Maybe<Scalars['String']>;
  galleryPhotos?: Maybe<ProviderGalleryPhotosConnection>;
  geolocation?: Maybe<ProviderGeolocation>;
  hasUnpublishedChanges: Scalars['Boolean'];
  id: Scalars['ID'];
  optInMarketingEvents?: Maybe<PublicProfileOptInMarketingEventsConnection>;
  name: Scalars['String'];
  officePractitioners?: Maybe<ViewerPractitionersConnection>;
  practiceType?: Maybe<Provider_Practice_Type>;
  products?: Maybe<ProviderProductsConnection>;
  profileSlug?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<DateTime>;
  publishedBy?: Maybe<Actor>;
  settings?: Maybe<ProviderSettings>;
  specialHoursNotices: Array<SpecialHoursNotice>;
  languages?: Maybe<DraftPublicProfileLanguagesConnection>;
};

export type DraftPublicProfileLanguagesConnection = {
  __typename?: 'DraftPublicProfileLanguagesConnection';
  nodes: Array<ProviderLanguage>;
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['String'];
  message: Scalars['String'];
  friendlyMessage?: Maybe<Scalars['String']>;
};

export type ExpiringPointsBusinessReport = {
  __typename?: 'ExpiringPointsBusinessReport';
  rowsConnection: ExpiringPointsBusinessReportRowsConnection;
};

export type ExpiringPointsBusinessReportCreateInput = {
  sort: Array<ExpiringPointsBusinessReportSort>;
  reportExportFormat?: InputMaybe<Report_Export_Format>;
};

export type ExpiringPointsBusinessReportLocationRow = {
  __typename?: 'ExpiringPointsBusinessReportLocationRow';
  /** @deprecated Use provider instead */
  address?: Maybe<Address>;
  patientsWithPointsTotal: Scalars['Int'];
  pointsTotal: Scalars['Int'];
  pointsTotalInCents: Scalars['Int'];
  provider: ProviderOrganization;
};

export type ExpiringPointsBusinessReportPageInput = {
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort: Array<ExpiringPointsBusinessReportSort>;
};

export type ExpiringPointsBusinessReportRowsConnection = {
  __typename?: 'ExpiringPointsBusinessReportRowsConnection';
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  nodes: Array<ExpiringPointsBusinessReportLocationRow>;
};

export type ExpiringPointsBusinessReportSort = {
  column?: ExpiringPointsBusinessReportSortColumn;
  order?: ReportSortOrder;
};

export enum ExpiringPointsBusinessReportSortColumn {
  PatientsWithPointsTotal = 'PATIENTS_WITH_POINTS_TOTAL',
  PointsTotal = 'POINTS_TOTAL',
  PointsTotalInCents = 'POINTS_TOTAL_IN_CENTS',
}

export type ExpiringPointsReport = {
  __typename?: 'ExpiringPointsReport';
  rowsConnection: ExpiringPointsReportRowsConnection;
};

export type ExpiringPointsReportCreateInput = {
  sort: Array<ExpiringPointsReportSort>;
  reportExportFormat?: InputMaybe<Report_Export_Format>;
};

export type ExpiringPointsReportPageInput = {
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort: Array<ExpiringPointsReportSort>;
};

export type ExpiringPointsReportRow = {
  __typename?: 'ExpiringPointsReportRow';
  consumerId: Scalars['ID'];
  emailAddress: Scalars['String'];
  expiration: DateTime;
  expireInDays: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  pointTotal: Scalars['Int'];
};

export type ExpiringPointsReportRowsConnection = {
  __typename?: 'ExpiringPointsReportRowsConnection';
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  nodes: Array<ExpiringPointsReportRow>;
};

export type ExpiringPointsReportSort = {
  column?: ExpiringPointsReportSortColumn;
  order?: ReportSortOrder;
};

export enum ExpiringPointsReportSortColumn {
  Expiration = 'EXPIRATION',
  ExpireInDays = 'EXPIRE_IN_DAYS',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  PointTotal = 'POINT_TOTAL',
}

export enum Facebook_Oauth_Scope {
  AdsManagement = 'ADS_MANAGEMENT',
  BusinessManagement = 'BUSINESS_MANAGEMENT',
  PagesManageAds = 'PAGES_MANAGE_ADS',
  PagesManageEngagement = 'PAGES_MANAGE_ENGAGEMENT',
  PagesManageMetadata = 'PAGES_MANAGE_METADATA',
  PagesManagePosts = 'PAGES_MANAGE_POSTS',
  PagesReadEngagement = 'PAGES_READ_ENGAGEMENT',
  PagesShowList = 'PAGES_SHOW_LIST',
}

export enum Facebook_Page_Partner_Request_Status {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export enum Flash_Rewards_Enrollment_Status {
  Enrolled = 'ENROLLED',
  /** @deprecated No longer used */
  EnrolledReshipmentAvailable = 'ENROLLED_RESHIPMENT_AVAILABLE',
  NotEnrolled = 'NOT_ENROLLED',
  OptedOut = 'OPTED_OUT',
}

/** Integration information of the practice facebook account. */
export type FacebookIntegration = {
  __typename?: 'FacebookIntegration';
  id: Scalars['ID'];
  /** @deprecated Authentication will happen on client side */
  authUrl: Scalars['URL'];
  isConnected: Scalars['Boolean'];
  oauthScopes: Array<Facebook_Oauth_Scope>;
  pages: Array<FacebookPage>;
};

export type FacebookPage = {
  __typename?: 'FacebookPage';
  id: Scalars['ID'];
  name: Scalars['String'];
  pageId: Scalars['ID'];
  profilePictureUrl: Scalars['URL'];
  partnerRequestStatus: Facebook_Page_Partner_Request_Status;
  /** @deprecated Use 'name' . */
  title: Scalars['String'];
};

export type FacebookPostCreateError = {
  __typename?: 'FacebookPostCreateError';
  error: Error;
};

export type FacebookPostCreateInput = {
  curatedPostId: Scalars['ID'];
  audience: PostAudienceInput;
  spendLimitInCents: Scalars['Int'];
  spendLimitMode: Spend_Limit_Mode;
  startAt: Scalars['DateTimeInput'];
  endAt: Scalars['DateTimeInput'];
};

export type FacebookPostCreatePayload =
  | FacebookPostCreateSuccess
  | FacebookPostCreateError;

export type FacebookPostCreateSuccess = {
  __typename?: 'FacebookPostCreateSuccess';
  post: AdvertisingPost;
};

export type FacebookPublicationOption = {
  __typename?: 'FacebookPublicationOption';
  pages: FacebookPublicationOptionPageConnection;
};

export type FacebookPublicationOptionPageConnection = {
  __typename?: 'FacebookPublicationOptionPageConnection';
  edges: Array<FacebookPublicationOptionPageEdge>;
};

export type FacebookPublicationOptionPageEdge = {
  __typename?: 'FacebookPublicationOptionPageEdge';
  node: FacebookPage;
};

export type FactSheetContent = {
  __typename?: 'FactSheetContent';
  title: CmsContent;
  titleFact: CmsFactContent;
  facts: Array<CmsFactContent>;
};

export type FailedReimbursement = {
  __typename?: 'FailedReimbursement';
  id: Scalars['ID'];
  amountInCents?: Maybe<Scalars['Int']>;
  createdAt: DateTime;
  cancelledAt?: Maybe<DateTime>;
  failedAt?: Maybe<DateTime>;
  failedReason?: Maybe<Scalars['String']>;
};

export type FailedReimbursementHistory = {
  __typename?: 'FailedReimbursementHistory';
  totalInCents?: Maybe<Scalars['Int']>;
  lastFailedReimbursement?: Maybe<FailedReimbursement>;
  failedReimbursements?: Maybe<FailedReimbursementHistoryReimbursementsConnection>;
};

export type FailedReimbursementHistoryReimbursementsConnection = {
  __typename?: 'FailedReimbursementHistoryReimbursementsConnection';
  nodes: Array<FailedReimbursement>;
};

export type FaqContent = {
  __typename?: 'FaqContent';
  title: CmsContent;
  description: CmsContent;
};

export type FieldValidationError = {
  /** A user friendly display name for the field, e.g. `phone number`. */
  displayName: Scalars['String'];
};

export type FindAProviderLeadSource = LeadSource & {
  __typename?: 'FindAProviderLeadSource';
  id: Scalars['ID'];
  url?: Maybe<Scalars['URL']>;
};

export type FlashOptOutReason = {
  __typename?: 'FlashOptOutReason';
  slug: Scalars['String'];
  label: Scalars['String'];
};

export type FlashOptOutReasonsConnection = {
  __typename?: 'FlashOptOutReasonsConnection';
  nodes: Array<FlashOptOutReason>;
};

export type FlashReactivationReason = {
  __typename?: 'FlashReactivationReason';
  slug: Scalars['String'];
  label: Scalars['String'];
};

export type FlashReactivationReasonsConnection = {
  __typename?: 'FlashReactivationReasonsConnection';
  nodes: Array<FlashReactivationReason>;
};

export type FlashRewardsProfile = {
  __typename?: 'FlashRewardsProfile';
  availableOptOutReasons: FlashOptOutReasonsConnection;
  availableReactivationReasons: FlashReactivationReasonsConnection;
  checkInUrl?: Maybe<Scalars['URL']>;
  enrolledAt?: Maybe<DateTime>;
  enrollmentStatus: Flash_Rewards_Enrollment_Status;
  optedOutAt?: Maybe<DateTime>;
  optedOutReason?: Maybe<Scalars['String']>;
  reactivatedAt?: Maybe<DateTime>;
  reactivatedReason?: Maybe<Scalars['String']>;
};

export type FlashRewardsReport = {
  __typename?: 'FlashRewardsReport';
  rowsConnection: FlashRewardsReportRowsConnection;
};

export type FlashRewardsReportCreateInput = {
  sort: Array<FlashRewardsReportSort>;
  reportExportFormat?: InputMaybe<Report_Export_Format>;
};

export type FlashRewardsReportPageInput = {
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort: Array<FlashRewardsReportSort>;
};

export type FlashRewardsReportRow = {
  __typename?: 'FlashRewardsReportRow';
  consumerId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  phoneNumber: Scalars['String'];
  offerDisplayName: Scalars['String'];
  scannedAt: DateTime;
  offerExpiresAt: DateTime;
  /** @deprecated Use availablePoints instead */
  expiringPoints: Scalars['Int'];
  availablePoints: Scalars['Int'];
  redeemedAt?: Maybe<DateTime>;
};

export type FlashRewardsReportRowsConnection = {
  __typename?: 'FlashRewardsReportRowsConnection';
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  nodes: Array<FlashRewardsReportRow>;
};

export type FlashRewardsReportSort = {
  column?: FlashRewardsReportSortColumn;
  order?: ReportSortOrder;
};

export enum FlashRewardsReportSortColumn {
  FirstName = 'FIRST_NAME',
  OfferExpirationDate = 'OFFER_EXPIRATION_DATE',
  RedemptionDate = 'REDEMPTION_DATE',
}

export enum ForgotPasswordFactor {
  Sms = 'SMS',
  Email = 'EMAIL',
  Both = 'BOTH',
}

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  message: Scalars['String'];
};

export type FundingSource = {
  __typename?: 'FundingSource';
  id?: Maybe<Scalars['ID']>;
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  bankAccountType: Bank_Account_Type;
  nickname: Scalars['String'];
  businessName?: Maybe<Scalars['String']>;
};

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Other = 'OTHER',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
  Unspecified = 'UNSPECIFIED',
}

export enum Gift_Card_Status {
  Claimed = 'CLAIMED',
  Created = 'CREATED',
  Deactivated = 'DEACTIVATED',
  Migrated = 'MIGRATED',
  Null = 'NULL',
  Pending = 'PENDING',
  PurchaseBeingReviewed = 'PURCHASE_BEING_REVIEWED',
  PurchaseCompleted = 'PURCHASE_COMPLETED',
  PurchaseFailed = 'PURCHASE_FAILED',
  Redeemed = 'REDEEMED',
  Removed = 'REMOVED',
  Shared = 'SHARED',
}

export type GalleryPhoto = {
  __typename?: 'GalleryPhoto';
  altText: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  url: Scalars['URL'];
};

export type GalleryPhotoCreateInput = {
  altText?: InputMaybe<Scalars['String']>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  url: Scalars['URL'];
};

export type GalleryPhotoUpdateInput = {
  altText?: InputMaybe<Scalars['String']>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  url?: InputMaybe<Scalars['URL']>;
};

export type GenericMetadataInput = {
  name: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type GeoPoint = {
  __typename?: 'GeoPoint';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type GeoPointInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GiftCard = {
  __typename?: 'GiftCard';
  giftCardLedgerId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  appliedValue?: Maybe<Scalars['Int']>;
  owner?: Maybe<Patient>;
  code: Scalars['String'];
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  purchasedBy?: Maybe<Patient>;
  displayName: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  isisConnection?: Maybe<GiftCardIsIsConnection>;
  status?: Maybe<Gift_Card_Status>;
  terms?: Maybe<Scalars['String']>;
  lastUpdatedAt: DateTime;
  lastUpdatedBy: Actor;
  valueInCents: Scalars['Int'];
  redemptionRulesConnection?: Maybe<GiftCardRedemptionRulesConnection>;
};

export type GiftCardCreateError = {
  __typename?: 'GiftCardCreateError';
  error?: Maybe<Error>;
};

export type GiftCardCreateInput = {
  code: Scalars['String'];
};

export type GiftCardCreatePayload = GiftCardCreateSuccess | GiftCardCreateError;

export type GiftCardCreateSuccess = {
  __typename?: 'GiftCardCreateSuccess';
  giftcard: GiftCard;
};

export type GiftCardDeactivateError = {
  __typename?: 'GiftCardDeactivateError';
  error?: Maybe<Error>;
};

export type GiftCardDeactivateInput = {
  id: Scalars['ID'];
};

export type GiftCardDeactivatePayload =
  | GiftCardDeactivateSuccess
  | GiftCardDeactivateError;

export type GiftCardDeactivateSuccess = {
  __typename?: 'GiftCardDeactivateSuccess';
  giftcard: GiftCard;
};

export type GiftCardGivenAudienceConnection = {
  __typename?: 'GiftCardGivenAudienceConnection';
  nodes: Array<MarketingOfferAudience>;
};

export type GiftCardGivenDistributionChannel = {
  __typename?: 'GiftCardGivenDistributionChannel';
  audienceConnection?: Maybe<GiftCardGivenAudienceConnection>;
  inventory?: Maybe<MarketingOfferInventory>;
};

export type GiftCardIsIsConnection = {
  __typename?: 'GiftCardISIsConnection';
  isis?: Maybe<Array<Maybe<BrandIsi>>>;
};

export type GiftCardOfferReward = {
  __typename?: 'GiftCardOfferReward';
  image?: Maybe<MarketingOfferRewardWalletImage>;
  redeemableBrandsConnection?: Maybe<MarketingOfferBrandsConnection>;
  terms?: Maybe<Scalars['Markdown']>;
  valueInCents?: Maybe<Scalars['Int']>;
  materialId?: Maybe<Scalars['ID']>;
};

export type GiftCardPurchasedDistributionChannel = {
  __typename?: 'GiftCardPurchasedDistributionChannel';
  consumerLimitPerOffer?: Maybe<Scalars['Int']>;
  duration?: Maybe<MarketingOfferDuration>;
  inventory?: Maybe<MarketingOfferInventory>;
  purchasePriceInCents?: Maybe<Scalars['Int']>;
};

export type GiftCardRedemptionRuleSet =
  | AtLeastOneBrandRedemptionRule
  | AtLeastOneProductRedemptionRule;

export type GiftCardRedemptionRulesConnection = {
  __typename?: 'GiftCardRedemptionRulesConnection';
  ruleSet?: Maybe<GiftCardRedemptionRuleSet>;
};

export type GiftCardRemoveError = {
  __typename?: 'GiftCardRemoveError';
  error?: Maybe<Error>;
};

export type GiftCardRemovePayload = GiftCardRemoveSuccess | GiftCardRemoveError;

export type GiftCardRemoveSuccess = {
  __typename?: 'GiftCardRemoveSuccess';
  success?: Maybe<Scalars['Boolean']>;
};

export type GiftCardShareError = {
  __typename?: 'GiftCardShareError';
  error?: Maybe<Error>;
};

export type GiftCardShareInput = {
  id: Scalars['ID'];
  email: Scalars['String'];
};

export type GiftCardSharePayload = GiftCardShareSuccess | GiftCardShareError;

export type GiftCardShareSuccess = {
  __typename?: 'GiftCardShareSuccess';
  success?: Maybe<Scalars['Boolean']>;
};

export type GiftCardsByKeywordConnection = {
  __typename?: 'GiftCardsByKeywordConnection';
  nodes: Array<GiftCard>;
};

export type GrowActor = GrowSystemUser | Actor;

export type GrowSystemUser = {
  __typename?: 'GrowSystemUser';
  name: Scalars['String'];
};

export type HumanActor = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export enum Integration_Platform {
  Facebook = 'FACEBOOK',
}

export type IsiContent = {
  __typename?: 'ISIContent';
  majorStatementFirstLineHTML?: Maybe<Scalars['HTML']>;
  consumerHTML?: Maybe<Scalars['HTML']>;
  consumerHTMLV2?: Maybe<Scalars['HTML']>;
  consumerAbbreviatedHTML?: Maybe<Scalars['HTML']>;
  providerHTML?: Maybe<Scalars['HTML']>;
};

export type IsiPrescribingInformation = {
  __typename?: 'ISIPrescribingInformation';
  statementHTML?: Maybe<Scalars['HTML']>;
  url?: Maybe<Scalars['URL']>;
};

export enum IsiType {
  Consumer = 'CONSUMER',
  Provider = 'PROVIDER',
}

export type IsIsConnection = {
  __typename?: 'ISIsConnection';
  /** @deprecated Use nodes instead */
  isis: Array<CmsLegalContent>;
  nodes: Array<CmsLegalContent>;
};

export type Image = {
  __typename?: 'Image';
  src: Scalars['URL'];
  alt: Scalars['String'];
};

export enum InsightsCategory {
  Flash = 'FLASH',
  GiftCards = 'GIFT_CARDS',
  Points = 'POINTS',
  Promotions = 'PROMOTIONS',
  Transactions = 'TRANSACTIONS',
}

export type InsightsColumn = {
  __typename?: 'InsightsColumn';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: InsightsCategory;
  sortable: Scalars['Boolean'];
};

export type InsightsReport = {
  __typename?: 'InsightsReport';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: InsightsCategory;
  columns: Array<InsightsColumn>;
};

export type ItemTypeTaxRateInput = {
  itemType: Scalars['String'];
  taxRatePercent: Scalars['Float'];
};

export enum Leads_By_Source_Sort_Column {
  CreatedAt = 'CREATED_AT',
}

export enum Leads_By_Source_Sort_Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum Leads_Export_Format {
  Csv = 'CSV',
  Xlsx = 'XLSX',
}

export enum Leads_Export_Sort_Column {
  CreatedAt = 'CREATED_AT',
}

export enum Leads_Export_Sort_Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum Lead_Contacted_Status {
  Contacted = 'CONTACTED',
  NotContacted = 'NOT_CONTACTED',
}

export enum Lead_Source_Type {
  /** A helper lead source type used to retrieve all kinds of leads */
  All = 'ALL',
  Unknown = 'UNKNOWN',
  Advertisement = 'ADVERTISEMENT',
  FindAProvider = 'FIND_A_PROVIDER',
  ConsultationRequest = 'CONSULTATION_REQUEST',
}

export enum Location_Catalog_Enhanced_Checkout_Setup_State {
  SetupInProgress = 'SETUP_IN_PROGRESS',
  SetupCompleted = 'SETUP_COMPLETED',
}

export type Lead = {
  __typename?: 'Lead';
  id: Scalars['ID'];
  patient?: Maybe<Patient>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  phoneNumber: Scalars['String'];
  source: LeadSource;
  /** @deprecated Please use hasBeenContacted instead. */
  contactedStatus: Lead_Contacted_Status;
  hasBeenContacted: Scalars['Boolean'];
  hasBookedAppointment: Scalars['Boolean'];
  viewedAt?: Maybe<DateTime>;
  createdAt: DateTime;
  notes?: Maybe<LeadNotesConnection>;
};

export type LeadNotesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type LeadAddNoteFailure = {
  __typename?: 'LeadAddNoteFailure';
  error: Error;
};

export type LeadAddNoteInput = {
  id: Scalars['ID'];
  textContent: Scalars['String'];
};

export type LeadAddNotePayload = LeadAddNoteSuccess | LeadAddNoteFailure;

export type LeadAddNoteSuccess = {
  __typename?: 'LeadAddNoteSuccess';
  note: LeadNote;
};

export type LeadContactedStatusUpdateError = {
  __typename?: 'LeadContactedStatusUpdateError';
  error: Error;
};

export type LeadContactedStatusUpdateInput = {
  id: Scalars['ID'];
  status: Lead_Contacted_Status;
};

export type LeadContactedStatusUpdatePayload =
  | LeadContactedStatusUpdateSuccess
  | LeadContactedStatusUpdateError;

export type LeadContactedStatusUpdateSuccess = {
  __typename?: 'LeadContactedStatusUpdateSuccess';
  lead: Lead;
};

export type LeadNote = {
  __typename?: 'LeadNote';
  id: Scalars['ID'];
  textContent: Scalars['String'];
  viewerCanEdit: Scalars['Boolean'];
  viewerCanRemove: Scalars['Boolean'];
  createdAt: DateTime;
  /** @deprecated Please use createdByV2 */
  createdBy: Actor;
  createdByV2: ActorV2;
  lastEditedAt?: Maybe<DateTime>;
  /** @deprecated Please use lastEditedByV2 */
  lastEditedBy?: Maybe<Actor>;
  lastEditedByV2?: Maybe<ActorV2>;
};

export type LeadNoteEditFailure = {
  __typename?: 'LeadNoteEditFailure';
  error: Error;
};

export type LeadNoteEditInput = {
  id: Scalars['ID'];
  textContent: Scalars['String'];
};

export type LeadNoteEditPayload = LeadNoteEditSuccess | LeadNoteEditFailure;

export type LeadNoteEditSuccess = {
  __typename?: 'LeadNoteEditSuccess';
  note: LeadNote;
};

export type LeadNoteRemoveFailure = {
  __typename?: 'LeadNoteRemoveFailure';
  error: Error;
};

export type LeadNoteRemovePayload =
  | LeadNoteRemoveSuccess
  | LeadNoteRemoveFailure;

export type LeadNoteRemoveSuccess = {
  __typename?: 'LeadNoteRemoveSuccess';
  lead: Lead;
};

export type LeadNotesConnection = {
  __typename?: 'LeadNotesConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<LeadNote>;
};

export type LeadSetHasBookedAppointmentFailure = {
  __typename?: 'LeadSetHasBookedAppointmentFailure';
  error: Error;
};

export type LeadSetHasBookedAppointmentInput = {
  id: Scalars['ID'];
  hasBookedAppointment: Scalars['Boolean'];
};

export type LeadSetHasBookedAppointmentPayload =
  | LeadSetHasBookedAppointmentSuccess
  | LeadSetHasBookedAppointmentFailure;

export type LeadSetHasBookedAppointmentSuccess = {
  __typename?: 'LeadSetHasBookedAppointmentSuccess';
  lead: Lead;
};

export type LeadSetIsViewedError = {
  __typename?: 'LeadSetIsViewedError';
  error: Error;
};

export type LeadSetIsViewedPayload =
  | LeadSetIsViewedSuccess
  | LeadSetIsViewedError;

export type LeadSetIsViewedSuccess = {
  __typename?: 'LeadSetIsViewedSuccess';
  lead: Lead;
};

export type LeadSource = {
  id: Scalars['ID'];
  url?: Maybe<Scalars['URL']>;
};

export type LeadsBySourceFilter = {
  /** @deprecated use sourceTypes instead */
  sourceType?: InputMaybe<Lead_Source_Type>;
  sourceTypes?: InputMaybe<Array<Lead_Source_Type>>;
  sourceId?: InputMaybe<Scalars['ID']>;
  contactedStatus?: InputMaybe<Lead_Contacted_Status>;
  hasBookedAppointment?: InputMaybe<Scalars['Boolean']>;
};

export type LeadsBySourceSort = {
  column?: InputMaybe<Leads_By_Source_Sort_Column>;
  order?: InputMaybe<Leads_By_Source_Sort_Order>;
};

export type LeadsExportError = {
  __typename?: 'LeadsExportError';
  error: Error;
};

export type LeadsExportFilter = {
  /** @deprecated use sourceTypes instead */
  sourceType?: InputMaybe<Lead_Source_Type>;
  sourceTypes?: InputMaybe<Array<Lead_Source_Type>>;
  sourceId?: InputMaybe<Scalars['ID']>;
  contactedStatus?: InputMaybe<Lead_Contacted_Status>;
  hasBookedAppointment?: InputMaybe<Scalars['Boolean']>;
};

export type LeadsExportInput = {
  exportFormat: Leads_Export_Format;
  filter?: InputMaybe<LeadsExportFilter>;
  sort?: InputMaybe<Array<LeadsExportSort>>;
};

export type LeadsExportPayload = LeadsExportSuccess | LeadsExportError;

export type LeadsExportSort = {
  column?: InputMaybe<Leads_Export_Sort_Column>;
  order?: InputMaybe<Leads_Export_Sort_Order>;
};

export type LeadsExportSuccess = {
  __typename?: 'LeadsExportSuccess';
  url: Scalars['URL'];
};

export type LeadsHub = {
  __typename?: 'LeadsHub';
  leadsPendingAppointmentCount: Scalars['Int'];
};

export type LegacyPracticeUserActor = ActorV2 &
  HumanActor & {
    __typename?: 'LegacyPracticeUserActor';
    id: Scalars['ID'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
  };

export type LocationCatalog = {
  __typename?: 'LocationCatalog';
  baseCatalog?: Maybe<BaseCatalog>;
  /** the current status of the enhanced checkout setup process for this location catalog - can be null for a new location catalog */
  enhancedCheckoutSetupStatus?: Maybe<LocationCatalogEnhancedCheckoutSetupStatus>;
  /** categories will return all of the categories which exist under this Location Catalog */
  categories: LocationCatalogCategoriesConnection;
  /** a list of all possible categories that an item in this location catalog can belong to */
  assignableCategories: CatalogCategoriesConnection;
  createdAt: DateTime;
  id: Scalars['ID'];
  items: LocationCatalogItemsConnection;
  location: ProviderOrganization;
  taxRates?: Maybe<LocationCatalogTaxRatesConnection>;
  updatedAt: DateTime;
};

export type LocationCatalogCategoriesArgs = {
  filter?: InputMaybe<LocationCatalogCategoriesFilter>;
};

export type LocationCatalogItemsArgs = {
  filter?: InputMaybe<LocationCatalogItemsFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type LocationCatalogAccessControl = {
  __typename?: 'LocationCatalogAccessControl';
  canViewCatalogSetupStatus: Scalars['Boolean'];
  canUpdateCatalogSetupStatus: Scalars['Boolean'];
};

export type LocationCatalogBrand = {
  __typename?: 'LocationCatalogBrand';
  createdAt: DateTime;
  displayName: Scalars['String'];
  displayOrder: Scalars['Int'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  /** a custom brand is one manually specified by a location admin as opposed to being inherited from a base catalog */
  isCustom: Scalars['Boolean'];
  items?: Maybe<LocationCatalogBrandItemsConnection>;
  name: Scalars['String'];
  updatedAt: DateTime;
};

export type LocationCatalogBrandItemsConnection = {
  __typename?: 'LocationCatalogBrandItemsConnection';
  nodes: Array<LocationCatalogItem>;
};

export type LocationCatalogCategoriesConnection = {
  __typename?: 'LocationCatalogCategoriesConnection';
  nodes: Array<LocationCatalogCategory>;
};

export type LocationCatalogCategoriesFilter = {
  includeInactive?: InputMaybe<Scalars['Boolean']>;
  includeCustom?: InputMaybe<Scalars['Boolean']>;
};

export type LocationCatalogCategory = {
  __typename?: 'LocationCatalogCategory';
  createdAt: DateTime;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  /** a custom category is one manually specified by a location admin as opposed to being inherited from a base catalog */
  isCustom: Scalars['Boolean'];
  items: LocationCatalogCategoryItemsConnection;
  name: Scalars['String'];
  updatedAt: DateTime;
};

export type LocationCatalogCategoryItemsConnection = {
  __typename?: 'LocationCatalogCategoryItemsConnection';
  nodes: Array<LocationCatalogItem>;
};

export type LocationCatalogCreateFailure = {
  __typename?: 'LocationCatalogCreateFailure';
  error: Error;
};

export type LocationCatalogCreatePayload =
  | LocationCatalogCreateFailure
  | LocationCatalogCreateSuccess;

export type LocationCatalogCreateSuccess = {
  __typename?: 'LocationCatalogCreateSuccess';
  locationCatalog: LocationCatalog;
};

export type LocationCatalogEnhancedCheckoutSetupStatus = {
  __typename?: 'LocationCatalogEnhancedCheckoutSetupStatus';
  /** The current status of the setup process - can be null for a new location catalog */
  current?: Maybe<LocationCatalogEnhancedCheckoutSetupStatusItem>;
  history: LocationCatalogEnhancedCheckoutSetupStatusItemsConnection;
};

export type LocationCatalogEnhancedCheckoutSetupStatusItem = {
  __typename?: 'LocationCatalogEnhancedCheckoutSetupStatusItem';
  id: Scalars['ID'];
  setupState: Location_Catalog_Enhanced_Checkout_Setup_State;
  createdAt: DateTime;
};

export type LocationCatalogEnhancedCheckoutSetupStatusItemsConnection = {
  __typename?: 'LocationCatalogEnhancedCheckoutSetupStatusItemsConnection';
  /** The history of the setup process - can be empty for a new location catalog */
  nodes: Array<LocationCatalogEnhancedCheckoutSetupStatusItem>;
};

export type LocationCatalogEnhancedCheckoutSetupStatusUpdateFailure = {
  __typename?: 'LocationCatalogEnhancedCheckoutSetupStatusUpdateFailure';
  error: Error;
};

export type LocationCatalogEnhancedCheckoutSetupStatusUpdateInput = {
  id: Scalars['ID'];
  setupState: Location_Catalog_Enhanced_Checkout_Setup_State;
};

export type LocationCatalogEnhancedCheckoutSetupStatusUpdatePayload =
  | LocationCatalogEnhancedCheckoutSetupStatusUpdateFailure
  | LocationCatalogEnhancedCheckoutSetupStatusUpdateSuccess;

export type LocationCatalogEnhancedCheckoutSetupStatusUpdateSuccess = {
  __typename?: 'LocationCatalogEnhancedCheckoutSetupStatusUpdateSuccess';
  locationCatalog: LocationCatalog;
};

export type LocationCatalogItem = {
  __typename?: 'LocationCatalogItem';
  brand?: Maybe<LocationCatalogBrand>;
  category?: Maybe<LocationCatalogCategory>;
  createdAt: DateTime;
  id: Scalars['ID'];
  internalName?: Maybe<Scalars['String']>;
  /** @deprecated Use status instead */
  isActive: Scalars['Boolean'];
  status: Catalog_Item_Status;
  isAvailableFromManufacturer: Scalars['Boolean'];
  isLocationFavorite: Scalars['Boolean'];
  itemType: Scalars['String'];
  manufacturer?: Maybe<LocationCatalogManufacturer>;
  name: Scalars['String'];
  pricingInfo: LocationCatalogItemPricingInfo;
  baseCatalogItem?: Maybe<BaseCatalogItem>;
  rewardsProduct?: Maybe<Product>;
  skuNumber?: Maybe<Scalars['String']>;
  unitType: BaseCatalogItemUnitType;
  assignableUnitTypes: BaseCatalogItemUnitTypesConnection;
  updatedAt: DateTime;
};

export type LocationCatalogItemCreateFailure = {
  __typename?: 'LocationCatalogItemCreateFailure';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationCatalogItemCreateInput = {
  locationCatalogId: Scalars['ID'];
  itemInfo: LocationCatalogItemInfoInput;
};

export type LocationCatalogItemCreatePayload =
  | LocationCatalogItemCreateSuccess
  | LocationCatalogItemCreateFailure;

export type LocationCatalogItemCreateSuccess = {
  __typename?: 'LocationCatalogItemCreateSuccess';
  locationCatalogItem: LocationCatalogItem;
};

export type LocationCatalogItemEditFailure = {
  __typename?: 'LocationCatalogItemEditFailure';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationCatalogItemEditInput = {
  id: Scalars['ID'];
  itemInfo: LocationCatalogItemInfoInput;
};

export type LocationCatalogItemEditPayload =
  | LocationCatalogItemEditSuccess
  | LocationCatalogItemEditFailure;

export type LocationCatalogItemEditSuccess = {
  __typename?: 'LocationCatalogItemEditSuccess';
  locationCatalogItem: LocationCatalogItem;
};

export type LocationCatalogItemInfoInput = {
  /**
   * Represents the base information for creating or editing a location catalog item.
   * Used as a field in LocationCatalogItemCreateInput and LocationCatalogItemEditInput
   */
  itemName: Scalars['String'];
  itemType: Scalars['String'];
  status: Catalog_Item_Status;
  isLocationFavorite: Scalars['Boolean'];
  isAvailableFromManufacturer: Scalars['Boolean'];
  brandName: Scalars['String'];
  categoryId: LocationOrBaseCatalogEntityId;
  manufacturerName: Scalars['String'];
  unitTypeId: Scalars['ID'];
  price: MoneyInput;
  taxRatePercent?: InputMaybe<Scalars['Float']>;
};

export type LocationCatalogItemPricingInfo = {
  __typename?: 'LocationCatalogItemPricingInfo';
  catalogTaxRatePercent?: Maybe<Scalars['Float']>;
  maximumSalesQuantity?: Maybe<Scalars['Int']>;
  price: Money;
  taxRatePercent?: Maybe<Scalars['Float']>;
};

export type LocationCatalogItemSetLocationFavoriteFailure = {
  __typename?: 'LocationCatalogItemSetLocationFavoriteFailure';
  error: Error;
};

export type LocationCatalogItemSetLocationFavoriteInput = {
  id: Scalars['ID'];
  isLocationFavorite: Scalars['Boolean'];
};

export type LocationCatalogItemSetLocationFavoritePayload =
  | LocationCatalogItemSetLocationFavoriteFailure
  | LocationCatalogItemSetLocationFavoriteSuccess;

export type LocationCatalogItemSetLocationFavoriteSuccess = {
  __typename?: 'LocationCatalogItemSetLocationFavoriteSuccess';
  locationCatalogItem: LocationCatalogItem;
};

export type LocationCatalogItemSetManufacturerAvailabilityFailure = {
  __typename?: 'LocationCatalogItemSetManufacturerAvailabilityFailure';
  error: Error;
};

export type LocationCatalogItemSetManufacturerAvailabilityInput = {
  id: Scalars['ID'];
  isAvailableFromManufacturer: Scalars['Boolean'];
};

export type LocationCatalogItemSetManufacturerAvailabilityPayload =
  | LocationCatalogItemSetManufacturerAvailabilityFailure
  | LocationCatalogItemSetManufacturerAvailabilitySuccess;

export type LocationCatalogItemSetManufacturerAvailabilitySuccess = {
  __typename?: 'LocationCatalogItemSetManufacturerAvailabilitySuccess';
  locationCatalogItem: LocationCatalogItem;
};

export type LocationCatalogItemSetStatusFailure = {
  __typename?: 'LocationCatalogItemSetStatusFailure';
  error: Error;
};

export type LocationCatalogItemSetStatusInput = {
  id: Scalars['ID'];
  status: Catalog_Item_Status;
};

export type LocationCatalogItemSetStatusPayload =
  | LocationCatalogItemSetStatusFailure
  | LocationCatalogItemSetStatusSuccess;

export type LocationCatalogItemSetStatusSuccess = {
  __typename?: 'LocationCatalogItemSetStatusSuccess';
  locationCatalogItem: LocationCatalogItem;
};

export type LocationCatalogItemUpdateFailure = {
  __typename?: 'LocationCatalogItemUpdateFailure';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationCatalogItemUpdateInput = {
  locationCatalogItemId: Scalars['ID'];
  price?: InputMaybe<MoneyInput>;
  taxRatePercent?: InputMaybe<Scalars['Float']>;
  unitTypeId?: InputMaybe<Scalars['ID']>;
};

export type LocationCatalogItemUpdatePayload =
  | LocationCatalogItemUpdateSuccess
  | LocationCatalogItemUpdateFailure;

export type LocationCatalogItemUpdateSuccess = {
  __typename?: 'LocationCatalogItemUpdateSuccess';
  locationCatalogItem: LocationCatalogItem;
};

export type LocationCatalogItemsConnection = {
  __typename?: 'LocationCatalogItemsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<LocationCatalogItem>;
};

export type LocationCatalogItemsFilter = {
  categoryId?: InputMaybe<Scalars['ID']>;
  locationFavoritesOnly?: InputMaybe<Scalars['Boolean']>;
  includeInactive?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
};

export type LocationCatalogManufacturer = {
  __typename?: 'LocationCatalogManufacturer';
  createdAt: DateTime;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  /** a custom manufacturer is one manually specified by a location admin as opposed to being inherited from a base catalog */
  isCustom: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: DateTime;
};

export type LocationCatalogSetBaseCatalogItemsFailure = {
  __typename?: 'LocationCatalogSetBaseCatalogItemsFailure';
  error: Error;
};

export type LocationCatalogSetBaseCatalogItemsInput = {
  id: Scalars['ID'];
  baseCatalogItemIds: Array<Scalars['ID']>;
};

export type LocationCatalogSetBaseCatalogItemsPayload =
  | LocationCatalogSetBaseCatalogItemsFailure
  | LocationCatalogSetBaseCatalogItemsSuccess;

export type LocationCatalogSetBaseCatalogItemsSuccess = {
  __typename?: 'LocationCatalogSetBaseCatalogItemsSuccess';
  locationCatalog: LocationCatalog;
};

export type LocationCatalogSetTaxRatesFailure = {
  __typename?: 'LocationCatalogSetTaxRatesFailure';
  error: Error;
};

export type LocationCatalogSetTaxRatesInput = {
  id: Scalars['ID'];
  taxRates: Array<ItemTypeTaxRateInput>;
};

export type LocationCatalogSetTaxRatesPayload =
  | LocationCatalogSetTaxRatesFailure
  | LocationCatalogSetTaxRatesSuccess;

export type LocationCatalogSetTaxRatesSuccess = {
  __typename?: 'LocationCatalogSetTaxRatesSuccess';
  locationCatalog: LocationCatalog;
};

export type LocationCatalogTaxRate = {
  __typename?: 'LocationCatalogTaxRate';
  itemType: Scalars['String'];
  taxRatePercent: Scalars['Float'];
};

export type LocationCatalogTaxRatesConnection = {
  __typename?: 'LocationCatalogTaxRatesConnection';
  nodes: Array<LocationCatalogTaxRate>;
};

export type LocationCloseWorkflow = {
  __typename?: 'LocationCloseWorkflow';
  id: Scalars['ID'];
  showInAppNotificationAfter: DateTime;
  inAppNotification: LocationCloseWorkflowInAppNotification;
  sendEmailNotificationAfter?: Maybe<DateTime>;
  emailNotificationCompletedAt?: Maybe<DateTime>;
  disableNewTransactionsAfter?: Maybe<DateTime>;
  transferDataAfter?: Maybe<DateTime>;
  transferDataResult?: Maybe<Scalars['String']>;
  transferDataCompletedAt?: Maybe<DateTime>;
  archiveAfter?: Maybe<DateTime>;
  archiveCompletedAt?: Maybe<DateTime>;
  createdAt: DateTime;
};

export type LocationCloseWorkflowInAppNotification = {
  __typename?: 'LocationCloseWorkflowInAppNotification';
  providerOrganizationId: Scalars['ID'];
  primaryBusinessAdminEmailAddress?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  longFormMessage?: Maybe<Scalars['String']>;
};

export type LocationOrBaseCatalogEntityId = {
  locationCatalogEntityId?: InputMaybe<Scalars['ID']>;
  baseCatalogEntityId?: InputMaybe<Scalars['ID']>;
};

export type LocationPublicProfile = {
  __typename?: 'LocationPublicProfile';
  about?: Maybe<Scalars['String']>;
  address: ProviderAddress;
  avatarUrl?: Maybe<Scalars['URL']>;
  businessHours?: Maybe<ProviderBusinessHoursConnection>;
  consultationRequestSettings?: Maybe<ProviderConsultationRequestSettings>;
  contactInformation?: Maybe<ProviderContactInformation>;
  displayName: Scalars['String'];
  practiceType?: Maybe<Provider_Practice_Type>;
  galleryPhotos?: Maybe<ProviderGalleryPhotosConnection>;
  geolocation: ProviderGeolocation;
  id: Scalars['ID'];
  parentOrganization: ProviderOrganization;
  products?: Maybe<ProviderProductsConnection>;
  profileSlug: Scalars['String'];
  providerOrganizationId: Scalars['String'];
  publishedAt: DateTime;
  settings?: Maybe<ProviderSettings>;
  /** @deprecated shipToAccountNumber is deprecated. Use providerOrganizationId instead. */
  shipToAccountNumber: Scalars['String'];
  specialHoursNotices: Array<SpecialHoursNotice>;
  languages?: Maybe<LocationPublicProfileLanguagesConnection>;
  indicators: ProviderSearchIndicatorsConnection;
  offersFinancing?: Maybe<Scalars['Boolean']>;
  /** @deprecated Field no longer supported */
  osHitId?: Maybe<Scalars['String']>;
  profileCompletenessPercentage: Scalars['Float'];
  searchHoursFilters?: Maybe<SearchHoursFilters>;
};

export type LocationPublicProfileBatchDraftAddGalleryPhotosError = {
  __typename?: 'LocationPublicProfileBatchDraftAddGalleryPhotosError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileBatchDraftAddGalleryPhotosInput = {
  photos: Array<GalleryPhotoCreateInput>;
};

export type LocationPublicProfileBatchDraftAddGalleryPhotosPayload =
  | LocationPublicProfileBatchDraftAddGalleryPhotosError
  | LocationPublicProfileBatchDraftAddGalleryPhotosSuccess;

export type LocationPublicProfileBatchDraftAddGalleryPhotosSuccess = {
  __typename?: 'LocationPublicProfileBatchDraftAddGalleryPhotosSuccess';
  galleryPhotos: Array<GalleryPhoto>;
};

export type LocationPublicProfileBatchDraftAddProductsError = {
  __typename?: 'LocationPublicProfileBatchDraftAddProductsError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileBatchDraftAddProductsInput = {
  products: Array<ProviderProductInput>;
};

export type LocationPublicProfileBatchDraftAddProductsPayload =
  | LocationPublicProfileBatchDraftAddProductsError
  | LocationPublicProfileBatchDraftAddProductsSuccess;

export type LocationPublicProfileBatchDraftAddProductsSuccess = {
  __typename?: 'LocationPublicProfileBatchDraftAddProductsSuccess';
  products: Array<ProviderProfileProduct>;
};

export type LocationPublicProfileBatchDraftApplyError = {
  __typename?: 'LocationPublicProfileBatchDraftApplyError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileBatchDraftApplyInput = {
  providerOrganizationIds: Array<Scalars['ID']>;
};

export type LocationPublicProfileBatchDraftApplyPayload =
  | LocationPublicProfileBatchDraftApplyError
  | LocationPublicProfileBatchDraftApplySuccess;

export type LocationPublicProfileBatchDraftApplySuccess = {
  __typename?: 'LocationPublicProfileBatchDraftApplySuccess';
  providers: Array<Provider>;
};

export type LocationPublicProfileBatchDraftContactInformation = {
  __typename?: 'LocationPublicProfileBatchDraftContactInformation';
  facebookUrl: Scalars['URL'];
  instagramHandle: Scalars['String'];
  tikTokHandle: Scalars['String'];
  shopUrl?: Maybe<Scalars['URL']>;
  bookingUrl?: Maybe<Scalars['URL']>;
};

export type LocationPublicProfileBatchDraftGalleryPhotosDeleteError = {
  __typename?: 'LocationPublicProfileBatchDraftGalleryPhotosDeleteError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileBatchDraftGalleryPhotosDeletePayload =
  | LocationPublicProfileBatchDraftGalleryPhotosDeleteError
  | LocationPublicProfileBatchDraftGalleryPhotosDeleteSuccess;

export type LocationPublicProfileBatchDraftGalleryPhotosDeleteSuccess = {
  __typename?: 'LocationPublicProfileBatchDraftGalleryPhotosDeleteSuccess';
  galleryPhotos: Array<GalleryPhoto>;
};

export type LocationPublicProfileBatchDraftGalleryPhotosUpdateError = {
  __typename?: 'LocationPublicProfileBatchDraftGalleryPhotosUpdateError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileBatchDraftGalleryPhotosUpdateInput = {
  photos: Array<GalleryPhotoUpdateInput>;
};

export type LocationPublicProfileBatchDraftGalleryPhotosUpdatePayload =
  | LocationPublicProfileBatchDraftGalleryPhotosUpdateError
  | LocationPublicProfileBatchDraftGalleryPhotosUpdateSuccess;

export type LocationPublicProfileBatchDraftGalleryPhotosUpdateSuccess = {
  __typename?: 'LocationPublicProfileBatchDraftGalleryPhotosUpdateSuccess';
  galleryPhotos: Array<GalleryPhoto>;
};

export type LocationPublicProfileBatchDraftProductsDeleteError = {
  __typename?: 'LocationPublicProfileBatchDraftProductsDeleteError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileBatchDraftProductsDeletePayload =
  | LocationPublicProfileBatchDraftProductsDeleteError
  | LocationPublicProfileBatchDraftProductsDeleteSuccess;

export type LocationPublicProfileBatchDraftProductsDeleteSuccess = {
  __typename?: 'LocationPublicProfileBatchDraftProductsDeleteSuccess';
  products: Array<ProviderProfileProduct>;
};

export type LocationPublicProfileBatchDraftUpdateAvatarUrlError = {
  __typename?: 'LocationPublicProfileBatchDraftUpdateAvatarUrlError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileBatchDraftUpdateAvatarUrlInput = {
  avatarUrl: Scalars['URL'];
};

export type LocationPublicProfileBatchDraftUpdateAvatarUrlPayload =
  | LocationPublicProfileBatchDraftUpdateAvatarUrlError
  | LocationPublicProfileBatchDraftUpdateAvatarUrlSuccess;

export type LocationPublicProfileBatchDraftUpdateAvatarUrlSuccess = {
  __typename?: 'LocationPublicProfileBatchDraftUpdateAvatarUrlSuccess';
  avatarUrl: Scalars['URL'];
};

export type LocationPublicProfileBatchDraftUpdateContactInformationError = {
  __typename?: 'LocationPublicProfileBatchDraftUpdateContactInformationError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileBatchDraftUpdateContactInformationInput = {
  facebookUrl: Scalars['URL'];
  instagramHandle: Scalars['String'];
  tikTokHandle: Scalars['String'];
  shopUrl?: InputMaybe<Scalars['URL']>;
  bookingUrl?: InputMaybe<Scalars['URL']>;
};

export type LocationPublicProfileBatchDraftUpdateContactInformationPayload =
  | LocationPublicProfileBatchDraftUpdateContactInformationError
  | LocationPublicProfileBatchDraftUpdateContactInformationSuccess;

export type LocationPublicProfileBatchDraftUpdateContactInformationSuccess = {
  __typename?: 'LocationPublicProfileBatchDraftUpdateContactInformationSuccess';
  contactInformation: LocationPublicProfileBatchDraftContactInformation;
};

export type LocationPublicProfileBatchDraftUpdateSettingsError = {
  __typename?: 'LocationPublicProfileBatchDraftUpdateSettingsError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileBatchDraftUpdateSettingsInput = {
  displayAveragePricing: Scalars['Boolean'];
};

export type LocationPublicProfileBatchDraftUpdateSettingsPayload =
  | LocationPublicProfileBatchDraftUpdateSettingsError
  | LocationPublicProfileBatchDraftUpdateSettingsSuccess;

export type LocationPublicProfileBatchDraftUpdateSettingsSuccess = {
  __typename?: 'LocationPublicProfileBatchDraftUpdateSettingsSuccess';
  settings: ProviderSettings;
};

export type LocationPublicProfileDraftAddGalleryPhotosError = {
  __typename?: 'LocationPublicProfileDraftAddGalleryPhotosError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftAddGalleryPhotosInput = {
  photos: Array<GalleryPhotoCreateInput>;
};

export type LocationPublicProfileDraftAddGalleryPhotosPayload =
  | LocationPublicProfileDraftAddGalleryPhotosError
  | LocationPublicProfileDraftAddGalleryPhotosSuccess;

export type LocationPublicProfileDraftAddGalleryPhotosSuccess = {
  __typename?: 'LocationPublicProfileDraftAddGalleryPhotosSuccess';
  galleryPhotos: Array<GalleryPhoto>;
};

export type LocationPublicProfileDraftAddLanguagesFailure = {
  __typename?: 'LocationPublicProfileDraftAddLanguagesFailure';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftAddLanguagesInput = {
  providerOrganizationId: Scalars['ID'];
  languageIds: Array<Scalars['ID']>;
};

export type LocationPublicProfileDraftAddLanguagesPayload =
  | LocationPublicProfileDraftAddLanguagesFailure
  | LocationPublicProfileDraftAddLanguagesSuccess;

export type LocationPublicProfileDraftAddLanguagesSuccess = {
  __typename?: 'LocationPublicProfileDraftAddLanguagesSuccess';
  languages: Array<ProviderLanguage>;
};

export type LocationPublicProfileDraftAddProductsError = {
  __typename?: 'LocationPublicProfileDraftAddProductsError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftAddProductsInput = {
  products: Array<ProviderProductInput>;
};

export type LocationPublicProfileDraftAddProductsPayload =
  | LocationPublicProfileDraftAddProductsError
  | LocationPublicProfileDraftAddProductsSuccess;

export type LocationPublicProfileDraftAddProductsSuccess = {
  __typename?: 'LocationPublicProfileDraftAddProductsSuccess';
  products: Array<ProviderProfileProduct>;
};

export type LocationPublicProfileDraftGalleryPhotosDeleteError = {
  __typename?: 'LocationPublicProfileDraftGalleryPhotosDeleteError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftGalleryPhotosDeletePayload =
  | LocationPublicProfileDraftGalleryPhotosDeleteError
  | LocationPublicProfileDraftGalleryPhotosDeleteSuccess;

export type LocationPublicProfileDraftGalleryPhotosDeleteSuccess = {
  __typename?: 'LocationPublicProfileDraftGalleryPhotosDeleteSuccess';
  galleryPhotos: Array<GalleryPhoto>;
};

export type LocationPublicProfileDraftGalleryPhotosUpdateError = {
  __typename?: 'LocationPublicProfileDraftGalleryPhotosUpdateError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftGalleryPhotosUpdateInput = {
  photos: Array<GalleryPhotoUpdateInput>;
};

export type LocationPublicProfileDraftGalleryPhotosUpdatePayload =
  | LocationPublicProfileDraftGalleryPhotosUpdateError
  | LocationPublicProfileDraftGalleryPhotosUpdateSuccess;

export type LocationPublicProfileDraftGalleryPhotosUpdateSuccess = {
  __typename?: 'LocationPublicProfileDraftGalleryPhotosUpdateSuccess';
  galleryPhotos: Array<GalleryPhoto>;
};

export type LocationPublicProfileDraftGoogleData = {
  __typename?: 'LocationPublicProfileDraftGoogleData';
  placeId?: Maybe<Scalars['String']>;
};

export type LocationPublicProfileDraftGoogleDataInput = {
  placeId?: InputMaybe<Scalars['String']>;
};

/** LocationPublicProfileDraftOptInMarketingEventsUpsert Response Options */
export type LocationPublicProfileDraftOptInMarketingEventsUpsertError = {
  __typename?: 'LocationPublicProfileDraftOptInMarketingEventsUpsertError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftOptInMarketingEventsUpsertInput = {
  providerOrganizationId: Scalars['ID'];
  marketingEvents: Array<ProviderOptInMarketingEventInput>;
};

export type LocationPublicProfileDraftOptInMarketingEventsUpsertPayload =
  | LocationPublicProfileDraftOptInMarketingEventsUpsertError
  | LocationPublicProfileDraftOptInMarketingEventsUpsertSuccess;

export type LocationPublicProfileDraftOptInMarketingEventsUpsertSuccess = {
  __typename?: 'LocationPublicProfileDraftOptInMarketingEventsUpsertSuccess';
  marketingEvents: Array<ProviderOptInMarketingEvent>;
};

export type LocationPublicProfileDraftProductsDeleteError = {
  __typename?: 'LocationPublicProfileDraftProductsDeleteError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftProductsDeletePayload =
  | LocationPublicProfileDraftProductsDeleteError
  | LocationPublicProfileDraftProductsDeleteSuccess;

export type LocationPublicProfileDraftProductsDeleteSuccess = {
  __typename?: 'LocationPublicProfileDraftProductsDeleteSuccess';
  products: Array<ProviderProfileProduct>;
};

export type LocationPublicProfileDraftPublishError = {
  __typename?: 'LocationPublicProfileDraftPublishError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftPublishPayload =
  | LocationPublicProfileDraftPublishError
  | LocationPublicProfileDraftPublishSuccess;

export type LocationPublicProfileDraftPublishSuccess = {
  __typename?: 'LocationPublicProfileDraftPublishSuccess';
  provider: Provider;
};

export type LocationPublicProfileDraftRemoveLanguagesFailure = {
  __typename?: 'LocationPublicProfileDraftRemoveLanguagesFailure';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftRemoveLanguagesInput = {
  providerOrganizationId: Scalars['ID'];
  languageIds: Array<Scalars['ID']>;
};

export type LocationPublicProfileDraftRemoveLanguagesPayload =
  | LocationPublicProfileDraftRemoveLanguagesFailure
  | LocationPublicProfileDraftRemoveLanguagesSuccess;

export type LocationPublicProfileDraftRemoveLanguagesSuccess = {
  __typename?: 'LocationPublicProfileDraftRemoveLanguagesSuccess';
  languages: Array<ProviderLanguage>;
};

export type LocationPublicProfileDraftUpdateAboutError = {
  __typename?: 'LocationPublicProfileDraftUpdateAboutError';
  error: Error;
};

export type LocationPublicProfileDraftUpdateAboutInput = {
  providerOrganizationId: Scalars['ID'];
  about?: InputMaybe<Scalars['String']>;
};

export type LocationPublicProfileDraftUpdateAboutPayload =
  | LocationPublicProfileDraftUpdateAboutError
  | LocationPublicProfileDraftUpdateAboutSuccess;

export type LocationPublicProfileDraftUpdateAboutSuccess = {
  __typename?: 'LocationPublicProfileDraftUpdateAboutSuccess';
  about?: Maybe<Scalars['String']>;
};

export type LocationPublicProfileDraftUpdateAddressError = {
  __typename?: 'LocationPublicProfileDraftUpdateAddressError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftUpdateAddressInput = {
  id: Scalars['ID'];
  address: ProviderAddressInput;
};

export type LocationPublicProfileDraftUpdateAddressPayload =
  | LocationPublicProfileDraftUpdateAddressError
  | LocationPublicProfileDraftUpdateAddressSuccess;

export type LocationPublicProfileDraftUpdateAddressSuccess = {
  __typename?: 'LocationPublicProfileDraftUpdateAddressSuccess';
  address: ProviderAddress;
};

export type LocationPublicProfileDraftUpdateBusinessHoursError = {
  __typename?: 'LocationPublicProfileDraftUpdateBusinessHoursError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftUpdateBusinessHoursInput = {
  businessHours: Array<ProviderBusinessHoursInput>;
};

export type LocationPublicProfileDraftUpdateBusinessHoursPayload =
  | LocationPublicProfileDraftUpdateBusinessHoursError
  | LocationPublicProfileDraftUpdateBusinessHoursSuccess;

export type LocationPublicProfileDraftUpdateBusinessHoursSuccess = {
  __typename?: 'LocationPublicProfileDraftUpdateBusinessHoursSuccess';
  businessHours: Array<ProviderBusinessHours>;
};

export type LocationPublicProfileDraftUpdateConsultationRequestSettingsError = {
  __typename?: 'LocationPublicProfileDraftUpdateConsultationRequestSettingsError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftUpdateConsultationRequestSettingsInput = {
  consultationCostInCents?: InputMaybe<Scalars['Int']>;
  displayVaryingConsultationFeeMessage?: InputMaybe<Scalars['Boolean']>;
  feeTowardsTreatmentCost?: InputMaybe<Scalars['Boolean']>;
  hideForm?: InputMaybe<Scalars['Boolean']>;
  sendRequestTo?: InputMaybe<Scalars['String']>;
  offerVirtualConsultation?: InputMaybe<Scalars['Boolean']>;
};

export type LocationPublicProfileDraftUpdateConsultationRequestSettingsPayload =

    | LocationPublicProfileDraftUpdateConsultationRequestSettingsError
    | LocationPublicProfileDraftUpdateConsultationRequestSettingsSuccess;

export type LocationPublicProfileDraftUpdateConsultationRequestSettingsSuccess =
  {
    __typename?: 'LocationPublicProfileDraftUpdateConsultationRequestSettingsSuccess';
    consultationRequestSettings: ProviderConsultationRequestSettings;
  };

export type LocationPublicProfileDraftUpdateContactInformationError = {
  __typename?: 'LocationPublicProfileDraftUpdateContactInformationError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftUpdateContactInformationInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  facebookUrl?: InputMaybe<Scalars['URL']>;
  instagramHandle?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  tikTokHandle?: InputMaybe<Scalars['String']>;
  bookingUrl?: InputMaybe<Scalars['URL']>;
  shopUrl?: InputMaybe<Scalars['URL']>;
  websiteUrl?: InputMaybe<Scalars['URL']>;
};

export type LocationPublicProfileDraftUpdateContactInformationPayload =
  | LocationPublicProfileDraftUpdateContactInformationError
  | LocationPublicProfileDraftUpdateContactInformationSuccess;

export type LocationPublicProfileDraftUpdateContactInformationSuccess = {
  __typename?: 'LocationPublicProfileDraftUpdateContactInformationSuccess';
  contactInformation: ProviderContactInformation;
};

export type LocationPublicProfileDraftUpdateDetailsError = {
  __typename?: 'LocationPublicProfileDraftUpdateDetailsError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftUpdateDetailsInput = {
  avatarUrl?: InputMaybe<Scalars['URL']>;
  displayName?: InputMaybe<Scalars['String']>;
  practiceType?: InputMaybe<Provider_Practice_Type>;
};

export type LocationPublicProfileDraftUpdateDetailsPayload =
  | LocationPublicProfileDraftUpdateDetailsError
  | LocationPublicProfileDraftUpdateDetailsSuccess;

export type LocationPublicProfileDraftUpdateDetailsSuccess = {
  __typename?: 'LocationPublicProfileDraftUpdateDetailsSuccess';
  provider: Provider;
};

export type LocationPublicProfileDraftUpdateSettingsError = {
  __typename?: 'LocationPublicProfileDraftUpdateSettingsError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftUpdateSettingsInput = {
  displayAveragePricing: Scalars['Boolean'];
};

export type LocationPublicProfileDraftUpdateSettingsPayload =
  | LocationPublicProfileDraftUpdateSettingsError
  | LocationPublicProfileDraftUpdateSettingsSuccess;

export type LocationPublicProfileDraftUpdateSettingsSuccess = {
  __typename?: 'LocationPublicProfileDraftUpdateSettingsSuccess';
  settings: ProviderSettings;
};

export type LocationPublicProfileDraftUpsertGoogleDataError = {
  __typename?: 'LocationPublicProfileDraftUpsertGoogleDataError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type LocationPublicProfileDraftUpsertGoogleDataInput = {
  providerOrganizationId: Scalars['ID'];
  googleData: LocationPublicProfileDraftGoogleDataInput;
};

export type LocationPublicProfileDraftUpsertGoogleDataPayload =
  | LocationPublicProfileDraftUpsertGoogleDataError
  | LocationPublicProfileDraftUpsertGoogleDataSuccess;

export type LocationPublicProfileDraftUpsertGoogleDataSuccess = {
  __typename?: 'LocationPublicProfileDraftUpsertGoogleDataSuccess';
  googleData: LocationPublicProfileDraftGoogleData;
};

export type LocationPublicProfileLanguagesConnection = {
  __typename?: 'LocationPublicProfileLanguagesConnection';
  nodes: Array<ProviderLanguage>;
};

export type LoyaltyPoints = {
  __typename?: 'LoyaltyPoints';
  id?: Maybe<Scalars['ID']>;
  pending?: Maybe<Scalars['Int']>;
  redemptionRate?: Maybe<PointsRedemptionRate>;
  available?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Date>;
  tier: Consumer_Tier;
  tierPoints?: Maybe<Scalars['Int']>;
  tierThreshold?: Maybe<Scalars['Int']>;
  tierMemberSince?: Maybe<DateTime>;
  nextTier?: Maybe<Consumer_Tier>;
};

export type LoyaltyProfile = {
  __typename?: 'LoyaltyProfile';
  id?: Maybe<Scalars['ID']>;
  giftCards?: Maybe<Array<Maybe<GiftCard>>>;
  /** @deprecated Please use promotionsConnection */
  promotions?: Maybe<Array<Maybe<PatientPromotion>>>;
  promotionsConnection?: Maybe<LoyaltyProfilePromotionsConnection>;
  points?: Maybe<LoyaltyPoints>;
  isBDMember?: Maybe<Scalars['Boolean']>;
  /** Brilliant Distinctions GUID */
  bdMemberId?: Maybe<Scalars['ID']>;
  memberSince?: Maybe<Date>;
  tier: Consumer_Tier;
};

export type LoyaltyProfilePromotionsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  availability?: InputMaybe<Offer_Availability>;
};

export type LoyaltyProfilePromotionsConnection = {
  __typename?: 'LoyaltyProfilePromotionsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<PatientPromotion>;
};

export enum Marketing_Offer_Activation_Type {
  Earned = 'EARNED',
  Given = 'GIVEN',
  OptIn = 'OPT_IN',
  Purchased = 'PURCHASED',
  Flash = 'FLASH',
}

export enum Marketing_Offer_Approval_Request_Approval_Team {
  Promotion = 'PROMOTION',
  Product = 'PRODUCT',
}

export enum Marketing_Offer_Approval_Request_State {
  PendingApproval = 'PENDING_APPROVAL',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
}

export enum Marketing_Offer_Change_Request_State {
  PendingApproval = 'PENDING_APPROVAL',
  Published = 'PUBLISHED',
}

export enum Marketing_Offer_Distribution_Type {
  DirectToConsumer = 'DIRECT_TO_CONSUMER',
  PracticeToConsumer = 'PRACTICE_TO_CONSUMER',
}

export enum Marketing_Offer_Earned_Points_Brand_Inclusion_Level {
  All = 'ALL',
  Any = 'ANY',
}

export enum Marketing_Offer_Earned_Points_Type {
  Bonus = 'BONUS',
  Multiplier = 'MULTIPLIER',
}

export enum Marketing_Offer_Gift_Card_Type {
  Single = 'SINGLE',
  BuyOneGetOne = 'BUY_ONE_GET_ONE',
}

export enum Marketing_Offer_Quantity_Setup_Type {
  SameQuantityPerPractice = 'SAME_QUANTITY_PER_PRACTICE',
  DifferentQuantityPerPractice = 'DIFFERENT_QUANTITY_PER_PRACTICE',
}

export enum Marketing_Offer_Sort_Column {
  NaturalSort = 'NATURAL_SORT',
  State = 'STATE',
  RewardType = 'REWARD_TYPE',
  DistributionChannel = 'DISTRIBUTION_CHANNEL',
}

export enum Marketing_Offer_Sort_Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum Marketing_Offer_State {
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Live = 'LIVE',
  OnHold = 'ON_HOLD',
  PendingApproval = 'PENDING_APPROVAL',
  Declined = 'DECLINED',
  Scheduled = 'SCHEDULED',
  Approved = 'APPROVED',
  DistributionScheduled = 'DISTRIBUTION_SCHEDULED',
  DistributionError = 'DISTRIBUTION_ERROR',
}

export enum Marketing_Offer_Timezone {
  AmericaAnchorage = 'AMERICA_ANCHORAGE',
  AmericaChicago = 'AMERICA_CHICAGO',
  AmericaDenver = 'AMERICA_DENVER',
  AmericaLosAngeles = 'AMERICA_LOS_ANGELES',
  AmericaNewYork = 'AMERICA_NEW_YORK',
  AmericaPhoenix = 'AMERICA_PHOENIX',
  AmericaPuertoRico = 'AMERICA_PUERTO_RICO',
  PacificHonolulu = 'PACIFIC_HONOLULU',
}

export enum Marketing_Offer_Value_Type {
  DollarsOff = 'DOLLARS_OFF',
  Points = 'POINTS',
  GiftCard = 'GIFT_CARD',
}

export enum Month {
  Jan = 'JAN',
  Feb = 'FEB',
  Mar = 'MAR',
  Apr = 'APR',
  May = 'MAY',
  Jun = 'JUN',
  Jul = 'JUL',
  Aug = 'AUG',
  Sep = 'SEP',
  Oct = 'OCT',
  Nov = 'NOV',
  Dec = 'DEC',
}

export type MarketingOffer = {
  __typename?: 'MarketingOffer';
  id: Scalars['ID'];
  distributionType?: Maybe<Marketing_Offer_Distribution_Type>;
  /** This is the Internal Name */
  name: Scalars['String'];
  displayName: Scalars['String'];
  state?: Maybe<Marketing_Offer_State>;
  stateReason?: Maybe<Error>;
  universalMarketingId?: Maybe<Scalars['ID']>;
  viewerCanEdit: Scalars['Boolean'];
  viewerCanPublish: Scalars['Boolean'];
  viewerCanApprove: Scalars['Boolean'];
  viewerCanDecline: Scalars['Boolean'];
  viewerCanSubmitForApproval: Scalars['Boolean'];
  rewards?: Maybe<MarketingOfferRewardsConnection>;
  channel?: Maybe<MarketingOfferDistributionChannel>;
  changeRequests?: Maybe<MarketingOfferChangeRequestsConnection>;
  /** This value represents the loyalty construct id in downstream services */
  loyaltyConstructId?: Maybe<Scalars['ID']>;
  /** @deprecated Use channel type instead */
  activationType?: Maybe<Marketing_Offer_Activation_Type>;
  /**
   * Brand connection specific to the Dollars Off offer type (will be null for all other offer types).
   * This includes which brands the Dollars Off can be applied to during consumer checkout.
   * @deprecated See redeemableBrandsConnection in DollarsOffOfferReward
   */
  brandsConnection?: Maybe<MarketingOfferBrandsConnection>;
  /**
   * Product connection specific to the Dollars Off offer type (will be null for all other offer types).
   * This includes which products the Dollars Off can be applied to during consumer checkout.
   * @deprecated See redeemableProductsConnection in DollarsOffOfferReward
   */
  productsConnection?: Maybe<MarketingOfferProductsConnection>;
  /** @deprecated Use description found within various types of MarketingOfferReward */
  description?: Maybe<Scalars['String']>;
  /** @deprecated See duration found within various types of MarketingOfferDistributionChannel */
  duration?: Maybe<MarketingOfferDuration>;
  /**
   * Brand connections specific to the Earned Points offer type (will be null for all other offer types).
   * This includes which brands must be purchased to earn the offer, and which brands the offer will be applied to.
   * @deprecated Fields moved to PointsEarnedOfferReward
   */
  earnedPointsBrandsSelection?: Maybe<MarketingOfferEarnedPointsBrandsSelection>;
  /**
   * Details specific to the Earned Points offer type (will be null for all other offer types).
   * This includes which type of points, either bonus or multiplier, and the value of the points offer.
   * In addition, this will define the consumer limits for how many times this offer can be earned.
   * @deprecated Fields moved to PointsEarnedOfferReward
   */
  earnedPointsDetails?: Maybe<MarketingOfferEarnedPointsDetails>;
  /**
   * A fixed or relative expiration date for when an offer expires in consumer wallet, only relevant to Dollars Off offers.
   * This field will be null for all other offer types.
   * Fixed means the offer expires on same date for all consumers, relative means it expires a set number of hours after being
   * deposited into a consumer wallet.
   * @deprecated See expiration found in DollarsOffOfferReward
   */
  expiration?: Maybe<MarketingOfferExpiration>;
  /**
   * Inventory related to Dollars Off offer type (will be null for all other offer types).
   * @deprecated See inventory found within various types of MarketingOfferDistributionChannel
   */
  inventory?: Maybe<MarketingOfferInventory>;
  /** @deprecated See terms found within various types of MarketingOfferReward */
  terms?: Maybe<Scalars['String']>;
  /**
   * The value in cents specific to the Dollars Off offer type (will be null for all other offer types).
   * @deprecated See valueInCents found within various types of MarketingOfferReward
   */
  valueInCents?: Maybe<Scalars['Int']>;
  /** @deprecated Use type found in rewards instead */
  valueType?: Maybe<Marketing_Offer_Value_Type>;
  approvalDocumentUrl?: Maybe<Scalars['URL']>;
};

export type MarketingOfferAccessControl = {
  __typename?: 'MarketingOfferAccessControl';
  canViewOfferDashboard: Scalars['Boolean'];
};

export type MarketingOfferAddChangeRequestIncreaseInventoryAllocationError = {
  __typename?: 'MarketingOfferAddChangeRequestIncreaseInventoryAllocationError';
  error?: Maybe<Error>;
};

export type MarketingOfferAddChangeRequestIncreaseInventoryAllocationInput = {
  id: Scalars['ID'];
  additionalInventoryAllocation?: InputMaybe<Scalars['Int']>;
  /**
   * When updating to unlimited inventory, set this boolean to true and leave the allocation int null.
   * If adding a fixed inventory, this boolean should be null or false.
   */
  updateToUnlimitedInventoryAllocation?: InputMaybe<Scalars['Boolean']>;
};

export type MarketingOfferAddChangeRequestIncreaseInventoryAllocationPayload =
  | MarketingOfferAddChangeRequestIncreaseInventoryAllocationSuccess
  | MarketingOfferAddChangeRequestIncreaseInventoryAllocationError;

export type MarketingOfferAddChangeRequestIncreaseInventoryAllocationSuccess = {
  __typename?: 'MarketingOfferAddChangeRequestIncreaseInventoryAllocationSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferAddChangeRequestModifyEndDateError = {
  __typename?: 'MarketingOfferAddChangeRequestModifyEndDateError';
  error?: Maybe<Error>;
};

export type MarketingOfferAddChangeRequestModifyEndDateInput = {
  id: Scalars['ID'];
  newEndDate?: InputMaybe<Scalars['DateTimeInput']>;
  newEndDateDisplayTimezone?: InputMaybe<Marketing_Offer_Timezone>;
};

export type MarketingOfferAddChangeRequestModifyEndDatePayload =
  | MarketingOfferAddChangeRequestModifyEndDateSuccess
  | MarketingOfferAddChangeRequestModifyEndDateError;

export type MarketingOfferAddChangeRequestModifyEndDateSuccess = {
  __typename?: 'MarketingOfferAddChangeRequestModifyEndDateSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferApprovalRequest = {
  __typename?: 'MarketingOfferApprovalRequest';
  id: Scalars['ID'];
  state: Marketing_Offer_Approval_Request_State;
  approvalTeam: Marketing_Offer_Approval_Request_Approval_Team;
  actor?: Maybe<MarketingOfferApprovalRequestActor>;
};

export type MarketingOfferApprovalRequestActor = {
  __typename?: 'MarketingOfferApprovalRequestActor';
  who?: Maybe<MarketingOfferApprovalRequestApprover>;
  when?: Maybe<DateTime>;
};

export type MarketingOfferApprovalRequestApprover = {
  __typename?: 'MarketingOfferApprovalRequestApprover';
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type MarketingOfferApprovalRequestConnection = {
  __typename?: 'MarketingOfferApprovalRequestConnection';
  nodes?: Maybe<Array<MarketingOfferApprovalRequest>>;
};

export type MarketingOfferApproveError = {
  __typename?: 'MarketingOfferApproveError';
  error: Error;
};

export type MarketingOfferApproveInput = {
  id: Scalars['ID'];
};

export type MarketingOfferApprovePayload =
  | MarketingOfferApproveSuccess
  | MarketingOfferApproveError;

export type MarketingOfferApproveSuccess = {
  __typename?: 'MarketingOfferApproveSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferAudience = {
  __typename?: 'MarketingOfferAudience';
  name: Scalars['String'];
};

export type MarketingOfferBrandsConnection = {
  __typename?: 'MarketingOfferBrandsConnection';
  nodes?: Maybe<Array<Brand>>;
};

export type MarketingOfferChangeRequest = {
  id: Scalars['ID'];
  state: Marketing_Offer_Change_Request_State;
  publishedAt?: Maybe<DateTime>;
  createdAt: DateTime;
  createdBy: Actor;
};

export type MarketingOfferChangeRequestApproveError = {
  __typename?: 'MarketingOfferChangeRequestApproveError';
  error: Error;
};

export type MarketingOfferChangeRequestApproveInput = {
  id: Scalars['ID'];
};

export type MarketingOfferChangeRequestApprovePayload =
  | MarketingOfferChangeRequestApproveSuccess
  | MarketingOfferChangeRequestApproveError;

export type MarketingOfferChangeRequestApproveSuccess = {
  __typename?: 'MarketingOfferChangeRequestApproveSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferChangeRequestDeclineError = {
  __typename?: 'MarketingOfferChangeRequestDeclineError';
  error: Error;
};

export type MarketingOfferChangeRequestDeclineInput = {
  id: Scalars['ID'];
};

export type MarketingOfferChangeRequestDeclinePayload =
  | MarketingOfferChangeRequestDeclineSuccess
  | MarketingOfferChangeRequestDeclineError;

export type MarketingOfferChangeRequestDeclineSuccess = {
  __typename?: 'MarketingOfferChangeRequestDeclineSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferChangeRequestPublishError = {
  __typename?: 'MarketingOfferChangeRequestPublishError';
  error: Error;
};

export type MarketingOfferChangeRequestPublishInput = {
  id: Scalars['ID'];
};

export type MarketingOfferChangeRequestPublishPayload =
  | MarketingOfferChangeRequestPublishSuccess
  | MarketingOfferChangeRequestPublishError;

export type MarketingOfferChangeRequestPublishSuccess = {
  __typename?: 'MarketingOfferChangeRequestPublishSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferChangeRequestsConnection = {
  __typename?: 'MarketingOfferChangeRequestsConnection';
  nodes?: Maybe<Array<MarketingOfferChangeRequest>>;
};

export type MarketingOfferConnection = {
  __typename?: 'MarketingOfferConnection';
  count: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  nodes?: Maybe<Array<MarketingOffer>>;
};

export type MarketingOfferCreateError = {
  __typename?: 'MarketingOfferCreateError';
  error?: Maybe<Error>;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type MarketingOfferCreateInput = {
  name: Scalars['String'];
  displayName?: InputMaybe<Scalars['String']>;
  universalMarketingId?: InputMaybe<Scalars['ID']>;
};

export type MarketingOfferCreatePayload =
  | MarketingOfferCreateSuccess
  | MarketingOfferCreateError;

export type MarketingOfferCreateSuccess = {
  __typename?: 'MarketingOfferCreateSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferCreateWalletImageUploadUrlError = {
  __typename?: 'MarketingOfferCreateWalletImageUploadURLError';
  error?: Maybe<Error>;
};

export type MarketingOfferCreateWalletImageUploadUrlPayload =
  | MarketingOfferCreateWalletImageUploadUrlError
  | MarketingOfferCreateWalletImageUploadUrlSuccess;

export type MarketingOfferCreateWalletImageUploadUrlSuccess = {
  __typename?: 'MarketingOfferCreateWalletImageUploadURLSuccess';
  signedUploadURL: Scalars['URL'];
};

export type MarketingOfferDeclineError = {
  __typename?: 'MarketingOfferDeclineError';
  error: Error;
};

export type MarketingOfferDeclineInput = {
  id: Scalars['ID'];
};

export type MarketingOfferDeclinePayload =
  | MarketingOfferDeclineSuccess
  | MarketingOfferDeclineError;

export type MarketingOfferDeclineSuccess = {
  __typename?: 'MarketingOfferDeclineSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferDeleteError = {
  __typename?: 'MarketingOfferDeleteError';
  error?: Maybe<Error>;
};

export type MarketingOfferDeleteInput = {
  id: Scalars['ID'];
};

export type MarketingOfferDeletePayload =
  | MarketingOfferDeleteSuccess
  | MarketingOfferDeleteError;

export type MarketingOfferDeleteSuccess = {
  __typename?: 'MarketingOfferDeleteSuccess';
  id: Scalars['ID'];
};

export type MarketingOfferDistributionChannel =
  | DollarsOffFlashDistributionChannel
  | DollarsOffOptInDistributionChannel
  | DollarsOffGivenDistributionChannel
  | DollarsOffPracticeGivenDistributionChannel
  | GiftCardGivenDistributionChannel
  | GiftCardPurchasedDistributionChannel
  | PointsEarnedDistributionChannel;

export type MarketingOfferDuplicateError = {
  __typename?: 'MarketingOfferDuplicateError';
  error?: Maybe<Error>;
};

export type MarketingOfferDuplicateInput = {
  id: Scalars['ID'];
};

export type MarketingOfferDuplicatePayload =
  | MarketingOfferDuplicateSuccess
  | MarketingOfferDuplicateError;

export type MarketingOfferDuplicateSuccess = {
  __typename?: 'MarketingOfferDuplicateSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferDuration = {
  __typename?: 'MarketingOfferDuration';
  endDate?: Maybe<DateTime>;
  endDateDisplayTimezone?: Maybe<Marketing_Offer_Timezone>;
  /** @deprecated See MarketingOffer.expiration */
  expireInDays?: Maybe<Scalars['Int']>;
  startDate?: Maybe<DateTime>;
  startDateDisplayTimezone?: Maybe<Marketing_Offer_Timezone>;
};

export type MarketingOfferEarnedPointsBrandsSelection = {
  __typename?: 'MarketingOfferEarnedPointsBrandsSelection';
  extraPointsAppliedToBrandsConnection: MarketingOfferExtraPointsAppliedToBrandsConnection;
  mustIncludeBrandsConnection: MarketingOfferMustIncludeBrandsConnection;
};

export type MarketingOfferEarnedPointsDetails = {
  __typename?: 'MarketingOfferEarnedPointsDetails';
  type: Marketing_Offer_Earned_Points_Type;
  /**
   * For a points type of BONUS, this field will determine how many bonus points are rewarded.
   * For a points type of MULTIPLIER, this field will determine the multiplication factor as a percent.
   * For example, a value of 150 would represent a 1.5x point multiplier.
   */
  value: Scalars['Int'];
  /** The maximum number of product increments a consumer can receive extra points on per single transaction. */
  consumerLimitPerTransaction: Scalars['Int'];
  /** The minimum number of product increments needed for a consumer to receive extra points on per single transaction. */
  consumerMinimumPerTransaction?: Maybe<Scalars['Int']>;
  /** The maximum number of product increments a consumer can receive extra points for this specific offer. */
  consumerLimitPerOffer: Scalars['Int'];
};

export type MarketingOfferExpiration =
  | MarketingOfferFixedExpiration
  | MarketingOfferRelativeExpiration;

export type MarketingOfferExtraPointsAppliedToBrandsConnection = {
  __typename?: 'MarketingOfferExtraPointsAppliedToBrandsConnection';
  nodes?: Maybe<Array<Brand>>;
};

export type MarketingOfferFixedExpiration = {
  __typename?: 'MarketingOfferFixedExpiration';
  expirationDate: DateTime;
  expirationDateDisplayTimezone: Marketing_Offer_Timezone;
};

export type MarketingOfferInventory = {
  __typename?: 'MarketingOfferInventory';
  hasUnlimitedAllocation: Scalars['Boolean'];
  quantityAllocated?: Maybe<Scalars['Int']>;
  /**
   * The number of offers that have been assigned.
   * Assignment includes a consumer claiming an Opt-in offer, being given an offer, or purchasing an offer.
   */
  quantityAssigned: Scalars['Int'];
  /** The number of offers that have been redeemed (used) within a transaction. */
  quantityRedeemed: Scalars['Int'];
  /**
   * A count of how many Dollars Off offers will be created when the offer is published.
   * If the offer has unlimited inventory, this field will have value of -1.
   * @deprecated Use quantityAllocated instead
   */
  available: Scalars['Int'];
  /**
   * A count of how many Opt-In Dollars Off offers have been claimed by consumers.
   * This field will be null for all other offer types.
   * @deprecated Use quantityAssigned instead
   */
  claimed?: Maybe<Scalars['Int']>;
  /** @deprecated Use quantityRedeemed instead */
  redeemed: Scalars['Int'];
};

export type MarketingOfferMustIncludeBrandsConnection = {
  __typename?: 'MarketingOfferMustIncludeBrandsConnection';
  nodes?: Maybe<Array<Brand>>;
  inclusionLevel: Marketing_Offer_Earned_Points_Brand_Inclusion_Level;
};

export type MarketingOfferPracticeInventory = {
  __typename?: 'MarketingOfferPracticeInventory';
  numberOfPracticesToReceive?: Maybe<Scalars['Int']>;
  quantityAllocatedPerPractice?: Maybe<Scalars['Int']>;
  quantityRedeemed: Scalars['Int'];
  quantitySetupType?: Maybe<Marketing_Offer_Quantity_Setup_Type>;
  quantityTotal?: Maybe<Scalars['Int']>;
};

export type MarketingOfferProductsConnection = {
  __typename?: 'MarketingOfferProductsConnection';
  nodes?: Maybe<Array<Product>>;
};

export type MarketingOfferProviderAudience = {
  __typename?: 'MarketingOfferProviderAudience';
  name: Scalars['String'];
};

export type MarketingOfferPublishError = {
  __typename?: 'MarketingOfferPublishError';
  error: Error;
};

export type MarketingOfferPublishInput = {
  id: Scalars['ID'];
  approvalDocumentUrl: Scalars['URL'];
};

export type MarketingOfferPublishPayload =
  | MarketingOfferPublishSuccess
  | MarketingOfferPublishError;

export type MarketingOfferPublishSuccess = {
  __typename?: 'MarketingOfferPublishSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferRelativeExpiration = {
  __typename?: 'MarketingOfferRelativeExpiration';
  expireInDays: Scalars['Int'];
  expireInHours: Scalars['Int'];
};

export type MarketingOfferReward =
  | DollarsOffOfferReward
  | GiftCardOfferReward
  | PointsEarnedOfferReward;

export type MarketingOfferRewardWalletImage = {
  __typename?: 'MarketingOfferRewardWalletImage';
  id: Scalars['ID'];
  url: Scalars['URL'];
  fileName: Scalars['String'];
  fileSizeInBytes: Scalars['Int'];
  disclaimerText?: Maybe<Scalars['String']>;
};

export type MarketingOfferRewardWalletImageInput = {
  urlToProcess: Scalars['URL'];
  fileName: Scalars['String'];
  disclaimerText?: InputMaybe<Scalars['String']>;
};

export type MarketingOfferRewardsConnection = {
  __typename?: 'MarketingOfferRewardsConnection';
  nodes: Array<MarketingOfferReward>;
};

export type MarketingOfferSimplePageInput = {
  resultsPerPage: Scalars['Int'];
  currentPage: Scalars['Int'];
  sort: Array<MarketingOffersSort>;
};

export type MarketingOfferSubmitForApprovalError = {
  __typename?: 'MarketingOfferSubmitForApprovalError';
  error: Error;
};

export type MarketingOfferSubmitForApprovalInput = {
  id: Scalars['ID'];
  approvalDocumentUrl: Scalars['URL'];
};

export type MarketingOfferSubmitForApprovalPayload =
  | MarketingOfferSubmitForApprovalSuccess
  | MarketingOfferSubmitForApprovalError;

export type MarketingOfferSubmitForApprovalSuccess = {
  __typename?: 'MarketingOfferSubmitForApprovalSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateActivationTypeError = {
  __typename?: 'MarketingOfferUpdateActivationTypeError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateActivationTypeInput = {
  id: Scalars['ID'];
  activationType: Marketing_Offer_Activation_Type;
};

export type MarketingOfferUpdateActivationTypePayload =
  | MarketingOfferUpdateActivationTypeSuccess
  | MarketingOfferUpdateActivationTypeError;

export type MarketingOfferUpdateActivationTypeSuccess = {
  __typename?: 'MarketingOfferUpdateActivationTypeSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateAudiencesError = {
  __typename?: 'MarketingOfferUpdateAudiencesError';
  error: Error;
};

export type MarketingOfferUpdateAudiencesInput = {
  id: Scalars['ID'];
  audienceNames: Array<Scalars['String']>;
};

export type MarketingOfferUpdateAudiencesPayload =
  | MarketingOfferUpdateAudiencesSuccess
  | MarketingOfferUpdateAudiencesError;

export type MarketingOfferUpdateAudiencesSuccess = {
  __typename?: 'MarketingOfferUpdateAudiencesSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateDistributionTypeError = {
  __typename?: 'MarketingOfferUpdateDistributionTypeError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateDistributionTypeInput = {
  id: Scalars['ID'];
  distributionType: Marketing_Offer_Distribution_Type;
};

export type MarketingOfferUpdateDistributionTypePayload =
  | MarketingOfferUpdateDistributionTypeSuccess
  | MarketingOfferUpdateDistributionTypeError;

export type MarketingOfferUpdateDistributionTypeSuccess = {
  __typename?: 'MarketingOfferUpdateDistributionTypeSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateDollarsOffRulesError = {
  __typename?: 'MarketingOfferUpdateDollarsOffRulesError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateDollarsOffRulesInput = {
  id: Scalars['ID'];
  cannotRedeemWithSameBrandSpecificOffers: Scalars['Boolean'];
};

export type MarketingOfferUpdateDollarsOffRulesPayload =
  | MarketingOfferUpdateDollarsOffRulesSuccess
  | MarketingOfferUpdateDollarsOffRulesError;

export type MarketingOfferUpdateDollarsOffRulesSuccess = {
  __typename?: 'MarketingOfferUpdateDollarsOffRulesSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateDurationError = {
  __typename?: 'MarketingOfferUpdateDurationError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateDurationInput = {
  id: Scalars['ID'];
  startDate: Scalars['DateTimeInput'];
  endDate?: InputMaybe<Scalars['DateTimeInput']>;
  startDateDisplayTimezone: Marketing_Offer_Timezone;
  endDateDisplayTimezone?: InputMaybe<Marketing_Offer_Timezone>;
};

export type MarketingOfferUpdateDurationPayload =
  | MarketingOfferUpdateDurationSuccess
  | MarketingOfferUpdateDurationError;

export type MarketingOfferUpdateDurationSuccess = {
  __typename?: 'MarketingOfferUpdateDurationSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateEarnedPointsBrandsSelectionError = {
  __typename?: 'MarketingOfferUpdateEarnedPointsBrandsSelectionError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateEarnedPointsBrandsSelectionInput = {
  id: Scalars['ID'];
  extraPointsAppliedToBrandIds: Array<Scalars['ID']>;
  mustIncludeBrandIds: Array<Scalars['ID']>;
  mustIncludeBrandIdsInclusionLevel: Marketing_Offer_Earned_Points_Brand_Inclusion_Level;
};

export type MarketingOfferUpdateEarnedPointsBrandsSelectionPayload =
  | MarketingOfferUpdateEarnedPointsBrandsSelectionSuccess
  | MarketingOfferUpdateEarnedPointsBrandsSelectionError;

export type MarketingOfferUpdateEarnedPointsBrandsSelectionSuccess = {
  __typename?: 'MarketingOfferUpdateEarnedPointsBrandsSelectionSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateEarnedPointsDetailsError = {
  __typename?: 'MarketingOfferUpdateEarnedPointsDetailsError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateEarnedPointsDetailsInput = {
  id: Scalars['ID'];
  type: Marketing_Offer_Earned_Points_Type;
  /**
   * For a points type of BONUS, this field will determine how many bonus points are rewarded.
   * For a points type of MULTIPLIER, this field will determine the multiplication factor as a percent.
   * For example, a value of 150 would represent a 1.5x point multiplier.
   */
  value: Scalars['Int'];
  /** The maximum number of product increments a consumer can receive extra points on per single transaction. */
  consumerLimitPerTransaction: Scalars['Int'];
  /** The minimum number of product increments needed for a consumer to receive extra points on per single transaction. */
  consumerMinimumPerTransaction?: InputMaybe<Scalars['Int']>;
  /** The maximum number of product increments a consumer can receive extra points for this specific offer. */
  consumerLimitPerOffer: Scalars['Int'];
  /** The maximum number of transactions eligible for promo period. */
  consumerMaximumPerLifetime?: InputMaybe<Scalars['Int']>;
};

export type MarketingOfferUpdateEarnedPointsDetailsPayload =
  | MarketingOfferUpdateEarnedPointsDetailsSuccess
  | MarketingOfferUpdateEarnedPointsDetailsError;

export type MarketingOfferUpdateEarnedPointsDetailsSuccess = {
  __typename?: 'MarketingOfferUpdateEarnedPointsDetailsSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateEligibleAudiencesError = {
  __typename?: 'MarketingOfferUpdateEligibleAudiencesError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateEligibleAudiencesInput = {
  id: Scalars['ID'];
  /**
   * A consumer must be included in at least one of the audiences to be eligible
   * for the offer. An empty list represents that all consumers are eligible.
   */
  eligibleAudienceNames: Array<Scalars['String']>;
  /**
   * When this is set to true, newly registered users will be able to claim the offer even
   * if they are not included in one of the eligible audiences.
   */
  newUsersAlwaysEligible?: InputMaybe<Scalars['Boolean']>;
};

export type MarketingOfferUpdateEligibleAudiencesPayload =
  | MarketingOfferUpdateEligibleAudiencesSuccess
  | MarketingOfferUpdateEligibleAudiencesError;

export type MarketingOfferUpdateEligibleAudiencesSuccess = {
  __typename?: 'MarketingOfferUpdateEligibleAudiencesSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateExpireInDaysError = {
  __typename?: 'MarketingOfferUpdateExpireInDaysError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateExpireInDaysInput = {
  id: Scalars['ID'];
  expireInDays: Scalars['Int'];
};

export type MarketingOfferUpdateExpireInDaysPayload =
  | MarketingOfferUpdateExpireInDaysSuccess
  | MarketingOfferUpdateExpireInDaysError;

export type MarketingOfferUpdateExpireInDaysSuccess = {
  __typename?: 'MarketingOfferUpdateExpireInDaysSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateFixedExpirationError = {
  __typename?: 'MarketingOfferUpdateFixedExpirationError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateFixedExpirationInput = {
  id: Scalars['ID'];
  expirationDate: Scalars['DateTimeInput'];
  expirationDateDisplayTimezone: Marketing_Offer_Timezone;
};

export type MarketingOfferUpdateFixedExpirationPayload =
  | MarketingOfferUpdateFixedExpirationSuccess
  | MarketingOfferUpdateFixedExpirationError;

export type MarketingOfferUpdateFixedExpirationSuccess = {
  __typename?: 'MarketingOfferUpdateFixedExpirationSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateGiftCardDetailsError = {
  __typename?: 'MarketingOfferUpdateGiftCardDetailsError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateGiftCardDetailsInput = {
  id: Scalars['ID'];
  type: Marketing_Offer_Gift_Card_Type;
  purchasePriceInCents: Scalars['Int'];
  valueInCents: Scalars['Int'];
  hasUnlimitedAllocation: Scalars['Boolean'];
  /** The limit for how many gift cards can be purchased. */
  inventoryAllocation?: InputMaybe<Scalars['Int']>;
  materialId?: InputMaybe<Scalars['ID']>;
};

export type MarketingOfferUpdateGiftCardDetailsPayload =
  | MarketingOfferUpdateGiftCardDetailsSuccess
  | MarketingOfferUpdateGiftCardDetailsError;

export type MarketingOfferUpdateGiftCardDetailsSuccess = {
  __typename?: 'MarketingOfferUpdateGiftCardDetailsSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateGiftCardRulesError = {
  __typename?: 'MarketingOfferUpdateGiftCardRulesError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateGiftCardRulesInput = {
  id: Scalars['ID'];
  /** The maximum number of gift cards a consumer can purchase for this specific offer. */
  consumerLimitPerOffer?: InputMaybe<Scalars['Int']>;
};

export type MarketingOfferUpdateGiftCardRulesPayload =
  | MarketingOfferUpdateGiftCardRulesSuccess
  | MarketingOfferUpdateGiftCardRulesError;

export type MarketingOfferUpdateGiftCardRulesSuccess = {
  __typename?: 'MarketingOfferUpdateGiftCardRulesSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateInventoryError = {
  __typename?: 'MarketingOfferUpdateInventoryError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateInventoryInput = {
  id: Scalars['ID'];
  valueInCents: Scalars['Int'];
  allocationQuantity: Scalars['Int'];
  /**
   * Sending "null" will delete any existing description.
   * If you do not mean to change the description, please send the original value.
   */
  description?: InputMaybe<Scalars['String']>;
};

export type MarketingOfferUpdateInventoryPayload =
  | MarketingOfferUpdateInventorySuccess
  | MarketingOfferUpdateInventoryError;

export type MarketingOfferUpdateInventorySuccess = {
  __typename?: 'MarketingOfferUpdateInventorySuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateNameError = {
  __typename?: 'MarketingOfferUpdateNameError';
  error?: Maybe<Error>;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type MarketingOfferUpdateNameInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName?: InputMaybe<Scalars['String']>;
  universalMarketingId?: InputMaybe<Scalars['ID']>;
};

export type MarketingOfferUpdateNamePayload =
  | MarketingOfferUpdateNameSuccess
  | MarketingOfferUpdateNameError;

export type MarketingOfferUpdateNameSuccess = {
  __typename?: 'MarketingOfferUpdateNameSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdatePracticeInventoryError = {
  __typename?: 'MarketingOfferUpdatePracticeInventoryError';
  error: Error;
};

export type MarketingOfferUpdatePracticeInventoryInput = {
  id: Scalars['ID'];
  valueInCents: Scalars['Int'];
  numberOfPracticesToReceive: Scalars['Int'];
  quantityAllocatedPerPractice?: InputMaybe<Scalars['Int']>;
  quantitySetupType?: InputMaybe<Marketing_Offer_Quantity_Setup_Type>;
  quantityTotal?: InputMaybe<Scalars['Int']>;
};

export type MarketingOfferUpdatePracticeInventoryPayload =
  | MarketingOfferUpdatePracticeInventorySuccess
  | MarketingOfferUpdatePracticeInventoryError;

export type MarketingOfferUpdatePracticeInventorySuccess = {
  __typename?: 'MarketingOfferUpdatePracticeInventorySuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateProductSelectionError = {
  __typename?: 'MarketingOfferUpdateProductSelectionError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateProductSelectionInput = {
  id: Scalars['ID'];
  /** Set which brands the Dollars Off offer can be redeemed with during checkout. */
  brandIds?: InputMaybe<Array<Scalars['ID']>>;
  /**
   * Set which products the Dollars Off offer can be redeemed with during checkout.
   * If provided, the product IDs will be given priority over the above set of brand IDs.
   * If not provided, any products within the above set of brand IDs will be accepted.
   */
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MarketingOfferUpdateProductSelectionPayload =
  | MarketingOfferUpdateProductSelectionSuccess
  | MarketingOfferUpdateProductSelectionError;

export type MarketingOfferUpdateProductSelectionSuccess = {
  __typename?: 'MarketingOfferUpdateProductSelectionSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateRelativeExpirationError = {
  __typename?: 'MarketingOfferUpdateRelativeExpirationError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateRelativeExpirationInput = {
  id: Scalars['ID'];
  expireInDays: Scalars['Int'];
  expireInHours: Scalars['Int'];
};

export type MarketingOfferUpdateRelativeExpirationPayload =
  | MarketingOfferUpdateRelativeExpirationSuccess
  | MarketingOfferUpdateRelativeExpirationError;

export type MarketingOfferUpdateRelativeExpirationSuccess = {
  __typename?: 'MarketingOfferUpdateRelativeExpirationSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateTermsError = {
  __typename?: 'MarketingOfferUpdateTermsError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateTermsInput = {
  id: Scalars['ID'];
  terms: Scalars['Markdown'];
};

export type MarketingOfferUpdateTermsPayload =
  | MarketingOfferUpdateTermsSuccess
  | MarketingOfferUpdateTermsError;

export type MarketingOfferUpdateTermsSuccess = {
  __typename?: 'MarketingOfferUpdateTermsSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateValueError = {
  __typename?: 'MarketingOfferUpdateValueError';
  error: Error;
};

export type MarketingOfferUpdateValueInput = {
  id: Scalars['ID'];
  valueInCents: Scalars['Int'];
};

export type MarketingOfferUpdateValuePayload =
  | MarketingOfferUpdateValueSuccess
  | MarketingOfferUpdateValueError;

export type MarketingOfferUpdateValueSuccess = {
  __typename?: 'MarketingOfferUpdateValueSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateValueTypeError = {
  __typename?: 'MarketingOfferUpdateValueTypeError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateValueTypeInput = {
  id: Scalars['ID'];
  valueType: Marketing_Offer_Value_Type;
};

export type MarketingOfferUpdateValueTypePayload =
  | MarketingOfferUpdateValueTypeSuccess
  | MarketingOfferUpdateValueTypeError;

export type MarketingOfferUpdateValueTypeSuccess = {
  __typename?: 'MarketingOfferUpdateValueTypeSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOfferUpdateWalletDetailsError = {
  __typename?: 'MarketingOfferUpdateWalletDetailsError';
  error?: Maybe<Error>;
};

export type MarketingOfferUpdateWalletDetailsInput = {
  id: Scalars['ID'];
  description?: InputMaybe<Scalars['Markdown']>;
  image?: InputMaybe<MarketingOfferRewardWalletImageInput>;
};

export type MarketingOfferUpdateWalletDetailsPayload =
  | MarketingOfferUpdateWalletDetailsSuccess
  | MarketingOfferUpdateWalletDetailsError;

export type MarketingOfferUpdateWalletDetailsSuccess = {
  __typename?: 'MarketingOfferUpdateWalletDetailsSuccess';
  marketingOffer: MarketingOffer;
};

export type MarketingOffersSort = {
  column?: Marketing_Offer_Sort_Column;
  order?: Marketing_Offer_Sort_Order;
};

export type MigrationUpdateInput = {
  emailAddress: Scalars['String'];
  password: Scalars['String'];
  optInSMS?: InputMaybe<Scalars['Boolean']>;
  optInEmail: Scalars['Boolean'];
};

export type Money = {
  __typename?: 'Money';
  /**
   * All API requests expect `amount` to be provided in a currency’s smallest unit.
   * For example, to charge 10 USD, provide an amount value of 1000 (that is, 1000 cents).
   */
  amount: Scalars['Int'];
  currencyCode: Scalars['CurrencyCode'];
};

export type MoneyInput = {
  /**
   * All API requests expect \`amount\` to be provided in a currency’s smallest unit.
   * For example, to charge 10 USD, provide an amount value of 1000 (that is, 1000 cents).
   */
  amount: Scalars['Int'];
  currencyCode: Scalars['CurrencyCode'];
};

export type Mutation = {
  __typename?: 'Mutation';
  directoryConsultationRequestCreate: DirectoryConsultationRequestCreatePayload;
  businessOnboardByInvitation: BusinessOnboardByInvitationPayload;
  consumerDeactivate: ConsumerDeactivatePayload;
  consumerDelete: ConsumerDeletePayload;
  providerAccessTokenGenerate: ProviderAccessTokenGeneratePayload;
  providerUserAcceptInvite: ProviderUserAcceptInvitePayload;
  providerUserAlternateEmailAddressChangeRequestCreate: ProviderUserAlternateEmailAddressChangeRequestCreatePayload;
  providerUserDelete: ProviderUserDeletePayload;
  providerUserInvitationCheckAlternateEmailAddressAvailability: ProviderUserInvitationCheckAlternateEmailAddressAvailabilityPayload;
  providerUserInvitationSendAlternateEmailAddressVerification: ProviderUserInvitationSendAlternateEmailAddressVerificationPayload;
  providerUserInvitationResend: ProviderUserInvitationResendPayload;
  providerUserInvitationVerifyToken: ProviderUserInvitationVerifyTokenPayload;
  providerUserInvite: ProviderUserInvitePayload;
  providerUserInviteBatch: Array<ProviderUserInvitePayload>;
  providerUserInviteAllSuggested: ProviderUserInviteAllSuggestedPayload;
  providerUserPhoneNumberChangeRequestCreate: ProviderUserPhoneNumberChangeRequestCreatePayload;
  providerUserPhoneNumberRelease: ProviderUserPhoneNumberReleasePayload;
  providerUserPrimaryEmailAddressChangeRequestCreate: ProviderUserPrimaryEmailAddressChangeRequestCreatePayload;
  providerUserAddRoleAssignment: ProviderUserAddRoleAssignmentPayload;
  providerUserRegisterByInvitationToken: ProviderUserRegisterByInvitationTokenPayload;
  providerUserRoleAssignmentChangeRole: ProviderUserRoleAssignmentChangeRolePayload;
  providerUserRoleAssignmentsDeactivate: ProviderUserRoleAssignmentsDeactivatePayload;
  providerUserUpdatePassword: ProviderUserUpdatePasswordPayload;
  providerUserVerifyAlternateEmailAddress: ProviderUserVerifyAlternateEmailAddressPayload;
  providerUserVerifyPhoneNumber: ProviderUserVerifyPhoneNumberPayload;
  providerUserVerifyPrimaryEmailAddress: ProviderUserVerifyPrimaryEmailAddressPayload;
  /** @deprecated Use providerAccessTokenGenerate instead */
  providerSessionTokenGenerate: ProviderSessionTokenGeneratePayload;
  providerUserDelegateInvitation: ProviderUserDelegateInvitationPayload;
  providerUserInvitationSendSMSVerification: ProviderUserInvitationSendSmsVerificationPayload;
  providerUserInvitationVerifySMSVerification: ProviderUserInvitationVerifySmsVerificationPayload;
  providerUserUpdateFirstName: ProviderUserUpdateFirstNamePayload;
  providerUserUpdateLastName: ProviderUserUpdateLastNamePayload;
  /**
   * Submits a consultation request triggering both consumer and provider emails
   * @deprecated use directoryConsultationRequestCreate
   */
  consultationRequestCreate?: Maybe<ConsultationRequestCreatePayload>;
  locationCatalogCreateForLocationProviderOrg: LocationCatalogCreatePayload;
  locationCatalogEnhancedCheckoutSetupStatusUpdate: LocationCatalogEnhancedCheckoutSetupStatusUpdatePayload;
  locationCatalogItemSetLocationFavorite: LocationCatalogItemSetLocationFavoritePayload;
  locationCatalogItemSetStatus: LocationCatalogItemSetStatusPayload;
  locationCatalogItemSetManufacturerAvailability: LocationCatalogItemSetManufacturerAvailabilityPayload;
  locationCatalogItemUpdate: LocationCatalogItemUpdatePayload;
  locationCatalogSetBaseCatalogItems: LocationCatalogSetBaseCatalogItemsPayload;
  locationCatalogSetTaxRates: LocationCatalogSetTaxRatesPayload;
  locationCatalogItemCreate: LocationCatalogItemCreatePayload;
  locationCatalogItemEdit: LocationCatalogItemEditPayload;
  /** Creates new a cart */
  checkoutCartCreate: CheckoutCartCreatePayload;
  /** Deletes the existing cart */
  checkoutCartDelete: CheckoutCartDeletePayload;
  /** Adds a new line item to the cart */
  checkoutCartAddLineItem: CheckoutCartAddLineItemPayload;
  /** Modifies the quantity ofa particular line item in the cart */
  checkoutCartUpdateLineItem: CheckoutCartUpdateLineItemPayload;
  /** Updates rewards items in a cart (points, gift cards, and promotions) */
  checkoutCartUpdateRewards: CheckoutCartUpdateRewardsPayload;
  /** Removes a specific line item from the cart */
  checkoutCartRemoveLineItem: CheckoutCartRemoveLineItemPayload;
  /** Removes a specific rewards line item from the cart */
  checkoutCartRemoveRewardsLineItem: CheckoutCartRemoveRewardsLineItemPayload;
  /** Associates a member(patient) to the cart */
  checkoutCartUpdatePatient: CheckoutCartUpdatePatientPayload;
  /** Adjusts the price of a line item. The adjusted price supersedes subtotal */
  checkoutCartSetLineItemPriceAdjustmentPriceOverride: CheckoutCartSetLineItemPriceAdjustmentPriceOverridePayload;
  /** Adjusts the price of a line item by discounting it by specific amount or percentage */
  checkoutCartSetLineItemPriceAdjustmentDiscount: CheckoutCartSetLineItemPriceAdjustmentDiscountPayload;
  /** Removes a previously applied price adjustment */
  checkoutCartClearLineItemPriceAdjustment: CheckoutCartClearLineItemPriceAdjustmentPayload;
  /** Assigns a staff to a line item */
  checkoutCartSetLineItemStaffMember: CheckoutCartSetLineItemStaffMemberPayload;
  /** Clears the staff assignment from a line item */
  checkoutCartClearLineItemStaffMember: CheckoutCartClearLineItemStaffMemberPayload;
  checkoutCartSubmitOrder: CheckoutCartSubmitOrderPayload;
  /** Creates a payment line item for terminal reader payment purchase intent */
  checkoutCartCreateTerminalReaderPaymentLineItem: CheckoutCartCreateTerminalReaderPaymentLineItemPayload;
  checkoutOrderCreateRefund: CheckoutOrderCreateRefundPayload;
  checkoutOrderRerun: CheckoutOrderRerunPayload;
  checkoutOrderSendEmailReceipt: CheckoutOrderSendEmailReceiptPayload;
  checkoutSalesReportCreate: CheckoutSalesReportCreatePayload;
  consumerConsentCommunicationPreferencesUpdate: ConsumerConsentCommunicationPreferencesUpdatePayload;
  consumerConsentCommunicationPreferencesUpdateUnsubscribeFromAll: ConsumerConsentCommunicationPreferencesUpdateUnsubscribeFromAllPayload;
  consumerConsentPrivacySettingsUpdate: ConsumerConsentPrivacySettingsUpdatePayload;
  consumerConsentPrivacySettingsLimitSensitiveDataUse: ConsumerConsentPrivacySettingsLimitSensitiveDataUsePayload;
  consumerConsentPrivacyTermsAcceptUpdatedTerms: ConsumerConsentPrivacyTermsAcceptUpdatedTermsPayload;
  email_sendForgotPassword?: Maybe<TransactionalEmailResponse>;
  email_sendTransactionReceiptEmail?: Maybe<TransactionalEmailResponse>;
  practiceOptOutFlashRewards: PracticeOptOutFlashRewardsPayload;
  practiceReactivateFlashRewards: PracticeReactivateFlashRewardsPayload;
  giftCardCreate: GiftCardCreatePayload;
  giftCardDeactivate: GiftCardDeactivatePayload;
  giftCardRemove: GiftCardRemovePayload;
  giftCardShare: GiftCardSharePayload;
  /** Validates the capability to allocate budget for a new campaign. */
  adsCampaignBudgetValidate: AdsCampaignBudgetValidatePayload;
  /** User can generate form data to post in order to upload a file of emails. */
  advertisingAudienceEmailsUploadFormCreate: AdvertisingAudienceEmailsUploadFormCreatePayload;
  advertisingContactUpdateEmailAddress: AdvertisingContactUpdateEmailAddressPayload;
  advertisingContactAutoCallProfileUpdate: AdvertisingContactAutoCallProfileUpdatePayload;
  advertisingProfileAcceptTermsAndConditions: AdvertisingProfileAcceptTermsAndConditionsPayload;
  /**
   * User can create a temporary link to upload a csv file of contacts.
   * @deprecated Please use advertisingAudienceEmailsUploadFormCreate.
   */
  contactsUploadUrlCreate: ContactsUploadUrlCreatePayload;
  /** Validates that the content of an uploaded file (csv/xls[x]) of contacts. */
  contactsFileValidate: ContactsFileValidatePayload;
  /**
   * Creates a Post to be published on Facebook.
   * @deprecated Please use providerMarketingCampaignCreate
   */
  facebookPostCreate: FacebookPostCreatePayload;
  /**
   * practiceActivateAdvertisingProfile allows admin user to enable advertising
   * features of a provider
   * @deprecated Please use practiceAdvertisingProfileUpdateEnrollmentStatus
   */
  practiceActivateAdvertisingProfile: PracticeActivateAdvertisingProfilePayload;
  /** Allows admin user update advertising profile enrollment status for a practice. */
  practiceAdvertisingProfileUpdateEnrollmentStatus: PracticeAdvertisingProfileUpdateEnrollmentStatusPayload;
  /** Allows admin user to enable/disable Ad Campaign for Grow users. */
  practiceAdvertisingProfileUpdateAdvertisementManagementStatus: PracticeAdvertisingProfileUpdateAdvertisementManagementStatusPayload;
  /** providerMarketingCampaignDraftCreate is for a provider to save a marketing campaign draft */
  providerMarketingCampaignDraftCreate: ProviderMarketingCampaignDraftCreatePayload;
  /** providerMarketingCampaignCreate is for a provider to create and publish a marketing campaign */
  providerMarketingCampaignCreate: ProviderMarketingCampaignCreatePayload;
  providerMarketingCampaignCreateContactLink: ProviderMarketingCampaignCreateContactLinkPayload;
  providerMarketingCampaignSubmitSurvey: ProviderMarketingCampaignSubmitSurveyPayload;
  /** providerMarketingCampaignGeneratePreview returns info needed to show preview of campaign like url, width, height */
  providerMarketingCampaignGeneratePreview: ProviderMarketingCampaignGeneratePreviewPayload;
  /** Validate that provider settings (from Profile Manager) are in a good state to use Grow. */
  providerSettingsValidate: ProviderSettingsValidatePayload;
  /** User can create an integration(e.g: disconnect facebook). */
  socialNetworkIntegrationCreate: SocialNetworkIntegrationCreatePayload;
  /** User can remove an active integration (e.g: disconnect facebook). */
  socialNetworkIntegrationDelete: SocialNetworkIntegrationDeletePayload;
  userActionTrack: UserActionTrackPayload;
  /** Pause provider marketing campaign */
  providerMarketingCampaignPause: ProviderMarketingCampaignPausePayload;
  /** Resume provider marketing campaign */
  providerMarketingCampaignResume: ProviderMarketingCampaignResumePayload;
  /** Stop provider marketing campaign */
  providerMarketingCampaignStop: ProviderMarketingCampaignStopPayload;
  /** Create a new provider email marketing campaign */
  providerEmailMarketingCampaignCreate: ProviderEmailMarketingCampaignCreatePayload;
  /** Enable/disable an email automation rule of a given email package */
  providerEmailMarketingCampaignPackageAutomationSetEnabled: ProviderEmailMarketingCampaignPackageAutomationSetEnabledPayload;
  providerEmailMarketingCampaignPackageSendPreview: ProviderEmailMarketingCampaignPackageSendPreviewPayload;
  /** Validate offer token and activate offer for email marketing campaigns with offers */
  advertisingOfferActivate: AdvertisingOfferActivatePayload;
  userLogout: UserLogoutPayload;
  passwordResetComplete: PasswordResetCompletePayload;
  passwordResetInitiate: PasswordResetInitiatePayload;
  passwordResetVerify: PasswordResetVerifyPayload;
  /** @deprecated Replacing this mutation with providerReportGenerateFileResult and providerReportFetchDataResult */
  providerReportGenerateResult: ProviderReportGenerateResultPayload;
  providerReportGenerateFileResult: ProviderReportGenerateFileResultPayload;
  leadAddNote: LeadAddNotePayload;
  leadContactedStatusUpdate: LeadContactedStatusUpdatePayload;
  leadNoteEdit: LeadNoteEditPayload;
  leadNoteRemove: LeadNoteRemovePayload;
  leadSetHasBookedAppointment: LeadSetHasBookedAppointmentPayload;
  leadSetIsViewed: LeadSetIsViewedPayload;
  leadsExport: LeadsExportPayload;
  /** @deprecated Please use advertisingLeadNotificationsAddSMSPhoneNumber */
  advertisingLeadNotificationSMSPhoneNumberAdd: AdvertisingLeadNotificationSmsPhoneNumberAddPayload;
  /** @deprecated Please use advertisingLeadNotificationsVerifySMSPhoneNumber */
  advertisingLeadNotificationSMSPhoneNumberVerify: AdvertisingLeadNotificationSmsPhoneNumberVerifyPayload;
  /** @deprecated Please use advertisingLeadNotificationsAddVoiceCallPhoneNumber */
  advertisingLeadNotificationVoiceCallPhoneNumberAdd: AdvertisingLeadNotificationVoiceCallPhoneNumberAddPayload;
  advertisingLeadNotificationsAddEmailAddress: AdvertisingLeadNotificationsAddEmailAddressPayload;
  advertisingLeadNotificationsVerifyEmailAddress: AdvertisingLeadNotificationsVerifyEmailAddressPayload;
  advertisingLeadNotificationsAddSMSPhoneNumber: AdvertisingLeadNotificationsAddSmsPhoneNumberPayload;
  advertisingLeadNotificationsVerifySMSPhoneNumber: AdvertisingLeadNotificationsVerifySmsPhoneNumberPayload;
  advertisingLeadNotificationsAddVoiceCallPhoneNumber: AdvertisingLeadNotificationsAddVoiceCallPhoneNumberPayload;
  advertisingLeadNotificationMethodRemove: AdvertisingLeadNotificationMethodRemovePayload;
  advertisingLeadNotificationMethodSetEnable: AdvertisingLeadNotificationMethodSetEnablePayload;
  providerMarketingEmailOptOut: ProviderMarketingEmailOptOutPayload;
  advertisingProfileSetPhoneNotificationMethod: AdvertisingProfileSetPhoneNotificationMethodPayload;
  advertisingNotificationMethodVerify: AdvertisingNotificationMethodVerifyPayload;
  changeRequestApprove: ChangeRequestApprovePayload;
  changeRequestDecline: ChangeRequestDeclinePayload;
  changeRequestDelete: ChangeRequestDeletePayload;
  changeRequestIncreaseInventoryAllocationUpdate: ChangeRequestIncreaseInventoryAllocationUpdatePayload;
  changeRequestModifyEndDateUpdate: ChangeRequestModifyEndDateUpdatePayload;
  marketingOfferAddChangeRequestIncreaseInventoryAllocation: MarketingOfferAddChangeRequestIncreaseInventoryAllocationPayload;
  marketingOfferAddChangeRequestModifyEndDate: MarketingOfferAddChangeRequestModifyEndDatePayload;
  marketingOfferCreate: MarketingOfferCreatePayload;
  marketingOfferCreateWalletImageUploadURL: MarketingOfferCreateWalletImageUploadUrlPayload;
  /** This mutation can only be called on offers in DRAFT state. */
  marketingOfferDelete: MarketingOfferDeletePayload;
  /** Create a copy of an existing offer. */
  marketingOfferDuplicate: MarketingOfferDuplicatePayload;
  marketingOfferUpdateActivationType: MarketingOfferUpdateActivationTypePayload;
  /** Set a list of audiences the offer will be given to. This is currently only supported by Dollars Off Given offers. */
  marketingOfferUpdateAudiences: MarketingOfferUpdateAudiencesPayload;
  marketingOfferUpdateDistributionType: MarketingOfferUpdateDistributionTypePayload;
  marketingOfferUpdateDollarsOffRules: MarketingOfferUpdateDollarsOffRulesPayload;
  marketingOfferUpdateDuration: MarketingOfferUpdateDurationPayload;
  /** This mutation can only be called on Earned Points offers. */
  marketingOfferUpdateEarnedPointsBrandsSelection: MarketingOfferUpdateEarnedPointsBrandsSelectionPayload;
  /** This mutation can only be called on Earned Points offers. */
  marketingOfferUpdateEarnedPointsDetails: MarketingOfferUpdateEarnedPointsDetailsPayload;
  /** Setting a list of eligible audiences is currently only supported by Dollars Off Opt-In offers. */
  marketingOfferUpdateEligibleAudiences: MarketingOfferUpdateEligibleAudiencesPayload;
  /** This mutation can only be called on Dollars Off offers. Setting a fixed expiration date will remove any previously set relative expiration date. */
  marketingOfferUpdateFixedExpiration: MarketingOfferUpdateFixedExpirationPayload;
  /**
   * This mutation can only be called on offers that are not using end date as expiration
   * @deprecated use marketingOfferUpdateRelativeExpiration or marketingOfferUpdateFixedExpiration
   */
  marketingOfferUpdateExpireInDays: MarketingOfferUpdateExpireInDaysPayload;
  /** This mutation can only be called on Gift Card offers. */
  marketingOfferUpdateGiftCardDetails: MarketingOfferUpdateGiftCardDetailsPayload;
  /** This mutation can only be called on Gift Card offers. */
  marketingOfferUpdateGiftCardRules: MarketingOfferUpdateGiftCardRulesPayload;
  marketingOfferUpdatePracticeInventory: MarketingOfferUpdatePracticeInventoryPayload;
  /** This mutation can only be called on Dollars Off offers. Setting a relative expiration date will remove any previously set fixed expiration date. */
  marketingOfferUpdateRelativeExpiration: MarketingOfferUpdateRelativeExpirationPayload;
  /** This mutation can only be called on Dollars Off offers. */
  marketingOfferUpdateInventory: MarketingOfferUpdateInventoryPayload;
  marketingOfferUpdateName: MarketingOfferUpdateNamePayload;
  /** This mutation can only be called on Dollars Off offers. */
  marketingOfferUpdateProductSelection: MarketingOfferUpdateProductSelectionPayload;
  marketingOfferUpdateTerms: MarketingOfferUpdateTermsPayload;
  marketingOfferUpdateValue: MarketingOfferUpdateValuePayload;
  marketingOfferUpdateValueType: MarketingOfferUpdateValueTypePayload;
  marketingOfferUpdateWalletDetails: MarketingOfferUpdateWalletDetailsPayload;
  marketingOfferPublish: MarketingOfferPublishPayload;
  marketingOfferSubmitForApproval: MarketingOfferSubmitForApprovalPayload;
  marketingOfferDecline: MarketingOfferDeclinePayload;
  marketingOfferApprove: MarketingOfferApprovePayload;
  marketingOfferChangeRequestApprove: MarketingOfferChangeRequestApprovePayload;
  marketingOfferChangeRequestDecline: MarketingOfferChangeRequestDeclinePayload;
  marketingOfferChangeRequestPublish: MarketingOfferChangeRequestPublishPayload;
  cherryFinancingApplicationInitiate: CherryFinancingApplicationInitiatePayload;
  cherryFinancingApplicationRefresh: CherryFinancingApplicationRefreshPayload;
  /** @deprecated No longer used */
  cherryPaymentApprovalRequestCancel: CherryPaymentApprovalRequestCancelPayload;
  cherryPaymentApprovalRequestCreate: CherryPaymentApprovalRequestCreatePayload;
  cherryProviderOrganizationOnboardComplete: CherryProviderOrganizationOnboardCompletePayload;
  cherryProviderOrganizationOnboardInitiate: CherryProviderOrganizationOnboardInitiatePayload;
  cherrySendSMSInvitation: CherrySendSmsInvitationPayload;
  connectAccountRedirectUrlCreate: ConnectAccountRedirectUrlCreatePayload;
  paymentsWalletCreateForPractice: PaymentsWalletCreateForPracticePayload;
  setupIntentComplete: SetupIntentCompletePayload;
  paymentMethodCharge: PaymentMethodChargePayload;
  paymentMethodDelete: PaymentMethodDeletePayload;
  paymentIntentCreate: PaymentIntentPayload;
  paymentsSetupIntentCreate: PaymentsSetupIntentPayload;
  paymentIntentUpdate: PaymentIntentPayload;
  paymentsUpdatePaymentMethod: PaymentMethodUpdatePayload;
  paymentsTransactionReportCreate: PaymentsTransactionReportCreatePayload;
  payoutSummaryReportCreate: PayoutSummaryReportCreatePayload;
  payoutDetailReportCreate: PayoutDetailReportCreatePayload;
  paymentsTransferRequestCancel: PaymentsTransferRequestCancelPayload;
  paymentsBalanceAutomationParametersUpdate: PaymentsBalanceAutomationParametersUpdatePayload;
  paymentsTransferRequestCreate: PaymentsTransferRequestCreatePayload;
  paymentsTransferRequestApprove: PaymentsTransferRequestApprovePayload;
  paymentsTransferRequestDecline: PaymentsTransferRequestDeclinePayload;
  platformSubscriptionCreate: PlatformSubscriptionPayload;
  platformSubscriptionCancel: PlatformSubscriptionCancelPayload;
  platformSubscriptionUpdatePaymentMethod: PlatformSubscriptionUpdatePaymentMethodPayload;
  practicePaymentProfileUpdateEmailAddress?: Maybe<PracticePaymentProfileUpdateEmailAddressPayload>;
  practicePaymentProfileUpdatePaymentFrequency?: Maybe<PracticePaymentProfileUpdatePaymentFrequencyPayload>;
  paymentProfileUpdateEmail?: Maybe<PaymentProfileUpdateEmailPayload>;
  paymentProfileUpdatePaymentFrequency?: Maybe<PaymentProfileUpdatePaymentFrequencyPayload>;
  paymentProfileDeactivate?: Maybe<PaymentProfileDeactivatePayload>;
  paymentProfileReactivate?: Maybe<PaymentProfileReactivatePayload>;
  paymentProfileUpdateFundingSource?: Maybe<PaymentProfileUpdateFundingSourcePayload>;
  paymentProfileUpdateFundingSourceForChildOrg?: Maybe<PaymentProfileUpdateFundingSourceForChildOrgPayload>;
  promotionClaimWithPoints: PromotionClaimWithPointsPayload;
  allerganStoreShoppingCartCreate: AllerganStoreShoppingCartCreatePayload;
  allerganStoreShoppingCartAddProduct: AllerganStoreShoppingCartAddProductPayload;
  allerganStoreShoppingCartUpdateProductQuantity: AllerganStoreShoppingCartUpdateProductQuantityPayload;
  allerganStoreShoppingCartUpdateShippingMethod: AllerganStoreShoppingCartUpdateShippingMethodPayload;
  allerganStoreShoppingCartUpdateCreditCard: AllerganStoreShoppingCartUpdateCreditCardPayload;
  allerganStoreShoppingCartUpdatePurchaseOrderName: AllerganStoreShoppingCartUpdatePurchaseOrderNamePayload;
  allerganStoreShoppingCartDelete: AllerganStoreShoppingCartDeletePayload;
  allerganStoreCreditCardFormCreate: AllerganStoreCreditCardFormCreatePayload;
  allerganStoreCreditCardAdd: AllerganStoreCreditCardAddPayload;
  allerganStoreCreditCardSetIsDefault: AllerganStoreCreditCardSetIsDefaultPayload;
  allerganStoreCreditCardDelete: AllerganStoreCreditCardDeletePayload;
  allerganStoreOrderCreate: AllerganStoreOrderCreatePayload;
  allerganStoreShoppingCartAddProductsFromOrder: AllerganStoreShoppingCartAddProductsFromOrderPayload;
  allerganStoreShoppingCartReplaceProductsFromOrder: AllerganStoreShoppingCartReplaceProductsFromOrderPayload;
  allerganStoreShoppingCartSetPaymentMethodToBillMeLater: AllerganStoreShoppingCartSetPaymentMethodToBillMeLaterPayload;
  allerganStoreInvoicesPay: AllerganStoreInvoicesPayPayload;
  allerganStoreOrderGenerateInvoiceFile: AllerganStoreOrderGenerateInvoiceFilePayload;
  allerganStoreInvoiceGenerateReceiptFile: AllerganStoreInvoiceGenerateReceiptFilePayload;
  /** Updates the location public profile about description */
  locationPublicProfileDraftUpdateAbout: LocationPublicProfileDraftUpdateAboutPayload;
  /** Updates location public profile draft root fields using auth token */
  locationPublicProfileDraftUpdateAddress: LocationPublicProfileDraftUpdateAddressPayload;
  /** Apply current location public profile batch draft to selected locations and publish new location public profiles */
  locationPublicProfileBatchDraftApply: LocationPublicProfileBatchDraftApplyPayload;
  /** Updates location public profile batch draft avatar url using auth token. This updates the batch draft that is used separately to batch update multiple location profiles. */
  locationPublicProfileBatchDraftUpdateAvatarUrl: LocationPublicProfileBatchDraftUpdateAvatarUrlPayload;
  /** Upserts location public profile draft business hours based on day of week using auth token */
  locationPublicProfileDraftUpdateBusinessHours: LocationPublicProfileDraftUpdateBusinessHoursPayload;
  /** Update location public profile draft consultation request settings using auth token */
  locationPublicProfileDraftUpdateConsultationRequestSettings: LocationPublicProfileDraftUpdateConsultationRequestSettingsPayload;
  /** Updates location public profile draft contact information using auth token */
  locationPublicProfileDraftUpdateContactInformation: LocationPublicProfileDraftUpdateContactInformationPayload;
  /** Updates location public profile batch draft contact information using auth token. This updates the batch draft that is used separately to batch update multiple location profiles. */
  locationPublicProfileBatchDraftUpdateContactInformation: LocationPublicProfileBatchDraftUpdateContactInformationPayload;
  /** Upserts the google data for the provider */
  locationPublicProfileDraftUpsertGoogleData: LocationPublicProfileDraftUpsertGoogleDataPayload;
  /** Given a filename this returns an upload signature */
  signedUploadURL: SignedUploadUrlPayload;
  /** Adds to location public profile draft languages */
  locationPublicProfileDraftAddLanguages: LocationPublicProfileDraftAddLanguagesPayload;
  /** Deletes from location public profile draft languages */
  locationPublicProfileDraftRemoveLanguages: LocationPublicProfileDraftRemoveLanguagesPayload;
  /** Publish current office public profile draft using auth token */
  locationPublicProfileDraftPublish: LocationPublicProfileDraftPublishPayload;
  /** Upserts the location public profile draft opt-in marketing events */
  locationPublicProfileDraftOptInMarketingEventsUpsert: LocationPublicProfileDraftOptInMarketingEventsUpsertPayload;
  /** Adds to location public profile draft products using auth token */
  locationPublicProfileDraftAddProducts: LocationPublicProfileDraftAddProductsPayload;
  /** Deletes from location public profile draft products */
  locationPublicProfileDraftProductsDelete: LocationPublicProfileDraftProductsDeletePayload;
  /** Adds to location public profile batch draft products using auth token. This updates the batch draft that is used separately to batch update multiple location profiles. */
  locationPublicProfileBatchDraftAddProducts: LocationPublicProfileBatchDraftAddProductsPayload;
  /** Deletes from location public profile batch draft products */
  locationPublicProfileBatchDraftProductsDelete: LocationPublicProfileBatchDraftProductsDeletePayload;
  /** Deletes a photo from location public profile draft */
  locationPublicProfileDraftGalleryPhotosDelete: LocationPublicProfileDraftGalleryPhotosDeletePayload;
  /** Update location public profile draft gallery photos and order of photos */
  locationPublicProfileDraftGalleryPhotosUpdate: LocationPublicProfileDraftGalleryPhotosUpdatePayload;
  /** Adds to location public profile draft gallery photos using auth token */
  locationPublicProfileDraftAddGalleryPhotos: LocationPublicProfileDraftAddGalleryPhotosPayload;
  /** Adds to location public profile batch draft gallery photos using auth token. This updates the batch draft that is used separately to batch update multiple location profiles. */
  locationPublicProfileBatchDraftAddGalleryPhotos: LocationPublicProfileBatchDraftAddGalleryPhotosPayload;
  /** Deletes a photo from location public profile batch draft. This updates the batch draft that is used separately to batch update multiple location profiles. */
  locationPublicProfileBatchDraftGalleryPhotosDelete: LocationPublicProfileBatchDraftGalleryPhotosDeletePayload;
  /** Update location public profile batch draft gallery photos and order of photos. This updates the batch draft that is used separately to batch update multiple location profiles. */
  locationPublicProfileBatchDraftGalleryPhotosUpdate: LocationPublicProfileBatchDraftGalleryPhotosUpdatePayload;
  /** Updates location public profile draft details fields using auth token */
  locationPublicProfileDraftUpdateDetails: LocationPublicProfileDraftUpdateDetailsPayload;
  /** Updates location public profile draft settings using auth token */
  locationPublicProfileDraftUpdateSettings: LocationPublicProfileDraftUpdateSettingsPayload;
  /** Updates location public profile batch draft settings using auth token. This updates the batch draft that is used separately to batch update multiple location profiles. */
  locationPublicProfileBatchDraftUpdateSettings: LocationPublicProfileBatchDraftUpdateSettingsPayload;
  providerBusinessAcceptTermsAndConditions: ProviderBusinessAcceptTermsAndConditionsPayload;
  providerLocationActivate: ProviderLocationActivatePayload;
  providerLocationArchive: ProviderLocationArchivePayload;
  providerLocationUnarchive: ProviderLocationUnarchivePayload;
  providerOrganizationTeamSetupComplete: ProviderOrganizationTeamSetupCompletePayload;
  providerOrganizationUpdateCustomName: ProviderOrganizationUpdateCustomNamePayload;
  practiceAddStaffMember: PracticeAddStaffMemberPayload;
  practiceDeleteStaffMember: PracticeDeleteStaffMemberPayload;
  practiceUpdateSettings: PracticeUpdateSettingsPayload;
  practiceUpdateStaffMember: PracticeUpdateStaffMemberPayload;
  referringUserProfileCreate: ReferringUserProfileCreatePayload;
  referralCreate: ReferralCreatePayload;
  /** @deprecated use providerReportGenerateFileResult */
  activePromotionsReportCreate: ReportResult;
  /** @deprecated No longer used */
  botoxFiftyOffPromotionReportCreate: ReportResult;
  /** @deprecated use providerReportGenerateFileResult */
  botoxJuvedermLastTransactionReportCreate: ReportResult;
  /** @deprecated use providerReportGenerateFileResult */
  expiringPointsReportCreate: ReportResult;
  expiringPointsBusinessReportCreate: ReportResult;
  /** @deprecated use providerReportGenerateFileResult */
  flashRewardsReportCreate: ReportResult;
  payoutStatementCreate: ReportResult;
  practiceTransactionsReportCreate: ReportResult;
  /** @deprecated use providerReportGenerateFileResult */
  productsLastTransactionReportExport: ReportExportPayload;
  /** @deprecated use providerReportGenerateFileResult */
  promotionReportExport: ReportExportPayload;
  /** @deprecated No longer used */
  transactionsReimbursementsReportCreate: ReportResult;
  transactionsReimbursementsBusinessReportCreate: ReportResult;
  /** @deprecated use providerReportGenerateFileResult */
  unusedGiftCardsReportCreate: ReportResult;
  pricingProfileUpdateEnrollmentStatus: PricingProfileUpdateEnrollmentStatusPayload;
  pricingProfileSendInvite: PricingProfileSendInvitePayload;
  /** @deprecated Use `providerOrganizationAcceptPricingTermsAndConditions` instead */
  providerOrganizationAcceptAllerganStoreTermsAndConditions: ProviderOrganizationAcceptAllerganStoreTermsAndConditionsPayload;
  providerOrganizationAcceptPricingTermsAndConditions: ProviderOrganizationAcceptPricingTermsAndConditionsPayload;
  patientTransaction?: Maybe<PatientTransactionPayload>;
  patientTransactionCancel?: Maybe<PatientTransactionCancelPayload>;
  patientTransactionCreate: PatientTransactionCreatePayload;
  patientTransactionCreateForPractice?: Maybe<PatientTransactionCreateForPracticePayload>;
  patientTransactionFinalize: PatientTransactionFinalizePayload;
  patientTransactionRefund: PatientTransactionRefundRequestPayload;
  patientTransactionSimulate: PatientTransactionSimulatePayload;
  patientTransactionUpdate: PatientTransactionUpdatePayload;
  practiceEnroll: PracticeEnrollPayload;
  practiceEnrollV2: PracticeEnrollV2Payload;
  sms_sendForgotPassword?: Maybe<Sms_Response>;
  taskComplete: TaskCompletePayload;
  optInPromotionClaim: OptInPromotionClaimPayload;
  patientAssignOffer: PatientAssignOfferResponse;
  patientPromotionSelect: PromotionSelectPayload;
  sweepstakesConfirmEntry: SweepstakesConfirmEntryPayload;
  /** @deprecated Use userCheckInToPractice */
  userCheckIn: UserCheckInPayload;
  /** @deprecated Use userCheckInToPracticeV2 */
  userCheckInToPractice: UserCheckInToPracticePayload;
  userCheckInToPracticeV2: UserCheckInToPracticePayloadV2;
  userCheckInToEvent: UserCheckInToEventPayload;
  consumerResendPhoneVerification: UserUpdatePhoneNumberResponse;
  consumerUpdateCommunicationMethods: UserUpdateCommunicationMethodResponse;
  consumerUpdatePhoneNumber: UserUpdatePhoneNumberResponse;
  consumerUpdateProfile: ConsumerUpdateProfileResponse;
  /** @deprecated Use passwordResetInitiate instead. */
  userForgotPassword?: Maybe<ForgotPasswordResponse>;
  /** @deprecated Use passwordResetVerify instead. */
  userVerifyForgotPassword?: Maybe<VerifyForgotPasswordResponse>;
  /** @deprecated Use passwordResetComplete instead. */
  userResetPassword?: Maybe<ResetPasswordResponse>;
  /**
   * Send two factor verification to consumers cell phone
   *
   * Test Phone Numbers
   * - `5550000000`: wrong format error
   * - `5550000001`: phone number in use error
   * - `5550000002`: success (doesn’t send sms message)
   */
  userSendSMSVerification?: Maybe<DefaultStatus>;
  userUpdateCommunicationMethod: UserUpdateCommunicationMethodResponse;
  userUpdateEmailAddress: UserUpdateEmailAddressResponse;
  consumerUpdateEmailAddress: ConsumerUpdateEmailAddressResponse;
  userUpdatePassword: UserUpdatePasswordResponse;
  userUpdateProfile: UserUpdateProfileResponse;
  /**
   * Test Phone Numbers
   * - `5550000004`: success
   */
  userVerifySMSCode?: Maybe<VerifySmsCodeResponse>;
  userVerifyEmail?: Maybe<UserVerifyEmailResponse>;
  consumerResendVerifyEmail?: Maybe<UserVerifyEmailResponse>;
  userVerifyPhoneChange: UserVerifyPhoneChangeResponse;
  userRegistration?: Maybe<RegisterUserResponse>;
  /**
   * Test users:
   * - `test.user.alle@mailinator.com`: `{ isPhoneVerified: false }`
   */
  userBDLogin?: Maybe<UserBdLoginResponse>;
  userStartMigration?: Maybe<UserStartMigrationResponse>;
  userMigrationUpdate?: Maybe<RegisterUserResponse>;
  userLinkBDAccount: UserLinkBdAccountPayload;
  viewerResendPhoneVerification: UserUpdatePhoneNumberResponse;
  viewerUpdatePhoneNumber: UserUpdatePhoneNumberResponse;
};

export type MutationDirectoryConsultationRequestCreateArgs = {
  input: DirectoryConsultationRequestCreateInput;
};

export type MutationBusinessOnboardByInvitationArgs = {
  input: BusinessOnboardByInvitationInput;
};

export type MutationConsumerDeactivateArgs = {
  input?: InputMaybe<ConsumerDeactivateInput>;
};

export type MutationConsumerDeleteArgs = {
  input?: InputMaybe<ConsumerDeleteInput>;
};

export type MutationProviderAccessTokenGenerateArgs = {
  input: ProviderAccessTokenGenerateInput;
};

export type MutationProviderUserAcceptInviteArgs = {
  input: ProviderUserAcceptInviteInput;
};

export type MutationProviderUserAlternateEmailAddressChangeRequestCreateArgs = {
  input: ProviderUserAlternateEmailAddressChangeRequestCreateInput;
};

export type MutationProviderUserDeleteArgs = {
  input: ProviderUserDeleteInput;
};

export type MutationProviderUserInvitationCheckAlternateEmailAddressAvailabilityArgs =
  {
    input: ProviderUserInvitationCheckAlternateEmailAddressAvailabilityInput;
  };

export type MutationProviderUserInvitationSendAlternateEmailAddressVerificationArgs =
  {
    input: ProviderUserInvitationSendAlternateEmailAddressVerificationInput;
  };

export type MutationProviderUserInvitationResendArgs = {
  input: ProviderUserInvitationResendInput;
};

export type MutationProviderUserInvitationVerifyTokenArgs = {
  input: ProviderUserInvitationVerifyTokenInput;
};

export type MutationProviderUserInviteArgs = {
  input: ProviderUserInviteInput;
};

export type MutationProviderUserInviteBatchArgs = {
  input: Array<ProviderUserInviteInput>;
};

export type MutationProviderUserPhoneNumberChangeRequestCreateArgs = {
  input: ProviderUserPhoneNumberChangeRequestCreateInput;
};

export type MutationProviderUserPhoneNumberReleaseArgs = {
  input: ProviderUserPhoneNumberReleaseInput;
};

export type MutationProviderUserPrimaryEmailAddressChangeRequestCreateArgs = {
  input: ProviderUserPrimaryEmailAddressChangeRequestCreateInput;
};

export type MutationProviderUserAddRoleAssignmentArgs = {
  input: ProviderUserAddRoleAssignmentInput;
};

export type MutationProviderUserRegisterByInvitationTokenArgs = {
  input: ProviderUserRegisterByInvitationTokenInput;
};

export type MutationProviderUserRoleAssignmentChangeRoleArgs = {
  input: ProviderUserRoleAssignmentChangeRoleInput;
};

export type MutationProviderUserRoleAssignmentsDeactivateArgs = {
  input: ProviderUserRoleAssignmentsDeactivateInput;
};

export type MutationProviderUserUpdatePasswordArgs = {
  input: ProviderUserUpdatePasswordInput;
};

export type MutationProviderUserVerifyAlternateEmailAddressArgs = {
  input: ProviderUserVerifyAlternateEmailAddressInput;
};

export type MutationProviderUserVerifyPhoneNumberArgs = {
  input: ProviderUserVerifyPhoneNumberInput;
};

export type MutationProviderUserVerifyPrimaryEmailAddressArgs = {
  input: ProviderUserVerifyPrimaryEmailAddressInput;
};

export type MutationProviderSessionTokenGenerateArgs = {
  input: ProviderSessionTokenGenerateInput;
};

export type MutationProviderUserDelegateInvitationArgs = {
  input: ProviderUserDelegateInvitationInput;
};

export type MutationProviderUserInvitationSendSmsVerificationArgs = {
  input: ProviderUserInvitationSendSmsVerificationInput;
};

export type MutationProviderUserInvitationVerifySmsVerificationArgs = {
  input: ProviderUserInvitationVerifySmsVerificationInput;
};

export type MutationProviderUserUpdateFirstNameArgs = {
  input: ProviderUserUpdateFirstNameInput;
};

export type MutationProviderUserUpdateLastNameArgs = {
  input: ProviderUserUpdateLastNameInput;
};

export type MutationConsultationRequestCreateArgs = {
  input: ConsultationRequestCreateInput;
};

export type MutationLocationCatalogCreateForLocationProviderOrgArgs = {
  id: Scalars['ID'];
};

export type MutationLocationCatalogEnhancedCheckoutSetupStatusUpdateArgs = {
  input: LocationCatalogEnhancedCheckoutSetupStatusUpdateInput;
};

export type MutationLocationCatalogItemSetLocationFavoriteArgs = {
  input: LocationCatalogItemSetLocationFavoriteInput;
};

export type MutationLocationCatalogItemSetStatusArgs = {
  input: LocationCatalogItemSetStatusInput;
};

export type MutationLocationCatalogItemSetManufacturerAvailabilityArgs = {
  input: LocationCatalogItemSetManufacturerAvailabilityInput;
};

export type MutationLocationCatalogItemUpdateArgs = {
  input: LocationCatalogItemUpdateInput;
};

export type MutationLocationCatalogSetBaseCatalogItemsArgs = {
  input: LocationCatalogSetBaseCatalogItemsInput;
};

export type MutationLocationCatalogSetTaxRatesArgs = {
  input: LocationCatalogSetTaxRatesInput;
};

export type MutationLocationCatalogItemCreateArgs = {
  input: LocationCatalogItemCreateInput;
};

export type MutationLocationCatalogItemEditArgs = {
  input: LocationCatalogItemEditInput;
};

export type MutationCheckoutCartCreateArgs = {
  input: CheckoutCartCreateInput;
};

export type MutationCheckoutCartDeleteArgs = {
  input: CheckoutCartDeleteInput;
};

export type MutationCheckoutCartAddLineItemArgs = {
  input: CheckoutCartAddLineItemInput;
};

export type MutationCheckoutCartUpdateLineItemArgs = {
  input: CheckoutCartUpdateLineItemInput;
};

export type MutationCheckoutCartUpdateRewardsArgs = {
  input: CheckoutCartUpdateRewardsInput;
};

export type MutationCheckoutCartRemoveLineItemArgs = {
  input: CheckoutCartRemoveLineItemInput;
};

export type MutationCheckoutCartRemoveRewardsLineItemArgs = {
  input: CheckoutCartRemoveRewardsLineItemInput;
};

export type MutationCheckoutCartUpdatePatientArgs = {
  input: CheckoutCartUpdatePatientInput;
};

export type MutationCheckoutCartSetLineItemPriceAdjustmentPriceOverrideArgs = {
  input: CheckoutCartSetLineItemsPriceAdjustmentPriceOverrideInput;
};

export type MutationCheckoutCartSetLineItemPriceAdjustmentDiscountArgs = {
  input: CheckoutCartSetLineItemsPriceAdjustmentDiscountInput;
};

export type MutationCheckoutCartClearLineItemPriceAdjustmentArgs = {
  input: CheckoutCartClearLineItemPriceAdjustmentInput;
};

export type MutationCheckoutCartSetLineItemStaffMemberArgs = {
  input: CheckoutCartSetLineItemStaffMemberInput;
};

export type MutationCheckoutCartClearLineItemStaffMemberArgs = {
  input: CheckoutCartClearLineItemStaffMemberInput;
};

export type MutationCheckoutCartSubmitOrderArgs = {
  input: CheckoutCartSubmitOrderInput;
};

export type MutationCheckoutCartCreateTerminalReaderPaymentLineItemArgs = {
  input: CheckoutCartCreateTerminalReaderPaymentLineItemInput;
};

export type MutationCheckoutOrderCreateRefundArgs = {
  input: CheckoutOrderCreateRefundInput;
};

export type MutationCheckoutOrderRerunArgs = {
  input: CheckoutOrderRerunInput;
};

export type MutationCheckoutOrderSendEmailReceiptArgs = {
  input: CheckoutOrderSendEmailReceiptInput;
};

export type MutationCheckoutSalesReportCreateArgs = {
  input: CheckoutSalesReportCreateInput;
};

export type MutationConsumerConsentCommunicationPreferencesUpdateArgs = {
  input: ConsumerConsentCommunicationPreferencesUpdateInput;
};

export type MutationConsumerConsentCommunicationPreferencesUpdateUnsubscribeFromAllArgs =
  {
    input?: InputMaybe<ConsumerConsentCommunicationPreferencesUpdateUnsubscribeFromAllInput>;
  };

export type MutationConsumerConsentPrivacySettingsUpdateArgs = {
  input: ConsumerConsentPrivacySettingsUpdateInput;
};

export type MutationConsumerConsentPrivacySettingsLimitSensitiveDataUseArgs = {
  input: ConsumerConsentPrivacySettingsLimitSensitiveDataUseInput;
};

export type MutationConsumerConsentPrivacyTermsAcceptUpdatedTermsArgs = {
  input: ConsumerConsentPrivacyTermsAcceptUpdatedTermsInput;
};

export type MutationEmail_SendForgotPasswordArgs = {
  user?: InputMaybe<PasswordResetUser>;
};

export type MutationEmail_SendTransactionReceiptEmailArgs = {
  consumerId: Scalars['String'];
  transactionId: Scalars['String'];
};

export type MutationPracticeOptOutFlashRewardsArgs = {
  input: PracticeOptOutFlashRewardsInput;
};

export type MutationPracticeReactivateFlashRewardsArgs = {
  input: PracticeReactivateFlashRewardsInput;
};

export type MutationGiftCardCreateArgs = {
  input: GiftCardCreateInput;
};

export type MutationGiftCardDeactivateArgs = {
  input: GiftCardDeactivateInput;
};

export type MutationGiftCardRemoveArgs = {
  id: Scalars['ID'];
};

export type MutationGiftCardShareArgs = {
  input: GiftCardShareInput;
};

export type MutationAdsCampaignBudgetValidateArgs = {
  input: AdsCampaignBudgetValidateInput;
};

export type MutationAdvertisingAudienceEmailsUploadFormCreateArgs = {
  input?: InputMaybe<AdvertisingAudienceEmailsUploadFormCreateInput>;
};

export type MutationAdvertisingContactUpdateEmailAddressArgs = {
  input: AdvertisingContactUpdateEmailAddressInput;
};

export type MutationAdvertisingContactAutoCallProfileUpdateArgs = {
  input: AdvertisingContactAutoCallProfileUpdateInput;
};

export type MutationAdvertisingProfileAcceptTermsAndConditionsArgs = {
  input: AdvertisingProfileAcceptTermsAndConditionsInput;
};

export type MutationContactsFileValidateArgs = {
  input: ContactsFileValidateInput;
};

export type MutationFacebookPostCreateArgs = {
  input: FacebookPostCreateInput;
};

export type MutationPracticeActivateAdvertisingProfileArgs = {
  input: PracticeActivateAdvertisingProfileInput;
};

export type MutationPracticeAdvertisingProfileUpdateEnrollmentStatusArgs = {
  input: PracticeAdvertisingProfileUpdateEnrollmentStatusInput;
};

export type MutationPracticeAdvertisingProfileUpdateAdvertisementManagementStatusArgs =
  {
    input: PracticeAdvertisingProfileUpdateAdvertisementManagementStatusInput;
  };

export type MutationProviderMarketingCampaignDraftCreateArgs = {
  input: ProviderMarketingCampaignDraftCreateInput;
};

export type MutationProviderMarketingCampaignCreateArgs = {
  input: ProviderMarketingCampaignCreateInput;
};

export type MutationProviderMarketingCampaignCreateContactLinkArgs = {
  input: ProviderMarketingCampaignCreateContactLinkInput;
};

export type MutationProviderMarketingCampaignSubmitSurveyArgs = {
  input: ProviderMarketingCampaignSubmitSurveyInput;
};

export type MutationProviderMarketingCampaignGeneratePreviewArgs = {
  input: ProviderMarketingCampaignGeneratePreviewInput;
};

export type MutationProviderSettingsValidateArgs = {
  input: ProviderSettingsValidateInput;
};

export type MutationSocialNetworkIntegrationCreateArgs = {
  input: SocialNetworkIntegrationCreateInput;
};

export type MutationSocialNetworkIntegrationDeleteArgs = {
  input: SocialNetworkIntegrationDeleteInput;
};

export type MutationUserActionTrackArgs = {
  input: UserActionTrackInput;
};

export type MutationProviderMarketingCampaignPauseArgs = {
  id: Scalars['ID'];
};

export type MutationProviderMarketingCampaignResumeArgs = {
  id: Scalars['ID'];
};

export type MutationProviderMarketingCampaignStopArgs = {
  id: Scalars['ID'];
};

export type MutationProviderEmailMarketingCampaignCreateArgs = {
  input: ProviderEmailMarketingCampaignCreateInput;
};

export type MutationProviderEmailMarketingCampaignPackageAutomationSetEnabledArgs =
  {
    input: ProviderEmailMarketingCampaignPackageAutomationSetEnabledInput;
  };

export type MutationProviderEmailMarketingCampaignPackageSendPreviewArgs = {
  input: ProviderEmailMarketingCampaignPackageSendPreviewInput;
};

export type MutationAdvertisingOfferActivateArgs = {
  input: AdvertisingOfferActivateInput;
};

export type MutationPasswordResetCompleteArgs = {
  input: PasswordResetCompleteInput;
};

export type MutationPasswordResetInitiateArgs = {
  input: PasswordResetInitiateInput;
};

export type MutationPasswordResetVerifyArgs = {
  input: PasswordResetVerifyInput;
};

export type MutationProviderReportGenerateResultArgs = {
  input: ProviderReportGenerateResultInput;
};

export type MutationProviderReportGenerateFileResultArgs = {
  input: ProviderReportGenerateFileResultInput;
};

export type MutationLeadAddNoteArgs = {
  input: LeadAddNoteInput;
};

export type MutationLeadContactedStatusUpdateArgs = {
  input: LeadContactedStatusUpdateInput;
};

export type MutationLeadNoteEditArgs = {
  input: LeadNoteEditInput;
};

export type MutationLeadNoteRemoveArgs = {
  id: Scalars['ID'];
};

export type MutationLeadSetHasBookedAppointmentArgs = {
  input: LeadSetHasBookedAppointmentInput;
};

export type MutationLeadSetIsViewedArgs = {
  id: Scalars['ID'];
};

export type MutationLeadsExportArgs = {
  input: LeadsExportInput;
};

export type MutationAdvertisingLeadNotificationSmsPhoneNumberAddArgs = {
  input: AdvertisingLeadNotificationSmsPhoneNumberAddInput;
};

export type MutationAdvertisingLeadNotificationSmsPhoneNumberVerifyArgs = {
  input: AdvertisingLeadNotificationSmsPhoneNumberVerifyInput;
};

export type MutationAdvertisingLeadNotificationVoiceCallPhoneNumberAddArgs = {
  input: AdvertisingLeadNotificationVoiceCallPhoneNumberAddInput;
};

export type MutationAdvertisingLeadNotificationsAddEmailAddressArgs = {
  input: AdvertisingLeadNotificationsAddEmailAddressInput;
};

export type MutationAdvertisingLeadNotificationsVerifyEmailAddressArgs = {
  input: AdvertisingLeadNotificationsVerifyEmailAddressInput;
};

export type MutationAdvertisingLeadNotificationsAddSmsPhoneNumberArgs = {
  input: AdvertisingLeadNotificationsAddSmsPhoneNumberInput;
};

export type MutationAdvertisingLeadNotificationsVerifySmsPhoneNumberArgs = {
  input: AdvertisingLeadNotificationsVerifySmsPhoneNumberInput;
};

export type MutationAdvertisingLeadNotificationsAddVoiceCallPhoneNumberArgs = {
  input: AdvertisingLeadNotificationsAddVoiceCallPhoneNumberInput;
};

export type MutationAdvertisingLeadNotificationMethodRemoveArgs = {
  id: Scalars['ID'];
};

export type MutationAdvertisingLeadNotificationMethodSetEnableArgs = {
  input: AdvertisingLeadNotificationMethodSetEnableInput;
};

export type MutationProviderMarketingEmailOptOutArgs = {
  input: ProviderMarketingEmailOptOutInput;
};

export type MutationAdvertisingProfileSetPhoneNotificationMethodArgs = {
  input: AdvertisingProfileSetPhoneNotificationMethodInput;
};

export type MutationAdvertisingNotificationMethodVerifyArgs = {
  input: AdvertisingNotificationMethodVerifyInput;
};

export type MutationChangeRequestApproveArgs = {
  input: ChangeRequestApproveInput;
};

export type MutationChangeRequestDeclineArgs = {
  input: ChangeRequestDeclineInput;
};

export type MutationChangeRequestDeleteArgs = {
  input: ChangeRequestDeleteInput;
};

export type MutationChangeRequestIncreaseInventoryAllocationUpdateArgs = {
  input: ChangeRequestIncreaseInventoryAllocationUpdateInput;
};

export type MutationChangeRequestModifyEndDateUpdateArgs = {
  input: ChangeRequestModifyEndDateUpdateInput;
};

export type MutationMarketingOfferAddChangeRequestIncreaseInventoryAllocationArgs =
  {
    input: MarketingOfferAddChangeRequestIncreaseInventoryAllocationInput;
  };

export type MutationMarketingOfferAddChangeRequestModifyEndDateArgs = {
  input: MarketingOfferAddChangeRequestModifyEndDateInput;
};

export type MutationMarketingOfferCreateArgs = {
  input: MarketingOfferCreateInput;
};

export type MutationMarketingOfferDeleteArgs = {
  input: MarketingOfferDeleteInput;
};

export type MutationMarketingOfferDuplicateArgs = {
  input: MarketingOfferDuplicateInput;
};

export type MutationMarketingOfferUpdateActivationTypeArgs = {
  input: MarketingOfferUpdateActivationTypeInput;
};

export type MutationMarketingOfferUpdateAudiencesArgs = {
  input: MarketingOfferUpdateAudiencesInput;
};

export type MutationMarketingOfferUpdateDistributionTypeArgs = {
  input: MarketingOfferUpdateDistributionTypeInput;
};

export type MutationMarketingOfferUpdateDollarsOffRulesArgs = {
  input: MarketingOfferUpdateDollarsOffRulesInput;
};

export type MutationMarketingOfferUpdateDurationArgs = {
  input: MarketingOfferUpdateDurationInput;
};

export type MutationMarketingOfferUpdateEarnedPointsBrandsSelectionArgs = {
  input: MarketingOfferUpdateEarnedPointsBrandsSelectionInput;
};

export type MutationMarketingOfferUpdateEarnedPointsDetailsArgs = {
  input: MarketingOfferUpdateEarnedPointsDetailsInput;
};

export type MutationMarketingOfferUpdateEligibleAudiencesArgs = {
  input: MarketingOfferUpdateEligibleAudiencesInput;
};

export type MutationMarketingOfferUpdateFixedExpirationArgs = {
  input: MarketingOfferUpdateFixedExpirationInput;
};

export type MutationMarketingOfferUpdateExpireInDaysArgs = {
  input: MarketingOfferUpdateExpireInDaysInput;
};

export type MutationMarketingOfferUpdateGiftCardDetailsArgs = {
  input: MarketingOfferUpdateGiftCardDetailsInput;
};

export type MutationMarketingOfferUpdateGiftCardRulesArgs = {
  input: MarketingOfferUpdateGiftCardRulesInput;
};

export type MutationMarketingOfferUpdatePracticeInventoryArgs = {
  input: MarketingOfferUpdatePracticeInventoryInput;
};

export type MutationMarketingOfferUpdateRelativeExpirationArgs = {
  input: MarketingOfferUpdateRelativeExpirationInput;
};

export type MutationMarketingOfferUpdateInventoryArgs = {
  input: MarketingOfferUpdateInventoryInput;
};

export type MutationMarketingOfferUpdateNameArgs = {
  input: MarketingOfferUpdateNameInput;
};

export type MutationMarketingOfferUpdateProductSelectionArgs = {
  input: MarketingOfferUpdateProductSelectionInput;
};

export type MutationMarketingOfferUpdateTermsArgs = {
  input: MarketingOfferUpdateTermsInput;
};

export type MutationMarketingOfferUpdateValueArgs = {
  input: MarketingOfferUpdateValueInput;
};

export type MutationMarketingOfferUpdateValueTypeArgs = {
  input: MarketingOfferUpdateValueTypeInput;
};

export type MutationMarketingOfferUpdateWalletDetailsArgs = {
  input: MarketingOfferUpdateWalletDetailsInput;
};

export type MutationMarketingOfferPublishArgs = {
  input: MarketingOfferPublishInput;
};

export type MutationMarketingOfferSubmitForApprovalArgs = {
  input: MarketingOfferSubmitForApprovalInput;
};

export type MutationMarketingOfferDeclineArgs = {
  input: MarketingOfferDeclineInput;
};

export type MutationMarketingOfferApproveArgs = {
  input: MarketingOfferApproveInput;
};

export type MutationMarketingOfferChangeRequestApproveArgs = {
  input: MarketingOfferChangeRequestApproveInput;
};

export type MutationMarketingOfferChangeRequestDeclineArgs = {
  input: MarketingOfferChangeRequestDeclineInput;
};

export type MutationMarketingOfferChangeRequestPublishArgs = {
  input: MarketingOfferChangeRequestPublishInput;
};

export type MutationCherryFinancingApplicationInitiateArgs = {
  input: CherryFinancingApplicationInitiateInput;
};

export type MutationCherryFinancingApplicationRefreshArgs = {
  input: CherryFinancingApplicationRefreshInput;
};

export type MutationCherryPaymentApprovalRequestCancelArgs = {
  input: CherryPaymentApprovalRequestCancelInput;
};

export type MutationCherryPaymentApprovalRequestCreateArgs = {
  input: CherryPaymentApprovalRequestCreateInput;
};

export type MutationCherryProviderOrganizationOnboardCompleteArgs = {
  input: CherryProviderOrganizationOnboardCompleteInput;
};

export type MutationCherryProviderOrganizationOnboardInitiateArgs = {
  input: CherryProviderOrganizationOnboardInitiateInput;
};

export type MutationCherrySendSmsInvitationArgs = {
  input: CherrySendSmsInvitationInput;
};

export type MutationConnectAccountRedirectUrlCreateArgs = {
  input: ConnectAccountRedirectUrlCreateInput;
};

export type MutationPaymentsWalletCreateForPracticeArgs = {
  input: PaymentsWalletCreateForPracticeInput;
};

export type MutationSetupIntentCompleteArgs = {
  input: SetupIntentCompleteInput;
};

export type MutationPaymentMethodChargeArgs = {
  input: PaymentMethodChargeInput;
};

export type MutationPaymentMethodDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationPaymentIntentCreateArgs = {
  input: PaymentIntentInput;
};

export type MutationPaymentsSetupIntentCreateArgs = {
  input: PaymentsSetupIntentInput;
};

export type MutationPaymentIntentUpdateArgs = {
  input: UpdatePaymentIntentInput;
};

export type MutationPaymentsUpdatePaymentMethodArgs = {
  input: PaymentMethodUpdateInput;
};

export type MutationPaymentsTransactionReportCreateArgs = {
  input: PaymentsTransactionReportCreateInput;
};

export type MutationPayoutSummaryReportCreateArgs = {
  input: PayoutSummaryReportCreateInput;
};

export type MutationPayoutDetailReportCreateArgs = {
  input: PayoutDetailReportCreateInput;
};

export type MutationPaymentsTransferRequestCancelArgs = {
  input: PaymentsTransferRequestCancelInput;
};

export type MutationPaymentsBalanceAutomationParametersUpdateArgs = {
  input: PaymentsBalanceAutomationParametersUpdateInput;
};

export type MutationPaymentsTransferRequestCreateArgs = {
  input: PaymentsTransferRequestCreateInput;
};

export type MutationPaymentsTransferRequestApproveArgs = {
  input: PaymentsTransferRequestApproveInput;
};

export type MutationPaymentsTransferRequestDeclineArgs = {
  input: PaymentsTransferRequestDeclineInput;
};

export type MutationPlatformSubscriptionCreateArgs = {
  input: PlatformSubscriptionCreateInput;
};

export type MutationPlatformSubscriptionCancelArgs = {
  input: PlatformSubscriptionCancelInput;
};

export type MutationPlatformSubscriptionUpdatePaymentMethodArgs = {
  input: PlatformSubscriptionUpdatePaymentMethodInput;
};

export type MutationPracticePaymentProfileUpdateEmailAddressArgs = {
  input: PracticePaymentProfileUpdateEmailAddressInput;
};

export type MutationPracticePaymentProfileUpdatePaymentFrequencyArgs = {
  input: PracticePaymentProfileUpdatePaymentFrequencyInput;
};

export type MutationPaymentProfileUpdateEmailArgs = {
  input: PaymentProfileUpdateEmailInput;
};

export type MutationPaymentProfileUpdatePaymentFrequencyArgs = {
  input: PaymentProfileUpdatePaymentFrequencyInput;
};

export type MutationPaymentProfileDeactivateArgs = {
  input: PaymentProfileDeactivateInput;
};

export type MutationPaymentProfileReactivateArgs = {
  input: PaymentProfileReactivateInput;
};

export type MutationPaymentProfileUpdateFundingSourceArgs = {
  input: PaymentProfileUpdateFundingSourceInput;
};

export type MutationPaymentProfileUpdateFundingSourceForChildOrgArgs = {
  input: PaymentProfileUpdateFundingSourceForChildOrgInput;
};

export type MutationPromotionClaimWithPointsArgs = {
  promotionId: Scalars['ID'];
};

export type MutationAllerganStoreShoppingCartCreateArgs = {
  input: AllerganStoreShoppingCartCreateInput;
};

export type MutationAllerganStoreShoppingCartAddProductArgs = {
  input: AllerganStoreShoppingCartAddProductInput;
};

export type MutationAllerganStoreShoppingCartUpdateProductQuantityArgs = {
  input: AllerganStoreShoppingCartUpdateProductQuantityInput;
};

export type MutationAllerganStoreShoppingCartUpdateShippingMethodArgs = {
  input: AllerganStoreShoppingCartUpdateShippingMethodInput;
};

export type MutationAllerganStoreShoppingCartUpdateCreditCardArgs = {
  input: AllerganStoreShoppingCartUpdateCreditCardInput;
};

export type MutationAllerganStoreShoppingCartUpdatePurchaseOrderNameArgs = {
  input: AllerganStoreShoppingCartUpdatePurchaseOrderNameInput;
};

export type MutationAllerganStoreShoppingCartDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationAllerganStoreCreditCardFormCreateArgs = {
  input: AllerganStoreCreditCardFormCreateInput;
};

export type MutationAllerganStoreCreditCardAddArgs = {
  input: AllerganStoreCreditCardAddInput;
};

export type MutationAllerganStoreCreditCardSetIsDefaultArgs = {
  input: AllerganStoreCreditCardSetIsDefaultInput;
};

export type MutationAllerganStoreCreditCardDeleteArgs = {
  input: AllerganStoreCreditCardDeleteInput;
};

export type MutationAllerganStoreOrderCreateArgs = {
  input: AllerganStoreOrderCreateInput;
};

export type MutationAllerganStoreShoppingCartAddProductsFromOrderArgs = {
  input: AllerganStoreShoppingCartAddProductsFromOrderInput;
};

export type MutationAllerganStoreShoppingCartReplaceProductsFromOrderArgs = {
  input: AllerganStoreShoppingCartReplaceProductsFromOrderInput;
};

export type MutationAllerganStoreShoppingCartSetPaymentMethodToBillMeLaterArgs =
  {
    id: Scalars['ID'];
  };

export type MutationAllerganStoreInvoicesPayArgs = {
  input: AllerganStoreInvoicesPayInput;
};

export type MutationAllerganStoreOrderGenerateInvoiceFileArgs = {
  id: Scalars['ID'];
};

export type MutationAllerganStoreInvoiceGenerateReceiptFileArgs = {
  id: Scalars['ID'];
};

export type MutationLocationPublicProfileDraftUpdateAboutArgs = {
  input: LocationPublicProfileDraftUpdateAboutInput;
};

export type MutationLocationPublicProfileDraftUpdateAddressArgs = {
  input: LocationPublicProfileDraftUpdateAddressInput;
  providerOrganizationId?: InputMaybe<Scalars['String']>;
};

export type MutationLocationPublicProfileBatchDraftApplyArgs = {
  input: LocationPublicProfileBatchDraftApplyInput;
};

export type MutationLocationPublicProfileBatchDraftUpdateAvatarUrlArgs = {
  input: LocationPublicProfileBatchDraftUpdateAvatarUrlInput;
};

export type MutationLocationPublicProfileDraftUpdateBusinessHoursArgs = {
  input: LocationPublicProfileDraftUpdateBusinessHoursInput;
  providerOrganizationId?: InputMaybe<Scalars['String']>;
};

export type MutationLocationPublicProfileDraftUpdateConsultationRequestSettingsArgs =
  {
    input: LocationPublicProfileDraftUpdateConsultationRequestSettingsInput;
    providerOrganizationId?: InputMaybe<Scalars['String']>;
  };

export type MutationLocationPublicProfileDraftUpdateContactInformationArgs = {
  input: LocationPublicProfileDraftUpdateContactInformationInput;
  providerOrganizationId?: InputMaybe<Scalars['String']>;
};

export type MutationLocationPublicProfileBatchDraftUpdateContactInformationArgs =
  {
    input: LocationPublicProfileBatchDraftUpdateContactInformationInput;
  };

export type MutationLocationPublicProfileDraftUpsertGoogleDataArgs = {
  input?: InputMaybe<LocationPublicProfileDraftUpsertGoogleDataInput>;
};

export type MutationSignedUploadUrlArgs = {
  input: SignedUploadUrlInput;
};

export type MutationLocationPublicProfileDraftAddLanguagesArgs = {
  input: LocationPublicProfileDraftAddLanguagesInput;
};

export type MutationLocationPublicProfileDraftRemoveLanguagesArgs = {
  input: LocationPublicProfileDraftRemoveLanguagesInput;
};

export type MutationLocationPublicProfileDraftPublishArgs = {
  providerOrganizationId?: InputMaybe<Scalars['String']>;
};

export type MutationLocationPublicProfileDraftOptInMarketingEventsUpsertArgs = {
  input?: InputMaybe<LocationPublicProfileDraftOptInMarketingEventsUpsertInput>;
};

export type MutationLocationPublicProfileDraftAddProductsArgs = {
  input: LocationPublicProfileDraftAddProductsInput;
  providerOrganizationId?: InputMaybe<Scalars['String']>;
};

export type MutationLocationPublicProfileDraftProductsDeleteArgs = {
  ids: Array<Scalars['ID']>;
  providerOrganizationId?: InputMaybe<Scalars['String']>;
};

export type MutationLocationPublicProfileBatchDraftAddProductsArgs = {
  input: LocationPublicProfileBatchDraftAddProductsInput;
};

export type MutationLocationPublicProfileBatchDraftProductsDeleteArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationLocationPublicProfileDraftGalleryPhotosDeleteArgs = {
  ids: Array<Scalars['ID']>;
  providerOrganizationId?: InputMaybe<Scalars['String']>;
};

export type MutationLocationPublicProfileDraftGalleryPhotosUpdateArgs = {
  input: LocationPublicProfileDraftGalleryPhotosUpdateInput;
  providerOrganizationId?: InputMaybe<Scalars['String']>;
};

export type MutationLocationPublicProfileDraftAddGalleryPhotosArgs = {
  input: LocationPublicProfileDraftAddGalleryPhotosInput;
  providerOrganizationId?: InputMaybe<Scalars['String']>;
};

export type MutationLocationPublicProfileBatchDraftAddGalleryPhotosArgs = {
  input: LocationPublicProfileBatchDraftAddGalleryPhotosInput;
};

export type MutationLocationPublicProfileBatchDraftGalleryPhotosDeleteArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationLocationPublicProfileBatchDraftGalleryPhotosUpdateArgs = {
  input: LocationPublicProfileBatchDraftGalleryPhotosUpdateInput;
};

export type MutationLocationPublicProfileDraftUpdateDetailsArgs = {
  input: LocationPublicProfileDraftUpdateDetailsInput;
  providerOrganizationId?: InputMaybe<Scalars['String']>;
};

export type MutationLocationPublicProfileDraftUpdateSettingsArgs = {
  input: LocationPublicProfileDraftUpdateSettingsInput;
  providerOrganizationId?: InputMaybe<Scalars['String']>;
};

export type MutationLocationPublicProfileBatchDraftUpdateSettingsArgs = {
  input: LocationPublicProfileBatchDraftUpdateSettingsInput;
};

export type MutationProviderBusinessAcceptTermsAndConditionsArgs = {
  input: ProviderBusinessAcceptTermsAndConditionsInput;
};

export type MutationProviderLocationActivateArgs = {
  input: ProviderLocationActivateInput;
};

export type MutationProviderLocationArchiveArgs = {
  input: ProviderLocationArchiveInput;
};

export type MutationProviderLocationUnarchiveArgs = {
  input: ProviderLocationUnarchiveInput;
};

export type MutationProviderOrganizationUpdateCustomNameArgs = {
  input: ProviderOrganizationUpdateCustomNameInput;
};

export type MutationPracticeAddStaffMemberArgs = {
  input: PracticeAddStaffMemberInput;
};

export type MutationPracticeDeleteStaffMemberArgs = {
  id: Scalars['ID'];
};

export type MutationPracticeUpdateSettingsArgs = {
  input: PracticeUpdateSettingsInput;
};

export type MutationPracticeUpdateStaffMemberArgs = {
  input: PracticeUpdateStaffMemberInput;
};

export type MutationReferringUserProfileCreateArgs = {
  input: ReferringUserProfileCreateInput;
};

export type MutationReferralCreateArgs = {
  input: ReferralCreateInput;
};

export type MutationActivePromotionsReportCreateArgs = {
  input: ActivePromotionsReportCreateInput;
};

export type MutationBotoxFiftyOffPromotionReportCreateArgs = {
  input: BotoxFiftyOffPromotionReportCreateInput;
};

export type MutationBotoxJuvedermLastTransactionReportCreateArgs = {
  input: BotoxJuvedermLastTransactionReportCreateInput;
};

export type MutationExpiringPointsReportCreateArgs = {
  input: ExpiringPointsReportCreateInput;
};

export type MutationExpiringPointsBusinessReportCreateArgs = {
  input: ExpiringPointsBusinessReportCreateInput;
};

export type MutationFlashRewardsReportCreateArgs = {
  input: FlashRewardsReportCreateInput;
};

export type MutationPayoutStatementCreateArgs = {
  payoutId: Scalars['String'];
  format?: InputMaybe<Report_Format>;
};

export type MutationPracticeTransactionsReportCreateArgs = {
  input: PracticeTransactionsReportCreateInput;
};

export type MutationProductsLastTransactionReportExportArgs = {
  input: ProductsLastTransactionReportExportInput;
};

export type MutationPromotionReportExportArgs = {
  input: PromotionReportExportInput;
};

export type MutationTransactionsReimbursementsReportCreateArgs = {
  input: TransactionsReimbursementsReportCreateInput;
};

export type MutationTransactionsReimbursementsBusinessReportCreateArgs = {
  input: TransactionsReimbursementsBusinessReportCreateInput;
};

export type MutationUnusedGiftCardsReportCreateArgs = {
  input: UnusedGiftCardsReportCreateInput;
};

export type MutationPricingProfileUpdateEnrollmentStatusArgs = {
  input: PricingProfileUpdateEnrollmentStatusInput;
};

export type MutationPricingProfileSendInviteArgs = {
  input: PricingProfileSendInviteInput;
};

export type MutationProviderOrganizationAcceptAllerganStoreTermsAndConditionsArgs =
  {
    input: ProviderOrganizationAcceptAllerganStoreTermsAndConditionsInput;
  };

export type MutationProviderOrganizationAcceptPricingTermsAndConditionsArgs = {
  input: ProviderOrganizationAcceptPricingTermsAndConditionsInput;
};

export type MutationPatientTransactionArgs = {
  input: PatientTransactionInput;
};

export type MutationPatientTransactionCancelArgs = {
  input: PatientTransactionCancelInput;
};

export type MutationPatientTransactionCreateArgs = {
  input: PatientTransactionCreateInput;
};

export type MutationPatientTransactionCreateForPracticeArgs = {
  input: PatientTransactionCreateForPracticeInput;
};

export type MutationPatientTransactionFinalizeArgs = {
  id: Scalars['ID'];
};

export type MutationPatientTransactionRefundArgs = {
  input: PatientTransactionRefundRequestInput;
};

export type MutationPatientTransactionSimulateArgs = {
  input: PatientTransactionSimulateInput;
};

export type MutationPatientTransactionUpdateArgs = {
  input: PatientTransactionUpdateInput;
};

export type MutationPracticeEnrollArgs = {
  input: PracticeEnrollInput;
};

export type MutationPracticeEnrollV2Args = {
  input: PracticeEnrollV2Input;
};

export type MutationSms_SendForgotPasswordArgs = {
  phoneNumber: Scalars['String'];
  url: Scalars['String'];
};

export type MutationTaskCompleteArgs = {
  input: TaskCompleteInput;
};

export type MutationOptInPromotionClaimArgs = {
  input: OptInPromotionClaimInput;
};

export type MutationPatientAssignOfferArgs = {
  input: PatientAssignOfferInput;
};

export type MutationPatientPromotionSelectArgs = {
  input: PromotionSelectInput;
};

export type MutationSweepstakesConfirmEntryArgs = {
  input?: InputMaybe<SweepstakesConfirmEntryInput>;
};

export type MutationUserCheckInToPracticeArgs = {
  input: UserCheckInToPracticeInput;
};

export type MutationUserCheckInToPracticeV2Args = {
  input: UserCheckInToPracticeV2Input;
};

export type MutationUserCheckInToEventArgs = {
  input: UserCheckInToEventInput;
};

export type MutationConsumerResendPhoneVerificationArgs = {
  input: ConsumerResendPhoneVerificationInput;
};

export type MutationConsumerUpdateCommunicationMethodsArgs = {
  input: ConsumerUpdateCommunicationMethodInput;
};

export type MutationConsumerUpdatePhoneNumberArgs = {
  input: ConsumerUpdatePhoneNumberInput;
};

export type MutationConsumerUpdateProfileArgs = {
  input: ConsumerUpdateProfileInput;
};

export type MutationUserForgotPasswordArgs = {
  username: Scalars['String'];
  factor: ForgotPasswordFactor;
  baseUrl: Scalars['String'];
};

export type MutationUserVerifyForgotPasswordArgs = {
  token: Scalars['String'];
};

export type MutationUserResetPasswordArgs = {
  stateToken: Scalars['String'];
  newPassword: Scalars['String'];
};

export type MutationUserSendSmsVerificationArgs = {
  phoneNumber: Scalars['String'];
  url: Scalars['String'];
  mergeToken?: InputMaybe<Scalars['String']>;
  optInSMS?: InputMaybe<Scalars['Boolean']>;
  sendCodeOnly?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserUpdateCommunicationMethodArgs = {
  input: UserUpdateCommunicationMethodInput;
};

export type MutationUserUpdateEmailAddressArgs = {
  input: UserUpdateEmailAddressInput;
};

export type MutationConsumerUpdateEmailAddressArgs = {
  input: ConsumerUpdateEmailAddressInput;
};

export type MutationUserUpdatePasswordArgs = {
  input: UserUpdatePasswordInput;
};

export type MutationUserUpdateProfileArgs = {
  input: UserUpdateProfileInput;
};

export type MutationUserVerifySmsCodeArgs = {
  phoneNumber: Scalars['String'];
  code: Scalars['String'];
};

export type MutationUserVerifyEmailArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export type MutationConsumerResendVerifyEmailArgs = {
  input: ConsumerResendVerifyEmailInput;
};

export type MutationUserVerifyPhoneChangeArgs = {
  input: UserVerifyPhoneChangeInput;
};

export type MutationUserRegistrationArgs = {
  user: ProfileInput;
  token: Scalars['String'];
  baseUrl?: InputMaybe<Scalars['String']>;
  acceptHIPAA?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Array<InputMaybe<GenericMetadataInput>>>;
};

export type MutationUserBdLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type MutationUserStartMigrationArgs = {
  mergeToken: Scalars['String'];
  smsToken?: InputMaybe<Scalars['String']>;
};

export type MutationUserMigrationUpdateArgs = {
  token: Scalars['String'];
  profile: MigrationUpdateInput;
  baseUrl?: InputMaybe<Scalars['String']>;
  acceptHIPAA?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUserLinkBdAccountArgs = {
  input: UserLinkBdAccountInput;
};

export type MutationViewerResendPhoneVerificationArgs = {
  input: ViewerResendPhoneVerificationInput;
};

export type MutationViewerUpdatePhoneNumberArgs = {
  input: UserUpdatePhoneNumberInput;
};

export type NumberRange = {
  __typename?: 'NumberRange';
  minimum?: Maybe<Scalars['Int']>;
  maximum?: Maybe<Scalars['Int']>;
};

export type NumberRangeInput = {
  minimum?: InputMaybe<Scalars['Int']>;
  maximum?: InputMaybe<Scalars['Int']>;
};

export enum Offer_Availability {
  Available = 'AVAILABLE',
  Expired = 'EXPIRED',
  Redeemed = 'REDEEMED',
}

export enum Offer_Claim_Status {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
}

export enum Offer_Unavailable_Reason {
  AuthenticationRequired = 'AUTHENTICATION_REQUIRED',
  NoInventory = 'NO_INVENTORY',
  AlreadyClaimed = 'ALREADY_CLAIMED',
  AlreadyRedeemed = 'ALREADY_REDEEMED',
  NotStarted = 'NOT_STARTED',
  Expired = 'EXPIRED',
  Ineligible = 'INELIGIBLE',
}

export type OfferIssuedBy = {
  __typename?: 'OfferIssuedBy';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type OfficePromotion = Promotion & {
  __typename?: 'OfficePromotion';
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  expiration?: Maybe<DateTime>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  type?: Maybe<Promotion_Type>;
  value: PromotionValue;
  imageURL?: Maybe<Scalars['URL']>;
  imageAltText?: Maybe<Scalars['String']>;
  isisConnection?: Maybe<PromotionIsIsConnection>;
  productsConnection?: Maybe<PromotionProductsConnection>;
  quantity?: Maybe<OfficePromotionQuantity>;
};

export type OfficePromotionQuantity = {
  __typename?: 'OfficePromotionQuantity';
  total?: Maybe<Scalars['Int']>;
  remaining?: Maybe<Scalars['Int']>;
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  totalResults: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  nextOffset?: Maybe<Scalars['Int']>;
  previousOffset?: Maybe<Scalars['Int']>;
};

export type OptInPromotion = {
  __typename?: 'OptInPromotion';
  id: Scalars['ID'];
  viewerAvailability: OptInPromotionAvailability;
};

export type OptInPromotionAvailability = {
  __typename?: 'OptInPromotionAvailability';
  viewerCanClaim: Scalars['Boolean'];
  reason?: Maybe<Offer_Unavailable_Reason>;
};

export type OptInPromotionClaim =
  | OptInPromotionClaimCompleted
  | OptInPromotionClaimFailed
  | OptInPromotionClaimPending;

export type OptInPromotionClaimAdvertisingMetadataInput = {
  campaignId: Scalars['ID'];
  /**
   * clickId is optional as click id may be stripped out from ad blocker
   * from time to time where we don't want to impact user ability to claim offer
   */
  clickId?: InputMaybe<Scalars['ID']>;
  /**
   * adSetId is optional as adSetId may be stripped out from ad blocker from
   * URL parameters where we don't necessary want to impact user ability to
   * claim an offer if we cant attribute
   */
  adSetId?: InputMaybe<Scalars['ID']>;
  /**
   * VariantID is expected to be part of the queryUrl as an extension to the campaignId
   * e.g: https://demo.xyz/grow/<campaignId>/<variantId>?fbclid=<clickId>&adsetId=<adsetId>
   */
  variantId?: InputMaybe<Scalars['ID']>;
  /**
   * ZipCode is an optional field part of the "Pass the lead" program where a lead can claim
   * an offer and request to be contacted by a Provider in the same area.
   */
  zipCode?: InputMaybe<Scalars['String']>;
};

export type OptInPromotionClaimCommunicationPreferenceInput = {
  optInSMS: Scalars['Boolean'];
};

export type OptInPromotionClaimCompleted = {
  __typename?: 'OptInPromotionClaimCompleted';
  id: Scalars['ID'];
  promotion: PatientPromotion;
};

export type OptInPromotionClaimError = {
  __typename?: 'OptInPromotionClaimError';
  error: Error;
};

export type OptInPromotionClaimFailed = {
  __typename?: 'OptInPromotionClaimFailed';
  id: Scalars['ID'];
  reason?: Maybe<Offer_Unavailable_Reason>;
};

export type OptInPromotionClaimInput = {
  promotionId: Scalars['ID'];
  /**
   * Optional advertising campaign id for tracking which advertisement consumer
   * comes from to claim the offer
   * Deprecated: Use OptInPromotionClaimAdvertisingMetadataInput.campaignId
   */
  advertisingCampaignId?: InputMaybe<Scalars['ID']>;
  /**
   * Optional advertising click id for Facebook Conversion Tracking
   * Deprecated: Use OptInPromotionClaimAdvertisingMetadataInput.clickId
   */
  advertisingClickId?: InputMaybe<Scalars['ID']>;
  /** Optional advertising metadata */
  advertisingMetadata?: InputMaybe<OptInPromotionClaimAdvertisingMetadataInput>;
  communicationPreference?: InputMaybe<OptInPromotionClaimCommunicationPreferenceInput>;
};

export type OptInPromotionClaimPayload =
  | OptInPromotionClaimSuccess
  | OptInPromotionClaimError;

export type OptInPromotionClaimPending = {
  __typename?: 'OptInPromotionClaimPending';
  id: Scalars['ID'];
};

export type OptInPromotionClaimSuccess = {
  __typename?: 'OptInPromotionClaimSuccess';
  claim: OptInPromotionClaim;
};

export enum Password_Reset_User_Type {
  Provider = 'PROVIDER',
  Consumer = 'CONSUMER',
}

export enum Patient_Financing_Cherry_Application_Status {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Expired = 'EXPIRED',
  Frozen = 'FROZEN',
  InReview = 'IN_REVIEW',
  Pending = 'PENDING',
  /** @deprecated No longer in use */
  ReapplicationRequired = 'REAPPLICATION_REQUIRED',
}

export enum Patient_Financing_Cherry_Borrower_Status {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
}

export enum Patient_Financing_Cherry_Enrollment_Completion_Status {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
}

export enum Patient_Financing_Cherry_Enrollment_Status {
  Declined = 'DECLINED',
  Enrolled = 'ENROLLED',
  InReview = 'IN_REVIEW',
  Pending = 'PENDING',
  NotEnrolled = 'NOT_ENROLLED',
  Unenrolled = 'UNENROLLED',
}

export enum Patient_Financing_Cherry_Payment_Approval_Request_Status {
  Created = 'CREATED',
  PendingApproval = 'PENDING_APPROVAL',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Refunded = 'REFUNDED',
  Completed = 'COMPLETED',
}

export enum Patient_Financing_Profile_Enrollment_Status {
  Enrolled = 'ENROLLED',
  EnrolledAwaitingTerms = 'ENROLLED_AWAITING_TERMS',
  NotEnrolled = 'NOT_ENROLLED',
}

export enum Patient_Transaction_Refund_Operation_Type {
  Full = 'FULL',
  Partial = 'PARTIAL',
}

export enum Patient_Transaction_Refund_Status {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Fail = 'FAIL',
  Success = 'SUCCESS',
  Processing = 'PROCESSING',
}

export enum Patient_Transaction_Refund_Type {
  PatientFinancing = 'PATIENT_FINANCING',
}

export enum Payments_Account_Transfer_Request_Status {
  PendingApproval = 'PENDING_APPROVAL',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  RequestExpired = 'REQUEST_EXPIRED',
  Canceled = 'CANCELED',
}

export enum Payments_Account_Transfer_Type {
  BankToIssuing = 'BANK_TO_ISSUING',
  IssuingToBank = 'ISSUING_TO_BANK',
  AcquiringToIssuing = 'ACQUIRING_TO_ISSUING',
}

export enum Payments_Export_Format {
  Csv = 'CSV',
}

export enum Payments_Intent_Status {
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  SystemError = 'SYSTEM_ERROR',
}

export enum Payment_Plan_Risk_Category {
  Okay = 'OKAY',
  Good = 'GOOD',
  Great = 'GREAT',
}

export enum Payment_Transaction_Status {
  Hold = 'hold',
  Succeeded = 'succeeded',
  Failed = 'failed',
  Refunded = 'refunded',
  DisputeOpened = 'dispute_opened',
  DisputeClosed = 'dispute_closed',
}

export enum Payout_Frequency {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Daily = 'DAILY',
}

export enum Platform_Subscription_Invoice_Section_Type {
  Plan = 'PLAN',
  Tax = 'TAX',
}

export enum Points_Cap_Duration {
  Day = 'DAY',
  Month = 'MONTH',
  Lifetime = 'LIFETIME',
}

export enum Points_Type {
  Earned = 'EARNED',
  Redeemed = 'REDEEMED',
  Adjusted = 'ADJUSTED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  PromotionExchanged = 'PROMOTION_EXCHANGED',
}

export enum Point_Of_Sale_Profile_Status {
  Active = 'ACTIVE',
  ActiveInformationNeeded = 'ACTIVE_INFORMATION_NEEDED',
  Inactive = 'INACTIVE',
  InformationNeeded = 'INFORMATION_NEEDED',
}

/** PRODUCT_TYPE enum used for Product.Type */
export enum Product_Type {
  AaProduct = 'AA_PRODUCT',
  OtherProduct = 'OTHER_PRODUCT',
  OtherTreatment = 'OTHER_TREATMENT',
}

export enum Promotion_Selection_Status {
  CreatedNew = 'CREATED_NEW',
  RejectedAlreadyClaimed = 'REJECTED_ALREADY_CLAIMED',
  UpdatedExisting = 'UPDATED_EXISTING',
}

export enum Promotion_Type {
  Patient = 'PATIENT',
  Office = 'OFFICE',
}

export enum Provider_Email_Marketing_Campaign_Status {
  Sent = 'SENT',
  Failed = 'FAILED',
}

export enum Provider_Marketing_Campaigns_Sort_Column {
  CreatedAt = 'CREATED_AT',
  Status = 'STATUS',
}

export enum Provider_Marketing_Campaigns_Sort_Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum Provider_Marketing_Campaign_Channel {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
}

export enum Provider_Marketing_Campaign_Filter_Status {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum Provider_Marketing_Campaign_Package_Previewable_Content_Html_Tag_Type {
  Iframe = 'IFRAME',
  Image = 'IMAGE',
}

export enum Provider_Marketing_Campaign_Package_Previewable_Content_Type {
  FacebookPost = 'FACEBOOK_POST',
  OfferLandingPage = 'OFFER_LANDING_PAGE',
  OfferInWalletApp = 'OFFER_IN_WALLET_APP',
}

export enum Provider_Marketing_Campaign_Package_Sort_Column {
  Name = 'NAME',
}

export enum Provider_Marketing_Campaign_Package_Sort_Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum Provider_Marketing_Campaign_Platform {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
}

export enum Provider_Marketing_Campaign_Status {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Ended = 'ENDED',
  Failed = 'FAILED',
  InReview = 'IN_REVIEW',
  Paused = 'PAUSED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Stopped = 'STOPPED',
}

export enum Provider_Organization_Alle_Eligibility {
  Eligible = 'ELIGIBLE',
  Ineligible = 'INELIGIBLE',
}

export enum Provider_Organization_Checkout_Orders_Sort_Column {
  OrderDate = 'ORDER_DATE',
  PaymentStatus = 'PAYMENT_STATUS',
  Amount = 'AMOUNT',
  PatientLastName = 'PATIENT_LAST_NAME',
}

export enum Provider_Organization_Checkout_Orders_Sort_Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum Provider_Organization_Source {
  Delta = 'DELTA',
  Onboarding = 'ONBOARDING',
  Wave = 'WAVE',
}

export enum Provider_Organization_Tag {
  ContractAccount = 'CONTRACT_ACCOUNT',
  PremierAccount = 'PREMIER_ACCOUNT',
  StrategicAccount = 'STRATEGIC_ACCOUNT',
}

export enum Provider_Organization_Team_Setup_Completion_Status {
  NotRequired = 'NOT_REQUIRED',
  Incomplete = 'INCOMPLETE',
  Complete = 'COMPLETE',
}

export enum Provider_Organization_Terms_And_Conditions_Status {
  NotRequired = 'NOT_REQUIRED',
  Incomplete = 'INCOMPLETE',
  ExpiringSoon = 'EXPIRING_SOON',
  Expired = 'EXPIRED',
  Complete = 'COMPLETE',
}

export enum Provider_Organization_Type {
  Practice = 'PRACTICE',
  Provider = 'PROVIDER',
}

export enum Provider_Organization_Type_V2 {
  Business = 'BUSINESS',
  Location = 'LOCATION',
}

export enum Provider_Practice_Type {
  Dermatology = 'DERMATOLOGY',
  MedicalSpa = 'MEDICAL_SPA',
  Other = 'OTHER',
  PlasticSurgery = 'PLASTIC_SURGERY',
}

export enum Provider_Pricing_Enrollment_Status {
  NotEnrolled = 'NOT_ENROLLED',
  Enrolled = 'ENROLLED',
  AwaitingTerms = 'AWAITING_TERMS',
}

export enum Provider_Pricing_Program_Enrollment_Status {
  NotEnrolled = 'NOT_ENROLLED',
  Contract = 'CONTRACT',
  Invited = 'INVITED',
  Pending = 'PENDING',
  Enrolled = 'ENROLLED',
}

export enum Provider_Pricing_Purchasing_Enrollment_Status {
  NotEnrolled = 'NOT_ENROLLED',
  Enrolled = 'ENROLLED',
}

export enum Provider_Report_Aggregate_Time_Series_Granularity {
  Month = 'MONTH',
  Quarter = 'QUARTER',
}

export enum Provider_Report_Category {
  Flash = 'FLASH',
  GiftCards = 'GIFT_CARDS',
  Points = 'POINTS',
  Promotions = 'PROMOTIONS',
  Transactions = 'TRANSACTIONS',
}

export enum Provider_Report_Export_Format {
  Csv = 'CSV',
  Xlsx = 'XLSX',
  Pdf = 'PDF',
}

export enum Provider_Report_Sort_Order {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Provider_Report_Value_Type {
  Integer = 'INTEGER',
  String = 'STRING',
  Object = 'OBJECT',
  Array = 'ARRAY',
  Date = 'DATE',
}

export enum Provider_Rewards_Eligibility_Code {
  Eligible = 'ELIGIBLE',
  IneligibleNumPatients = 'INELIGIBLE_NUM_PATIENTS',
  IneligibleBotoxTransactionHistory = 'INELIGIBLE_BOTOX_TRANSACTION_HISTORY',
  IneligibleNotEnrolledFinancing = 'INELIGIBLE_NOT_ENROLLED_FINANCING',
}

export enum Provider_User_Category {
  BusinessUser = 'BUSINESS_USER',
  OfficeUser = 'OFFICE_USER',
  SuggestedUser = 'SUGGESTED_USER',
}

export enum Provider_User_Invitation_Type {
  NewUser = 'NEW_USER',
  RegisteredUserOnboardingNewBusiness = 'REGISTERED_USER_ONBOARDING_NEW_BUSINESS',
}

export enum Provider_User_Role {
  BusinessOwner = 'BUSINESS_OWNER',
  BusinessAdmin = 'BUSINESS_ADMIN',
  OfficeAdmin = 'OFFICE_ADMIN',
  OfficeMember = 'OFFICE_MEMBER',
}

export enum Provider_User_Role_Assignment_State {
  Active = 'ACTIVE',
  Suggested = 'SUGGESTED',
  Suspended = 'SUSPENDED',
}

export enum Provider_User_Role_V2 {
  BusinessOwner = 'BUSINESS_OWNER',
  BusinessAdmin = 'BUSINESS_ADMIN',
  LocationManager = 'LOCATION_MANAGER',
  StaffMember = 'STAFF_MEMBER',
}

export enum Provider_Workspace_Organization_Type {
  Business = 'BUSINESS',
  Location = 'LOCATION',
}

export enum Purchase_Intent_Status {
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED',
  RequiresPayment = 'REQUIRES_PAYMENT',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type PasswordResetCompleteFailure = {
  __typename?: 'PasswordResetCompleteFailure';
  error: Error;
};

export type PasswordResetCompleteInput = {
  passwordResetId: Scalars['ID'];
  code: Scalars['String'];
  newPassword: Scalars['String'];
};

export type PasswordResetCompletePayload =
  | PasswordResetCompleteSuccess
  | PasswordResetCompleteFailure;

export type PasswordResetCompleteSuccess = {
  __typename?: 'PasswordResetCompleteSuccess';
  sessionToken: Scalars['String'];
};

export type PasswordResetInitiateFailure = {
  __typename?: 'PasswordResetInitiateFailure';
  error: Error;
};

export type PasswordResetInitiateInput = {
  /** A username can be an email address or phone number. */
  username: Scalars['String'];
  userType?: InputMaybe<Password_Reset_User_Type>;
};

export type PasswordResetInitiatePayload =
  | PasswordResetInitiateSuccess
  | PasswordResetInitiateFailure;

export type PasswordResetInitiateSuccess = {
  __typename?: 'PasswordResetInitiateSuccess';
  passwordResetId: Scalars['ID'];
};

export type PasswordResetUser = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  resetToken: Scalars['String'];
};

export type PasswordResetVerifyFailure = {
  __typename?: 'PasswordResetVerifyFailure';
  error: Error;
};

export type PasswordResetVerifyInput = {
  passwordResetId: Scalars['ID'];
  code: Scalars['String'];
};

export type PasswordResetVerifyPayload =
  | PasswordResetVerifySuccess
  | PasswordResetVerifyFailure;

export type PasswordResetVerifySuccess = {
  __typename?: 'PasswordResetVerifySuccess';
  passwordResetId: Scalars['ID'];
};

export type Patient = {
  __typename?: 'Patient';
  id: Scalars['ID'];
  transactionsConnection?: Maybe<PatientTransactionsConnection>;
  activitiesConnection?: Maybe<PatientActivitiesConnection>;
  cartRewards?: Maybe<CheckoutCartPatientRewards>;
  financing?: Maybe<PatientFinancing>;
  pointActivities?: Maybe<PatientPointActivitiesConnection>;
  offerAssignmentHistory?: Maybe<PatientOfferAssignmentsConnection>;
  /** This is an admin-level property that allows admins to see what Appreciation Offers they can offer to this consumer */
  assignableAppreciationOffers?: Maybe<PatientAppreciationOffersConnection>;
  /** @deprecated See LoyaltyProfile.promotions */
  promotions?: Maybe<Array<Maybe<PatientPromotion>>>;
  profile?: Maybe<Profile>;
  loyaltyProfile?: Maybe<LoyaltyProfile>;
  bdActivitiesConnection?: Maybe<BdActivitiesConnection>;
};

export type PatientActivitiesConnectionArgs = {
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  range?: InputMaybe<DateRangeInput>;
};

export type PatientPointActivitiesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PatientOfferAssignmentHistoryArgs = {
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  range?: InputMaybe<DateRangeInput>;
};

export type PatientActivitiesConnection = {
  __typename?: 'PatientActivitiesConnection';
  count: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  activities: Array<Maybe<PatientActivity>>;
};

export type PatientActivity = PatientTransaction | BdMigrationActivity;

export type PatientAppreciationOffer = {
  __typename?: 'PatientAppreciationOffer';
  id: Scalars['ID'];
  expiresAt: DateTime;
  issuedAt: DateTime;
  issuedBy: OfferIssuedBy;
  metadata?: Maybe<Scalars['String']>;
  offer: AppreciationOffer;
  patient: Patient;
  value: PromotionValue;
  caseNumber?: Maybe<Scalars['String']>;
};

export type PatientAppreciationOffersConnection = {
  __typename?: 'PatientAppreciationOffersConnection';
  edges?: Maybe<Array<PatientAppreciationOffersEdge>>;
};

export type PatientAppreciationOffersEdge = {
  __typename?: 'PatientAppreciationOffersEdge';
  node: AppreciationOffer;
};

export type PatientAssignOfferError = {
  __typename?: 'PatientAssignOfferError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type PatientAssignOfferInput = {
  patientId: Scalars['ID'];
  offerId: Scalars['ID'];
  /**
   * This input depends on whether or not the offer has a metadataType.
   * If the metadataType is null, then this field is not required.
   * If the metadataType is DATE, then it requires the value to be in format MM-DD-YYYY.
   * If the metadataType is STRING, then it is a free form text.
   */
  metadataValue?: InputMaybe<Scalars['String']>;
  value: Scalars['Int'];
  caseNumber?: InputMaybe<Scalars['String']>;
};

export type PatientAssignOfferResponse =
  | PatientAssignOfferSuccess
  | PatientAssignOfferError;

export type PatientAssignOfferSuccess = {
  __typename?: 'PatientAssignOfferSuccess';
  offer?: Maybe<PatientAppreciationOffer>;
  patient: Patient;
};

export type PatientFinancing = {
  __typename?: 'PatientFinancing';
  applications?: Maybe<PatientFinancingApplicationsConnection>;
};

export type PatientFinancingApplication = {
  __typename?: 'PatientFinancingApplication';
  id: Scalars['ID'];
  /** @deprecated prima type. to be removed once no consumer utlization is confirmed */
  status: PatientFinancingApplicationStatus;
  approvedCreditInCents: Scalars['Int'];
  availableCreditInCents: Scalars['Int'];
  /** @deprecated prima type. to be removed once no consumer utlization is confirmed */
  creditInquiryStatus: PatientFinancingCreditInquiryStatus;
  /** @deprecated prima type. to be removed once no consumer utlization is confirmed */
  financingRequests?: Maybe<PatientFinancingRequestsConnection>;
  /** @deprecated prima type. to be removed once no consumer utlization is confirmed */
  vendor: PatientFinancingVendor;
  expiresAt?: Maybe<DateTime>;
  createdAt: DateTime;
};

export type PatientFinancingApplicationFinancingRequestsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export enum PatientFinancingApplicationStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  ExtendedFraudAlert = 'EXTENDED_FRAUD_ALERT',
  Expired = 'EXPIRED',
  Frozen = 'FROZEN',
  Started = 'STARTED',
}

export type PatientFinancingApplicationsConnection = {
  __typename?: 'PatientFinancingApplicationsConnection';
  nodes: Array<PatientFinancingVendorApplication>;
};

export type PatientFinancingApprovalRequest =
  | PatientFinancingCherryPaymentApprovalRequest
  | PatientFinancingRequest;

export type PatientFinancingCherryApplication = {
  __typename?: 'PatientFinancingCherryApplication';
  id: Scalars['ID'];
  status: Patient_Financing_Cherry_Application_Status;
  availableBalanceInCents: Scalars['Int'];
  paymentApprovalRequests?: Maybe<PatientFinancingCherryPaymentApprovalRequestsConnection>;
  expiresAt?: Maybe<DateTime>;
  createdAt: DateTime;
  borrowerStatus: Patient_Financing_Cherry_Borrower_Status;
  applicationStatusUpdatedAt?: Maybe<DateTime>;
  borrowerStatusUpdatedAt?: Maybe<DateTime>;
  reApplyEligibilityDate?: Maybe<Date>;
};

export type PatientFinancingCherryApplicationPaymentApprovalRequestsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<CherryPaymentApprovalRequestFilter>;
};

export type PatientFinancingCherryPaymentApprovalRequest = {
  __typename?: 'PatientFinancingCherryPaymentApprovalRequest';
  id: Scalars['ID'];
  requestedAmountInCents: Scalars['Int'];
  requestedAmountInCentsForAllerganProducts?: Maybe<Scalars['Int']>;
  processingFeeInCentsForAllerganProducts?: Maybe<Scalars['Int']>;
  requestedAmountInCentsForOtherProducts?: Maybe<Scalars['Int']>;
  processingFeeInCentsForOtherProducts?: Maybe<Scalars['Int']>;
  status: Patient_Financing_Cherry_Payment_Approval_Request_Status;
  providerOrganization: ProviderOrganization;
  createdAt: DateTime;
  patientTransaction?: Maybe<PatientTransaction>;
};

export type PatientFinancingCherryPaymentApprovalRequestsConnection = {
  __typename?: 'PatientFinancingCherryPaymentApprovalRequestsConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<PatientFinancingCherryPaymentApprovalRequestsEdge>;
};

export type PatientFinancingCherryPaymentApprovalRequestsEdge = {
  __typename?: 'PatientFinancingCherryPaymentApprovalRequestsEdge';
  node?: Maybe<PatientFinancingCherryPaymentApprovalRequest>;
};

export enum PatientFinancingCreditInquiryStatus {
  Soft = 'SOFT',
  Hard = 'HARD',
}

export type PatientFinancingPaymentPlanOption = {
  __typename?: 'PatientFinancingPaymentPlanOption';
  financeChargeInCents: Scalars['Int'];
  interestRate: Scalars['Float'];
  monthlyPaymentInCents: Scalars['Int'];
  selected: Scalars['Boolean'];
  termInMonths: Scalars['Int'];
  totalPaymentInCents: Scalars['Int'];
};

export type PatientFinancingPaymentPlanOptionsConnection = {
  __typename?: 'PatientFinancingPaymentPlanOptionsConnection';
  nodes: Array<PatientFinancingPaymentPlanOption>;
};

export type PatientFinancingProfile = {
  __typename?: 'PatientFinancingProfile';
  /** @deprecated Use enrollment */
  enrollmentStatus: Patient_Financing_Profile_Enrollment_Status;
  enrollment?: Maybe<PatientFinancingProfileEnrollment>;
  referralLinkSms?: Maybe<Scalars['String']>;
  referralLinkQr?: Maybe<Scalars['String']>;
};

export type PatientFinancingProfileEnrollment = {
  __typename?: 'PatientFinancingProfileEnrollment';
  vendor: PatientFinancingVendor;
  details: PatientFinancingProfileEnrollmentDetails;
};

export type PatientFinancingProfileEnrollmentDetails =
  CherryPatientFinancingProfileEnrollmentDetails;

export type PatientFinancingRequest = {
  __typename?: 'PatientFinancingRequest';
  id: Scalars['ID'];
  treatmentDate?: Maybe<Date>;
  requestedAmountInCents: Scalars['Int'];
  providerOrganization: ProviderOrganization;
  createdAt: DateTime;
  reminderSentAt?: Maybe<DateTime>;
};

export type PatientFinancingRequestsConnection = {
  __typename?: 'PatientFinancingRequestsConnection';
  offsetPageInfo: OffsetPageInfo;
};

export type PatientFinancingVendor = {
  __typename?: 'PatientFinancingVendor';
  id: Scalars['ID'];
  name: Scalars['String'];
  vendorKey: PatientFinancingVendorKey;
};

export type PatientFinancingVendorApplication =
  | PatientFinancingCherryApplication
  | PatientFinancingApplication;

export enum PatientFinancingVendorKey {
  /** @deprecated prima type. to be removed once no consumer utlization is confirmed */
  PrimaHealthCredit = 'PRIMA_HEALTH_CREDIT',
  Cherry = 'CHERRY',
}

export type PatientOfferAssignmentsConnection = {
  __typename?: 'PatientOfferAssignmentsConnection';
  edges?: Maybe<Array<PatientOfferAssignmentsEdge>>;
};

export type PatientOfferAssignmentsEdge = {
  __typename?: 'PatientOfferAssignmentsEdge';
  node: PatientAppreciationOffer;
};

export type PatientPointActivitiesConnection = {
  __typename?: 'PatientPointActivitiesConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<PatientPointActivitiesEdge>;
};

export type PatientPointActivitiesEdge = {
  __typename?: 'PatientPointActivitiesEdge';
  node: ConsumerPointActivity;
};

export type PatientPromotion = Promotion & {
  __typename?: 'PatientPromotion';
  id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  expiration?: Maybe<DateTime>;
  hideImageInWallet: Scalars['Boolean'];
  imageAltText?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['URL']>;
  imageDisclaimerText?: Maybe<Scalars['String']>;
  isisConnection?: Maybe<PromotionIsIsConnection>;
  name: Scalars['String'];
  productsConnection?: Maybe<PromotionProductsConnection>;
  startAt: DateTime;
  summary?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  type?: Maybe<Promotion_Type>;
  value: PromotionValue;
  /**
   * redeemablePractice is the practice of where patient can use the promotion at
   *
   * Practice specific promotions require patient to visit specific practices to
   * redeem/apply the promotion.
   *
   * In the case promotion can be used anywhere, the redeemablePractice would
   * return as null
   */
  redeemablePractice?: Maybe<Practice>;
  offerStatus?: Maybe<Scalars['String']>;
  offerType?: Maybe<Scalars['String']>;
  redeemedAt?: Maybe<DateTime>;
  addedToWalletAt?: Maybe<DateTime>;
  transactions?: Maybe<Array<PatientTransaction>>;
  construct: PromotionConstruct;
  externalDiscountCode?: Maybe<Scalars['String']>;
  isFreeProductPromotion?: Maybe<Scalars['Boolean']>;
};

/** Deprecated */
export type PatientPromotionInventory = {
  __typename?: 'PatientPromotionInventory';
  total?: Maybe<Scalars['Int']>;
  available?: Maybe<Scalars['Int']>;
};

export type PatientSearchConnection = {
  __typename?: 'PatientSearchConnection';
  edges: Array<Maybe<PatientSearchEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PatientSearchEdge = {
  __typename?: 'PatientSearchEdge';
  cursor: Scalars['String'];
  node: Consumer;
};

export enum PatientSearchType {
  EmailAddress = 'emailAddress',
  Name = 'name',
  PhoneNumber = 'phoneNumber',
}

export type PatientTransaction = {
  __typename?: 'PatientTransaction';
  id: Scalars['ID'];
  pointTotal: Scalars['Int'];
  status: PatientTransactionStatus;
  isEdited?: Maybe<Scalars['Boolean']>;
  hasTransferHistory?: Maybe<Scalars['Boolean']>;
  viewerCanEdit?: Maybe<Scalars['Boolean']>;
  viewerCanCancel?: Maybe<Scalars['Boolean']>;
  viewerCanApplyPromotions?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<DateTime>;
  transactionDate?: Maybe<DateTime>;
  giftCardsConnection?: Maybe<PatientTransactionGiftCardsConnection>;
  productsConnection?: Maybe<PatientTransactionProductsConnection>;
  discountsConnection?: Maybe<PatientTransactionDiscountsConnection>;
  summaryConnection?: Maybe<PatientTransactionLineItemsConnection>;
  patient: Patient;
  practice?: Maybe<Practice>;
  financingLineItem?: Maybe<PatientTransactionFinancingLineItem>;
  refundRequestConnection?: Maybe<PatientTransactionRefundRequestConnection>;
  transferHistoryConnection?: Maybe<PatientTransactionTransferHistoryConnection>;
};

export type PatientTransactionAwardsConnection = {
  __typename?: 'PatientTransactionAwardsConnection';
  point?: Maybe<Array<PatientTransactionPointAward>>;
};

export type PatientTransactionCancelError = {
  __typename?: 'PatientTransactionCancelError';
  error?: Maybe<Error>;
};

export type PatientTransactionCancelInput = {
  transactionId: Scalars['String'];
};

export type PatientTransactionCancelPayload =
  | PatientTransactionCancelSuccess
  | PatientTransactionCancelError;

export type PatientTransactionCancelSuccess = {
  __typename?: 'PatientTransactionCancelSuccess';
  patient: Patient;
};

export type PatientTransactionCreateError = {
  __typename?: 'PatientTransactionCreateError';
  error: Error;
};

export type PatientTransactionCreateForPracticeError = {
  __typename?: 'PatientTransactionCreateForPracticeError';
  error: Error;
};

export type PatientTransactionCreateForPracticeInput = {
  patientId: Scalars['ID'];
  shipToAccountNumber: Scalars['String'];
  products: Array<PatientTransactionProductInput>;
  promotionIdsToApply?: InputMaybe<Array<Scalars['ID']>>;
  giftCardIdsToApply?: InputMaybe<Array<Scalars['ID']>>;
  pointsToApply?: InputMaybe<Scalars['Int']>;
};

export type PatientTransactionCreateForPracticePayload =
  | PatientTransactionCreateForPracticeError
  | PatientTransactionCreateForPracticeSuccess;

export type PatientTransactionCreateForPracticeSuccess = {
  __typename?: 'PatientTransactionCreateForPracticeSuccess';
  patient: Patient;
  transaction: PatientTransaction;
  warnings?: Maybe<PatientTransactionCreateForPracticeWarningsConnection>;
};

export type PatientTransactionCreateForPracticeWarningsConnection = {
  __typename?: 'PatientTransactionCreateForPracticeWarningsConnection';
  product?: Maybe<Array<PatientTransactionProductError>>;
};

export type PatientTransactionCreateInput = {
  patientId: Scalars['ID'];
  products: Array<PatientTransactionProductInput>;
  promotionIdsToApply?: InputMaybe<Array<Scalars['ID']>>;
  giftCardIdsToApply?: InputMaybe<Array<Scalars['ID']>>;
  pointsToApply?: InputMaybe<Scalars['Int']>;
};

export type PatientTransactionCreatePayload =
  | PatientTransactionCreateError
  | PatientTransactionCreateSuccess;

export type PatientTransactionCreateSuccess = {
  __typename?: 'PatientTransactionCreateSuccess';
  loyaltyProfile: LoyaltyProfile;
  transaction: PatientTransaction;
  warnings?: Maybe<PatientTransactionCreateWarningsConnection>;
};

export type PatientTransactionCreateWarningsConnection = {
  __typename?: 'PatientTransactionCreateWarningsConnection';
  product?: Maybe<Array<PatientTransactionProductError>>;
};

export type PatientTransactionDiscountsConnection = {
  __typename?: 'PatientTransactionDiscountsConnection';
  displayLineItems?: Maybe<Array<PatientTransactionDisplayLineItem>>;
  edges?: Maybe<Array<PatientTransactionDiscountsEdge>>;
  pointsUsed: Scalars['Int'];
};

export type PatientTransactionDiscountsEdge = {
  __typename?: 'PatientTransactionDiscountsEdge';
  node?: Maybe<Promotion>;
};

export type PatientTransactionDisplayLineItem = {
  __typename?: 'PatientTransactionDisplayLineItem';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type PatientTransactionError = {
  __typename?: 'PatientTransactionError';
  error?: Maybe<Error>;
};

export type PatientTransactionFinalizeError = {
  __typename?: 'PatientTransactionFinalizeError';
  error: Error;
};

export type PatientTransactionFinalizePayload =
  | PatientTransactionFinalizeSuccess
  | PatientTransactionFinalizeError;

export type PatientTransactionFinalizeSuccess = {
  __typename?: 'PatientTransactionFinalizeSuccess';
  transaction: PatientTransaction;
};

export type PatientTransactionFinancingInput = {
  applicationId?: InputMaybe<Scalars['ID']>;
  providerOrganizationId?: InputMaybe<Scalars['ID']>;
  allerganFinancedAmountInCents?: InputMaybe<Scalars['Int']>;
  nonAllerganFinancedAmountInCents?: InputMaybe<Scalars['Int']>;
};

export type PatientTransactionFinancingLineItem = {
  __typename?: 'PatientTransactionFinancingLineItem';
  id: Scalars['ID'];
  transactionFinancingRequest: PatientTransactionFinancingRequest;
  transactionFinancingStatement?: Maybe<PatientTransactionFinancingStatement>;
};

export type PatientTransactionFinancingRequest = {
  __typename?: 'PatientTransactionFinancingRequest';
  id: Scalars['ID'];
  vendor?: Maybe<PatientFinancingVendor>;
  financingRequest?: Maybe<PatientFinancingApprovalRequest>;
  canRefundFinancing: Scalars['Boolean'];
  /** @deprecated No longer in use */
  amountRefundableInCents: Scalars['Int'];
  outstandingLoanBalanceRefundableInCents: Scalars['Int'];
};

export type PatientTransactionFinancingResponse = {
  __typename?: 'PatientTransactionFinancingResponse';
  financingRequestId?: Maybe<Scalars['ID']>;
  paymentApprovalRequestId?: Maybe<Scalars['ID']>;
};

export type PatientTransactionFinancingStatement = {
  __typename?: 'PatientTransactionFinancingStatement';
  allerganFinancedAmountInCents: Scalars['Int'];
  nonAllerganFinancedAmountInCents: Scalars['Int'];
  fees?: Maybe<PatientTransactionFinancingStatementFeesConnection>;
};

export type PatientTransactionFinancingStatementFee = {
  __typename?: 'PatientTransactionFinancingStatementFee';
  id: Scalars['ID'];
  amountInCents: Scalars['Int'];
  feeType: Scalars['String'];
  createdAt: DateTime;
};

export type PatientTransactionFinancingStatementFeesConnection = {
  __typename?: 'PatientTransactionFinancingStatementFeesConnection';
  feesTotalInCents: Scalars['Int'];
  nodes: Array<PatientTransactionFinancingStatementFee>;
};

export type PatientTransactionGiftCardsConnection = {
  __typename?: 'PatientTransactionGiftCardsConnection';
  edges?: Maybe<Array<PatientTransactionGiftCardsEdge>>;
};

export type PatientTransactionGiftCardsEdge = {
  __typename?: 'PatientTransactionGiftCardsEdge';
  node: GiftCard;
};

export type PatientTransactionInput = {
  patientId: Scalars['ID'];
  variants?: InputMaybe<Array<InputMaybe<PatientTransactionVariantInput>>>;
  applyPromotionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  applyGiftCardIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  applyPoints?: InputMaybe<Scalars['Int']>;
  /** @deprecated use financing applicationID and providerOrganizationID */
  applyFinancingRequestId?: InputMaybe<Scalars['ID']>;
  financing?: InputMaybe<PatientTransactionFinancingInput>;
};

export type PatientTransactionLineItemsConnection = {
  __typename?: 'PatientTransactionLineItemsConnection';
  displayLineItems?: Maybe<Array<PatientTransactionDisplayLineItem>>;
};

export type PatientTransactionPayload =
  | PatientTransactionError
  | PatientTransactionSuccess;

export type PatientTransactionPointAward = {
  __typename?: 'PatientTransactionPointAward';
  name: Scalars['String'];
  products?: Maybe<Array<ProductVariant>>;
};

export type PatientTransactionProductError = {
  __typename?: 'PatientTransactionProductError';
  productId?: Maybe<Scalars['ID']>;
  materialId?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  code: Scalars['String'];
};

export type PatientTransactionProductInput = {
  materialId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type PatientTransactionProductsConnection = {
  __typename?: 'PatientTransactionProductsConnection';
  /** @deprecated Use edges instead */
  displayLineItems?: Maybe<Array<PatientTransactionDisplayLineItem>>;
  edges?: Maybe<Array<PatientTransactionProductsEdge>>;
};

export type PatientTransactionProductsEdge = {
  __typename?: 'PatientTransactionProductsEdge';
  id: Scalars['ID'];
  node: ProductVariant;
  points?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  staffMember?: Maybe<PracticeStaffMember>;
  treatmentProvidedByProviderUser?: Maybe<ProviderUser>;
  warnings?: Maybe<PatientTransactionWarningsConnection>;
  awards?: Maybe<PatientTransactionAwardsConnection>;
};

export type PatientTransactionQueryDisplayLineItems = {
  __typename?: 'PatientTransactionQueryDisplayLineItems';
  displayLineItems?: Maybe<Array<Maybe<PatientTransactionQueryLineItem>>>;
};

export type PatientTransactionQueryLineItem = {
  __typename?: 'PatientTransactionQueryLineItem';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type PatientTransactionQueryPayload = {
  __typename?: 'PatientTransactionQueryPayload';
  id?: Maybe<Scalars['ID']>;
  pointTotal: Scalars['Int'];
  viewerCanApplyPromotions?: Maybe<Scalars['Boolean']>;
  viewerCanEdit?: Maybe<Scalars['Boolean']>;
  viewerCanCancel?: Maybe<Scalars['Boolean']>;
  transactionDate?: Maybe<Scalars['String']>;
  productsConnection?: Maybe<PatientTransactionQueryDisplayLineItems>;
  discountsConnection?: Maybe<PatientTransactionQueryDisplayLineItems>;
  summaryConnection?: Maybe<PatientTransactionQueryDisplayLineItems>;
};

export type PatientTransactionRefundRequest = {
  __typename?: 'PatientTransactionRefundRequest';
  id: Scalars['ID'];
  createdAt: DateTime;
  amountInCents: Scalars['Int'];
  status: Patient_Transaction_Refund_Status;
  refundType: Patient_Transaction_Refund_Type;
  operationType: Patient_Transaction_Refund_Operation_Type;
};

export type PatientTransactionRefundRequestConnection = {
  __typename?: 'PatientTransactionRefundRequestConnection';
  nodes: Array<PatientTransactionRefundRequest>;
};

export type PatientTransactionRefundRequestFailure = {
  __typename?: 'PatientTransactionRefundRequestFailure';
  error: Error;
};

export type PatientTransactionRefundRequestInput = {
  transactionId: Scalars['ID'];
  refundType: Patient_Transaction_Refund_Type;
  operationType: Patient_Transaction_Refund_Operation_Type;
  value?: InputMaybe<Scalars['Int']>;
  transactionFinancingRequestId?: InputMaybe<Scalars['ID']>;
};

export type PatientTransactionRefundRequestPayload =
  | PatientTransactionRefundRequestFailure
  | PatientTransactionRefundRequestSuccess;

export type PatientTransactionRefundRequestSuccess = {
  __typename?: 'PatientTransactionRefundRequestSuccess';
  refundRequestId: Scalars['String'];
};

export type PatientTransactionSimulateAwardsConnection = {
  __typename?: 'PatientTransactionSimulateAwardsConnection';
  point?: Maybe<Array<PatientTransactionPointAward>>;
};

export type PatientTransactionSimulateError = {
  __typename?: 'PatientTransactionSimulateError';
  error: Error;
};

export type PatientTransactionSimulateInput = {
  patientId: Scalars['ID'];
  products: Array<PatientTransactionProductInput>;
  promotionIdsToApply?: InputMaybe<Array<Scalars['ID']>>;
  giftCardIdsToApply?: InputMaybe<Array<Scalars['ID']>>;
  pointsToApply?: InputMaybe<Scalars['Int']>;
};

export type PatientTransactionSimulatePayload =
  | PatientTransactionSimulateSuccess
  | PatientTransactionSimulateError;

export type PatientTransactionSimulateSuccess = {
  __typename?: 'PatientTransactionSimulateSuccess';
  estimatedPointsEarned: Scalars['Int'];
  warnings?: Maybe<PatientTransactionSimulateWarningsConnection>;
  awards?: Maybe<PatientTransactionSimulateAwardsConnection>;
};

export type PatientTransactionSimulateWarningsConnection = {
  __typename?: 'PatientTransactionSimulateWarningsConnection';
  product?: Maybe<Array<PatientTransactionProductError>>;
  pointsCap?: Maybe<Array<PointsCapError>>;
  totalPointsWithheldByUniversalPointsCapPerTransaction?: Maybe<Scalars['Int']>;
};

export enum PatientTransactionStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
}

export type PatientTransactionSuccess = {
  __typename?: 'PatientTransactionSuccess';
  patient: Patient;
  transaction: PatientTransactionQueryPayload;
  transactionV2?: Maybe<PatientTransaction>;
  /** @deprecated use patientTransactionSimulate.warnings */
  warnings?: Maybe<PatientTransactionWarningsConnection>;
  /** @deprecated use patientTransactionSimulate.awards */
  awards?: Maybe<PatientTransactionAwardsConnection>;
  financing?: Maybe<PatientTransactionFinancingResponse>;
};

export type PatientTransactionTransferHistory = {
  __typename?: 'PatientTransactionTransferHistory';
  id: Scalars['ID'];
  reason?: Maybe<Transaction_Transfer_Reason>;
  fromPractice?: Maybe<Practice>;
  toPractice?: Maybe<Practice>;
  transferredAt: DateTime;
};

export type PatientTransactionTransferHistoryConnection = {
  __typename?: 'PatientTransactionTransferHistoryConnection';
  nodes: Array<PatientTransactionTransferHistory>;
};

export type PatientTransactionUpdateError = {
  __typename?: 'PatientTransactionUpdateError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type PatientTransactionUpdateGiftCardInput = {
  apply?: InputMaybe<Array<Scalars['ID']>>;
  unapply?: InputMaybe<Array<Scalars['ID']>>;
};

export type PatientTransactionUpdateInput = {
  /** TransactionID */
  id: Scalars['ID'];
  /** The final changed product variant with their quantity (quantity of 0 means removing a product) */
  updatedVariants?: InputMaybe<
    Array<PatientTransactionUpdateProductVariantInput>
  >;
  /** The changed promotions (apply means addition and unapply means deletion of a promotion) */
  updatedPromotions?: InputMaybe<PatientTransactionUpdatePromotionInput>;
  /** The changed giftcards (apply means addition and unapply means deletion of a giftcard) */
  updatedGiftCards?: InputMaybe<PatientTransactionUpdateGiftCardInput>;
  /** The final changed points value (if no change, do not send them) */
  updatedApplyPoints?: InputMaybe<Scalars['Int']>;
};

export type PatientTransactionUpdatePayload =
  | PatientTransactionUpdateSuccess
  | PatientTransactionUpdateError;

export type PatientTransactionUpdateProductVariantInput = {
  variantId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type PatientTransactionUpdatePromotionInput = {
  apply?: InputMaybe<Array<Scalars['ID']>>;
  unapply?: InputMaybe<Array<Scalars['ID']>>;
};

export type PatientTransactionUpdateSuccess = {
  __typename?: 'PatientTransactionUpdateSuccess';
  transaction: PatientTransaction;
};

export type PatientTransactionVariantInput = {
  variantId: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
  staffMemberId?: InputMaybe<Scalars['ID']>;
  treatmentProvidedByProviderUserId?: InputMaybe<Scalars['ID']>;
};

export type PatientTransactionWarningsConnection = {
  __typename?: 'PatientTransactionWarningsConnection';
  pointsCap?: Maybe<Array<PointsCapError>>;
  promotion?: Maybe<Array<PromotionError>>;
  totalPointsWithheldByUniversalPointsCapPerTransaction?: Maybe<Scalars['Int']>;
};

export type PatientTransactionsConnection = {
  __typename?: 'PatientTransactionsConnection';
  count: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  transactions?: Maybe<Array<Maybe<PatientTransaction>>>;
};

export type PatientTransactionsSort = {
  column?: PatientTransactionsSortColumn;
  order?: PatientTransactionsSortOrder;
};

export enum PatientTransactionsSortColumn {
  Date = 'DATE',
  Name = 'NAME',
}

export enum PatientTransactionsSortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type PatientTransactionsSortType = {
  __typename?: 'PatientTransactionsSortType';
  column: PatientTransactionsSortColumn;
  order: PatientTransactionsSortOrder;
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  id: Scalars['ID'];
  clientSecret: Scalars['String'];
  status: Payments_Intent_Status;
  amountInCents: Scalars['Int'];
  /** The payment method will remain as null until payment intent is completed */
  paymentMethod?: Maybe<PaymentMethod>;
};

export type PaymentIntentError = {
  __typename?: 'PaymentIntentError';
  error: Error;
};

export type PaymentIntentInput = {
  walletId: Scalars['ID'];
  amountInCents: Scalars['Int'];
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  triggerPayment?: InputMaybe<Scalars['Boolean']>;
};

export type PaymentIntentPayload = PaymentIntentSuccess | PaymentIntentError;

export type PaymentIntentSuccess = {
  __typename?: 'PaymentIntentSuccess';
  paymentIntent: PaymentIntent;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  providerName: Scalars['String'];
  paymentType: Scalars['String'];
  last4Digits: Scalars['String'];
  zipcode: Scalars['String'];
  /** This is in MM/YYYY string format as stored in the database and on stripe */
  expirationDate: Scalars['String'];
  isDefault: Scalars['Boolean'];
};

export type PaymentMethodChargeFailure = {
  __typename?: 'PaymentMethodChargeFailure';
  error: Error;
};

export type PaymentMethodChargeInput = {
  walletId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
  amountInCents: Scalars['Int'];
};

export type PaymentMethodChargePayload =
  | PaymentMethodChargeSuccess
  | PaymentMethodChargeFailure;

export type PaymentMethodChargeSuccess = {
  __typename?: 'PaymentMethodChargeSuccess';
  wallet: PaymentsWallet;
};

export type PaymentMethodDeleteError = {
  __typename?: 'PaymentMethodDeleteError';
  error: Error;
};

export type PaymentMethodDeletePayload =
  | PaymentMethodDeleteSuccess
  | PaymentMethodDeleteError;

export type PaymentMethodDeleteSuccess = {
  __typename?: 'PaymentMethodDeleteSuccess';
  id: Scalars['ID'];
};

export type PaymentMethodError = {
  __typename?: 'PaymentMethodError';
  error: Error;
};

export type PaymentMethodUpdateError = {
  __typename?: 'PaymentMethodUpdateError';
  error: Error;
};

export type PaymentMethodUpdateInput = {
  id: Scalars['ID'];
  zipcode: Scalars['String'];
  /** We take in format of "MM" (e.g. 06 for June) */
  expirationMonth: Scalars['String'];
  /** We take in format of "YY" (e.g. 25 for 2025 – 2022 at the point of writing) */
  expirationYear: Scalars['String'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
};

export type PaymentMethodUpdatePayload =
  | PaymentMethodUpdateSuccess
  | PaymentMethodUpdateError;

export type PaymentMethodUpdateSuccess = {
  __typename?: 'PaymentMethodUpdateSuccess';
  paymentMethod: PaymentMethod;
};

export type PaymentProfile = {
  __typename?: 'PaymentProfile';
  accountNumber?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  bankAccountType?: Maybe<Bank_Account_Type>;
  nickname?: Maybe<Scalars['String']>;
  /** @deprecated We never provided this */
  bankName?: Maybe<Scalars['String']>;
  /** @deprecated non-scalar reference to scalar type, use emailAddress instead */
  email?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  payoutFrequency?: Maybe<Payout_Frequency>;
  source?: Maybe<Bank_Information_Source>;
  updatedAt?: Maybe<DateTime>;
  businessName?: Maybe<Scalars['String']>;
};

export type PaymentProfileDeactivateFailure = {
  __typename?: 'PaymentProfileDeactivateFailure';
  error?: Maybe<Error>;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type PaymentProfileDeactivateInput = {
  shipToAccountNumber: Scalars['String'];
};

export type PaymentProfileDeactivatePayload =
  | PaymentProfileDeactivateSuccess
  | PaymentProfileDeactivateFailure;

export type PaymentProfileDeactivateSuccess = {
  __typename?: 'PaymentProfileDeactivateSuccess';
  success: Scalars['Boolean'];
};

export type PaymentProfileReactivateFailure = {
  __typename?: 'PaymentProfileReactivateFailure';
  error?: Maybe<Error>;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type PaymentProfileReactivateInput = {
  shipToAccountNumber: Scalars['String'];
};

export type PaymentProfileReactivatePayload =
  | PaymentProfileReactivateSuccess
  | PaymentProfileReactivateFailure;

export type PaymentProfileReactivateSuccess = {
  __typename?: 'PaymentProfileReactivateSuccess';
  success: Scalars['Boolean'];
};

export type PaymentProfileUpdateEmailError = {
  __typename?: 'PaymentProfileUpdateEmailError';
  error?: Maybe<Error>;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type PaymentProfileUpdateEmailInput = {
  email: Scalars['String'];
};

export type PaymentProfileUpdateEmailPayload =
  | PaymentProfileUpdateEmailSuccess
  | PaymentProfileUpdateEmailError;

export type PaymentProfileUpdateEmailSuccess = {
  __typename?: 'PaymentProfileUpdateEmailSuccess';
  email?: Maybe<Scalars['String']>;
};

export type PaymentProfileUpdateFundingSourceError = {
  __typename?: 'PaymentProfileUpdateFundingSourceError';
  error?: Maybe<Error>;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type PaymentProfileUpdateFundingSourceForChildOrgError = {
  __typename?: 'PaymentProfileUpdateFundingSourceForChildOrgError';
  error?: Maybe<Error>;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type PaymentProfileUpdateFundingSourceForChildOrgInput = {
  childProviderOrganizationId: Scalars['ID'];
  paymentProfileUpdateFundingSourceInput: PaymentProfileUpdateFundingSourceInput;
};

export type PaymentProfileUpdateFundingSourceForChildOrgPayload =
  | PaymentProfileUpdateFundingSourceForChildOrgSuccess
  | PaymentProfileUpdateFundingSourceForChildOrgError;

export type PaymentProfileUpdateFundingSourceForChildOrgSuccess = {
  __typename?: 'PaymentProfileUpdateFundingSourceForChildOrgSuccess';
  fundingSource?: Maybe<FundingSource>;
};

export type PaymentProfileUpdateFundingSourceInput = {
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  bankAccountType: Bank_Account_Type;
  nickname: Scalars['String'];
  businessName?: InputMaybe<Scalars['String']>;
};

export type PaymentProfileUpdateFundingSourcePayload =
  | PaymentProfileUpdateFundingSourceSuccess
  | PaymentProfileUpdateFundingSourceError;

export type PaymentProfileUpdateFundingSourceSuccess = {
  __typename?: 'PaymentProfileUpdateFundingSourceSuccess';
  fundingSource?: Maybe<FundingSource>;
};

export type PaymentProfileUpdatePaymentFrequencyError = {
  __typename?: 'PaymentProfileUpdatePaymentFrequencyError';
  error?: Maybe<Error>;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type PaymentProfileUpdatePaymentFrequencyInput = {
  payoutFrequency?: InputMaybe<Payout_Frequency>;
};

export type PaymentProfileUpdatePaymentFrequencyPayload =
  | PaymentProfileUpdatePaymentFrequencySuccess
  | PaymentProfileUpdatePaymentFrequencyError;

export type PaymentProfileUpdatePaymentFrequencySuccess = {
  __typename?: 'PaymentProfileUpdatePaymentFrequencySuccess';
  payoutFrequency?: Maybe<Payout_Frequency>;
};

export type PaymentWalletPaymentMethodsConnection = {
  __typename?: 'PaymentWalletPaymentMethodsConnection';
  nodes: Array<PaymentMethod>;
};

export type PaymentsAccessControl = {
  __typename?: 'PaymentsAccessControl';
  canViewPaymentsDashboard: Scalars['Boolean'];
};

export type PaymentsAccount = {
  __typename?: 'PaymentsAccount';
  id: Scalars['ID'];
  name: Scalars['String'];
  availableBalanceInCents: Scalars['Int'];
  pendingBalanceInCents: Scalars['Int'];
};

export type PaymentsAccountConnections = {
  __typename?: 'PaymentsAccountConnections';
  nodes: Array<PaymentsAccount>;
};

export type PaymentsActor = PaymentsSystemUser | Actor;

export type PaymentsBalanceAutomationParameters = {
  __typename?: 'PaymentsBalanceAutomationParameters';
  id: Scalars['ID'];
  healthyIssuingBalanceInCents: Scalars['Int'];
  thresholdToTriggerIncreaseInHealthyIssuingBalance: Scalars['Float'];
  increaseHealthyIssuingBalanceMultiplier: Scalars['Float'];
  acquiringBalanceMinimumReserveInCents: Scalars['Int'];
  minimumAmountToTransferFromExternalToIssuingInCents: Scalars['Int'];
};

export type PaymentsBalanceAutomationParametersUpdateFailure = {
  __typename?: 'PaymentsBalanceAutomationParametersUpdateFailure';
  error: Error;
};

export type PaymentsBalanceAutomationParametersUpdateInput = {
  healthyIssuingBalanceInCents: Scalars['Int'];
  thresholdToTriggerIncreaseInHealthyIssuingBalance: Scalars['Float'];
  increaseHealthyIssuingBalanceMultiplier: Scalars['Float'];
  acquiringBalanceMinimumReserveInCents: Scalars['Int'];
  minimumAmountToTransferFromExternalToIssuingInCents: Scalars['Int'];
};

export type PaymentsBalanceAutomationParametersUpdatePayload =
  | PaymentsBalanceAutomationParametersUpdateSuccess
  | PaymentsBalanceAutomationParametersUpdateFailure;

export type PaymentsBalanceAutomationParametersUpdateSuccess = {
  __typename?: 'PaymentsBalanceAutomationParametersUpdateSuccess';
  paymentsBalanceAutomationParameters: PaymentsBalanceAutomationParameters;
};

export type PaymentsCustomer = PaymentsProviderCustomer;

export type PaymentsHealthcheck = {
  __typename?: 'PaymentsHealthcheck';
  status?: Maybe<Scalars['String']>;
};

export type PaymentsProviderCustomer = {
  __typename?: 'PaymentsProviderCustomer';
  id: Scalars['ID'];
  practice: Practice;
  ownerType: Scalars['String'];
};

export type PaymentsSetupIntent = {
  __typename?: 'PaymentsSetupIntent';
  id: Scalars['ID'];
  clientSecret: Scalars['String'];
  paymentMethodId?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: Payments_Intent_Status;
};

export type PaymentsSetupIntentError = {
  __typename?: 'PaymentsSetupIntentError';
  error: Error;
};

export type PaymentsSetupIntentInput = {
  walletId: Scalars['ID'];
};

export type PaymentsSetupIntentPayload =
  | PaymentsSetupIntentSuccess
  | PaymentsSetupIntentError;

export type PaymentsSetupIntentSuccess = {
  __typename?: 'PaymentsSetupIntentSuccess';
  setupIntent: PaymentsSetupIntent;
};

export type PaymentsSystemUser = {
  __typename?: 'PaymentsSystemUser';
  name: Scalars['String'];
};

export type PaymentsTransaction = {
  __typename?: 'PaymentsTransaction';
  id: Scalars['ID'];
  receiptSlug: Scalars['String'];
  amountInCents: Scalars['Int'];
  transactionType: Scalars['String'];
  description: Scalars['String'];
  transactionEvents: PaymentsTransactionEventsConnection;
  transactionStatus: Payment_Transaction_Status;
  paymentMethod?: Maybe<PaymentMethod>;
  owner?: Maybe<PaymentsCustomer>;
  ipAddress?: Maybe<Scalars['String']>;
  createdAt: DateTime;
  updatedAt: DateTime;
  /**
   * For transactions of type 'wallet_funding', the stripeURL should link
   * to the transaction details on the Stripe dashboard.
   */
  stripeUrl?: Maybe<Scalars['URL']>;
  /** For transactions of type 'refund', there will be an originalTransaction */
  originalTransaction?: Maybe<PaymentsTransaction>;
};

export type PaymentsTransactionEvent = {
  __typename?: 'PaymentsTransactionEvent';
  id: Scalars['ID'];
  actor: PaymentsActor;
  amountInCents: Scalars['Int'];
  ipAddress?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  createdAt: DateTime;
};

export type PaymentsTransactionEventsConnection = {
  __typename?: 'PaymentsTransactionEventsConnection';
  nodes: Array<PaymentsTransactionEvent>;
};

export type PaymentsTransactionFilter = {
  range?: InputMaybe<DateTimeRangeInput>;
  statuses: Array<Payment_Transaction_Status>;
};

export type PaymentsTransactionReportCreateFailure = {
  __typename?: 'PaymentsTransactionReportCreateFailure';
  error: Error;
};

export type PaymentsTransactionReportCreateInput = {
  range: DateTimeRangeInput;
  statuses: Array<Payment_Transaction_Status>;
  walletId: Scalars['ID'];
  reportExportFormat: Payments_Export_Format;
};

export type PaymentsTransactionReportCreatePayload =
  | PaymentsTransactionReportCreateSuccess
  | PaymentsTransactionReportCreateFailure;

export type PaymentsTransactionReportCreateSuccess = {
  __typename?: 'PaymentsTransactionReportCreateSuccess';
  url: Scalars['URL'];
};

export type PaymentsTransactionsConnection = {
  __typename?: 'PaymentsTransactionsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<PaymentsTransaction>;
};

export type PaymentsTransferRequest = {
  __typename?: 'PaymentsTransferRequest';
  id: Scalars['ID'];
  amountInCents: Scalars['Int'];
  notesContent: Scalars['String'];
  type: Payments_Account_Transfer_Type;
  status: Payments_Account_Transfer_Request_Status;
  createdAt: DateTime;
  requestedBy: AdminUserActor;
};

export type PaymentsTransferRequestApproveFailure = {
  __typename?: 'PaymentsTransferRequestApproveFailure';
  error: Error;
};

export type PaymentsTransferRequestApproveInput = {
  id: Scalars['ID'];
};

export type PaymentsTransferRequestApprovePayload =
  | PaymentsTransferRequestApproveSuccess
  | PaymentsTransferRequestApproveFailure;

export type PaymentsTransferRequestApproveSuccess = {
  __typename?: 'PaymentsTransferRequestApproveSuccess';
  paymentsTransferRequest: PaymentsTransferRequest;
};

export type PaymentsTransferRequestCancelFailure = {
  __typename?: 'PaymentsTransferRequestCancelFailure';
  error: Error;
};

export type PaymentsTransferRequestCancelInput = {
  id: Scalars['ID'];
};

export type PaymentsTransferRequestCancelPayload =
  | PaymentsTransferRequestCancelSuccess
  | PaymentsTransferRequestCancelFailure;

export type PaymentsTransferRequestCancelSuccess = {
  __typename?: 'PaymentsTransferRequestCancelSuccess';
  paymentsTransferRequest: PaymentsTransferRequest;
};

export type PaymentsTransferRequestCreateFailure = {
  __typename?: 'PaymentsTransferRequestCreateFailure';
  error: Error;
};

export type PaymentsTransferRequestCreateInput = {
  amountInCents: Scalars['Int'];
  notes: Scalars['String'];
  type: Payments_Account_Transfer_Type;
};

export type PaymentsTransferRequestCreatePayload =
  | PaymentsTransferRequestCreateSuccess
  | PaymentsTransferRequestCreateFailure;

export type PaymentsTransferRequestCreateSuccess = {
  __typename?: 'PaymentsTransferRequestCreateSuccess';
  paymentsTransferRequest: PaymentsTransferRequest;
};

export type PaymentsTransferRequestDeclineFailure = {
  __typename?: 'PaymentsTransferRequestDeclineFailure';
  error: Error;
};

export type PaymentsTransferRequestDeclineInput = {
  id: Scalars['ID'];
};

export type PaymentsTransferRequestDeclinePayload =
  | PaymentsTransferRequestDeclineSuccess
  | PaymentsTransferRequestDeclineFailure;

export type PaymentsTransferRequestDeclineSuccess = {
  __typename?: 'PaymentsTransferRequestDeclineSuccess';
  paymentsTransferRequest: PaymentsTransferRequest;
};

export type PaymentsTransferRequestsConnections = {
  __typename?: 'PaymentsTransferRequestsConnections';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<PaymentsTransferRequest>;
};

export type PaymentsWallet = {
  __typename?: 'PaymentsWallet';
  balanceInCents: Scalars['Int'];
  id: Scalars['ID'];
  paymentMethods: PaymentWalletPaymentMethodsConnection;
  paymentsWalletModal: PaymentsWalletModal;
  transactions: PaymentsTransactionsConnection;
};

export type PaymentsWalletTransactionsArgs = {
  filter?: InputMaybe<PaymentsTransactionFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type PaymentsWalletCreateForPracticeFailure = {
  __typename?: 'PaymentsWalletCreateForPracticeFailure';
  error: Error;
};

export type PaymentsWalletCreateForPracticeInput = {
  practiceId: Scalars['ID'];
};

export type PaymentsWalletCreateForPracticePayload =
  | PaymentsWalletCreateForPracticeSuccess
  | PaymentsWalletCreateForPracticeFailure;

export type PaymentsWalletCreateForPracticeSuccess = {
  __typename?: 'PaymentsWalletCreateForPracticeSuccess';
  practice: Practice;
};

export type PaymentsWalletModal = {
  __typename?: 'PaymentsWalletModal';
  id: Scalars['ID'];
  status: PaymentsWalletModalStatus;
};

export enum PaymentsWalletModalStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export type Payout = {
  __typename?: 'Payout';
  id: Scalars['ID'];
  displayId: Scalars['ID'];
  periodStartAt: DateTime;
  periodEndAt: DateTime;
  amount: PayoutAmount;
  practice: Practice;
  total?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  transactionsConnection?: Maybe<PayoutTransactionsConnection>;
  pendingAt?: Maybe<DateTime>;
  successAt?: Maybe<DateTime>;
  failedAt?: Maybe<DateTime>;
  reimbursementPeriod?: Maybe<PayoutReimbursementPeriod>;
};

export type PayoutAmount = {
  __typename?: 'PayoutAmount';
  unit: Scalars['String'];
  quantity: Scalars['Int'];
};

export type PayoutDetailReportCreateFailure = {
  __typename?: 'PayoutDetailReportCreateFailure';
  error: Error;
};

export type PayoutDetailReportCreateInput = {
  providerOrganizationId: Scalars['ID'];
  reportExportFormat: Payments_Export_Format;
  timezone?: InputMaybe<Scalars['String']>;
};

export type PayoutDetailReportCreatePayload =
  | PayoutDetailReportCreateSuccess
  | PayoutDetailReportCreateFailure;

export type PayoutDetailReportCreateSuccess = {
  __typename?: 'PayoutDetailReportCreateSuccess';
  url: Scalars['URL'];
};

export type PayoutLineItem = {
  __typename?: 'PayoutLineItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  total: Scalars['Int'];
  value: Scalars['String'];
};

export type PayoutReimbursementPeriod = {
  __typename?: 'PayoutReimbursementPeriod';
  startDate?: Maybe<Date>;
  endDate?: Maybe<Date>;
};

export type PayoutSummaryFilter = {
  range?: InputMaybe<DateTimeRangeInput>;
};

export type PayoutSummaryReportCreateFailure = {
  __typename?: 'PayoutSummaryReportCreateFailure';
  error: Error;
};

export type PayoutSummaryReportCreateInput = {
  providerOrganizationId: Scalars['ID'];
  reportExportFormat: Payments_Export_Format;
  timezone?: InputMaybe<Scalars['String']>;
};

export type PayoutSummaryReportCreatePayload =
  | PayoutSummaryReportCreateSuccess
  | PayoutSummaryReportCreateFailure;

export type PayoutSummaryReportCreateSuccess = {
  __typename?: 'PayoutSummaryReportCreateSuccess';
  url: Scalars['URL'];
};

export type PayoutTransaction = {
  __typename?: 'PayoutTransaction';
  id?: Maybe<Scalars['ID']>;
  total: Scalars['Float'];
  patientTransaction: PatientTransaction;
  lineItemsConnection?: Maybe<PayoutTransactionLineItemsConnection>;
};

export type PayoutTransactionLineItemsConnection = {
  __typename?: 'PayoutTransactionLineItemsConnection';
  lineItems?: Maybe<Array<Maybe<PayoutLineItem>>>;
};

export type PayoutTransactionsConnection = {
  __typename?: 'PayoutTransactionsConnection';
  transactions?: Maybe<Array<Maybe<PayoutTransaction>>>;
};

export type PlatformSubscription = {
  __typename?: 'PlatformSubscription';
  id: Scalars['ID'];
  canceledAt?: Maybe<DateTime>;
  endDate?: Maybe<Date>;
  nextInvoiceDueDate?: Maybe<Date>;
  paymentMethod?: Maybe<PaymentMethod>;
  platformSubscriptionPlan: PlatformSubscriptionPlan;
  priceModel: PriceModel;
  subscribedAt: DateTime;
  trialPeriodEndDate?: Maybe<Date>;
  status: Subscription_Status;
  invoiceHistory?: Maybe<PlatformSubscriptionInvoicesConnection>;
  cancelReason?: Maybe<Scalars['String']>;
};

export type PlatformSubscriptionInvoiceHistoryArgs = {
  filter?: InputMaybe<PlatformSubscriptionInvoiceHistoryFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type PlatformSubscriptionCancelFailure = {
  __typename?: 'PlatformSubscriptionCancelFailure';
  error: Error;
};

export type PlatformSubscriptionCancelInput = {
  id: Scalars['ID'];
  cancelReason?: InputMaybe<Scalars['String']>;
};

export type PlatformSubscriptionCancelPayload =
  | PlatformSubscriptionCancelSuccess
  | PlatformSubscriptionCancelFailure;

export type PlatformSubscriptionCancelSuccess = {
  __typename?: 'PlatformSubscriptionCancelSuccess';
  platformSubscription: PlatformSubscription;
};

export type PlatformSubscriptionCreateFailure = {
  __typename?: 'PlatformSubscriptionCreateFailure';
  error: Error;
};

export type PlatformSubscriptionCreateInput = {
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  platformSubscriptionPlanId: Scalars['ID'];
  priceModelId: Scalars['ID'];
  providerOrganizationId: Scalars['ID'];
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type PlatformSubscriptionCreateSuccess = {
  __typename?: 'PlatformSubscriptionCreateSuccess';
  platformSubscription: PlatformSubscription;
};

export type PlatformSubscriptionExcludedPlanFeaturesConnection = {
  __typename?: 'PlatformSubscriptionExcludedPlanFeaturesConnection';
  nodes: Array<PlatformSubscriptionPlanFeature>;
};

export type PlatformSubscriptionHistoryConnection = {
  __typename?: 'PlatformSubscriptionHistoryConnection';
  nodes: Array<PlatformSubscription>;
};

export type PlatformSubscriptionHistoryFilter = {
  range?: InputMaybe<DateTimeRangeInput>;
};

export type PlatformSubscriptionIncludedPlanFeaturesConnection = {
  __typename?: 'PlatformSubscriptionIncludedPlanFeaturesConnection';
  nodes: Array<PlatformSubscriptionPlanFeature>;
};

export type PlatformSubscriptionInvoice = {
  __typename?: 'PlatformSubscriptionInvoice';
  id: Scalars['ID'];
  receiptId: Scalars['ID'];
  periodStartDate: Date;
  periodEndDate: Date;
  status: Scalars['String'];
  totalAmountInCents: Scalars['Int'];
  paymentMethod: PaymentMethod;
  sections: PlatformSubscriptionInvoiceSectionsConnection;
};

export type PlatformSubscriptionInvoiceHistoryFilter = {
  range?: InputMaybe<DateTimeRangeInput>;
};

export type PlatformSubscriptionInvoiceLineItem = {
  __typename?: 'PlatformSubscriptionInvoiceLineItem';
  id: Scalars['ID'];
  description: Scalars['String'];
  amountInCents: Scalars['Int'];
};

export type PlatformSubscriptionInvoiceSection = {
  __typename?: 'PlatformSubscriptionInvoiceSection';
  type: Platform_Subscription_Invoice_Section_Type;
  lineItems: PlatformSubscriptionInvoiceSectionLineItemsConnection;
};

export type PlatformSubscriptionInvoiceSectionLineItemsConnection = {
  __typename?: 'PlatformSubscriptionInvoiceSectionLineItemsConnection';
  nodes: Array<PlatformSubscriptionInvoiceLineItem>;
};

export type PlatformSubscriptionInvoiceSectionsConnection = {
  __typename?: 'PlatformSubscriptionInvoiceSectionsConnection';
  nodes: Array<PlatformSubscriptionInvoiceSection>;
};

export type PlatformSubscriptionInvoicesConnection = {
  __typename?: 'PlatformSubscriptionInvoicesConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<PlatformSubscriptionInvoice>;
};

export type PlatformSubscriptionPayload =
  | PlatformSubscriptionCreateSuccess
  | PlatformSubscriptionCreateFailure;

export type PlatformSubscriptionPlan = {
  __typename?: 'PlatformSubscriptionPlan';
  id: Scalars['ID'];
  excludedFeatures?: Maybe<PlatformSubscriptionExcludedPlanFeaturesConnection>;
  includedFeatures?: Maybe<PlatformSubscriptionIncludedPlanFeaturesConnection>;
  isRecommended: Scalars['Boolean'];
  name: Scalars['String'];
  pricingModels?: Maybe<PlatformSubscriptionPlanPriceModelsConnection>;
  viewerCanSubscribe: Scalars['Boolean'];
  viewerIsSubscribed: Scalars['Boolean'];
};

export type PlatformSubscriptionPlanFeature = {
  __typename?: 'PlatformSubscriptionPlanFeature';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type PlatformSubscriptionPlanPriceModelsConnection = {
  __typename?: 'PlatformSubscriptionPlanPriceModelsConnection';
  nodes: Array<PriceModel>;
};

export type PlatformSubscriptionPlansConnection = {
  __typename?: 'PlatformSubscriptionPlansConnection';
  nodes: Array<PlatformSubscriptionPlan>;
};

export type PlatformSubscriptionUpdatePaymentMethodFailure = {
  __typename?: 'PlatformSubscriptionUpdatePaymentMethodFailure';
  error: Error;
};

export type PlatformSubscriptionUpdatePaymentMethodInput = {
  id: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
};

export type PlatformSubscriptionUpdatePaymentMethodPayload =
  | PlatformSubscriptionUpdatePaymentMethodSuccess
  | PlatformSubscriptionUpdatePaymentMethodFailure;

export type PlatformSubscriptionUpdatePaymentMethodSuccess = {
  __typename?: 'PlatformSubscriptionUpdatePaymentMethodSuccess';
  platformSubscription: PlatformSubscription;
};

export type PointOfSalePaymentProfile = {
  __typename?: 'PointOfSalePaymentProfile';
  status?: Maybe<Point_Of_Sale_Profile_Status>;
  payoutHistory?: Maybe<PointOfSalePaymentProfilePayoutSummariesConnection>;
};

export type PointOfSalePaymentProfilePayoutHistoryArgs = {
  filter?: InputMaybe<PayoutSummaryFilter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type PointOfSalePaymentProfilePayoutSummariesConnection = {
  __typename?: 'PointOfSalePaymentProfilePayoutSummariesConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<AllePayoutSummary>;
};

export type PointOfSaleTransaction = {
  __typename?: 'PointOfSaleTransaction';
  id: Scalars['ID'];
  fees?: Maybe<PointOfSaleTransactionFees>;
  receiptDetails?: Maybe<PointOfSaleTransactionReceiptDetails>;
};

export type PointOfSaleTransactionFee = {
  __typename?: 'PointOfSaleTransactionFee';
  id: Scalars['ID'];
  value: Money;
};

export type PointOfSaleTransactionFees = {
  __typename?: 'PointOfSaleTransactionFees';
  nodes: Array<PointOfSaleTransactionFee>;
};

export type PointOfSaleTransactionReceiptDetails = {
  __typename?: 'PointOfSaleTransactionReceiptDetails';
  accountType?: Maybe<Scalars['String']>;
  aid?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
};

/** A type of promotion a consumer can claim using points. */
export type PointPurchasablePromotion = Promotion & {
  __typename?: 'PointPurchasablePromotion';
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  expiration?: Maybe<DateTime>;
  expireInDays?: Maybe<Scalars['Int']>;
  summary?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  type?: Maybe<Promotion_Type>;
  price: PromotionPriceValue;
  value: PromotionValue;
  imageURL?: Maybe<Scalars['URL']>;
  imageAltText?: Maybe<Scalars['String']>;
  isisConnection?: Maybe<PromotionIsIsConnection>;
  productsConnection?: Maybe<PromotionProductsConnection>;
  viewerAvailability: PointPurchasablePromotionAvailability;
  contentKey?: Maybe<Scalars['String']>;
};

export type PointPurchasablePromotionAvailability = {
  __typename?: 'PointPurchasablePromotionAvailability';
  viewerCanClaim: Scalars['Boolean'];
  reason?: Maybe<Offer_Unavailable_Reason>;
};

export type PointsCancelledEmailInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  link: Scalars['String'];
  cancelledBy: Scalars['String'];
};

/**
 * PointsCapError is used to construct product points earning cap warning messages in the following format
 * The member has reached their {duration.expression} point earning limit for [{product.name}] ({quantity} {product.unit} - {points * quantity} points)
 */
export type PointsCapError = {
  __typename?: 'PointsCapError';
  duration?: Maybe<PointsDuration>;
  product: ProductVariant;
  /** withheld points for a single product variant */
  points: Scalars['Int'];
  quantity: Scalars['Int'];
  /** total withheld points for this product across all points earning rules */
  totalPoints?: Maybe<Scalars['Int']>;
  /** total withheld points for this product due to universal points cap rule only */
  totalPointsWithheldByUniversalPointsCapPerProduct?: Maybe<Scalars['Int']>;
};

export type PointsDuration = {
  __typename?: 'PointsDuration';
  number?: Maybe<Scalars['Int']>;
  unit: Points_Cap_Duration;
  expression?: Maybe<Scalars['String']>;
};

export type PointsEarnedDistributionChannel = {
  __typename?: 'PointsEarnedDistributionChannel';
  duration?: Maybe<MarketingOfferDuration>;
  inventory?: Maybe<MarketingOfferInventory>;
};

export type PointsEarnedOfferReward = {
  __typename?: 'PointsEarnedOfferReward';
  /** The maximum number of product increments a consumer can receive extra points on per single transaction. */
  consumerLimitPerTransaction?: Maybe<Scalars['Int']>;
  /** The minimum number of product increments needed for a consumer to receive extra points on per single transaction. */
  consumerMinimumPerTransaction?: Maybe<Scalars['Int']>;
  /** The maximum number of product increments a consumer can receive extra points for this specific offer. */
  consumerLimitPerOffer?: Maybe<Scalars['Int']>;
  extraPointsAppliedToBrandsConnection?: Maybe<MarketingOfferExtraPointsAppliedToBrandsConnection>;
  mustIncludeBrandsConnection?: Maybe<MarketingOfferMustIncludeBrandsConnection>;
  terms?: Maybe<Scalars['Markdown']>;
  type?: Maybe<Marketing_Offer_Earned_Points_Type>;
  /**
   * For a points type of BONUS, this field will determine how many bonus points are rewarded.
   * For a points type of MULTIPLIER, this field will determine the multiplication factor as a percent.
   * For example, a value of 150 would represent a 1.5x point multiplier.
   */
  value?: Maybe<Scalars['Int']>;
  /** The maximum number of transactions eligible for promo period. */
  consumerMaximumPerLifetime?: Maybe<Scalars['Int']>;
};

export type PointsRedemptionRate = {
  __typename?: 'PointsRedemptionRate';
  rate: Scalars['Float'];
  id: Scalars['String'];
};

export type PostAudienceInput = {
  patientEmailsUrl?: InputMaybe<Scalars['URL']>;
  ageRange: NumberRangeInput;
  gender: Ad_Audience_Gender;
  radiusInMiles: Scalars['Int'];
};

export type Practice = {
  __typename?: 'Practice';
  id: Scalars['ID'];
  /** @deprecated use a4bUpgradeNotification instead */
  a4bUpgradeInvitation?: Maybe<ProviderA4BUpgradeInvitation>;
  a4bUpgradeNotification?: Maybe<ProviderA4BUpgradeNotification>;
  transactions?: Maybe<PracticeTransactionsConnection>;
  flashRewardsProfile?: Maybe<FlashRewardsProfile>;
  advertisingProfile: AdvertisingProfile;
  /**
   * Practices performance metrics following posts publication (leads, clicks, likes, etc...).
   * @deprecated Please use advertisingProfile.performance
   */
  socialMediaPerformanceMetrics: PracticeSocialMediaPerformanceMetricsConnection;
  /** List of connected social network accounts */
  socialNetworkIntegrations: PracticeSocialNetworkIntegrationsConnection;
  /**
   * List of Leads by sourceType under the practice
   * Giving options to filter down by the source (e.g. Advertisement)
   */
  leadsBySourceType: PracticeLeadsConnection;
  paymentsWallet?: Maybe<PaymentsWallet>;
  accountNumber: Scalars['String'];
  paymentAlerts?: Maybe<Array<PracticePaymentAlerts>>;
  paymentProfile?: Maybe<PaymentProfile>;
  payoutsHistory?: Maybe<PracticePayoutsConnection>;
  failedReimbursementHistory?: Maybe<FailedReimbursementHistory>;
  providerOrganization?: Maybe<ProviderOrganization>;
  abbvieSapAccountNumber?: Maybe<Scalars['String']>;
  allerganSapAccountNumber?: Maybe<Scalars['String']>;
  contact: PracticeContact;
  enrolledAt?: Maybe<DateTime>;
  name: Scalars['String'];
  timeZone?: Maybe<Scalars['String']>;
  isStaffMemberEnabled: Scalars['Boolean'];
  staffMembersConnection?: Maybe<PracticeStaffMembersConnection>;
  insightsMetrics?: Maybe<PracticeInsightsMetrics>;
  offersHistory?: Maybe<PracticeOfferHistoryConnection>;
  promotions?: Maybe<PracticePromotionsConnection>;
};

export type PracticeTransactionsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  range?: InputMaybe<DateRangeInput>;
};

export type PracticeSocialMediaPerformanceMetricsArgs = {
  input?: InputMaybe<SocialMediaPerformanceMetricsInput>;
};

export type PracticeLeadsBySourceTypeArgs = {
  sourceType: Lead_Source_Type;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PracticePayoutsHistoryArgs = {
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  range?: InputMaybe<DateRangeInput>;
};

export type PracticeOffersHistoryArgs = {
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  range?: InputMaybe<DateRangeInput>;
};

export type PracticeActivateAdvertisingProfileFailure = {
  __typename?: 'PracticeActivateAdvertisingProfileFailure';
  error: Error;
};

export type PracticeActivateAdvertisingProfileInput = {
  id: Scalars['ID'];
};

export type PracticeActivateAdvertisingProfilePayload =
  | PracticeActivateAdvertisingProfileSuccess
  | PracticeActivateAdvertisingProfileFailure;

export type PracticeActivateAdvertisingProfileSuccess = {
  __typename?: 'PracticeActivateAdvertisingProfileSuccess';
  practice: Practice;
};

export type PracticeAddStaffMemberError = {
  __typename?: 'PracticeAddStaffMemberError';
  error: Error;
};

export type PracticeAddStaffMemberInput = {
  practiceId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type PracticeAddStaffMemberPayload =
  | PracticeAddStaffMemberSuccess
  | PracticeAddStaffMemberError;

export type PracticeAddStaffMemberSuccess = {
  __typename?: 'PracticeAddStaffMemberSuccess';
  staffMember: PracticeStaffMember;
};

export type PracticeAdvertisingProfileUpdateAdvertisementManagementStatusFailure =
  {
    __typename?: 'PracticeAdvertisingProfileUpdateAdvertisementManagementStatusFailure';
    error: Error;
  };

export type PracticeAdvertisingProfileUpdateAdvertisementManagementStatusInput =
  {
    id: Scalars['ID'];
    advertisementManagementStatus: Advertisement_Management_Status;
  };

export type PracticeAdvertisingProfileUpdateAdvertisementManagementStatusPayload =

    | PracticeAdvertisingProfileUpdateAdvertisementManagementStatusSuccess
    | PracticeAdvertisingProfileUpdateAdvertisementManagementStatusFailure;

export type PracticeAdvertisingProfileUpdateAdvertisementManagementStatusSuccess =
  {
    __typename?: 'PracticeAdvertisingProfileUpdateAdvertisementManagementStatusSuccess';
    practice: Practice;
  };

export type PracticeAdvertisingProfileUpdateEnrollmentStatusFailure = {
  __typename?: 'PracticeAdvertisingProfileUpdateEnrollmentStatusFailure';
  error: Error;
};

export type PracticeAdvertisingProfileUpdateEnrollmentStatusInput = {
  id: Scalars['ID'];
  enrollmentStatus: Advertising_Profile_Enrollment_Status;
};

export type PracticeAdvertisingProfileUpdateEnrollmentStatusPayload =
  | PracticeAdvertisingProfileUpdateEnrollmentStatusSuccess
  | PracticeAdvertisingProfileUpdateEnrollmentStatusFailure;

export type PracticeAdvertisingProfileUpdateEnrollmentStatusSuccess = {
  __typename?: 'PracticeAdvertisingProfileUpdateEnrollmentStatusSuccess';
  practice: Practice;
};

export type PracticeContact = {
  __typename?: 'PracticeContact';
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
};

export type PracticeDateTime = {
  __typename?: 'PracticeDateTime';
  format?: Maybe<Scalars['String']>;
};

export type PracticeDateTimeFormatArgs = {
  layout?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type PracticeDeleteStaffMemberError = {
  __typename?: 'PracticeDeleteStaffMemberError';
  error: Error;
};

export type PracticeDeleteStaffMemberPayload =
  | PracticeDeleteStaffMemberSuccess
  | PracticeDeleteStaffMemberError;

export type PracticeDeleteStaffMemberSuccess = {
  __typename?: 'PracticeDeleteStaffMemberSuccess';
  staffMember: PracticeStaffMember;
};

export type PracticeEmployee = {
  __typename?: 'PracticeEmployee';
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  authProviderId?: Maybe<Scalars['String']>;
  authProviderType?: Maybe<Scalars['String']>;
  practices?: Maybe<Array<Maybe<Practice>>>;
  practiceEmployeeTypeRelations?: Maybe<
    Array<Maybe<PracticeEmployeeTypeRelation>>
  >;
};

export type PracticeEmployeeInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  authProviderId?: InputMaybe<Scalars['String']>;
  authProviderType?: InputMaybe<Scalars['String']>;
  practiceId: Scalars['String'];
  practiceEmployeeTypeId: Scalars['String'];
};

export type PracticeEmployeeProfile = ViewerProfile & {
  __typename?: 'PracticeEmployeeProfile';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  practice?: Maybe<Practice>;
  roles?: Maybe<Array<Scalars['String']>>;
};

export type PracticeEmployeeType = {
  __typename?: 'PracticeEmployeeType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PracticeEmployeeTypeRelation = {
  __typename?: 'PracticeEmployeeTypeRelation';
  id: Scalars['ID'];
  practice?: Maybe<Practice>;
  practiceEmployeeType?: Maybe<PracticeEmployeeType>;
};

export type PracticeEnrollError = {
  __typename?: 'PracticeEnrollError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type PracticeEnrollInput = {
  email: Scalars['String'];
  payoutFrequency: Payout_Frequency;
  bankNickname: Scalars['String'];
  bankAccountType: Bank_Account_Type;
  bankAccountNumber: Scalars['String'];
  bankRoutingNumber: Scalars['String'];
  viewerAcceptsPaymentTerms: Scalars['Boolean'];
  viewerAcceptsPrivacyPolicy: Scalars['Boolean'];
  viewerAcceptsTerms: Scalars['Boolean'];
  timezone?: InputMaybe<Scalars['String']>;
};

export type PracticeEnrollPayload = PracticeEnrollSuccess | PracticeEnrollError;

export type PracticeEnrollSuccess = {
  __typename?: 'PracticeEnrollSuccess';
  shipToAccountNumber?: Maybe<Scalars['String']>;
};

export type PracticeEnrollV2Failure = {
  __typename?: 'PracticeEnrollV2Failure';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type PracticeEnrollV2Input = {
  practiceId: Scalars['ID'];
  email: Scalars['String'];
  payoutFrequency: Payout_Frequency;
  bankNickname: Scalars['String'];
  bankAccountType: Bank_Account_Type;
  bankAccountNumber: Scalars['String'];
  bankRoutingNumber: Scalars['String'];
  viewerAcceptsPaymentTerms: Scalars['Boolean'];
  viewerAcceptsPrivacyPolicy: Scalars['Boolean'];
  viewerAcceptsTerms: Scalars['Boolean'];
  timezone?: InputMaybe<Scalars['String']>;
};

export type PracticeEnrollV2Payload =
  | PracticeEnrollV2Success
  | PracticeEnrollV2Failure;

export type PracticeEnrollV2Success = {
  __typename?: 'PracticeEnrollV2Success';
  practice: Practice;
};

export type PracticeInput = {
  name: Scalars['String'];
  shipToAccountNumber?: InputMaybe<Scalars['String']>;
  soldToAccountNumber?: InputMaybe<Scalars['String']>;
};

export type PracticeInsightsMetrics = {
  __typename?: 'PracticeInsightsMetrics';
  totalConsumersExpiringPointsInCents: Scalars['Int'];
  totalConsumersUnusedGiftCardsInCents: Scalars['Int'];
  totalLapsedBotoxConsumers: Scalars['Int'];
};

export type PracticeLeadsConnection = {
  __typename?: 'PracticeLeadsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<Lead>;
};

export type PracticeOfferHistoryConnection = {
  __typename?: 'PracticeOfferHistoryConnection';
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  items: Array<Maybe<PracticeOfferTransactionItem>>;
};

export type PracticeOfferTransactionItem = {
  __typename?: 'PracticeOfferTransactionItem';
  assignedAt?: Maybe<DateTime>;
  patient?: Maybe<Patient>;
  promotion?: Maybe<OfficePromotion>;
};

export type PracticeOptOutFlashRewardsFailure = {
  __typename?: 'PracticeOptOutFlashRewardsFailure';
  error: Error;
};

export type PracticeOptOutFlashRewardsInput = {
  id: Scalars['ID'];
  flashOptOutReasonSlug: Scalars['String'];
};

export type PracticeOptOutFlashRewardsPayload =
  | PracticeOptOutFlashRewardsSuccess
  | PracticeOptOutFlashRewardsFailure;

export type PracticeOptOutFlashRewardsSuccess = {
  __typename?: 'PracticeOptOutFlashRewardsSuccess';
  practice: Practice;
};

export enum PracticePaymentAlerts {
  BankingInformationNotFound = 'BANKING_INFORMATION_NOT_FOUND',
  InvalidBankingInformation = 'INVALID_BANKING_INFORMATION',
  PracticeNotEnrolled = 'PRACTICE_NOT_ENROLLED',
}

export type PracticePaymentProfileUpdateEmailAddressFailure = {
  __typename?: 'PracticePaymentProfileUpdateEmailAddressFailure';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type PracticePaymentProfileUpdateEmailAddressInput = {
  practiceId: Scalars['ID'];
  emailAddress: Scalars['String'];
};

export type PracticePaymentProfileUpdateEmailAddressPayload =
  | PracticePaymentProfileUpdateEmailAddressSuccess
  | PracticePaymentProfileUpdateEmailAddressFailure;

export type PracticePaymentProfileUpdateEmailAddressSuccess = {
  __typename?: 'PracticePaymentProfileUpdateEmailAddressSuccess';
  practice: Practice;
};

export type PracticePaymentProfileUpdatePaymentFrequencyFailure = {
  __typename?: 'PracticePaymentProfileUpdatePaymentFrequencyFailure';
  error: Error;
};

export type PracticePaymentProfileUpdatePaymentFrequencyInput = {
  practiceId: Scalars['ID'];
  payoutFrequency: Payout_Frequency;
};

export type PracticePaymentProfileUpdatePaymentFrequencyPayload =
  | PracticePaymentProfileUpdatePaymentFrequencySuccess
  | PracticePaymentProfileUpdatePaymentFrequencyFailure;

export type PracticePaymentProfileUpdatePaymentFrequencySuccess = {
  __typename?: 'PracticePaymentProfileUpdatePaymentFrequencySuccess';
  practice: Practice;
};

export type PracticePayoutsConnection = {
  __typename?: 'PracticePayoutsConnection';
  payouts?: Maybe<Array<Maybe<Payout>>>;
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PracticePromotionsConnection = {
  __typename?: 'PracticePromotionsConnection';
  edges?: Maybe<Array<Maybe<PracticePromotionsEdge>>>;
  totalCount: Scalars['Int'];
};

export type PracticePromotionsEdge = {
  __typename?: 'PracticePromotionsEdge';
  node: OfficePromotion;
};

export type PracticeReactivateFlashRewardsFailure = {
  __typename?: 'PracticeReactivateFlashRewardsFailure';
  error: Error;
};

export type PracticeReactivateFlashRewardsInput = {
  id: Scalars['ID'];
  flashReactivationReasonSlug: Scalars['String'];
};

export type PracticeReactivateFlashRewardsPayload =
  | PracticeReactivateFlashRewardsSuccess
  | PracticeReactivateFlashRewardsFailure;

export type PracticeReactivateFlashRewardsSuccess = {
  __typename?: 'PracticeReactivateFlashRewardsSuccess';
  practice: Practice;
};

export type PracticeSocialMediaPerformanceMetricsConnection = {
  __typename?: 'PracticeSocialMediaPerformanceMetricsConnection';
  edges: Array<PracticeSocialMediaPerformanceMetricsEdge>;
};

export type PracticeSocialMediaPerformanceMetricsEdge = {
  __typename?: 'PracticeSocialMediaPerformanceMetricsEdge';
  node?: Maybe<SocialMediaPerformanceMetric>;
};

export type PracticeSocialNetworkIntegrationsConnection = {
  __typename?: 'PracticeSocialNetworkIntegrationsConnection';
  edges: Array<PracticeSocialNetworkIntegrationsEdge>;
};

export type PracticeSocialNetworkIntegrationsEdge = {
  __typename?: 'PracticeSocialNetworkIntegrationsEdge';
  node: SocialNetworkIntegration;
};

export type PracticeStaffMember = {
  __typename?: 'PracticeStaffMember';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type PracticeStaffMembersConnection = {
  __typename?: 'PracticeStaffMembersConnection';
  staffMembers?: Maybe<Array<PracticeStaffMember>>;
};

export type PracticeTransactionsConnection = {
  __typename?: 'PracticeTransactionsConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<PracticeTransactionsEdge>>>;
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PracticeTransactionsEdge = {
  __typename?: 'PracticeTransactionsEdge';
  cursor: Scalars['String'];
  node: PatientTransaction;
};

export type PracticeTransactionsReportCreateInput = {
  range: DateRangeInput;
  sort: Array<PracticeTransactionsReportSort>;
};

export type PracticeTransactionsReportSort = {
  column?: PracticeTransactionsReportSortColumn;
  order?: ReportSortOrder;
};

export enum PracticeTransactionsReportSortColumn {
  Date = 'DATE',
  Name = 'NAME',
}

export type PracticeUpdateSettingsError = {
  __typename?: 'PracticeUpdateSettingsError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

/**
 * Valid timezone inputs are:
 *   - America/Anchorage
 *   - America/Chicago
 *   - America/Denver
 *   - America/Los_Angeles
 *   - America/New_York
 *   - America/Phoenix
 *   - America/Puerto_Rico
 *   - Pacific/Honolulu
 */
export type PracticeUpdateSettingsInput = {
  id: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
  isStaffMemberEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type PracticeUpdateSettingsPayload =
  | PracticeUpdateSettingsSuccess
  | PracticeUpdateSettingsError;

export type PracticeUpdateSettingsSuccess = {
  __typename?: 'PracticeUpdateSettingsSuccess';
  practice: Practice;
};

export type PracticeUpdateStaffMemberError = {
  __typename?: 'PracticeUpdateStaffMemberError';
  error: Error;
};

export type PracticeUpdateStaffMemberInput = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type PracticeUpdateStaffMemberPayload =
  | PracticeUpdateStaffMemberSuccess
  | PracticeUpdateStaffMemberError;

export type PracticeUpdateStaffMemberSuccess = {
  __typename?: 'PracticeUpdateStaffMemberSuccess';
  staffMember: PracticeStaffMember;
};

export type Practitioner = {
  __typename?: 'Practitioner';
  id: Scalars['ID'];
};

export type PriceModel = {
  __typename?: 'PriceModel';
  id: Scalars['ID'];
  amountInCents: Scalars['Int'];
  billingCycle: Billing_Cycle;
};

export type PricingProfileSendInviteFailure = {
  __typename?: 'PricingProfileSendInviteFailure';
  error: Error;
};

export type PricingProfileSendInviteInput = {
  id: Scalars['ID'];
  wave: Scalars['String'];
};

export type PricingProfileSendInvitePayload =
  | PricingProfileSendInviteSuccess
  | PricingProfileSendInviteFailure;

export type PricingProfileSendInviteSuccess = {
  __typename?: 'PricingProfileSendInviteSuccess';
  pricingProfile: ProviderPricingProfile;
};

export type PricingProfileUpdateEnrollmentStatusFailure = {
  __typename?: 'PricingProfileUpdateEnrollmentStatusFailure';
  error: Error;
};

export type PricingProfileUpdateEnrollmentStatusInput = {
  id: Scalars['ID'];
  enrollmentStatus: Provider_Pricing_Enrollment_Status;
};

export type PricingProfileUpdateEnrollmentStatusPayload =
  | PricingProfileUpdateEnrollmentStatusSuccess
  | PricingProfileUpdateEnrollmentStatusFailure;

export type PricingProfileUpdateEnrollmentStatusSuccess = {
  __typename?: 'PricingProfileUpdateEnrollmentStatusSuccess';
  pricingProfile: ProviderPricingProfile;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  brand?: Maybe<Brand>;
  internalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  variants?: Maybe<Array<Maybe<ProductVariant>>>;
};

export type ProductReimbursementReportItemsFilter = {
  fromDate: Scalars['DateTimeInput'];
  toDate: Scalars['DateTimeInput'];
};

export type ProductReimbursementReportRow = {
  __typename?: 'ProductReimbursementReportRow';
  id: Scalars['ID'];
  transaction: PatientTransaction;
  patient: Patient;
  promotion: PatientPromotion;
  skuNumber: Scalars['String'];
  sapOrderNumber?: Maybe<Scalars['String']>;
  shippingCompany?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  trackingUrl?: Maybe<Scalars['String']>;
  shipments?: Maybe<ProductReimbursementShipmentsConnection>;
};

export type ProductReimbursementShipment = {
  __typename?: 'ProductReimbursementShipment';
  id: Scalars['ID'];
  shippingCompany: Scalars['String'];
  trackingNumber: Scalars['String'];
  trackingUrl?: Maybe<Scalars['String']>;
};

export type ProductReimbursementShipmentsConnection = {
  __typename?: 'ProductReimbursementShipmentsConnection';
  nodes: Array<ProductReimbursementShipment>;
};

export type ProductTreatmentAreasConnection = {
  __typename?: 'ProductTreatmentAreasConnection';
  nodes: Array<ProviderProfileTreatmentArea>;
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  id: Scalars['ID'];
  brand?: Maybe<Brand>;
  displayName?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  limits?: Maybe<ProductVariantLimits>;
  materialId?: Maybe<Scalars['ID']>;
  msrp?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  unit?: Maybe<ProductVariantUnit>;
};

export type ProductVariantLimits = {
  __typename?: 'ProductVariantLimits';
  maximum?: Maybe<Scalars['Int']>;
  minimum?: Maybe<Scalars['Int']>;
};

export type ProductVariantUnit = {
  __typename?: 'ProductVariantUnit';
  single?: Maybe<Scalars['String']>;
  plural?: Maybe<Scalars['String']>;
};

export type ProductsLastTransactionReport = {
  __typename?: 'ProductsLastTransactionReport';
  rows: ProductsLastTransactionReportRowsConnection;
};

export type ProductsLastTransactionReportExportInput = {
  filename?: InputMaybe<Scalars['String']>;
  productIdsToInclude?: InputMaybe<Array<Scalars['ID']>>;
  productSet: Report_Filter_Products_Last_Transaction_Product_Set;
  range?: InputMaybe<DateRangeInput>;
  sort?: InputMaybe<Array<ProductsLastTransactionReportSort>>;
  reportExportFormat?: InputMaybe<Report_Export_Format>;
};

export type ProductsLastTransactionReportRow = {
  __typename?: 'ProductsLastTransactionReportRow';
  consumerId: Scalars['ID'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  products: Array<ConsumerProductLastTransactionData>;
};

export type ProductsLastTransactionReportRowsConnection = {
  __typename?: 'ProductsLastTransactionReportRowsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<ProductsLastTransactionReportRow>;
};

export type ProductsLastTransactionReportSort = {
  columnId: Scalars['ID'];
  order?: ReportSortOrder;
};

export type Profile = {
  __typename?: 'Profile';
  phoneNumber: Scalars['String'];
  pendingPhoneNumber?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  optInMarketingSMS: Scalars['Boolean'];
  optInMarketingEmail: Scalars['Boolean'];
  birthDate?: Maybe<Date>;
  birthday?: Maybe<Birthday>;
  gender?: Maybe<Gender>;
  address?: Maybe<Address>;
  verifiedEmail?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  communicationPreferences?: Maybe<ConsumerCommunicationPreferences>;
};

export type ProfileInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  password: Scalars['String'];
  optInSMS?: InputMaybe<Scalars['Boolean']>;
  optInEmail: Scalars['Boolean'];
};

export type ProfileLanguage = {
  __typename?: 'ProfileLanguage';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Promotion = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  expiration?: Maybe<DateTime>;
  imageAltText?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['URL']>;
  isisConnection?: Maybe<PromotionIsIsConnection>;
  name: Scalars['String'];
  productsConnection?: Maybe<PromotionProductsConnection>;
  summary?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  type?: Maybe<Promotion_Type>;
  value: PromotionValue;
};

export type PromotionClaimWithPointsFailure = {
  __typename?: 'PromotionClaimWithPointsFailure';
  error: Error;
};

export type PromotionClaimWithPointsPayload =
  | PromotionClaimWithPointsSuccess
  | PromotionClaimWithPointsFailure;

export type PromotionClaimWithPointsSuccess = {
  __typename?: 'PromotionClaimWithPointsSuccess';
  id: Scalars['ID'];
  promotion?: Maybe<PatientPromotion>;
};

export type PromotionConstruct = {
  __typename?: 'PromotionConstruct';
  id: Scalars['ID'];
  advertisingCampaign?: Maybe<ProviderPublicMarketingCampaign>;
  displayName: Scalars['String'];
  expiresAt?: Maybe<DateTime>;
  terms?: Maybe<Scalars['String']>;
  value: PromotionValue;
  productsConnection?: Maybe<PromotionProductsConnection>;
};

export type PromotionError = {
  __typename?: 'PromotionError';
  code?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  friendlyMessage?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  promotionId?: Maybe<Scalars['ID']>;
  promotionInventoryId?: Maybe<Scalars['ID']>;
};

export type PromotionIsIsConnection = {
  __typename?: 'PromotionISIsConnection';
  isis?: Maybe<Array<Maybe<BrandIsi>>>;
};

export type PromotionPriceValue = {
  __typename?: 'PromotionPriceValue';
  unit: Scalars['String'];
  quantity: Scalars['Int'];
};

export type PromotionProductsConnection = {
  __typename?: 'PromotionProductsConnection';
  productIds?: Maybe<Array<Scalars['ID']>>;
  edges?: Maybe<Array<Maybe<PromotionProductsEdge>>>;
};

export type PromotionProductsEdge = {
  __typename?: 'PromotionProductsEdge';
  node?: Maybe<ProductVariant>;
};

export type PromotionReport = {
  __typename?: 'PromotionReport';
  rows: PromotionReportRowsConnection;
};

export type PromotionReportExportInput = {
  filename?: InputMaybe<Scalars['String']>;
  productsLastTransactionProductSet: Report_Filter_Promotion_Last_Transaction_Product_Set;
  reportingPromotionId: Scalars['ID'];
  sort?: InputMaybe<Array<ProductsLastTransactionReportSort>>;
};

export type PromotionReportRow = {
  __typename?: 'PromotionReportRow';
  consumerId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  pointTotal: Scalars['Int'];
  emailAddress: Scalars['String'];
  productsLastTransaction: Array<ConsumerProductLastTransactionData>;
  selectedStaffMemberName?: Maybe<Scalars['String']>;
};

export type PromotionReportRowsConnection = {
  __typename?: 'PromotionReportRowsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<PromotionReportRow>;
};

export type PromotionReportSort = {
  columnId: Scalars['ID'];
  order?: ReportSortOrder;
};

export type PromotionSelectError = {
  __typename?: 'PromotionSelectError';
  error: Error;
};

export type PromotionSelectInput = {
  promotionId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type PromotionSelectPayload =
  | PromotionSelectSuccess
  | PromotionSelectError;

export type PromotionSelectSuccess = {
  __typename?: 'PromotionSelectSuccess';
  status?: Maybe<Promotion_Selection_Status>;
};

export type PromotionValue = {
  __typename?: 'PromotionValue';
  unit: Scalars['String'];
  quantity: Scalars['Int'];
};

export type Provider = {
  __typename?: 'Provider';
  draftPublicProfile: DraftPublicProfile;
  id: Scalars['ID'];
  name: Scalars['String'];
  shipToAccountNumber: Scalars['String'];
};

export type ProviderA4BUpgradeCompletedRequestInviteNotification = {
  __typename?: 'ProviderA4BUpgradeCompletedRequestInviteNotification';
  a4pAccessExpiresAt: DateTime;
};

export type ProviderA4BUpgradeFreeFormNotification = {
  __typename?: 'ProviderA4BUpgradeFreeFormNotification';
  a4pAccessExpiresAt: DateTime;
  businessOwnerEmail?: Maybe<Scalars['String']>;
  isBusinessActive?: Maybe<Scalars['Boolean']>;
};

export type ProviderA4BUpgradeInvitation = {
  __typename?: 'ProviderA4BUpgradeInvitation';
  expiresAt: DateTime;
  id: Scalars['ID'];
  isBusinessOwner: Scalars['Boolean'];
  token: Scalars['String'];
};

export type ProviderA4BUpgradeNotification =
  | ProviderA4BUpgradeInvitation
  | ProviderA4BUpgradeCompletedRequestInviteNotification
  | ProviderA4BUpgradePendingNotification
  | ProviderA4BUpgradeFreeFormNotification;

export type ProviderA4BUpgradePendingNotification = {
  __typename?: 'ProviderA4BUpgradePendingNotification';
  a4pAccessExpiresAt: DateTime;
  businessOwnerEmailAddress: Scalars['String'];
};

export type ProviderAccessTokenGenerateFailure = {
  __typename?: 'ProviderAccessTokenGenerateFailure';
  error: Error;
};

export type ProviderAccessTokenGenerateInput = {
  idpAccessToken: Scalars['String'];
  providerOrganizationId?: InputMaybe<Scalars['ID']>;
};

export type ProviderAccessTokenGeneratePayload =
  | ProviderAccessTokenGenerateSuccess
  | ProviderAccessTokenGenerateFailure;

export type ProviderAccessTokenGenerateSuccess = {
  __typename?: 'ProviderAccessTokenGenerateSuccess';
  accessToken: Scalars['String'];
};

export type ProviderAddress = {
  __typename?: 'ProviderAddress';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type ProviderAddressInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type ProviderBusinessAcceptTermsAndConditionsFailure = {
  __typename?: 'ProviderBusinessAcceptTermsAndConditionsFailure';
  error: Error;
};

export type ProviderBusinessAcceptTermsAndConditionsInput = {
  organizationId: Scalars['ID'];
  termsAndConditionsVersion?: InputMaybe<Scalars['String']>;
};

export type ProviderBusinessAcceptTermsAndConditionsPayload =
  | ProviderBusinessAcceptTermsAndConditionsSuccess
  | ProviderBusinessAcceptTermsAndConditionsFailure;

export type ProviderBusinessAcceptTermsAndConditionsSuccess = {
  __typename?: 'ProviderBusinessAcceptTermsAndConditionsSuccess';
  providerOrganization: ProviderOrganization;
};

export type ProviderBusinessHours = {
  __typename?: 'ProviderBusinessHours';
  appointmentRequired?: Maybe<Scalars['Boolean']>;
  close?: Maybe<Scalars['Time']>;
  closed?: Maybe<Scalars['Boolean']>;
  day: Day_Of_The_Week;
  id: Scalars['ID'];
  open?: Maybe<Scalars['Time']>;
};

export type ProviderBusinessHoursConnection = {
  __typename?: 'ProviderBusinessHoursConnection';
  nodes: Array<ProviderBusinessHours>;
};

export type ProviderBusinessHoursInput = {
  appointmentRequired?: InputMaybe<Scalars['Boolean']>;
  close?: InputMaybe<Scalars['Time']>;
  closed?: InputMaybe<Scalars['Boolean']>;
  day: Day_Of_The_Week;
  id?: InputMaybe<Scalars['ID']>;
  open?: InputMaybe<Scalars['Time']>;
};

export type ProviderBusinessWorkspace = {
  __typename?: 'ProviderBusinessWorkspace';
  roles: Array<Provider_User_Role_V2>;
  business: ProviderWorkspaceOrganization;
  locationWorkspaces: ProviderBusinessWorkspaceLocationWorkspacesConnection;
};

export type ProviderBusinessWorkspaceLocationWorkspacesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ProviderLocationWorkspacesFilter>;
};

export type ProviderBusinessWorkspaceLocationWorkspacesConnection = {
  __typename?: 'ProviderBusinessWorkspaceLocationWorkspacesConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ProviderBusinessWorkspaceLocationWorkspacesEdge>;
};

export type ProviderBusinessWorkspaceLocationWorkspacesEdge = {
  __typename?: 'ProviderBusinessWorkspaceLocationWorkspacesEdge';
  node: ProviderLocationWorkspace;
};

export type ProviderConsultationRequestSettings = {
  __typename?: 'ProviderConsultationRequestSettings';
  consultationCostInCents?: Maybe<Scalars['Int']>;
  displayVaryingConsultationFeeMessage?: Maybe<Scalars['Boolean']>;
  feeTowardsTreatmentCost?: Maybe<Scalars['Boolean']>;
  hideForm: Scalars['Boolean'];
  sendRequestTo?: Maybe<Scalars['String']>;
  offerVirtualConsultation?: Maybe<Scalars['Boolean']>;
};

export type ProviderContactInformation = {
  __typename?: 'ProviderContactInformation';
  address: Scalars['String'];
  emailAddress: Scalars['String'];
  facebookUrl?: Maybe<Scalars['URL']>;
  instagramHandle?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  tikTokHandle?: Maybe<Scalars['String']>;
  bookingUrl?: Maybe<Scalars['URL']>;
  shopUrl?: Maybe<Scalars['URL']>;
  websiteUrl?: Maybe<Scalars['URL']>;
};

export type ProviderEmailCampaignOfferValueConstraints = {
  __typename?: 'ProviderEmailCampaignOfferValueConstraints';
  rangeInCents: NumberRange;
  defaultInCents: Scalars['Int'];
  incrementsInCents: Scalars['Int'];
};

export type ProviderEmailMarketingCampaign = {
  __typename?: 'ProviderEmailMarketingCampaign';
  id: Scalars['ID'];
  name: Scalars['String'];
  status: Provider_Email_Marketing_Campaign_Status;
  preview: ProviderEmailMarketingCampaignPackagePreview;
  offer?: Maybe<ProviderEmailMarketingCampaignOffer>;
  activityLogs: ProviderEmailMarketingCampaignActivityLogsConnection;
  createdBy: ActorV2;
  updatedAt: DateTime;
  performance: ProviderEmailMarketingCampaignPerformance;
};

export type ProviderEmailMarketingCampaignActivityLogsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ProviderEmailMarketingCampaignActivityLog = {
  __typename?: 'ProviderEmailMarketingCampaignActivityLog';
  createdAt: DateTime;
  createdBy: ActorV2;
  action: Scalars['String'];
};

export type ProviderEmailMarketingCampaignActivityLogsConnection = {
  __typename?: 'ProviderEmailMarketingCampaignActivityLogsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<ProviderEmailMarketingCampaignActivityLog>;
};

export type ProviderEmailMarketingCampaignConnection = {
  __typename?: 'ProviderEmailMarketingCampaignConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<ProviderEmailMarketingCampaign>;
};

export type ProviderEmailMarketingCampaignCreateError = {
  __typename?: 'ProviderEmailMarketingCampaignCreateError';
  error: Error;
};

export type ProviderEmailMarketingCampaignCreateInput = {
  packageId: Scalars['ID'];
  internalDisplayName?: InputMaybe<Scalars['String']>;
  offer?: InputMaybe<ProviderEmailMarketingCampaignOfferInput>;
};

export type ProviderEmailMarketingCampaignCreatePayload =
  | ProviderEmailMarketingCampaignCreateSuccess
  | ProviderEmailMarketingCampaignCreateError;

export type ProviderEmailMarketingCampaignCreateSuccess = {
  __typename?: 'ProviderEmailMarketingCampaignCreateSuccess';
  campaign: ProviderEmailMarketingCampaign;
};

export type ProviderEmailMarketingCampaignOffer = {
  __typename?: 'ProviderEmailMarketingCampaignOffer';
  brand: Brand;
  createdAt: DateTime;
  claimPeriodInDays: Scalars['Int'];
  expireInDays: Scalars['Int'];
  amountInCents: Scalars['Int'];
  claimedCount: Scalars['Int'];
  redeemedCount: Scalars['Int'];
};

export type ProviderEmailMarketingCampaignOfferInput = {
  valueInCents: Scalars['Int'];
};

export type ProviderEmailMarketingCampaignOfferTemplate = {
  __typename?: 'ProviderEmailMarketingCampaignOfferTemplate';
  valueConstraints: ProviderEmailCampaignOfferValueConstraints;
  claimPeriodInDays: Scalars['Int'];
  expireInDays: Scalars['Int'];
  brand: Brand;
  termsAndConditions?: Maybe<Scalars['String']>;
};

export type ProviderEmailMarketingCampaignOverallPerformance = {
  __typename?: 'ProviderEmailMarketingCampaignOverallPerformance';
  emailAutomatedDeliveryPercentage: Scalars['Float'];
  emailsDelivered: Scalars['Int'];
  emailsOpened: Scalars['Int'];
  emailsSent: Scalars['Int'];
  emailCallCtaClicks: Scalars['Int'];
  emailBookingUrlClicks?: Maybe<Scalars['Int']>;
  emailOfferActivationClicks?: Maybe<Scalars['Int']>;
  emailAverageClickRatePercentage: Scalars['Float'];
  totalRecipients: Scalars['Int'];
};

export type ProviderEmailMarketingCampaignPackage = {
  __typename?: 'ProviderEmailMarketingCampaignPackage';
  id: Scalars['ID'];
  /**
   * A stable identifier for external use. UUID `id` is subject to change for the
   * same conceptual package like "Botox offer". If you are hard coding a reference
   * to a package, this is probably what you want.
   */
  slug: Scalars['String'];
  automation?: Maybe<ProviderEmailMarketingCampaignPackageAutomation>;
  name: Scalars['String'];
  automationDescription: Scalars['String'];
  automationName: Scalars['String'];
  thumbnail: Image;
  preview: ProviderEmailMarketingCampaignPackagePreview;
  insightsReport: ProviderReport;
  insightsReportFilters: Array<ProviderEmailMarketingCampaignPackageInsightsReportFilter>;
  offerTemplate?: Maybe<ProviderEmailMarketingCampaignOfferTemplate>;
};

export type ProviderEmailMarketingCampaignPackageAutomation = {
  __typename?: 'ProviderEmailMarketingCampaignPackageAutomation';
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
};

export type ProviderEmailMarketingCampaignPackageAutomationSetEnabledFailure = {
  __typename?: 'ProviderEmailMarketingCampaignPackageAutomationSetEnabledFailure';
  error: Error;
};

export type ProviderEmailMarketingCampaignPackageAutomationSetEnabledInput = {
  packageId: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
};

export type ProviderEmailMarketingCampaignPackageAutomationSetEnabledPayload =
  | ProviderEmailMarketingCampaignPackageAutomationSetEnabledSuccess
  | ProviderEmailMarketingCampaignPackageAutomationSetEnabledFailure;

export type ProviderEmailMarketingCampaignPackageAutomationSetEnabledSuccess = {
  __typename?: 'ProviderEmailMarketingCampaignPackageAutomationSetEnabledSuccess';
  package: ProviderEmailMarketingCampaignPackage;
};

export type ProviderEmailMarketingCampaignPackageInsightsReportFilter = {
  __typename?: 'ProviderEmailMarketingCampaignPackageInsightsReportFilter';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ProviderEmailMarketingCampaignPackagePreview = {
  __typename?: 'ProviderEmailMarketingCampaignPackagePreview';
  desktopImage: Image;
  mobileImage: Image;
  offerLandingPageImage?: Maybe<Image>;
  offerInWalletImage?: Maybe<Image>;
};

export type ProviderEmailMarketingCampaignPackageSendPreviewFailure = {
  __typename?: 'ProviderEmailMarketingCampaignPackageSendPreviewFailure';
  error: Error;
};

export type ProviderEmailMarketingCampaignPackageSendPreviewInput = {
  id: Scalars['ID'];
  emailAddress: Scalars['EmailAddress'];
};

export type ProviderEmailMarketingCampaignPackageSendPreviewPayload =
  | ProviderEmailMarketingCampaignPackageSendPreviewSuccess
  | ProviderEmailMarketingCampaignPackageSendPreviewFailure;

export type ProviderEmailMarketingCampaignPackageSendPreviewSuccess = {
  __typename?: 'ProviderEmailMarketingCampaignPackageSendPreviewSuccess';
  package: ProviderEmailMarketingCampaignPackage;
};

export type ProviderEmailMarketingCampaignPackagesConnection = {
  __typename?: 'ProviderEmailMarketingCampaignPackagesConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<ProviderEmailMarketingCampaignPackage>;
};

export type ProviderEmailMarketingCampaignPackagesFilterInput = {
  hasOffer: Scalars['Boolean'];
};

export type ProviderEmailMarketingCampaignPerformance = {
  __typename?: 'ProviderEmailMarketingCampaignPerformance';
  emailsDelivered: Scalars['Int'];
  emailsOpened: Scalars['Int'];
  emailsSent: Scalars['Int'];
  emailCallCtaClicks: Scalars['Int'];
  emailBookingUrlClicks?: Maybe<Scalars['Int']>;
};

export type ProviderEmailMarketingCampaignsFilterInput = {
  hasOffer?: InputMaybe<Scalars['Boolean']>;
  /** DateTime range to filter for the CreatedAt DateTime. */
  createdAtRange?: InputMaybe<DateTimeRangeInput>;
};

export type ProviderGalleryPhotosConnection = {
  __typename?: 'ProviderGalleryPhotosConnection';
  nodes: Array<GalleryPhoto>;
};

export type ProviderGeolocation = {
  __typename?: 'ProviderGeolocation';
  latitude: Scalars['String'];
  longitude: Scalars['String'];
};

export type ProviderJobFunction = {
  __typename?: 'ProviderJobFunction';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProviderLanguage = {
  __typename?: 'ProviderLanguage';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProviderLocationActivateFailure = {
  __typename?: 'ProviderLocationActivateFailure';
  error: Error;
};

export type ProviderLocationActivateInput = {
  organizationId: Scalars['ID'];
};

export type ProviderLocationActivatePayload =
  | ProviderLocationActivateSuccess
  | ProviderLocationActivateFailure;

export type ProviderLocationActivateSuccess = {
  __typename?: 'ProviderLocationActivateSuccess';
  providerOrganization: ProviderOrganization;
};

export type ProviderLocationArchiveFailure = {
  __typename?: 'ProviderLocationArchiveFailure';
  error: Error;
};

export type ProviderLocationArchiveInput = {
  organizationId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type ProviderLocationArchivePayload =
  | ProviderLocationArchiveSuccess
  | ProviderLocationArchiveFailure;

export type ProviderLocationArchiveSuccess = {
  __typename?: 'ProviderLocationArchiveSuccess';
  providerOrganization: ProviderOrganization;
};

export type ProviderLocationUnarchiveFailure = {
  __typename?: 'ProviderLocationUnarchiveFailure';
  error: Error;
};

export type ProviderLocationUnarchiveInput = {
  organizationId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type ProviderLocationUnarchivePayload =
  | ProviderLocationUnarchiveSuccess
  | ProviderLocationUnarchiveFailure;

export type ProviderLocationUnarchiveSuccess = {
  __typename?: 'ProviderLocationUnarchiveSuccess';
  providerOrganization: ProviderOrganization;
};

export type ProviderLocationWorkspace = {
  __typename?: 'ProviderLocationWorkspace';
  role: Provider_User_Role_V2;
  location: ProviderWorkspaceOrganization;
};

export type ProviderLocationWorkspacesFilter = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type ProviderLocationsFilter = {
  accountStatuses?: InputMaybe<Array<Account_Status>>;
  isEnrolledInAlleRewards?: InputMaybe<Scalars['Boolean']>;
  isEligibleForEnrollmentInAlleRewards?: InputMaybe<Scalars['Boolean']>;
};

export type ProviderMarkeringCampaignCreateCreativeOverwriteInput = {
  imageVariantIds: Array<Scalars['ID']>;
};

export type ProviderMarketingCamapignPackageRelatedPackagesEdge = {
  __typename?: 'ProviderMarketingCamapignPackageRelatedPackagesEdge';
  isRecommended: Scalars['Boolean'];
  node: ProviderMarketingCampaignPackage;
};

export type ProviderMarketingCampaign = {
  __typename?: 'ProviderMarketingCampaign';
  id: Scalars['ID'];
  name: Scalars['String'];
  practice: Practice;
  status: Provider_Marketing_Campaign_Status;
  offer?: Maybe<ProviderMarketingCampaignOffer>;
  audience?: Maybe<ProviderMarketingCampaignAudience>;
  creative?: Maybe<ProviderMarketingCampaignCreative>;
  budget?: Maybe<ProviderMarketingCampaignBudget>;
  duration?: Maybe<ProviderMarketingCampaignDuration>;
  performance?: Maybe<ProviderMarketingCampaignPerformance>;
  package?: Maybe<ProviderMarketingCampaignPackage>;
  activityLogs: ProviderMarketingCampaignActivityLogsConnection;
  survey?: Maybe<ProviderMarketingCampaignSurvey>;
};

export type ProviderMarketingCampaignActivityLogsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ProviderMarketingCampaignActivityLog = {
  __typename?: 'ProviderMarketingCampaignActivityLog';
  createdAt: DateTime;
  createdBy: GrowActor;
  /** @deprecated use action */
  message: Scalars['String'];
  action: Scalars['String'];
};

export type ProviderMarketingCampaignActivityLogsConnection = {
  __typename?: 'ProviderMarketingCampaignActivityLogsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<ProviderMarketingCampaignActivityLog>;
};

export type ProviderMarketingCampaignAdvertisingCreative = {
  __typename?: 'ProviderMarketingCampaignAdvertisingCreative';
  id: Scalars['ID'];
  callToActionLabel: Scalars['String'];
  caption: Scalars['String'];
  description: Scalars['String'];
  headline: Scalars['String'];
  imageUrl: Scalars['URL'];
};

export type ProviderMarketingCampaignAdvertisingTargetingAudience = {
  __typename?: 'ProviderMarketingCampaignAdvertisingTargetingAudience';
  id: Scalars['ID'];
  ageRange: AdvertisingAgeRange;
  gender: Ad_Audience_Gender;
  address: AdvertisingAddress;
  radiusInMiles: Scalars['Int'];
  patientEmailsFileMetadata?: Maybe<AdvertisingPatientListFileMetadata>;
};

export type ProviderMarketingCampaignAudience =
  ProviderMarketingCampaignAdvertisingTargetingAudience;

export type ProviderMarketingCampaignBudget = {
  __typename?: 'ProviderMarketingCampaignBudget';
  budgetInCents: Scalars['Int'];
};

export type ProviderMarketingCampaignCreateContactLinkFailure = {
  __typename?: 'ProviderMarketingCampaignCreateContactLinkFailure';
  error: Error;
};

export type ProviderMarketingCampaignCreateContactLinkInput = {
  campaignId: Scalars['String'];
};

export type ProviderMarketingCampaignCreateContactLinkPayload =
  | ProviderMarketingCampaignCreateContactLinkSuccess
  | ProviderMarketingCampaignCreateContactLinkFailure;

export type ProviderMarketingCampaignCreateContactLinkSuccess = {
  __typename?: 'ProviderMarketingCampaignCreateContactLinkSuccess';
  shortLink: Scalars['String'];
};

export type ProviderMarketingCampaignCreateError = {
  __typename?: 'ProviderMarketingCampaignCreateError';
  error: Error;
};

export type ProviderMarketingCampaignCreateInput = {
  /**
   * if the campaignId is not given, we will create a new campaign and publish
   * the campaign at the same time; otherwise, we will update the campaign by
   * id and publish it
   */
  campaignId?: InputMaybe<Scalars['ID']>;
  campaignPackageId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  offerDiscountInCents: Scalars['Int'];
  offerInventoryCount?: InputMaybe<Scalars['Int']>;
  budgetInCents: Scalars['Int'];
  startedAt: Scalars['DateTimeInput'];
  endedAt: Scalars['DateTimeInput'];
  creativeOverwrite?: InputMaybe<ProviderMarkeringCampaignCreateCreativeOverwriteInput>;
};

export type ProviderMarketingCampaignCreatePayload =
  | ProviderMarketingCampaignCreateSuccess
  | ProviderMarketingCampaignCreateError;

export type ProviderMarketingCampaignCreateSuccess = {
  __typename?: 'ProviderMarketingCampaignCreateSuccess';
  campaign: ProviderMarketingCampaign;
};

export type ProviderMarketingCampaignCreative =
  ProviderMarketingCampaignAdvertisingCreative;

export type ProviderMarketingCampaignDraftCreateError = {
  __typename?: 'ProviderMarketingCampaignDraftCreateError';
  error: Error;
};

export type ProviderMarketingCampaignDraftCreateInput = {
  campaignPackageId: Scalars['ID'];
  offerDiscountInCents: Scalars['Int'];
  offerInventoryCount?: InputMaybe<Scalars['Int']>;
  budgetInCents: Scalars['Int'];
  startedAt: Scalars['DateTimeInput'];
  endedAt: Scalars['DateTimeInput'];
};

export type ProviderMarketingCampaignDraftCreatePayload =
  | ProviderMarketingCampaignDraftCreateSuccess
  | ProviderMarketingCampaignDraftCreateError;

export type ProviderMarketingCampaignDraftCreateSuccess = {
  __typename?: 'ProviderMarketingCampaignDraftCreateSuccess';
  campaign: ProviderMarketingCampaign;
};

export type ProviderMarketingCampaignDuration = {
  __typename?: 'ProviderMarketingCampaignDuration';
  startedAt: DateTime;
  stoppedAt?: Maybe<DateTime>;
  endedAt: DateTime;
};

export type ProviderMarketingCampaignGeneratePreviewFailure = {
  __typename?: 'ProviderMarketingCampaignGeneratePreviewFailure';
  error: Error;
};

export type ProviderMarketingCampaignGeneratePreviewInput = {
  campaignPackageId: Scalars['ID'];
  offerAmountInCents?: InputMaybe<Scalars['Int']>;
  platform?: InputMaybe<Provider_Marketing_Campaign_Platform>;
  type?: InputMaybe<Provider_Marketing_Campaign_Package_Previewable_Content_Type>;
  imageVariantId?: InputMaybe<Scalars['ID']>;
};

export type ProviderMarketingCampaignGeneratePreviewPayload =
  | ProviderMarketingCampaignGeneratePreviewSuccess
  | ProviderMarketingCampaignGeneratePreviewFailure;

export type ProviderMarketingCampaignGeneratePreviewSuccess = {
  __typename?: 'ProviderMarketingCampaignGeneratePreviewSuccess';
  preview: ProviderMarketingCampaignPreview;
};

export type ProviderMarketingCampaignOffer =
  ProviderMarketingCampaignOptInOffer;

export type ProviderMarketingCampaignOptInOffer = {
  __typename?: 'ProviderMarketingCampaignOptInOffer';
  id: Scalars['ID'];
  name: Scalars['String'];
  productsConnection?: Maybe<ProviderMarketingCampaignOptInOfferProductsConnection>;
  discountValueInCents: Scalars['Int'];
  quantityTotal: Scalars['Int'];
  expireInDays: Scalars['Int'];
  terms: Scalars['String'];
};

export type ProviderMarketingCampaignOptInOfferProductsConnection = {
  __typename?: 'ProviderMarketingCampaignOptInOfferProductsConnection';
  nodes: Array<ProductVariant>;
};

export type ProviderMarketingCampaignPackage = Taggable & {
  __typename?: 'ProviderMarketingCampaignPackage';
  id: Scalars['ID'];
  category: ProviderMarketingCampaignPackageCategory;
  targetAudience: ProviderMarketingCampaignPackageAudience;
  channels: Array<Provider_Marketing_Campaign_Channel>;
  name: Scalars['String'];
  imageUrl: Scalars['URL'];
  description: Scalars['String'];
  valueIncents: Scalars['Int'];
  productsConnection?: Maybe<ProviderMarketingCampaignPackageProductsConnection>;
  previewableContentTypes: Array<Provider_Marketing_Campaign_Package_Previewable_Content_Type>;
  imageVariants: ProviderMarketingCampaignPackageImageVariantsConnection;
  tags: TagsConnection;
  relatedPackages: ProviderMarketingCampaignPackageRelatedPackagesConnection;
  validOfferAmountInCents: Array<Scalars['Int']>;
};

export type ProviderMarketingCampaignPackageTagsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ProviderMarketingCampaignPackageAudience = {
  __typename?: 'ProviderMarketingCampaignPackageAudience';
  gender: Ad_Audience_Gender;
};

export type ProviderMarketingCampaignPackageCategoriesConnection = {
  __typename?: 'ProviderMarketingCampaignPackageCategoriesConnection';
  nodes: Array<ProviderMarketingCampaignPackageCategory>;
};

export type ProviderMarketingCampaignPackageCategory = {
  __typename?: 'ProviderMarketingCampaignPackageCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  brand?: Maybe<Brand>;
  packages: ProviderMarketingCampaignPackageCategoryPackagesConnection;
};

export type ProviderMarketingCampaignPackageCategoryPackagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort: Array<ProviderMarketingCampaignPackageSort>;
};

export type ProviderMarketingCampaignPackageCategoryPackagesConnection = {
  __typename?: 'ProviderMarketingCampaignPackageCategoryPackagesConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<ProviderMarketingCampaignPackage>;
};

export type ProviderMarketingCampaignPackageImageVariant = {
  __typename?: 'ProviderMarketingCampaignPackageImageVariant';
  id: Scalars['ID'];
  image: Image;
};

export type ProviderMarketingCampaignPackageImageVariantsConnection = {
  __typename?: 'ProviderMarketingCampaignPackageImageVariantsConnection';
  edges: Array<ProviderMarketingCampaignPackageImageVariantsEdge>;
};

export type ProviderMarketingCampaignPackageImageVariantsEdge = {
  __typename?: 'ProviderMarketingCampaignPackageImageVariantsEdge';
  node: ProviderMarketingCampaignPackageImageVariant;
};

export type ProviderMarketingCampaignPackageProductsConnection = {
  __typename?: 'ProviderMarketingCampaignPackageProductsConnection';
  nodes: Array<ProductVariant>;
};

export type ProviderMarketingCampaignPackageRelatedPackagesConnection = {
  __typename?: 'ProviderMarketingCampaignPackageRelatedPackagesConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ProviderMarketingCamapignPackageRelatedPackagesEdge>;
};

export type ProviderMarketingCampaignPackageSort = {
  column?: Provider_Marketing_Campaign_Package_Sort_Column;
  order?: Provider_Marketing_Campaign_Package_Sort_Order;
};

export type ProviderMarketingCampaignPackagesFilter = {
  categoryIds: Array<Scalars['ID']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ProviderMarketingCampaignPauseError = {
  __typename?: 'ProviderMarketingCampaignPauseError';
  error: Error;
};

export type ProviderMarketingCampaignPausePayload =
  | ProviderMarketingCampaignPauseSuccess
  | ProviderMarketingCampaignPauseError;

export type ProviderMarketingCampaignPauseSuccess = {
  __typename?: 'ProviderMarketingCampaignPauseSuccess';
  /** @deprecated Please use campaign */
  post: AdvertisingPost;
  campaign: ProviderMarketingCampaign;
};

export type ProviderMarketingCampaignPerformance = AdvertisingPerformanceInfo;

export type ProviderMarketingCampaignPreview = {
  __typename?: 'ProviderMarketingCampaignPreview';
  url: Scalars['URL'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  htmlTagType: Provider_Marketing_Campaign_Package_Previewable_Content_Html_Tag_Type;
};

export type ProviderMarketingCampaignResumeError = {
  __typename?: 'ProviderMarketingCampaignResumeError';
  error: Error;
};

export type ProviderMarketingCampaignResumePayload =
  | ProviderMarketingCampaignResumeSuccess
  | ProviderMarketingCampaignResumeError;

export type ProviderMarketingCampaignResumeSuccess = {
  __typename?: 'ProviderMarketingCampaignResumeSuccess';
  /** @deprecated Please use campaign */
  post: AdvertisingPost;
  campaign: ProviderMarketingCampaign;
};

export type ProviderMarketingCampaignStopError = {
  __typename?: 'ProviderMarketingCampaignStopError';
  error: Error;
};

export type ProviderMarketingCampaignStopPayload =
  | ProviderMarketingCampaignStopSuccess
  | ProviderMarketingCampaignStopError;

export type ProviderMarketingCampaignStopSuccess = {
  __typename?: 'ProviderMarketingCampaignStopSuccess';
  /** @deprecated Please use campaign */
  post: AdvertisingPost;
  campaign: ProviderMarketingCampaign;
};

export type ProviderMarketingCampaignSubmitSurveyFailure = {
  __typename?: 'ProviderMarketingCampaignSubmitSurveyFailure';
  error: Error;
};

export type ProviderMarketingCampaignSubmitSurveyInput = {
  campaignId: Scalars['ID'];
  expectedRevenueInCents: Scalars['Int'];
};

export type ProviderMarketingCampaignSubmitSurveyPayload =
  | ProviderMarketingCampaignSubmitSurveySuccess
  | ProviderMarketingCampaignSubmitSurveyFailure;

export type ProviderMarketingCampaignSubmitSurveySuccess = {
  __typename?: 'ProviderMarketingCampaignSubmitSurveySuccess';
  campaign: ProviderMarketingCampaign;
};

export type ProviderMarketingCampaignSurvey = {
  __typename?: 'ProviderMarketingCampaignSurvey';
  expectedRevenueInCents: Scalars['Int'];
};

export type ProviderMarketingCampaignsConnection = {
  __typename?: 'ProviderMarketingCampaignsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<ProviderMarketingCampaign>;
};

export type ProviderMarketingCampaignsFilter = {
  status?: InputMaybe<Provider_Marketing_Campaign_Filter_Status>;
  hasLeads?: InputMaybe<Scalars['Boolean']>;
};

export type ProviderMarketingCampaignsSort = {
  column?: InputMaybe<Provider_Marketing_Campaigns_Sort_Column>;
  order?: InputMaybe<Provider_Marketing_Campaigns_Sort_Order>;
};

export type ProviderMarketingEmailOptOutError = {
  __typename?: 'ProviderMarketingEmailOptOutError';
  error: Error;
};

export type ProviderMarketingEmailOptOutInput = {
  token: Scalars['String'];
};

export type ProviderMarketingEmailOptOutPayload =
  | ProviderMarketingEmailOptOutSuccess
  | ProviderMarketingEmailOptOutError;

export type ProviderMarketingEmailOptOutSuccess = {
  __typename?: 'ProviderMarketingEmailOptOutSuccess';
  practice?: Maybe<Practice>;
};

export type ProviderMetricResult = {
  __typename?: 'ProviderMetricResult';
  id: Scalars['ID'];
  key: Scalars['String'];
  unitsAchieved: Scalars['Float'];
  eligibilityCode: Provider_Rewards_Eligibility_Code;
  tiers: Array<ProviderMetricTier>;
};

export type ProviderMetricTier = {
  __typename?: 'ProviderMetricTier';
  metricValue: Scalars['Float'];
  rewardValueInCents: Scalars['Int'];
};

export type ProviderOptInMarketingEvent = {
  __typename?: 'ProviderOptInMarketingEvent';
  id: Scalars['ID'];
  title: Scalars['String'];
  providerIsEnrolled: Scalars['Boolean'];
};

export type ProviderOptInMarketingEventInput = {
  marketingEventId: Scalars['ID'];
  providerIsEnrolled: Scalars['Boolean'];
};

export type ProviderOrganization = {
  __typename?: 'ProviderOrganization';
  id: Scalars['ID'];
  users: ProviderOrganizationUsersConnection;
  locationCatalog?: Maybe<LocationCatalog>;
  checkoutOrders?: Maybe<ProviderOrganizationCheckoutOrdersConnection>;
  leadsHub: LeadsHub;
  patientFinancingProfile?: Maybe<PatientFinancingProfile>;
  paymentsWallet?: Maybe<PaymentsWallet>;
  pointOfSalePaymentProfile?: Maybe<PointOfSalePaymentProfile>;
  platformSubscription?: Maybe<PlatformSubscription>;
  subscriptionsProfile?: Maybe<SubscriptionsProfile>;
  pricingPurchasingProfile?: Maybe<ProviderPricingPurchasingProfile>;
  draftPublicProfile?: Maybe<DraftPublicProfile>;
  publicProfile?: Maybe<LocationPublicProfile>;
  type: Provider_Organization_Type;
  shortId?: Maybe<Scalars['String']>;
  typeV2: Provider_Organization_Type_V2;
  /** @deprecated use less ambiguous allerganAccountNumber instead */
  accountNumber?: Maybe<Scalars['String']>;
  allerganAccountNumber?: Maybe<Scalars['String']>;
  alleEligibility?: Maybe<Provider_Organization_Alle_Eligibility>;
  abbvieAccountNumber?: Maybe<Scalars['String']>;
  /**
   * This name defaults to a custom name defined by the user, or falls back to the sapAccountName
   * if no custom name was saved within the A4B application.
   */
  accountName?: Maybe<Scalars['String']>;
  /**
   * This name always returns the original account name as saved in SAP and should therefore
   * always be defined, unless the caller doesn't have access to view this org.
   */
  sapAccountName?: Maybe<Scalars['String']>;
  /**
   * This name utilizes the providers public profile name, if not present it defaults to the SAP name.
   * Should always be defined, unless the caller has no authorization.
   */
  publicAccountName?: Maybe<Scalars['String']>;
  accountStatus?: Maybe<Account_Status>;
  accountStatusHistory?: Maybe<ProviderOrganizationAccountStatusHistoryConnection>;
  address?: Maybe<Address>;
  /** @deprecated use termsAndConditionsStatus instead */
  termsAndConditionsAgreementStatus?: Maybe<Provider_Organization_Terms_And_Conditions_Status>;
  termsAndConditionsStatus?: Maybe<ProviderOrganizationTermsAndConditionsStatus>;
  teamSetupCompletionStatus?: Maybe<Provider_Organization_Team_Setup_Completion_Status>;
  wave?: Maybe<ProviderOrganizationWave>;
  children: ProviderOrganizationChildrenConnection;
  parent?: Maybe<ProviderOrganization>;
  practice?: Maybe<Practice>;
  migratedAt?: Maybe<DateTime>;
  tags?: Maybe<Array<Provider_Organization_Tag>>;
  isStrategicOrContractAccount?: Maybe<Scalars['Boolean']>;
  a4bMigrationProfile?: Maybe<ProviderOrganizationA4BMigrationProfile>;
  wasAccountStatusEverActive?: Maybe<Scalars['Boolean']>;
  canLogTransactions?: Maybe<Scalars['Boolean']>;
  isInvalidClone?: Maybe<Scalars['Boolean']>;
  locationCloseWorkflow?: Maybe<LocationCloseWorkflow>;
  source?: Maybe<Provider_Organization_Source>;
  sapOnboardingStatus?: Maybe<Sap_Onboarding_Status>;
  providerRewardsMetrics?: Maybe<ProviderRewardsMetrics>;
  productReimbursementReport: ProviderOrganizationProductReimbursementReportConnection;
  pricingProfile?: Maybe<ProviderPricingProfile>;
  taskStatuses: Array<Maybe<TaskStatus>>;
};

export type ProviderOrganizationUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ProviderOrganizationUsersFilter>;
};

export type ProviderOrganizationCheckoutOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProviderOrganizationCheckoutOrdersSort>;
  filter?: InputMaybe<ProviderOrganizationCheckoutOrdersFilter>;
};

export type ProviderOrganizationAccountStatusHistoryArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ProviderOrganizationChildrenArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ProviderLocationsFilter>;
};

export type ProviderOrganizationProductReimbursementReportArgs = {
  filter: ProductReimbursementReportItemsFilter;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ProviderOrganizationTaskStatusesArgs = {
  slugs: Array<Scalars['String']>;
};

export type ProviderOrganizationA4BMigrationProfile = {
  __typename?: 'ProviderOrganizationA4BMigrationProfile';
  providerOrganizationId: Scalars['ID'];
  initializedWithSuggestedUsers?: Maybe<Scalars['Boolean']>;
  isMigrated?: Maybe<Scalars['Boolean']>;
  locationsMigratedCount?: Maybe<Scalars['Int']>;
};

export type ProviderOrganizationAcceptAllerganStoreTermsAndConditionsFailure = {
  __typename?: 'ProviderOrganizationAcceptAllerganStoreTermsAndConditionsFailure';
  error: Error;
};

export type ProviderOrganizationAcceptAllerganStoreTermsAndConditionsInput = {
  id: Scalars['ID'];
  versionId: Scalars['ID'];
};

export type ProviderOrganizationAcceptAllerganStoreTermsAndConditionsPayload =
  | ProviderOrganizationAcceptAllerganStoreTermsAndConditionsSuccess
  | ProviderOrganizationAcceptAllerganStoreTermsAndConditionsFailure;

export type ProviderOrganizationAcceptAllerganStoreTermsAndConditionsSuccess = {
  __typename?: 'ProviderOrganizationAcceptAllerganStoreTermsAndConditionsSuccess';
  pricingProfile: ProviderPricingProfile;
};

export type ProviderOrganizationAcceptPricingTermsAndConditionsFailure = {
  __typename?: 'ProviderOrganizationAcceptPricingTermsAndConditionsFailure';
  error: Error;
};

export type ProviderOrganizationAcceptPricingTermsAndConditionsInput = {
  id: Scalars['ID'];
  versionId: Scalars['ID'];
};

export type ProviderOrganizationAcceptPricingTermsAndConditionsPayload =
  | ProviderOrganizationAcceptPricingTermsAndConditionsSuccess
  | ProviderOrganizationAcceptPricingTermsAndConditionsFailure;

export type ProviderOrganizationAcceptPricingTermsAndConditionsSuccess = {
  __typename?: 'ProviderOrganizationAcceptPricingTermsAndConditionsSuccess';
  pricingProfile: ProviderPricingProfile;
};

export type ProviderOrganizationAccountStatus = {
  __typename?: 'ProviderOrganizationAccountStatus';
  id: Scalars['ID'];
  accountStatus: Account_Status;
  reason?: Maybe<Scalars['String']>;
  createdAt: DateTime;
};

export type ProviderOrganizationAccountStatusHistoryConnection = {
  __typename?: 'ProviderOrganizationAccountStatusHistoryConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ProviderOrganizationAccountStatusHistoryEdge>;
};

export type ProviderOrganizationAccountStatusHistoryEdge = {
  __typename?: 'ProviderOrganizationAccountStatusHistoryEdge';
  node: ProviderOrganizationAccountStatus;
};

export type ProviderOrganizationCheckoutOrdersConnection = {
  __typename?: 'ProviderOrganizationCheckoutOrdersConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<CheckoutOrder>;
};

export type ProviderOrganizationCheckoutOrdersFilter = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type ProviderOrganizationCheckoutOrdersSort = {
  column: Provider_Organization_Checkout_Orders_Sort_Column;
  order: Provider_Organization_Checkout_Orders_Sort_Order;
};

export type ProviderOrganizationChildrenConnection = {
  __typename?: 'ProviderOrganizationChildrenConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ProviderOrganizationChildrenEdge>;
};

export type ProviderOrganizationChildrenEdge = {
  __typename?: 'ProviderOrganizationChildrenEdge';
  node: ProviderOrganization;
};

export type ProviderOrganizationProductReimbursementReportConnection = {
  __typename?: 'ProviderOrganizationProductReimbursementReportConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<ProductReimbursementReportRow>;
};

export type ProviderOrganizationSearchResultsConnection = {
  __typename?: 'ProviderOrganizationSearchResultsConnection';
  nodes: Array<ProviderOrganization>;
  offsetPageInfo: OffsetPageInfo;
};

export type ProviderOrganizationTeamSetupCompleteFailure = {
  __typename?: 'ProviderOrganizationTeamSetupCompleteFailure';
  error: Error;
};

export type ProviderOrganizationTeamSetupCompletePayload =
  | ProviderOrganizationTeamSetupCompleteSuccess
  | ProviderOrganizationTeamSetupCompleteFailure;

export type ProviderOrganizationTeamSetupCompleteSuccess = {
  __typename?: 'ProviderOrganizationTeamSetupCompleteSuccess';
  providerOrganization: ProviderOrganization;
};

export type ProviderOrganizationTermsAndConditionsStatus = {
  __typename?: 'ProviderOrganizationTermsAndConditionsStatus';
  status: Provider_Organization_Terms_And_Conditions_Status;
  expiresAt?: Maybe<DateTime>;
};

export type ProviderOrganizationUpdateCustomNameFailure = {
  __typename?: 'ProviderOrganizationUpdateCustomNameFailure';
  error: Error;
};

export type ProviderOrganizationUpdateCustomNameInput = {
  organizationId: Scalars['ID'];
  newCustomName: Scalars['String'];
};

export type ProviderOrganizationUpdateCustomNamePayload =
  | ProviderOrganizationUpdateCustomNameSuccess
  | ProviderOrganizationUpdateCustomNameFailure;

export type ProviderOrganizationUpdateCustomNameSuccess = {
  __typename?: 'ProviderOrganizationUpdateCustomNameSuccess';
  providerOrganization: ProviderOrganization;
};

export type ProviderOrganizationUsersConnection = {
  __typename?: 'ProviderOrganizationUsersConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ProviderOrganizationUsersEdge>;
};

export type ProviderOrganizationUsersEdge = {
  __typename?: 'ProviderOrganizationUsersEdge';
  node: ProviderUser;
};

export type ProviderOrganizationUsersFilter = {
  keyword?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Provider_User_Role>>;
};

export type ProviderOrganizationWave = {
  __typename?: 'ProviderOrganizationWave';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ProviderPricingProfile = {
  __typename?: 'ProviderPricingProfile';
  id: Scalars['ID'];
  providerOrganization: ProviderOrganization;
  enrollmentStatus: Provider_Pricing_Enrollment_Status;
  pricingProgramEnrollmentStatus: Provider_Pricing_Program_Enrollment_Status;
  invite?: Maybe<AllerganStorePricingInvite>;
  termsAcceptance?: Maybe<AllerganStorePricingTermsAcceptance>;
  retentionRate?: Maybe<AllerganStorePricingRetentionRate>;
  retentionRateDetail?: Maybe<AllerganStorePricingRetentionRateDetail>;
  retentionRateSnapshots: ProviderPricingProfileRetentionSnapshotsConnection;
  salesVolume?: Maybe<AllerganStorePricingSalesVolumeFigure>;
  salesVolumeSnapshots: ProviderPricingProfileVolumeSnapshotsConnection;
  savings?: Maybe<AllerganStoreSavings>;
  tier?: Maybe<AllerganStorePricingTier>;
  tierUpgradeReason?: Maybe<AllerganStorePricingTierUpgradeReason>;
};

export type ProviderPricingProfileRetentionRateSnapshotsArgs = {
  startDate: Scalars['DateInput'];
  endDate: Scalars['DateInput'];
  duration: Allergan_Store_Pricing_Duration;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ProviderPricingProfileSalesVolumeSnapshotsArgs = {
  startDate: Scalars['DateInput'];
  endDate: Scalars['DateInput'];
  duration: Allergan_Store_Pricing_Duration;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ProviderPricingProfileSavingsArgs = {
  startDate: Scalars['DateInput'];
  endDate: Scalars['DateInput'];
};

export type ProviderPricingProfileRetentionSnapshotsConnection = {
  __typename?: 'ProviderPricingProfileRetentionSnapshotsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<AllerganStorePricingRetentionSnapshot>;
};

export type ProviderPricingProfileVolumeSnapshotsConnection = {
  __typename?: 'ProviderPricingProfileVolumeSnapshotsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<AllerganStorePricingVolumeSnapshot>;
};

export type ProviderPricingPurchasingProfile = {
  __typename?: 'ProviderPricingPurchasingProfile';
  enrollmentStatus: Provider_Pricing_Purchasing_Enrollment_Status;
  savedCreditCards: ProviderPricingPurchasingProfileStoreCreditCardConnection;
  cartByProviderUserId?: Maybe<AllerganStoreShoppingCart>;
  orders: ProviderPricingPurchasingProfileOrdersConnection;
  invoices: ProviderPricingPurchasingProfileInvoicesConnection;
  creditMemos: ProviderPricingPurchasingProfileMemosConnection;
  availablePaymentOptions: Array<Allergan_Store_Payment_Option>;
  hasPurchased: Scalars['Boolean'];
  creditTermsDetails?: Maybe<AllerganStoreCreditTermsDetails>;
};

export type ProviderPricingPurchasingProfileCartByProviderUserIdArgs = {
  providerUserId: Scalars['ID'];
};

export type ProviderPricingPurchasingProfileOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AllerganStoreOrdersFilter>;
  sort?: InputMaybe<Array<AllerganStoreOrdersSort>>;
};

export type ProviderPricingPurchasingProfileInvoicesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AllerganStoreInvoicesFilter>;
  sort?: InputMaybe<Array<AllerganStoreInvoicesSort>>;
};

export type ProviderPricingPurchasingProfileCreditMemosArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AllerganStoreInvoiceMemoFilter>;
  sort?: InputMaybe<Array<AllerganStoreInvoiceMemosSort>>;
};

export type ProviderPricingPurchasingProfileInvoicesConnection = {
  __typename?: 'ProviderPricingPurchasingProfileInvoicesConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ProviderPricingPurchasingProfileInvoicesEdge>;
};

export type ProviderPricingPurchasingProfileInvoicesEdge = {
  __typename?: 'ProviderPricingPurchasingProfileInvoicesEdge';
  node: AllerganStoreInvoice;
};

export type ProviderPricingPurchasingProfileMemosConnection = {
  __typename?: 'ProviderPricingPurchasingProfileMemosConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ProviderPricingPurchasingProfileMemosEdge>;
};

export type ProviderPricingPurchasingProfileMemosEdge = {
  __typename?: 'ProviderPricingPurchasingProfileMemosEdge';
  node: AllerganStoreInvoiceMemo;
};

export type ProviderPricingPurchasingProfileOrderEdge = {
  __typename?: 'ProviderPricingPurchasingProfileOrderEdge';
  node: AllerganStoreOrder;
};

export type ProviderPricingPurchasingProfileOrdersConnection = {
  __typename?: 'ProviderPricingPurchasingProfileOrdersConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ProviderPricingPurchasingProfileOrderEdge>;
};

export type ProviderPricingPurchasingProfileStoreCreditCardConnection = {
  __typename?: 'ProviderPricingPurchasingProfileStoreCreditCardConnection';
  edges: Array<ProviderPricingPurchasingProfileStoreCreditCardEdge>;
};

export type ProviderPricingPurchasingProfileStoreCreditCardEdge = {
  __typename?: 'ProviderPricingPurchasingProfileStoreCreditCardEdge';
  isDefault: Scalars['Boolean'];
  node: AllerganStoreCreditCard;
};

export type ProviderProductInput = {
  productId: Scalars['ID'];
  showPrice: Scalars['Boolean'];
};

export type ProviderProductsConnection = {
  __typename?: 'ProviderProductsConnection';
  nodes: Array<ProviderProfileProduct>;
};

export type ProviderProfileProduct = {
  __typename?: 'ProviderProfileProduct';
  brandName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  maxPriceInCents?: Maybe<Scalars['Int']>;
  minPriceInCents?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  priceAnnotation?: Maybe<Scalars['String']>;
  profileDisplayName?: Maybe<Scalars['String']>;
  showPrice: Scalars['Boolean'];
  slugName: Scalars['String'];
  treatmentAreas?: Maybe<ProductTreatmentAreasConnection>;
  type: Product_Type;
};

export type ProviderProfileSlug = {
  __typename?: 'ProviderProfileSlug';
  profileSlug: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProviderProfileTreatmentArea = {
  __typename?: 'ProviderProfileTreatmentArea';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
};

export type ProviderPublicMarketingCampaign = {
  __typename?: 'ProviderPublicMarketingCampaign';
  id: Scalars['ID'];
};

export type ProviderReport = {
  __typename?: 'ProviderReport';
  id: Scalars['ID'];
  name: Scalars['String'];
  category?: Maybe<Provider_Report_Category>;
  columnNames: Array<Scalars['String']>;
  filters?: Maybe<Array<ProviderReportFilter>>;
  aggregates?: Maybe<Array<ProviderReportAggregate>>;
};

export type ProviderReportAggregate = {
  __typename?: 'ProviderReportAggregate';
  key: Scalars['String'];
  valueType?: Maybe<Provider_Report_Value_Type>;
};

export type ProviderReportDataResult = {
  __typename?: 'ProviderReportDataResult';
  reportId: Scalars['ID'];
  headerRow: ProviderReportResultRow;
  filters?: Maybe<Array<ProviderReportResultFilter>>;
  aggregates?: Maybe<Array<ProviderReportResultAggregate>>;
  rows: ProviderReportResultRowsConnection;
};

export type ProviderReportDataResultAggregatesArgs = {
  timeSeriesGranularity?: InputMaybe<Provider_Report_Aggregate_Time_Series_Granularity>;
};

export type ProviderReportDataResultRowsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProviderReportResultSortInput>;
};

export type ProviderReportExportResult = {
  __typename?: 'ProviderReportExportResult';
  uri: Scalars['String'];
};

export type ProviderReportFetchDataResult = {
  __typename?: 'ProviderReportFetchDataResult';
  report: ProviderReport;
  result: ProviderReportDataResult;
};

export type ProviderReportFileResult = {
  __typename?: 'ProviderReportFileResult';
  reportId: Scalars['ID'];
  filters?: Maybe<Array<ProviderReportResultFilter>>;
  export?: Maybe<ProviderReportExportResult>;
};

export type ProviderReportFileResultExportArgs = {
  format?: InputMaybe<Provider_Report_Export_Format>;
  sort?: InputMaybe<ProviderReportResultSortInput>;
};

export type ProviderReportFilter = {
  __typename?: 'ProviderReportFilter';
  key: Scalars['String'];
  valueType?: Maybe<Provider_Report_Value_Type>;
};

export type ProviderReportGenerateFileResultInput = {
  reportId: Scalars['ID'];
  providerOrganizationId: Scalars['ID'];
  filters?: InputMaybe<Array<ProviderReportResultFilterInput>>;
};

export type ProviderReportGenerateFileResultPayload =
  | ProviderReportGenerateFileResultSuccess
  | ProviderReportResultFailure;

export type ProviderReportGenerateFileResultSuccess = {
  __typename?: 'ProviderReportGenerateFileResultSuccess';
  report: ProviderReport;
  result: ProviderReportFileResult;
};

export type ProviderReportGenerateResultError = {
  __typename?: 'ProviderReportGenerateResultError';
  error: Error;
};

export type ProviderReportGenerateResultInput = {
  id: Scalars['ID'];
  filters?: InputMaybe<Array<ProviderReportResultFilterInput>>;
  aggregateNames?: InputMaybe<Array<Scalars['String']>>;
};

export type ProviderReportGenerateResultPayload =
  | ProviderReportGenerateResultSuccess
  | ProviderReportGenerateResultError;

export type ProviderReportGenerateResultSuccess = {
  __typename?: 'ProviderReportGenerateResultSuccess';
  report: ProviderReport;
  result: ProviderReportResult;
};

export type ProviderReportResult = {
  __typename?: 'ProviderReportResult';
  id: Scalars['ID'];
  headerRow: ProviderReportResultRow;
  filters?: Maybe<Array<ProviderReportResultFilter>>;
  aggregates?: Maybe<Array<ProviderReportResultAggregate>>;
  rows: ProviderReportResultRowsConnection;
  export?: Maybe<ProviderReportExportResult>;
};

export type ProviderReportResultAggregatesArgs = {
  timeSeriesGranularity?: InputMaybe<Provider_Report_Aggregate_Time_Series_Granularity>;
};

export type ProviderReportResultRowsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProviderReportResultSortInput>;
};

export type ProviderReportResultExportArgs = {
  format?: InputMaybe<Provider_Report_Export_Format>;
  sort?: InputMaybe<ProviderReportResultSortInput>;
};

export type ProviderReportResultAggregate =
  | ProviderReportResultAggregateInString
  | ProviderReportResultAggregateInInt
  | ProviderReportResultAggregateTotalRewards
  | ProviderReportResultAggregateNumberOfPatientsTimeSeries
  | ProviderReportResultAggregatePatientTreatmentStatus
  | ProviderReportResultAggregateTierRewardEligibility;

export type ProviderReportResultAggregateInInt = {
  __typename?: 'ProviderReportResultAggregateInInt';
  key: Scalars['String'];
  value: Scalars['Int'];
};

export type ProviderReportResultAggregateInString = {
  __typename?: 'ProviderReportResultAggregateInString';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ProviderReportResultAggregateInTimeSeries = {
  key: Scalars['String'];
  dataSets: Array<ProviderReportResultAggregateInTimeSeriesDataSet>;
};

export type ProviderReportResultAggregateInTimeSeriesDataPoint = {
  __typename?: 'ProviderReportResultAggregateInTimeSeriesDataPoint';
  label: Scalars['String'];
  value: Scalars['Int'];
  dateRange: DateRange;
};

export type ProviderReportResultAggregateInTimeSeriesDataSet = {
  __typename?: 'ProviderReportResultAggregateInTimeSeriesDataSet';
  label: Scalars['String'];
  dataPoints: Array<ProviderReportResultAggregateInTimeSeriesDataPoint>;
};

export type ProviderReportResultAggregateInput = {
  key: Scalars['String'];
};

export type ProviderReportResultAggregateNumberOfPatientsTimeSeries =
  ProviderReportResultAggregateInTimeSeries & {
    __typename?: 'ProviderReportResultAggregateNumberOfPatientsTimeSeries';
    key: Scalars['String'];
    dataSets: Array<ProviderReportResultAggregateInTimeSeriesDataSet>;
  };

export type ProviderReportResultAggregatePatientTreatmentStatus = {
  __typename?: 'ProviderReportResultAggregatePatientTreatmentStatus';
  key: Scalars['String'];
  totalOnTrackPatients: Scalars['Int'];
  totalLapsingPatients: Scalars['Int'];
  totalLapsedPatients: Scalars['Int'];
};

export type ProviderReportResultAggregateTierRewardEligibility = {
  __typename?: 'ProviderReportResultAggregateTierRewardEligibility';
  key: Scalars['String'];
  pointsDataSets?: Maybe<
    Array<
      Maybe<ProviderReportResultAggregateTierRewardEligibilityPointsDataSet>
    >
  >;
};

export type ProviderReportResultAggregateTierRewardEligibilityPointsDataSet = {
  __typename?: 'ProviderReportResultAggregateTierRewardEligibilityPointsDataSet';
  points: Scalars['Int'];
  numOfPatientsEligible: Scalars['Int'];
  productsDataSets?: Maybe<
    Array<
      Maybe<ProviderReportResultAggregateTierRewardEligibilityProductsDataSet>
    >
  >;
};

export type ProviderReportResultAggregateTierRewardEligibilityProductsDataSet =
  {
    __typename?: 'ProviderReportResultAggregateTierRewardEligibilityProductsDataSet';
    skuCode: Scalars['String'];
    brand: Scalars['String'];
    variation: Scalars['String'];
  };

export type ProviderReportResultAggregateTotalRewards = {
  __typename?: 'ProviderReportResultAggregateTotalRewards';
  key: Scalars['String'];
  totalPointsInCents: Scalars['Int'];
  totalPromotionsInCents: Scalars['Int'];
  totalGiftcardsInCents: Scalars['Int'];
  totalRewardsInCents: Scalars['Int'];
  totalConsumers: Scalars['Int'];
};

export type ProviderReportResultFailure = {
  __typename?: 'ProviderReportResultFailure';
  error: Error;
};

export type ProviderReportResultFilter =
  | ProviderReportResultFilterInString
  | ProviderReportResultFilterInInt;

export type ProviderReportResultFilterInInt = {
  __typename?: 'ProviderReportResultFilterInInt';
  key: Scalars['String'];
  value: Scalars['Int'];
};

export type ProviderReportResultFilterInString = {
  __typename?: 'ProviderReportResultFilterInString';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ProviderReportResultFilterInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ProviderReportResultRow = {
  __typename?: 'ProviderReportResultRow';
  cells?: Maybe<Array<Scalars['String']>>;
};

export type ProviderReportResultRowsConnection = {
  __typename?: 'ProviderReportResultRowsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes?: Maybe<Array<ProviderReportResultRow>>;
};

export type ProviderReportResultSortInput = {
  column: Scalars['String'];
  order: Provider_Report_Sort_Order;
};

export type ProviderRewardsEligibility = {
  __typename?: 'ProviderRewardsEligibility';
  code: Provider_Rewards_Eligibility_Code;
  numUniquePatientTransactions: Scalars['Int'];
  minNumUniquePatientTransactions: Scalars['Int'];
};

export type ProviderRewardsMetrics = {
  __typename?: 'ProviderRewardsMetrics';
  metrics: Array<ProviderMetricResult>;
  daysRemaining: Scalars['Int'];
  rewards: ProviderRewardsOfferInfo;
  eligibility: ProviderRewardsEligibility;
};

export type ProviderRewardsOfferInfo = {
  __typename?: 'ProviderRewardsOfferInfo';
  numOffers: Scalars['Int'];
  individualOfferValueInCents: Scalars['Int'];
  distributionDayOfMonth: Scalars['Int'];
  totalRewardValueInCents: Scalars['Int'];
};

export type ProviderSearchBusinessHoursFilters = {
  isOpenPast5pm?: InputMaybe<Scalars['Boolean']>;
  isOpenOnSaturday?: InputMaybe<Scalars['Boolean']>;
  isOpenOnSunday?: InputMaybe<Scalars['Boolean']>;
};

export type ProviderSearchCity = {
  __typename?: 'ProviderSearchCity';
  name: Scalars['String'];
  numberOfProviders: Scalars['Int'];
  state: Us_State;
  treatmentAreas?: Maybe<CityProductTreatmentAreasConnection>;
  products?: Maybe<CityProviderProductsConnection>;
};

export type ProviderSearchConnection = {
  __typename?: 'ProviderSearchConnection';
  edges: Array<ProviderSearchEdge>;
  offsetPageInfo: OffsetPageInfo;
  filterMatchCounts: Array<ProviderSearchMatchCount>;
};

export type ProviderSearchEdge = {
  __typename?: 'ProviderSearchEdge';
  displayDistance: Scalars['String'];
  node: SearchProvider;
};

export type ProviderSearchFilters = {
  proximity: ProviderSearchProximityFilter;
  profile?: InputMaybe<ProviderSearchProfileFilters>;
  hours?: InputMaybe<ProviderSearchBusinessHoursFilters>;
  keyword?: InputMaybe<Scalars['String']>;
};

export type ProviderSearchIndicator = {
  __typename?: 'ProviderSearchIndicator';
  label: Scalars['String'];
  slug: Scalars['String'];
};

export type ProviderSearchIndicatorsConnection = {
  __typename?: 'ProviderSearchIndicatorsConnection';
  nodes: Array<ProviderSearchIndicator>;
};

export type ProviderSearchInput = {
  filters: ProviderSearchFilters;
  sort?: InputMaybe<ProviderSearchSort>;
};

export type ProviderSearchMatchCount = {
  __typename?: 'ProviderSearchMatchCount';
  type: Search_Filter_Match_Type;
  name: Scalars['String'];
  count: Scalars['Int'];
};

export type ProviderSearchProfileFilters = {
  hasPhotos?: InputMaybe<Scalars['Boolean']>;
  practiceTypes?: InputMaybe<Array<Scalars['String']>>;
  treatmentAreaIds?: InputMaybe<Array<Scalars['String']>>;
  productIds?: InputMaybe<Array<Scalars['String']>>;
  brandNames?: InputMaybe<Array<Scalars['String']>>;
  hasFreeConsultations?: InputMaybe<Scalars['Boolean']>;
  offersOnlineBooking?: InputMaybe<Scalars['Boolean']>;
  offersFinancing?: InputMaybe<Scalars['Boolean']>;
};

export type ProviderSearchProximityFilter = {
  geoPoint?: InputMaybe<GeoPointInput>;
  radiusInMiles?: InputMaybe<Scalars['Float']>;
  boundingBox?: InputMaybe<ProximityFilterBoundingBoxInput>;
};

export type ProviderSearchSort = {
  column: Search_Sort_Column;
  order: Search_Sort_Order;
};

export type ProviderSearchState = {
  __typename?: 'ProviderSearchState';
  name: Us_State;
  abbreviation: Us_State_Abbreviation;
  cities?: Maybe<ProviderSearchStateCitiesConnection>;
};

export type ProviderSearchStateCitiesArgs = {
  filters?: InputMaybe<ProviderSearchStateCitiesFiltersInput>;
};

export type ProviderSearchStateCitiesConnection = {
  __typename?: 'ProviderSearchStateCitiesConnection';
  nodes: Array<ProviderSearchCity>;
};

export type ProviderSearchStateCitiesFiltersInput = {
  minNumberOfProviders?: InputMaybe<Scalars['Int']>;
  cityName?: InputMaybe<Scalars['String']>;
};

export type ProviderSearchV2Edge = {
  __typename?: 'ProviderSearchV2Edge';
  displayDistance: Scalars['Float'];
  node: LocationPublicProfile;
};

export type ProviderSearchV2Input = {
  filters: ProviderSearchFilters;
  sort?: InputMaybe<ProviderSearchSort>;
};

export type ProviderSearchV2ResultsConnection = {
  __typename?: 'ProviderSearchV2ResultsConnection';
  edges: Array<ProviderSearchV2Edge>;
  offsetPageInfo: OffsetPageInfo;
  filterMatchCounts: Array<ProviderSearchMatchCount>;
};

export type ProviderSessionTokenGenerateFailure = {
  __typename?: 'ProviderSessionTokenGenerateFailure';
  error: Error;
};

export type ProviderSessionTokenGenerateInput = {
  identityToken: Scalars['String'];
  providerOrganizationId?: InputMaybe<Scalars['ID']>;
};

export type ProviderSessionTokenGeneratePayload =
  | ProviderSessionTokenGenerateSuccess
  | ProviderSessionTokenGenerateFailure;

export type ProviderSessionTokenGenerateSuccess = {
  __typename?: 'ProviderSessionTokenGenerateSuccess';
  sessionToken: Scalars['String'];
};

export type ProviderSettings = {
  __typename?: 'ProviderSettings';
  displayAveragePricing?: Maybe<Scalars['Boolean']>;
};

export type ProviderSettingsFieldError =
  | ProviderSettingsMissingFieldError
  | ProviderSettingsInvalidFieldError;

export type ProviderSettingsInvalidFieldError = FieldValidationError & {
  __typename?: 'ProviderSettingsInvalidFieldError';
  displayName: Scalars['String'];
};

export type ProviderSettingsMissingFieldError = FieldValidationError & {
  __typename?: 'ProviderSettingsMissingFieldError';
  displayName: Scalars['String'];
};

export type ProviderSettingsValidateFailure = {
  __typename?: 'ProviderSettingsValidateFailure';
  error: Error;
};

export type ProviderSettingsValidateInput = {
  id: Scalars['ID'];
};

export type ProviderSettingsValidatePayload =
  | ProviderSettingsValidateSuccess
  | ProviderSettingsValidateFailure;

export type ProviderSettingsValidateSuccess = {
  __typename?: 'ProviderSettingsValidateSuccess';
  /** Practice ID given in input. For client caching. */
  id: Scalars['ID'];
  fieldErrors: Array<ProviderSettingsFieldError>;
};

export type ProviderUser = {
  __typename?: 'ProviderUser';
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  alternateEmailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  invitation: ProviderUserInvitation;
  roleAssignments: ProviderUserRoleAssignmentsConnection;
  taskStatuses: Array<Maybe<TaskStatus>>;
};

export type ProviderUserRoleAssignmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ProviderUserRoleAssignmentsFilter>;
};

export type ProviderUserTaskStatusesArgs = {
  slugs: Array<Scalars['String']>;
};

export type ProviderUserAcceptInviteFailure = {
  __typename?: 'ProviderUserAcceptInviteFailure';
  error: Error;
};

export type ProviderUserAcceptInviteInput = {
  invitationId: Scalars['ID'];
  invitationToken: Scalars['String'];
  /** If the invite is being accepted for the business owner role, we need to know that they accept the T&Cs */
  termsAccepted?: InputMaybe<Scalars['Boolean']>;
  idpAccessToken: Scalars['String'];
};

export type ProviderUserAcceptInvitePayload =
  | ProviderUserAcceptInviteSuccess
  | ProviderUserAcceptInviteFailure;

export type ProviderUserAcceptInviteSuccess = {
  __typename?: 'ProviderUserAcceptInviteSuccess';
  providerUser: ProviderUser;
};

export type ProviderUserAddRoleAssignmentFailure = {
  __typename?: 'ProviderUserAddRoleAssignmentFailure';
  error: Error;
};

export type ProviderUserAddRoleAssignmentInput = {
  id: Scalars['ID'];
  role: Provider_User_Role;
  /** If organizationId is not provided, the organizationId found in current workspace access token will be used. */
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type ProviderUserAddRoleAssignmentPayload =
  | ProviderUserAddRoleAssignmentSuccess
  | ProviderUserAddRoleAssignmentFailure;

export type ProviderUserAddRoleAssignmentSuccess = {
  __typename?: 'ProviderUserAddRoleAssignmentSuccess';
  providerUser: ProviderUser;
};

export type ProviderUserAlternateEmailAddressChangeRequestCreateFailure = {
  __typename?: 'ProviderUserAlternateEmailAddressChangeRequestCreateFailure';
  error: Error;
};

export type ProviderUserAlternateEmailAddressChangeRequestCreateInput = {
  password: Scalars['String'];
  newEmailAddress: Scalars['String'];
};

export type ProviderUserAlternateEmailAddressChangeRequestCreatePayload =
  | ProviderUserAlternateEmailAddressChangeRequestCreateSuccess
  | ProviderUserAlternateEmailAddressChangeRequestCreateFailure;

export type ProviderUserAlternateEmailAddressChangeRequestCreateSuccess = {
  __typename?: 'ProviderUserAlternateEmailAddressChangeRequestCreateSuccess';
  codeExpiresAt: DateTime;
};

export type ProviderUserDelegateInvitationFailure = {
  __typename?: 'ProviderUserDelegateInvitationFailure';
  error: Error;
};

export type ProviderUserDelegateInvitationInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  sourceInvitationId: Scalars['String'];
  sourceInvitationToken: Scalars['String'];
};

export type ProviderUserDelegateInvitationPayload =
  | ProviderUserDelegateInvitationSuccess
  | ProviderUserDelegateInvitationFailure;

export type ProviderUserDelegateInvitationSuccess = {
  __typename?: 'ProviderUserDelegateInvitationSuccess';
  id: Scalars['ID'];
};

export type ProviderUserDeleteFailure = {
  __typename?: 'ProviderUserDeleteFailure';
  error: Error;
};

export type ProviderUserDeleteInput = {
  id: Scalars['ID'];
};

export type ProviderUserDeletePayload =
  | ProviderUserDeleteSuccess
  | ProviderUserDeleteFailure;

export type ProviderUserDeleteSuccess = {
  __typename?: 'ProviderUserDeleteSuccess';
  providerUser: ProviderUser;
};

export type ProviderUserInvitation = {
  __typename?: 'ProviderUserInvitation';
  isPending: Scalars['Boolean'];
};

export type ProviderUserInvitationCheckAlternateEmailAddressAvailabilityFailure =
  {
    __typename?: 'ProviderUserInvitationCheckAlternateEmailAddressAvailabilityFailure';
    error: Error;
  };

export type ProviderUserInvitationCheckAlternateEmailAddressAvailabilityInput =
  {
    emailAddress: Scalars['String'];
    invitationId: Scalars['ID'];
    token: Scalars['String'];
  };

export type ProviderUserInvitationCheckAlternateEmailAddressAvailabilityPayload =

    | ProviderUserInvitationCheckAlternateEmailAddressAvailabilitySuccess
    | ProviderUserInvitationCheckAlternateEmailAddressAvailabilityFailure;

export type ProviderUserInvitationCheckAlternateEmailAddressAvailabilitySuccess =
  {
    __typename?: 'ProviderUserInvitationCheckAlternateEmailAddressAvailabilitySuccess';
    message: Scalars['String'];
  };

export type ProviderUserInvitationResendFailure = {
  __typename?: 'ProviderUserInvitationResendFailure';
  error: Error;
};

export type ProviderUserInvitationResendInput = {
  roleAssignmentId: Scalars['ID'];
};

export type ProviderUserInvitationResendPayload =
  | ProviderUserInvitationResendSuccess
  | ProviderUserInvitationResendFailure;

export type ProviderUserInvitationResendSuccess = {
  __typename?: 'ProviderUserInvitationResendSuccess';
  providerUser: ProviderUser;
};

export type ProviderUserInvitationSendAlternateEmailAddressVerificationFailure =
  {
    __typename?: 'ProviderUserInvitationSendAlternateEmailAddressVerificationFailure';
    error: Error;
  };

export type ProviderUserInvitationSendAlternateEmailAddressVerificationInput = {
  emailAddress: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
};

export type ProviderUserInvitationSendAlternateEmailAddressVerificationPayload =

    | ProviderUserInvitationSendAlternateEmailAddressVerificationSuccess
    | ProviderUserInvitationSendAlternateEmailAddressVerificationFailure;

export type ProviderUserInvitationSendAlternateEmailAddressVerificationSuccess =
  {
    __typename?: 'ProviderUserInvitationSendAlternateEmailAddressVerificationSuccess';
    codeExpiresAt: DateTime;
  };

export type ProviderUserInvitationSendSmsVerificationFailure = {
  __typename?: 'ProviderUserInvitationSendSMSVerificationFailure';
  error: Error;
};

export type ProviderUserInvitationSendSmsVerificationInput = {
  id?: InputMaybe<Scalars['ID']>;
  token?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type ProviderUserInvitationSendSmsVerificationPayload =
  | ProviderUserInvitationSendSmsVerificationSuccess
  | ProviderUserInvitationSendSmsVerificationFailure;

export type ProviderUserInvitationSendSmsVerificationSuccess = {
  __typename?: 'ProviderUserInvitationSendSMSVerificationSuccess';
  status: Scalars['String'];
  expiresAt: DateTime;
};

export type ProviderUserInvitationVerifySmsVerificationFailure = {
  __typename?: 'ProviderUserInvitationVerifySMSVerificationFailure';
  error: Error;
  /**
   * If the phone number being verified is already tied to an existing account,
   * we will show an error message that contains a masked version of the
   * accounts email address.
   */
  existingUsersMaskedEmailAddress?: Maybe<Scalars['String']>;
};

export type ProviderUserInvitationVerifySmsVerificationInput = {
  id?: InputMaybe<Scalars['ID']>;
  token?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  code: Scalars['String'];
};

export type ProviderUserInvitationVerifySmsVerificationPayload =
  | ProviderUserInvitationVerifySmsVerificationSuccess
  | ProviderUserInvitationVerifySmsVerificationFailure;

export type ProviderUserInvitationVerifySmsVerificationSuccess = {
  __typename?: 'ProviderUserInvitationVerifySMSVerificationSuccess';
  status: Scalars['String'];
};

export type ProviderUserInvitationVerifyTokenFailure = {
  __typename?: 'ProviderUserInvitationVerifyTokenFailure';
  error: Error;
};

export type ProviderUserInvitationVerifyTokenInput = {
  id: Scalars['ID'];
  token: Scalars['String'];
};

export type ProviderUserInvitationVerifyTokenPayload =
  | ProviderUserInvitationVerifyTokenSuccess
  | ProviderUserInvitationVerifyTokenFailure;

export type ProviderUserInvitationVerifyTokenSuccess = {
  __typename?: 'ProviderUserInvitationVerifyTokenSuccess';
  id: Scalars['ID'];
  invitationType: Provider_User_Invitation_Type;
  providerUser: ProviderUser;
  organizationId: Scalars['ID'];
  organizationName: Scalars['String'];
  organizationType: Scalars['String'];
  organizationStatus: Scalars['String'];
  parentOrganizationId?: Maybe<Scalars['ID']>;
  parentOrganizationName?: Maybe<Scalars['String']>;
  parentOrganizationType?: Maybe<Scalars['String']>;
};

export type ProviderUserInviteAllSuggestedFailure = {
  __typename?: 'ProviderUserInviteAllSuggestedFailure';
  error: Error;
};

export type ProviderUserInviteAllSuggestedPayload =
  | ProviderUserInviteAllSuggestedSuccess
  | ProviderUserInviteAllSuggestedFailure;

export type ProviderUserInviteAllSuggestedSuccess = {
  __typename?: 'ProviderUserInviteAllSuggestedSuccess';
  totalInvitationsTriggered: Scalars['Int'];
};

export type ProviderUserInviteFailure = {
  __typename?: 'ProviderUserInviteFailure';
  error: Error;
};

export type ProviderUserInviteInput = {
  emailAddress: Scalars['String'];
  /** @deprecated Use roleAssignments instead */
  role?: InputMaybe<Provider_User_Role>;
  /**
   * If organizationId is not provided, the organizationId found in current workspace access token will be used.
   * @deprecated Use roleAssignments instead
   */
  organizationId?: InputMaybe<Scalars['ID']>;
  roleAssignments?: InputMaybe<Array<ProviderUserRoleAssignmentInput>>;
};

export type ProviderUserInvitePayload =
  | ProviderUserInviteSuccess
  | ProviderUserInviteFailure;

export type ProviderUserInviteSuccess = {
  __typename?: 'ProviderUserInviteSuccess';
  providerUser: ProviderUser;
};

export type ProviderUserInviteValidation =
  | ProviderUserInviteValidationSuccess
  | ProviderUserInviteValidationFailure;

export type ProviderUserInviteValidationFailure = {
  __typename?: 'ProviderUserInviteValidationFailure';
  error: Error;
};

export type ProviderUserInviteValidationInput = {
  emailAddress: Scalars['String'];
  roleAssignments: Array<ProviderUserRoleAssignmentInput>;
};

export type ProviderUserInviteValidationSuccess = {
  __typename?: 'ProviderUserInviteValidationSuccess';
  emailAddress: Scalars['String'];
  roleAssignments: Array<ProviderUserRoleAssignmentParameter>;
};

export type ProviderUserPhoneNumberChangeRequestCreateFailure = {
  __typename?: 'ProviderUserPhoneNumberChangeRequestCreateFailure';
  error: Error;
};

export type ProviderUserPhoneNumberChangeRequestCreateInput = {
  password: Scalars['String'];
  newPhoneNumber: Scalars['String'];
};

export type ProviderUserPhoneNumberChangeRequestCreatePayload =
  | ProviderUserPhoneNumberChangeRequestCreateSuccess
  | ProviderUserPhoneNumberChangeRequestCreateFailure;

export type ProviderUserPhoneNumberChangeRequestCreateSuccess = {
  __typename?: 'ProviderUserPhoneNumberChangeRequestCreateSuccess';
  codeExpiresAt: DateTime;
};

export type ProviderUserPhoneNumberReleaseFailure = {
  __typename?: 'ProviderUserPhoneNumberReleaseFailure';
  error: Error;
};

export type ProviderUserPhoneNumberReleaseInput = {
  apiKey: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ProviderUserPhoneNumberReleasePayload =
  | ProviderUserPhoneNumberReleaseSuccess
  | ProviderUserPhoneNumberReleaseFailure;

export type ProviderUserPhoneNumberReleaseSuccess = {
  __typename?: 'ProviderUserPhoneNumberReleaseSuccess';
  message: Scalars['String'];
};

export type ProviderUserPrimaryEmailAddressChangeRequestCreateFailure = {
  __typename?: 'ProviderUserPrimaryEmailAddressChangeRequestCreateFailure';
  error: Error;
};

export type ProviderUserPrimaryEmailAddressChangeRequestCreateInput = {
  password: Scalars['String'];
  newEmailAddress: Scalars['String'];
};

export type ProviderUserPrimaryEmailAddressChangeRequestCreatePayload =
  | ProviderUserPrimaryEmailAddressChangeRequestCreateSuccess
  | ProviderUserPrimaryEmailAddressChangeRequestCreateFailure;

export type ProviderUserPrimaryEmailAddressChangeRequestCreateSuccess = {
  __typename?: 'ProviderUserPrimaryEmailAddressChangeRequestCreateSuccess';
  codeExpiresAt: DateTime;
};

export type ProviderUserRegisterByInvitationTokenFailure = {
  __typename?: 'ProviderUserRegisterByInvitationTokenFailure';
  error: Error;
};

export type ProviderUserRegisterByInvitationTokenInput = {
  id: Scalars['ID'];
  token: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  termsAccepted?: InputMaybe<Scalars['Boolean']>;
  termsAndConditionsVersion?: InputMaybe<Scalars['String']>;
  alternateEmailAddress?: InputMaybe<Scalars['String']>;
  jobFunctionIds?: InputMaybe<Array<Scalars['ID']>>;
  emailOptIn?: InputMaybe<Scalars['Boolean']>;
  smsOptIn?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
};

export type ProviderUserRegisterByInvitationTokenPayload =
  | ProviderUserRegisterByInvitationTokenSuccess
  | ProviderUserRegisterByInvitationTokenFailure;

export type ProviderUserRegisterByInvitationTokenSuccess = {
  __typename?: 'ProviderUserRegisterByInvitationTokenSuccess';
  sessionToken?: Maybe<Scalars['String']>;
};

export type ProviderUserRoleAssignment = {
  __typename?: 'ProviderUserRoleAssignment';
  id: Scalars['ID'];
  role: Provider_User_Role;
  organization: ProviderOrganization;
  state: Provider_User_Role_Assignment_State;
  /** @deprecated use state instead */
  isActive: Scalars['Boolean'];
};

export type ProviderUserRoleAssignmentChangeRoleFailure = {
  __typename?: 'ProviderUserRoleAssignmentChangeRoleFailure';
  error: Error;
};

export type ProviderUserRoleAssignmentChangeRoleInput = {
  id: Scalars['ID'];
  role: Provider_User_Role;
};

export type ProviderUserRoleAssignmentChangeRolePayload =
  | ProviderUserRoleAssignmentChangeRoleSuccess
  | ProviderUserRoleAssignmentChangeRoleFailure;

export type ProviderUserRoleAssignmentChangeRoleSuccess = {
  __typename?: 'ProviderUserRoleAssignmentChangeRoleSuccess';
  providerUser: ProviderUser;
};

export type ProviderUserRoleAssignmentInput = {
  role: Provider_User_Role;
  /** If organizationId is not provided, the organizationId found in current workspace access token will be used. */
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type ProviderUserRoleAssignmentParameter = {
  __typename?: 'ProviderUserRoleAssignmentParameter';
  role: Provider_User_Role;
  organizationId?: Maybe<Scalars['ID']>;
};

export type ProviderUserRoleAssignmentsConnection = {
  __typename?: 'ProviderUserRoleAssignmentsConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ProviderUserRoleAssignmentsEdge>;
};

export type ProviderUserRoleAssignmentsDeactivateFailure = {
  __typename?: 'ProviderUserRoleAssignmentsDeactivateFailure';
  error: Error;
};

export type ProviderUserRoleAssignmentsDeactivateInput = {
  ids: Array<Scalars['ID']>;
};

export type ProviderUserRoleAssignmentsDeactivatePayload =
  | ProviderUserRoleAssignmentsDeactivateSuccess
  | ProviderUserRoleAssignmentsDeactivateFailure;

export type ProviderUserRoleAssignmentsDeactivateSuccess = {
  __typename?: 'ProviderUserRoleAssignmentsDeactivateSuccess';
  providerUser: ProviderUser;
};

export type ProviderUserRoleAssignmentsEdge = {
  __typename?: 'ProviderUserRoleAssignmentsEdge';
  node: ProviderUserRoleAssignment;
};

export type ProviderUserRoleAssignmentsFilter = {
  /**
   * If the user is being returned from the ProviderOrganization.users connection,
   * the organizationIds will by default be the ID of the parent org and this
   * filter will be ignored.
   */
  organizationIds?: InputMaybe<Array<Scalars['ID']>>;
  states?: InputMaybe<Array<Provider_User_Role_Assignment_State>>;
};

export type ProviderUserUpdateFirstNameFailure = {
  __typename?: 'ProviderUserUpdateFirstNameFailure';
  error: Error;
};

export type ProviderUserUpdateFirstNameInput = {
  firstName: Scalars['String'];
};

export type ProviderUserUpdateFirstNamePayload =
  | ProviderUserUpdateFirstNameSuccess
  | ProviderUserUpdateFirstNameFailure;

export type ProviderUserUpdateFirstNameSuccess = {
  __typename?: 'ProviderUserUpdateFirstNameSuccess';
  providerUser: ProviderUser;
};

export type ProviderUserUpdateLastNameFailure = {
  __typename?: 'ProviderUserUpdateLastNameFailure';
  error: Error;
};

export type ProviderUserUpdateLastNameInput = {
  lastName: Scalars['String'];
};

export type ProviderUserUpdateLastNamePayload =
  | ProviderUserUpdateLastNameSuccess
  | ProviderUserUpdateLastNameFailure;

export type ProviderUserUpdateLastNameSuccess = {
  __typename?: 'ProviderUserUpdateLastNameSuccess';
  providerUser: ProviderUser;
};

export type ProviderUserUpdatePasswordFailure = {
  __typename?: 'ProviderUserUpdatePasswordFailure';
  error: Error;
};

export type ProviderUserUpdatePasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ProviderUserUpdatePasswordPayload =
  | ProviderUserUpdatePasswordSuccess
  | ProviderUserUpdatePasswordFailure;

export type ProviderUserUpdatePasswordSuccess = {
  __typename?: 'ProviderUserUpdatePasswordSuccess';
  providerUser: ProviderUser;
};

export type ProviderUserVerifyAlternateEmailAddressFailure = {
  __typename?: 'ProviderUserVerifyAlternateEmailAddressFailure';
  error: Error;
};

export type ProviderUserVerifyAlternateEmailAddressInput = {
  emailAddress: Scalars['String'];
  code: Scalars['String'];
};

export type ProviderUserVerifyAlternateEmailAddressPayload =
  | ProviderUserVerifyAlternateEmailAddressSuccess
  | ProviderUserVerifyAlternateEmailAddressFailure;

export type ProviderUserVerifyAlternateEmailAddressSuccess = {
  __typename?: 'ProviderUserVerifyAlternateEmailAddressSuccess';
  providerUser: ProviderUser;
};

export type ProviderUserVerifyPhoneNumberFailure = {
  __typename?: 'ProviderUserVerifyPhoneNumberFailure';
  error: Error;
};

export type ProviderUserVerifyPhoneNumberInput = {
  phoneNumber: Scalars['String'];
  code: Scalars['String'];
};

export type ProviderUserVerifyPhoneNumberPayload =
  | ProviderUserVerifyPhoneNumberSuccess
  | ProviderUserVerifyPhoneNumberFailure;

export type ProviderUserVerifyPhoneNumberSuccess = {
  __typename?: 'ProviderUserVerifyPhoneNumberSuccess';
  providerUser: ProviderUser;
};

export type ProviderUserVerifyPrimaryEmailAddressFailure = {
  __typename?: 'ProviderUserVerifyPrimaryEmailAddressFailure';
  error: Error;
};

export type ProviderUserVerifyPrimaryEmailAddressInput = {
  emailAddress: Scalars['String'];
  code: Scalars['String'];
};

export type ProviderUserVerifyPrimaryEmailAddressPayload =
  | ProviderUserVerifyPrimaryEmailAddressSuccess
  | ProviderUserVerifyPrimaryEmailAddressFailure;

export type ProviderUserVerifyPrimaryEmailAddressSuccess = {
  __typename?: 'ProviderUserVerifyPrimaryEmailAddressSuccess';
  providerUser: ProviderUser;
};

export type ProviderUsersFilter = {
  /** @deprecated use 'roles' and 'roleAssignmentStates' instead */
  category?: InputMaybe<Provider_User_Category>;
  keyword?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Provider_User_Role_V2>>;
  roleAssignmentStates?: InputMaybe<Array<Provider_User_Role_Assignment_State>>;
  isRegistered?: InputMaybe<Scalars['Boolean']>;
};

export type ProviderWorkspace = {
  __typename?: 'ProviderWorkspace';
  role: Provider_User_Role;
  providerOrganization: ProviderOrganization;
  activeLocationForBusinessWorkspace?: Maybe<ProviderOrganization>;
  numberOfAvailableLocationWorkspaces?: Maybe<Scalars['Int']>;
};

export type ProviderWorkspaceOrganization = {
  __typename?: 'ProviderWorkspaceOrganization';
  id: Scalars['ID'];
  address: Address;
  name: Scalars['String'];
  type: Provider_Workspace_Organization_Type;
  allerganSapId?: Maybe<Scalars['String']>;
  abbvieSapId?: Maybe<Scalars['String']>;
  businessId: Scalars['ID'];
  businessName: Scalars['String'];
};

export type ProviderWorkspaceV2 = {
  __typename?: 'ProviderWorkspaceV2';
  role: Provider_User_Role_V2;
  organization: ProviderWorkspaceOrganization;
};

export type ProviderWorkspacesFilter = {
  businessId?: InputMaybe<Scalars['ID']>;
  keyword?: InputMaybe<Scalars['String']>;
};

export type ProximityFilterBoundingBoxInput = {
  geoPoints: BoundingBoxGeoPointsInput;
};

export type PublicProfileOptInMarketingEventsConnection = {
  __typename?: 'PublicProfileOptInMarketingEventsConnection';
  nodes: Array<ProviderOptInMarketingEvent>;
};

export type Query = {
  __typename?: 'Query';
  pingAdminsLambda?: Maybe<Scalars['String']>;
  pingAppointment?: Maybe<Scalars['String']>;
  pingAuthV2?: Maybe<Scalars['String']>;
  providerJobFunctions: Array<ProviderJobFunction>;
  providerUser?: Maybe<ProviderUser>;
  providerUserById?: Maybe<ProviderUser>;
  providerUserInviteValidation: ProviderUserInviteValidation;
  providerUsers: ViewerProviderUsersConnection;
  providerWorkspace?: Maybe<ProviderWorkspace>;
  providerWorkspaces: ViewerProviderWorkspacesConnection;
  providerWorkspacesV2: ViewerProviderWorkspacesV2Connection;
  providerWorkspacesV3: ViewerProviderWorkspacesV3Connection;
  pingBook?: Maybe<Scalars['String']>;
  baseCatalog: BaseCatalog;
  locationCatalogById?: Maybe<LocationCatalog>;
  locationCatalogBrandById?: Maybe<LocationCatalogBrand>;
  locationCatalogCategoryById?: Maybe<LocationCatalogCategory>;
  locationCatalogItemById?: Maybe<LocationCatalogItem>;
  viewerLocationCatalogAccessControlForLocationProviderOrg: LocationCatalogAccessControl;
  pingCatalog?: Maybe<Scalars['String']>;
  patientTransaction?: Maybe<PatientTransaction>;
  patientTransactions?: Maybe<ViewerPatientTransactionsConnection>;
  patientTransactionsByPatientId?: Maybe<PatientTransactionsConnection>;
  pingCheckoutLambda?: Maybe<Scalars['String']>;
  pingCheckoutSales?: Maybe<Scalars['String']>;
  /** Retrieves a cart by ID */
  checkoutCartById?: Maybe<CheckoutCart>;
  checkoutOrderById?: Maybe<CheckoutOrder>;
  checkoutOrderRefundById?: Maybe<CheckoutOrderRefund>;
  checkoutSalesReportById?: Maybe<CheckoutSalesReport>;
  cmsArticles: CmsArticlesConnection;
  /** slugs: six_myths_about_filler_debunked | alle_vs_brilliantdistinctions */
  cmsArticleBySlug?: Maybe<CmsArticle>;
  cmsFeaturedItems: CmsFeaturedItemsConnection;
  /**
   * slug: an unique isi key composed of isi type and document name in a snake_lowercase form
   * e.g. consumer_botox, consumer_diamond_glow, provider_botox, provider_diamond_glow ...
   */
  isi?: Maybe<CmsLegalContent>;
  isis?: Maybe<IsIsConnection>;
  pingCMS?: Maybe<Scalars['String']>;
  treatmentGuides: TreatmentGuidesConnection;
  /** slugs: juvederm | coolsculpting | botox | cooltone */
  treatmentGuideBySlug?: Maybe<TreatmentGuide>;
  consumerConsentCommunicationPreferencesEmail?: Maybe<
    Array<ConsumerPreference>
  >;
  consumerConsentCommunicationPreferencesSMS?: Maybe<Array<ConsumerPreference>>;
  consumerConsentPrivacySettings?: Maybe<Array<ConsumerPreference>>;
  pingConsumerPreferences?: Maybe<Scalars['String']>;
  pingDeviceLocation?: Maybe<Scalars['String']>;
  deviceLocation?: Maybe<DeviceLocation>;
  pingEmail?: Maybe<Scalars['String']>;
  email_verifyEmailToken?: Maybe<VerifyEmailResponse>;
  pingFlash?: Maybe<Scalars['String']>;
  userCheckInToEventClaim: UserCheckInToEventClaim;
  userCheckInToPracticeClaim: UserCheckInToPracticeClaim;
  giftCard?: Maybe<GiftCard>;
  /** @deprecated Please use giftCardsByKeyword */
  giftCardLookUp?: Maybe<GiftCard>;
  /** Search for Gift Cards by code or order number */
  giftCardsByKeyword: GiftCardsByKeywordConnection;
  /** Gets the page meta data for a given Landinge Page using advertising campaign id */
  advertisingLandingPageMetadata?: Maybe<AdvertisingLandingPageMetadata>;
  /**
   * Gets a single post.
   * @deprecated Please use providerMarketingCampaign instead.
   */
  advertisingPost?: Maybe<AdvertisingPost>;
  advertisingProfileTermsAndConditions: AdvertisingProfileTermsAndConditions;
  pingGrow?: Maybe<Scalars['String']>;
  /** Gets an email marketing campaign package by its id */
  providerEmailMarketingCampaignPackage?: Maybe<ProviderEmailMarketingCampaignPackage>;
  providerEmailMarketingCampaignById?: Maybe<ProviderEmailMarketingCampaign>;
  providerMarketingCampaign?: Maybe<ProviderMarketingCampaign>;
  providerMarketingCampaignPackageCategories: ProviderMarketingCampaignPackageCategoriesConnection;
  allProviderMarketingCampaignPackages: AllProviderMarketingCampaignPackagesConnection;
  providerMarketingCampaignPackageById?: Maybe<ProviderMarketingCampaignPackage>;
  /** @deprecated use allProviderMarketingCampaignPackages instead. */
  providerMarketingCampaignPackagesByCategoryId: ProviderMarketingCampaignPackageCategoryPackagesConnection;
  providerMarketingCampaignPackageRecommendedImageVariant: ProviderMarketingCampaignPackageImageVariant;
  /** Gets a single curate post. */
  socialMediaCuratedPost?: Maybe<SocialMediaCuratedPost>;
  /** List of posts to quick start publications with curated content. */
  socialMediaCuratedPosts: ViewerSocialMediaCuratedPostsConnection;
  /** @deprecated Please use socialMediaCuratedPosts instead. */
  socialMediaCurratedPosts: ViewerSocialMediaCurratedPostsConnection;
  /** recommendedAdvertisingConfiguration provides a sane(recommended) value for provider to enter on ad configuration */
  recommendedAdConfiguration: RecommendedAdConfiguration;
  /** providerMarketingCampaignPreview returns info needed to show preview of campaign like url, width, height */
  providerMarketingCampaignPreview: ProviderMarketingCampaignPreview;
  /** List of provider email marketing campaigns */
  providerEmailMarketingCampaigns: ProviderEmailMarketingCampaignConnection;
  providerEmailMarketingCampaignPackages: ProviderEmailMarketingCampaignPackagesConnection;
  pingIam?: Maybe<Scalars['String']>;
  viewerPermissions: ViewerPermissions;
  pingIdentity?: Maybe<Scalars['String']>;
  pingInsights?: Maybe<Scalars['String']>;
  providerReports?: Maybe<Array<ProviderReport>>;
  providerReportFetchDataResult: ProviderReportFetchDataResult;
  lead?: Maybe<Lead>;
  leadsBySource: ViewerLeadsConnection;
  /** @deprecated Please use leadsBySource instead. */
  leads: ViewerLeadsConnection;
  pingLeads?: Maybe<Scalars['String']>;
  pingLocationTypeahead?: Maybe<Scalars['String']>;
  zipCodeSearch: ZipCodeSearchResultsConnection;
  /** @deprecated Please use Practice.AdvertisingProfile.advertisingLeadNotificationMethods */
  advertisingAutoCallPhoneNumberVerification?: Maybe<AdvertisingAutoCallPhoneNumberVerification>;
  pingMarketingNotifications?: Maybe<Scalars['String']>;
  pingServiceGhaWorkflowTesting: Scalars['String'];
  authenticatedPingOffers?: Maybe<Scalars['String']>;
  marketingOffer?: Maybe<MarketingOffer>;
  marketingOffers?: Maybe<MarketingOfferConnection>;
  marketingOfferAudiences?: Maybe<ViewerMarketingOfferAudienceConnection>;
  marketingOfferProviderAudiences?: Maybe<ViewerMarketingOfferProviderAudienceConnection>;
  pingOffers?: Maybe<Scalars['String']>;
  viewerMarketingOfferAccessControl: MarketingOfferAccessControl;
  marketingOfferApprovalRequests?: Maybe<MarketingOfferApprovalRequestConnection>;
  pingPatientFinancing?: Maybe<Scalars['String']>;
  cherryPaymentPlanEstimateSearch: CherryPaymentPlanEstimateSearchResultsConnection;
  paymentIntent: PaymentIntent;
  paymentsHealthcheck?: Maybe<PaymentsHealthcheck>;
  pingPaymentsWallet?: Maybe<Scalars['String']>;
  paymentsSetupIntent: PaymentsSetupIntent;
  paymentsWallet?: Maybe<PaymentsWallet>;
  paymentsTransaction?: Maybe<PaymentsTransaction>;
  providerOrganizationWalletById?: Maybe<PaymentsWallet>;
  allePayoutById?: Maybe<AllePayout>;
  viewerPaymentsAccessControl: PaymentsAccessControl;
  pingMoveMoney?: Maybe<Scalars['String']>;
  paymentsAccounts: PaymentsAccountConnections;
  paymentsBalanceAutomationParameters: PaymentsBalanceAutomationParameters;
  paymentsTransferRequests: PaymentsTransferRequestsConnections;
  pingSubscriptions?: Maybe<Scalars['String']>;
  platformSubscriptionPlans?: Maybe<PlatformSubscriptionPlansConnection>;
  platformSubscriptionById?: Maybe<PlatformSubscription>;
  platformSubscriptionInvoiceById?: Maybe<PlatformSubscriptionInvoice>;
  pingPayouts?: Maybe<Scalars['String']>;
  payout?: Maybe<Payout>;
  pingPointsBanking?: Maybe<Scalars['String']>;
  /**
   * pingPointsService is a simple ping method to check if points service is still alive
   * if so, it will return a string or not this resolver should throw errors back
   */
  pingPointsService?: Maybe<Scalars['String']>;
  /**
   * pointsRedemptionRate return the rate of the consumer Points at a given point in time.
   * If a date is not provided in the input it will return the rate active today.
   * The redemptionRate * ConsumerPoints return the values in dollars of the points the consumer has.
   */
  pointsRedemptionRate?: Maybe<PointsRedemptionRate>;
  pingPricingPurchasing?: Maybe<Scalars['String']>;
  allerganProductById?: Maybe<AllerganProduct>;
  allerganProductBundleById?: Maybe<AllerganStoreProductBundle>;
  allerganProductSearch: AllerganProductSearchResultsConnection;
  allerganProductOfferingSearch: AllerganProductOfferingSearchResultsConnection;
  allerganStoreShoppingCart?: Maybe<AllerganStoreShoppingCart>;
  allerganStoreOrder?: Maybe<AllerganStoreOrder>;
  allerganStoreOrderSimulate?: Maybe<AllerganStoreOrderSimulate>;
  allerganProductCostById?: Maybe<AllerganProductCost>;
  brands?: Maybe<Array<Maybe<Brand>>>;
  brandISIs?: Maybe<Array<Maybe<BrandIsi>>>;
  products?: Maybe<Array<Maybe<Product>>>;
  productVariantByIds?: Maybe<Array<Maybe<ProductVariant>>>;
  pingProducts?: Maybe<Scalars['String']>;
  pingProfileManager?: Maybe<Scalars['String']>;
  /** Retrieves list of all languages */
  allProviderProfileLanguages: AllProviderProfileLanguagesConnection;
  /** Retrieves location public profile by profile slug */
  locationPublicProfileBySlug?: Maybe<LocationPublicProfile>;
  /** Retrieves location public profile by provider organization id */
  locationPublicProfileByOrgId?: Maybe<LocationPublicProfile>;
  /** Retrieves list of provider profile products */
  providerProfileProducts: Array<ProviderProfileProduct>;
  /** Retrieves list of provider profile treatment areas */
  providerProfileTreatmentAreas: Array<ProviderProfileTreatmentArea>;
  pingProviderOrg?: Maybe<Scalars['String']>;
  providerLocations: ViewerProviderLocationsConnection;
  providerOrganization?: Maybe<ProviderOrganization>;
  providerOrganizationById?: Maybe<ProviderOrganization>;
  providerOrganizationsByKeyword: ProviderOrganizationSearchResultsConnection;
  pingProviderRewards?: Maybe<Scalars['String']>;
  pingProvidersLambda?: Maybe<Scalars['String']>;
  practice?: Maybe<Practice>;
  practicesByKeyword?: Maybe<ViewerPracticesConnection>;
  pingServiceReferrals?: Maybe<Scalars['String']>;
  referringUserCodeInfo: ReferringUserCodeInfo;
  adminReferringUserProfile: AdminReferringUserProfile;
  /** @deprecated use providerReportFetchDataResult */
  activePromotionsReport: ActivePromotionsReport;
  /** @deprecated no longer used */
  botoxFiftyOffPromotionReport: BotoxFiftyOffPromotionReport;
  /** @deprecated use providerReportFetchDataResult */
  botoxJuvedermLastTransactionReport: BotoxJuvedermLastTransactionReport;
  /** @deprecated use providerReportFetchDataResult */
  expiringPointsReport: ExpiringPointsReport;
  expiringPointsBusinessReport: ExpiringPointsBusinessReport;
  /** @deprecated use providerReportFetchDataResult */
  flashRewardsReport: FlashRewardsReport;
  /** @deprecated use providerReports */
  insightsReports?: Maybe<Array<InsightsReport>>;
  pingReports?: Maybe<Scalars['String']>;
  /** @deprecated use providerReportFetchDataResult */
  productsLastTransactionReport: ProductsLastTransactionReport;
  /** @deprecated use providerReportFetchDataResult */
  promotionReport: PromotionReport;
  /** @deprecated No longer used */
  reportingPromotions: ViewerReportingPromotionsConnection;
  /** @deprecated No longer used */
  transactionsReimbursementsReport: TransactionsReimbursementsReport;
  transactionsReimbursementsBusinessReport: TransactionsReimbursementsBusinessReport;
  /** @deprecated use providerReportFetchDataResult */
  unusedGiftCardsReport: UnusedGiftCardsReport;
  pingSapFulfillment?: Maybe<Scalars['String']>;
  pingSearch?: Maybe<Scalars['String']>;
  providerSearch: ProviderSearchConnection;
  providerSearchIndicators: ProviderSearchIndicatorsConnection;
  providerLocationBySlug?: Maybe<SearchProvider>;
  allProviderProfileSlugs: AllProviderProfileSlugsConnection;
  providerSearchStateByName: ProviderSearchState;
  allProviderStates: AllProviderStatesConnection;
  providerSearchV2: ProviderSearchV2ResultsConnection;
  allAllerganStorePricingTiers: AllAllerganStorePricingTiersConnection;
  allerganStorePricingTierById?: Maybe<AllerganStorePricingTier>;
  /** @deprecated Use `allerganStorePricingTermsAndConditions` instead */
  allerganStoreTermsAndConditions: AllerganStoreTermsAndConditions;
  allerganStorePricingTermsAndConditions: AllerganStorePricingTermsAndConditions;
  pingPricing?: Maybe<Scalars['String']>;
  /**
   * patientTransactionSummary is a "dry-run" to check how many points/rewards
   * that patient can gain given the input
   * @deprecated use patientTransactionSimulate
   */
  patientTransactionSummary?: Maybe<PatientTransactionPayload>;
  pingRewardsCheckout?: Maybe<Scalars['String']>;
  pingPractices?: Maybe<Scalars['String']>;
  pingSMS?: Maybe<Scalars['String']>;
  sms_lookupPhoneNumber?: Maybe<Sms_LookupResponse>;
  sms_verifyTwoFactorCode?: Maybe<Sms_Response>;
  pingTaskCompletion?: Maybe<Scalars['String']>;
  officePromotions?: Maybe<Array<Maybe<OfficePromotion>>>;
  optInPromotion: OptInPromotion;
  optInPromotionClaimRequest: OptInPromotionClaim;
  pingTransactions?: Maybe<Scalars['String']>;
  promotion?: Maybe<Promotion>;
  allPointPurchasablePromotions?: Maybe<AllPointPurchasablePromotionsConnection>;
  auditEvents: AuditEventConnection;
  pingUserActivityTrail?: Maybe<Scalars['String']>;
  pingUsers?: Maybe<Scalars['String']>;
  viewer?: Maybe<Viewer>;
  consumer?: Maybe<Consumer>;
  findConsumerByName?: Maybe<Consumer>;
  patient?: Maybe<Patient>;
  patientsByKeyword?: Maybe<PatientSearchConnection>;
  userGetMigrationStatus?: Maybe<UserMigrationStatusResponse>;
};

export type QueryProviderUserByIdArgs = {
  id: Scalars['ID'];
};

export type QueryProviderUserInviteValidationArgs = {
  input: ProviderUserInviteValidationInput;
};

export type QueryProviderUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ProviderUsersFilter>;
};

export type QueryProviderWorkspacesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  identityToken: Scalars['String'];
};

export type QueryProviderWorkspacesV2Args = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  identityToken: Scalars['String'];
};

export type QueryProviderWorkspacesV3Args = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ProviderWorkspacesFilter>;
};

export type QueryLocationCatalogByIdArgs = {
  id: Scalars['ID'];
};

export type QueryLocationCatalogBrandByIdArgs = {
  id: Scalars['ID'];
};

export type QueryLocationCatalogCategoryByIdArgs = {
  id: Scalars['ID'];
};

export type QueryLocationCatalogItemByIdArgs = {
  id: Scalars['ID'];
};

export type QueryViewerLocationCatalogAccessControlForLocationProviderOrgArgs =
  {
    id: Scalars['ID'];
  };

export type QueryPatientTransactionArgs = {
  id: Scalars['ID'];
};

export type QueryPatientTransactionsArgs = {
  page: SimplePageInput;
  range?: InputMaybe<DateRangeInput>;
};

export type QueryPatientTransactionsByPatientIdArgs = {
  id: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  range?: InputMaybe<DateRangeInput>;
};

export type QueryCheckoutCartByIdArgs = {
  id: Scalars['ID'];
};

export type QueryCheckoutOrderByIdArgs = {
  id: Scalars['ID'];
};

export type QueryCheckoutOrderRefundByIdArgs = {
  id: Scalars['ID'];
};

export type QueryCheckoutSalesReportByIdArgs = {
  id: Scalars['ID'];
};

export type QueryCmsArticlesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  excludeFeatured?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCmsArticleBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryCmsFeaturedItemsArgs = {
  first?: InputMaybe<Scalars['Int']>;
};

export type QueryIsiArgs = {
  slug: Scalars['String'];
};

export type QueryIsisArgs = {
  type: IsiType;
};

export type QueryTreatmentGuidesArgs = {
  first?: InputMaybe<Scalars['Int']>;
};

export type QueryTreatmentGuideBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryConsumerConsentCommunicationPreferencesEmailArgs = {
  consumerId?: InputMaybe<Scalars['ID']>;
};

export type QueryConsumerConsentCommunicationPreferencesSmsArgs = {
  consumerId?: InputMaybe<Scalars['ID']>;
};

export type QueryConsumerConsentPrivacySettingsArgs = {
  consumerId?: InputMaybe<Scalars['ID']>;
};

export type QueryEmail_VerifyEmailTokenArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export type QueryUserCheckInToEventClaimArgs = {
  id: Scalars['ID'];
};

export type QueryUserCheckInToPracticeClaimArgs = {
  id: Scalars['ID'];
};

export type QueryGiftCardArgs = {
  id: Scalars['ID'];
};

export type QueryGiftCardLookUpArgs = {
  code: Scalars['String'];
};

export type QueryGiftCardsByKeywordArgs = {
  keyword: Scalars['String'];
};

export type QueryAdvertisingLandingPageMetadataArgs = {
  id: Scalars['ID'];
  variantId?: InputMaybe<Scalars['ID']>;
};

export type QueryAdvertisingPostArgs = {
  id: Scalars['ID'];
};

export type QueryProviderEmailMarketingCampaignPackageArgs = {
  id: Scalars['ID'];
};

export type QueryProviderEmailMarketingCampaignByIdArgs = {
  id: Scalars['ID'];
};

export type QueryProviderMarketingCampaignArgs = {
  id: Scalars['ID'];
};

export type QueryAllProviderMarketingCampaignPackagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter: ProviderMarketingCampaignPackagesFilter;
};

export type QueryProviderMarketingCampaignPackageByIdArgs = {
  id: Scalars['ID'];
};

export type QueryProviderMarketingCampaignPackagesByCategoryIdArgs = {
  categoryId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryProviderMarketingCampaignPackageRecommendedImageVariantArgs = {
  campaignPackageId: Scalars['ID'];
};

export type QuerySocialMediaCuratedPostArgs = {
  id: Scalars['ID'];
};

export type QuerySocialMediaCuratedPostsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QuerySocialMediaCurratedPostsArgs = {
  page?: InputMaybe<ViewerSocialMediaCurratedPostsPageInput>;
};

export type QueryProviderMarketingCampaignPreviewArgs = {
  campaignPackageId: Scalars['ID'];
  offerAmountInCents?: InputMaybe<Scalars['Int']>;
  platform?: InputMaybe<Provider_Marketing_Campaign_Platform>;
  type?: InputMaybe<Provider_Marketing_Campaign_Package_Previewable_Content_Type>;
  imageVariantId?: InputMaybe<Scalars['ID']>;
};

export type QueryProviderEmailMarketingCampaignsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ProviderEmailMarketingCampaignsFilterInput>;
};

export type QueryProviderEmailMarketingCampaignPackagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ProviderEmailMarketingCampaignPackagesFilterInput>;
};

export type QueryProviderReportFetchDataResultArgs = {
  reportId: Scalars['ID'];
  providerOrganizationId: Scalars['ID'];
  filters?: InputMaybe<Array<ProviderReportResultFilterInput>>;
  aggregateNames?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryLeadArgs = {
  id: Scalars['ID'];
};

export type QueryLeadsBySourceArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<LeadsBySourceFilter>;
  sort?: InputMaybe<Array<LeadsBySourceSort>>;
};

export type QueryLeadsArgs = {
  sources?: InputMaybe<Array<Lead_Source_Type>>;
  range?: InputMaybe<DateRangeInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryZipCodeSearchArgs = {
  keyword: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryAdvertisingAutoCallPhoneNumberVerificationArgs = {
  id: Scalars['ID'];
};

export type QueryMarketingOfferArgs = {
  id: Scalars['ID'];
};

export type QueryMarketingOffersArgs = {
  page?: InputMaybe<MarketingOfferSimplePageInput>;
};

export type QueryMarketingOfferProviderAudiencesArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryMarketingOfferApprovalRequestsArgs = {
  id: Scalars['ID'];
};

export type QueryCherryPaymentPlanEstimateSearchArgs = {
  filter: CherryPaymentPlanEstimateSearchFilter;
};

export type QueryPaymentIntentArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentsSetupIntentArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentsTransactionArgs = {
  id: Scalars['ID'];
};

export type QueryProviderOrganizationWalletByIdArgs = {
  id: Scalars['ID'];
};

export type QueryAllePayoutByIdArgs = {
  id: Scalars['ID'];
};

export type QueryPlatformSubscriptionByIdArgs = {
  id: Scalars['ID'];
};

export type QueryPlatformSubscriptionInvoiceByIdArgs = {
  id: Scalars['ID'];
};

export type QueryPayoutArgs = {
  id: Scalars['ID'];
};

export type QueryPointsRedemptionRateArgs = {
  date?: InputMaybe<Scalars['DateInput']>;
};

export type QueryAllerganProductByIdArgs = {
  id: Scalars['ID'];
};

export type QueryAllerganProductBundleByIdArgs = {
  id: Scalars['ID'];
};

export type QueryAllerganProductSearchArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AllerganProductSearchFilter>;
  sort?: InputMaybe<Array<AllerganProductSearchSort>>;
};

export type QueryAllerganProductOfferingSearchArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AllerganProductOfferingSearchFilter>;
  sort?: InputMaybe<Array<AllerganProductOfferingSearchSort>>;
};

export type QueryAllerganStoreShoppingCartArgs = {
  id: Scalars['ID'];
};

export type QueryAllerganStoreOrderArgs = {
  id: Scalars['ID'];
};

export type QueryAllerganStoreOrderSimulateArgs = {
  cartId?: InputMaybe<Scalars['ID']>;
  products: Array<AllerganStoreOrderSimulateProductInput>;
};

export type QueryAllerganProductCostByIdArgs = {
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  providerOrganizationId: Scalars['ID'];
};

export type QueryProductVariantByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type QueryLocationPublicProfileBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryLocationPublicProfileByOrgIdArgs = {
  providerOrganizationId: Scalars['ID'];
};

export type QueryProviderLocationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ProviderLocationsFilter>;
};

export type QueryProviderOrganizationByIdArgs = {
  id: Scalars['ID'];
};

export type QueryProviderOrganizationsByKeywordArgs = {
  keyword: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryPracticeArgs = {
  id: Scalars['ID'];
};

export type QueryPracticesByKeywordArgs = {
  keyword: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};

export type QueryReferringUserCodeInfoArgs = {
  referringUserCode: Scalars['String'];
};

export type QueryAdminReferringUserProfileArgs = {
  patientId: Scalars['ID'];
};

export type QueryActivePromotionsReportArgs = {
  page: ActivePromotionsReportPageInput;
};

export type QueryBotoxFiftyOffPromotionReportArgs = {
  page: BotoxFiftyOffPromotionReportPageInput;
};

export type QueryBotoxJuvedermLastTransactionReportArgs = {
  page: BotoxJuvedermLastTransactionSimplePageInput;
  range?: InputMaybe<DateRangeInput>;
};

export type QueryExpiringPointsReportArgs = {
  page: ExpiringPointsReportPageInput;
};

export type QueryExpiringPointsBusinessReportArgs = {
  page: ExpiringPointsBusinessReportPageInput;
};

export type QueryFlashRewardsReportArgs = {
  page: FlashRewardsReportPageInput;
};

export type QueryProductsLastTransactionReportArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  productIdsToInclude?: InputMaybe<Array<Scalars['ID']>>;
  productSet: Report_Filter_Products_Last_Transaction_Product_Set;
  range?: InputMaybe<DateRangeInput>;
  sort?: InputMaybe<Array<ProductsLastTransactionReportSort>>;
};

export type QueryPromotionReportArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  reportingPromotionId: Scalars['ID'];
  productsLastTransactionProductSet: Report_Filter_Promotion_Last_Transaction_Product_Set;
  sort?: InputMaybe<Array<PromotionReportSort>>;
};

export type QueryTransactionsReimbursementsReportArgs = {
  page: TransactionsReimbursementsReportPageInput;
};

export type QueryTransactionsReimbursementsBusinessReportArgs = {
  page: TransactionsReimbursementsBusinessReportPageInput;
  range?: InputMaybe<DateRangeInput>;
};

export type QueryUnusedGiftCardsReportArgs = {
  page: UnusedGiftCardsSimplePageInput;
  range: DateRangeInput;
};

export type QueryProviderSearchArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  searchInput: ProviderSearchInput;
};

export type QueryProviderSearchIndicatorsArgs = {
  profileSlug: Scalars['String'];
};

export type QueryProviderLocationBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryAllProviderProfileSlugsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryProviderSearchStateByNameArgs = {
  name: Us_State;
};

export type QueryProviderSearchV2Args = {
  searchInput: ProviderSearchV2Input;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryAllerganStorePricingTierByIdArgs = {
  id: Scalars['ID'];
};

export type QueryPatientTransactionSummaryArgs = {
  input: PatientTransactionInput;
};

export type QuerySms_LookupPhoneNumberArgs = {
  phoneNumber: Scalars['String'];
};

export type QuerySms_VerifyTwoFactorCodeArgs = {
  phoneNumber: Scalars['String'];
  code: Scalars['String'];
};

export type QueryOptInPromotionArgs = {
  id: Scalars['ID'];
};

export type QueryOptInPromotionClaimRequestArgs = {
  id: Scalars['ID'];
};

export type QueryPromotionArgs = {
  id: Scalars['ID'];
};

export type QueryAuditEventsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<AuditEventSort>>;
  filter?: InputMaybe<AuditEventFilter>;
};

export type QueryConsumerArgs = {
  where?: InputMaybe<ConsumerClause>;
};

export type QueryFindConsumerByNameArgs = {
  query?: InputMaybe<ConsumerByNameClause>;
};

export type QueryPatientArgs = {
  id: Scalars['ID'];
};

export type QueryPatientsByKeywordArgs = {
  keyword: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
};

export type QueryUserGetMigrationStatusArgs = {
  token: Scalars['String'];
};

export type QuestionSheetContent = {
  __typename?: 'QuestionSheetContent';
  sections: Array<QuestionSheetSection>;
};

export type QuestionSheetSection = {
  __typename?: 'QuestionSheetSection';
  link: CmsLink;
  content: QuestionSheetSectionContent;
};

export type QuestionSheetSectionContent =
  | QuestionSheetSectionContentIntro
  | QuestionSheetSectionContentFaq;

export type QuestionSheetSectionContentFaq = {
  __typename?: 'QuestionSheetSectionContentFaq';
  anchor: Scalars['String'];
  title: CmsContent;
  description?: CmsContent;
  faq: Array<FaqContent>;
};

export type QuestionSheetSectionContentIntro = {
  __typename?: 'QuestionSheetSectionContentIntro';
  anchor: Scalars['String'];
  title: CmsContent;
  description: CmsContent;
  isi: CmsContent;
  image: CmsImage;
};

export enum Receipt_Kind {
  Purchase = 'PURCHASE',
  Refund = 'REFUND',
}

export enum Referral_For_Admin_Status {
  Ineligible = 'INELIGIBLE',
  Eligible = 'ELIGIBLE',
  Completed = 'COMPLETED',
}

export enum Referring_User_Referral_Status {
  LimitExceeded = 'LIMIT_EXCEEDED',
  AwaitingTransaction = 'AWAITING_TRANSACTION',
  Completed = 'COMPLETED',
  TransactionPeriodExpired = 'TRANSACTION_PERIOD_EXPIRED',
}

export enum Refund_Reason {
  AccidentalCharge = 'ACCIDENTAL_CHARGE',
  FraudulentCharge = 'FRAUDULENT_CHARGE',
  ChangedMind = 'CHANGED_MIND',
  DissatisfiedCustomer = 'DISSATISFIED_CUSTOMER',
  Other = 'OTHER',
}

export enum Report_Export_Format {
  Csv = 'CSV',
  Xlsx = 'XLSX',
}

export enum Report_Filter_Products_Last_Transaction_Product_Set {
  SkinMedicaExcluded = 'SKIN_MEDICA_EXCLUDED',
}

export enum Report_Filter_Promotion_Last_Transaction_Product_Set {
  BotoxJuvederm = 'BOTOX_JUVEDERM',
}

export enum Report_Format {
  Csv = 'CSV',
  Pdf = 'PDF',
}

export enum Rewards_Inventory_Type {
  GiftCard = 'GIFT_CARD',
  OfficePromotion = 'OFFICE_PROMOTION',
  PatientPromotion = 'PATIENT_PROMOTION',
  Points = 'POINTS',
}

export enum Rewards_Payment_Type {
  Discount = 'DISCOUNT',
  Credit = 'CREDIT',
}

export type RecommendedAdConfiguration = {
  __typename?: 'RecommendedAdConfiguration';
  audience: AdvertisingPostAudience;
  adParameters: AdParameters;
};

export type Referral = {
  __typename?: 'Referral';
  id: Scalars['ID'];
  referringUserReferralStatus: Referring_User_Referral_Status;
  registrationDate: Date;
  referringUserPromotionInventory?: Maybe<PatientPromotion>;
  referralPromotionInventory?: Maybe<PatientPromotion>;
};

export type ReferralCreateFailure = {
  __typename?: 'ReferralCreateFailure';
  error: Error;
};

export type ReferralCreateInput = {
  referringUserCode: Scalars['String'];
  acceptedReferAFriendTerms?: InputMaybe<Scalars['Boolean']>;
};

export type ReferralCreatePayload =
  | ReferralCreateSuccess
  | ReferralCreateFailure;

export type ReferralCreateSuccess = {
  __typename?: 'ReferralCreateSuccess';
  referral: Referral;
};

export type ReferralForAdmin = {
  __typename?: 'ReferralForAdmin';
  patient: Patient;
  status: Referral_For_Admin_Status;
  treatmentDeadline: Date;
};

export type ReferralProgramData = {
  __typename?: 'ReferralProgramData';
  friendlyReferringUserCode: Scalars['String'];
  referralLink: Scalars['String'];
  referrals: ReferralsForAdminConnections;
};

export type ReferralProgramDataReferralsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ReferralsConnections = {
  __typename?: 'ReferralsConnections';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<Referral>;
};

export type ReferralsForAdminConnections = {
  __typename?: 'ReferralsForAdminConnections';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<ReferralForAdmin>;
};

export type ReferringUserCodeInfo = {
  __typename?: 'ReferringUserCodeInfo';
  isOverLimit: Scalars['Boolean'];
  friendlyReferringUserCode: Scalars['String'];
};

export type ReferringUserProfile = {
  __typename?: 'ReferringUserProfile';
  id: Scalars['ID'];
  /** @deprecated Used only during refer a friend pilot period */
  referringUserCode: Scalars['String'];
  friendlyReferringUserCode: Scalars['String'];
  referringUserLink: Scalars['String'];
  /** @deprecated Used only during refer a friend pilot period */
  referringUserPromotionToApply?: Maybe<PromotionConstruct>;
  /** @deprecated Used only during refer a friend pilot period */
  referralPromotionToApply?: Maybe<PromotionConstruct>;
  referrals: ReferralsConnections;
};

export type ReferringUserProfileReferralsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ReferringUserProfileCreateFailure = {
  __typename?: 'ReferringUserProfileCreateFailure';
  error: Error;
};

export type ReferringUserProfileCreateInput = {
  referringUserPromotionIdToApply: Scalars['ID'];
  referralPromotionIdToApply: Scalars['ID'];
};

export type ReferringUserProfileCreatePayload =
  | ReferringUserProfileCreateSuccess
  | ReferringUserProfileCreateFailure;

export type ReferringUserProfileCreateSuccess = {
  __typename?: 'ReferringUserProfileCreateSuccess';
  referringUserProfile: ReferringUserProfile;
};

export type RegisterUserResponse = {
  __typename?: 'RegisterUserResponse';
  status: Scalars['String'];
  message: Scalars['String'];
  token?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  validationErrors?: Maybe<Array<ValidationError>>;
  profile?: Maybe<Profile>;
};

export type ReimbursementLineItem = {
  __typename?: 'ReimbursementLineItem';
  payoutDate?: Maybe<DateTime>;
  paymentId: Scalars['ID'];
  status: ReimbursementStatus;
  type: ReimbursementType;
  valueInCents: Scalars['Int'];
};

export enum ReimbursementStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Paid = 'PAID',
  Pending = 'PENDING',
}

export enum ReimbursementType {
  AlleOffer = 'ALLE_OFFER',
  AllePoints = 'ALLE_POINTS',
  GiftCard = 'GIFT_CARD',
}

export type ReportExportError = {
  __typename?: 'ReportExportError';
  error: Error;
};

export type ReportExportPayload = ReportExportSuccess | ReportExportError;

export type ReportExportSuccess = {
  __typename?: 'ReportExportSuccess';
  url: Scalars['URL'];
};

export type ReportResult = {
  __typename?: 'ReportResult';
  uri: Scalars['String'];
};

export enum ReportSortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type ReportingPromotion = {
  __typename?: 'ReportingPromotion';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  status: Scalars['String'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<ValidationError>>;
  sessionToken?: Maybe<Scalars['String']>;
};

export enum Sap_Onboarding_Status {
  Approved = 'APPROVED',
  NotApproved = 'NOT_APPROVED',
  Processing = 'PROCESSING',
  Review = 'REVIEW',
  Submitted = 'SUBMITTED',
  SubmissionFailure = 'SUBMISSION_FAILURE',
  /** @deprecated This status is no longer in use. Use `SUBMITTED` instead. */
  InProgress = 'IN_PROGRESS',
  /** @deprecated This status is no longer in use. Use `NOT_APPROVED` instead. */
  Denied = 'DENIED',
}

export enum Search_Filter_Match_Type {
  TreatmentArea = 'TREATMENT_AREA',
  Product = 'PRODUCT',
  PracticeType = 'PRACTICE_TYPE',
  Other = 'OTHER',
}

export enum Search_Sort_Column {
  Default = 'DEFAULT',
  Name = 'NAME',
  CreatedAt = 'CREATED_AT',
  ShipToAccountNumber = 'SHIP_TO_ACCOUNT_NUMBER',
  ProfileCompletionPercentage = 'PROFILE_COMPLETION_PERCENTAGE',
  Distance = 'DISTANCE',
}

export enum Search_Sort_Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum Social_Media_Post_Status {
  /** The post is now live on the publisher platform (e.g. Facebook) */
  Active = 'ACTIVE',
  /**
   * The post has ended and is no longer active on the publisher platform (e.g.
   * Facebook)
   */
  Ended = 'ENDED',
  /**
   * The post is created and sent to the publisher (e.g. Facebook) for review.
   * Once the post is approved, the post will change its status to ACTIVE.
   */
  Pending = 'PENDING',
  /**
   * Publisher (e.g. Facebook) rejected the post.
   * Ops is looking into reinstating the post back to ACTIVE.
   */
  Rejected = 'REJECTED',
  /**
   * The post is stopped by the Publisher Platform (e.g. Facebook) due to
   * flagging issue.
   * Ops is looking into reinstating the post back to ACTIVE.
   */
  Stopped = 'STOPPED',
  /**
   * Campaign creation failed by some internal server issues, facebook api issues, etc
   * Other statues above are the statues when campaign is created on grow and facebook at least.
   * This status is to indicate that something went wrong before facebook campaign is really created.
   */
  Failed = 'FAILED',
  /** Campaign is paused by provider and it can be resumed before the end date of campaign */
  Paused = 'PAUSED',
}

export enum Spend_Limit_Mode {
  Daily = 'DAILY',
  Maximum = 'MAXIMUM',
}

export enum Subscription_Status {
  Trialing = 'TRIALING',
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  PastDue = 'PAST_DUE',
  Unpaid = 'UNPAID',
}

export type SearchHoursFilters = {
  __typename?: 'SearchHoursFilters';
  isOpenOnSaturday?: Maybe<Scalars['Boolean']>;
  isOpenOnSunday?: Maybe<Scalars['Boolean']>;
  isOpenPast5pm?: Maybe<Scalars['Boolean']>;
};

export type SearchProvider = {
  __typename?: 'SearchProvider';
  /** @deprecated Field no longer supported */
  id: Scalars['ID'];
  providerOrganizationId: Scalars['String'];
  parentProviderOrganizationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  shipToAccountNumber?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  avatarImageUrl?: Maybe<Scalars['String']>;
  profileSlug: Scalars['String'];
  practiceType?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  bookingUrl?: Maybe<Scalars['String']>;
  geoLocation: SearchProviderGeoPoint;
  address: SearchProviderAddress;
  businessHours: Array<SearchProviderBusinessHour>;
  galleryPhotos?: Maybe<Array<SearchProviderGalleryPhoto>>;
  consultationRequestSettings?: Maybe<SearchProviderConsultationRequestSettings>;
  productIds?: Maybe<Array<Scalars['String']>>;
  treatmentAreaIds?: Maybe<Array<Scalars['String']>>;
  profileCompletenessPercentage: Scalars['Float'];
  indicators: ProviderSearchIndicatorsConnection;
  offersFinancing?: Maybe<Scalars['Boolean']>;
  optInMarketingEvents: SearchProviderOptInMarketingEventsConnection;
};

export type SearchProviderAddress = {
  __typename?: 'SearchProviderAddress';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipcode: Scalars['String'];
};

export type SearchProviderBusinessHour = {
  __typename?: 'SearchProviderBusinessHour';
  day: Day_Of_The_Week;
  open?: Maybe<Scalars['String']>;
  close?: Maybe<Scalars['String']>;
  closed: Scalars['Boolean'];
  appointmentRequired?: Maybe<Scalars['Boolean']>;
};

export type SearchProviderConsultationRequestSettings = {
  __typename?: 'SearchProviderConsultationRequestSettings';
  hideForm: Scalars['Boolean'];
  displayVaryingFeeMessage: Scalars['Boolean'];
  sendRequestsTo: Scalars['String'];
  feeTowardsTreatmentCost?: Maybe<Scalars['Boolean']>;
  costInCents?: Maybe<Scalars['Int']>;
};

export type SearchProviderGalleryPhoto = {
  __typename?: 'SearchProviderGalleryPhoto';
  url: Scalars['String'];
  altText: Scalars['String'];
  displayOrder: Scalars['Float'];
};

export type SearchProviderGeoPoint = {
  __typename?: 'SearchProviderGeoPoint';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type SearchProviderOptInMarketingEventsConnection = {
  __typename?: 'SearchProviderOptInMarketingEventsConnection';
  nodes: Array<ProviderOptInMarketingEvent>;
};

export type SearchProviderSettings = {
  __typename?: 'SearchProviderSettings';
  displayAveragePricing?: Maybe<Scalars['Boolean']>;
};

export type SegmentMetadata = {
  __typename?: 'SegmentMetadata';
  /** This will always be the product covered in a Treatment Guide */
  productName?: Maybe<Scalars['String']>;
  /** Examples: crow's feet, chin, lips, etc */
  areaOfConcern?: Maybe<Array<Scalars['String']>>;
  contentType: Cms_Content_Type;
  contentHeadline: Scalars['String'];
  contentCampaign?: Maybe<Scalars['String']>;
  contentPublishDate: Scalars['String'];
  /** This will always be START_TREATMENT and INCREASE_PRODUCTS for Treatment Guides */
  consumerJourney?: Maybe<Array<Scalars['String']>>;
  visualType?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
};

/**
 * Web pages will transform an array of SeoMetaTags like:
 * [{ name: 'description', content: 'foobar' }]
 * into proper HTML meta tags:
 * <meta name="description" content="foobar" />
 */
export type SeoMetaTag = {
  __typename?: 'SeoMetaTag';
  name?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  content: Scalars['String'];
};

export type SeoMetadata = {
  __typename?: 'SeoMetadata';
  title: Scalars['String'];
  metaTags: Array<SeoMetaTag>;
};

export type SetupIntentCompleteFailure = {
  __typename?: 'SetupIntentCompleteFailure';
  error: Error;
};

export type SetupIntentCompleteInput = {
  /** This is for the front end to notify the backend a new payment method was created */
  walletId: Scalars['ID'];
  providerPaymentMethodId: Scalars['ID'];
};

export type SetupIntentCompletePayload =
  | SetupIntentCompleteSuccess
  | SetupIntentCompleteFailure;

export type SetupIntentCompleteSuccess = {
  __typename?: 'SetupIntentCompleteSuccess';
  paymentMethod: PaymentMethod;
};

export type SignedUploadForm = {
  __typename?: 'SignedUploadForm';
  url: Scalars['URL'];
  fields: Array<SignedUploadFormField>;
};

export type SignedUploadFormField = {
  __typename?: 'SignedUploadFormField';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type SignedUploadUrlError = {
  __typename?: 'SignedUploadURLError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type SignedUploadUrlInput = {
  publicId: Scalars['String'];
  folderName?: InputMaybe<Scalars['String']>;
};

export type SignedUploadUrlPayload =
  | SignedUploadUrlError
  | SignedUploadUrlSuccess;

export type SignedUploadUrlSuccess = {
  __typename?: 'SignedUploadURLSuccess';
  apiKey: Scalars['String'];
  cloudName: Scalars['String'];
  publicId: Scalars['String'];
  folderName?: Maybe<Scalars['String']>;
  signature: Scalars['String'];
  timestamp: Scalars['String'];
  url: Scalars['URL'];
};

export type SimplePageInput = {
  resultsPerPage: Scalars['Int'];
  currentPage: Scalars['Int'];
  sort?: InputMaybe<PatientTransactionsSort>;
};

export type SimplePagination = {
  __typename?: 'SimplePagination';
  hasPreviousPage: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  currentPage: Scalars['Int'];
  resultsPerPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  totalResults: Scalars['Int'];
  sort: PatientTransactionsSortType;
};

export type SocialMediaCuratedPost = {
  __typename?: 'SocialMediaCuratedPost';
  id: Scalars['ID'];
  brandName: Scalars['String'];
  ctaButtonLabel: Scalars['String'];
  description: Scalars['String'];
  displayUrl: Scalars['URL'];
  headline: Scalars['String'];
  imageUrl: Scalars['URL'];
  promotionName: Scalars['String'];
  caption: Scalars['String'];
};

export type SocialMediaPerformanceMetric = {
  __typename?: 'SocialMediaPerformanceMetric';
  value: Scalars['Int'];
};

export type SocialMediaPerformanceMetricsInput = {
  /** Filter global performance report whether on Paid vs Organic Posts. */
  filterByTargetingMode: Targeting_Mode;
};

export type SocialNetworkIntegration = FacebookIntegration;

export type SocialNetworkIntegrationCreateError = {
  __typename?: 'SocialNetworkIntegrationCreateError';
  error: Error;
};

export type SocialNetworkIntegrationCreateInput = {
  accessToken: Scalars['String'];
  practiceId?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Integration_Platform>;
};

export type SocialNetworkIntegrationCreatePayload =
  | SocialNetworkIntegrationCreateSuccess
  | SocialNetworkIntegrationCreateError;

export type SocialNetworkIntegrationCreateSuccess = {
  __typename?: 'SocialNetworkIntegrationCreateSuccess';
  socialNetworkIntegration: SocialNetworkIntegration;
};

export type SocialNetworkIntegrationDeleteError = {
  __typename?: 'SocialNetworkIntegrationDeleteError';
  error: Error;
};

export type SocialNetworkIntegrationDeleteInput = {
  id: Scalars['ID'];
};

export type SocialNetworkIntegrationDeletePayload =
  | SocialNetworkIntegrationDeleteSuccess
  | SocialNetworkIntegrationDeleteError;

export type SocialNetworkIntegrationDeleteSuccess = {
  __typename?: 'SocialNetworkIntegrationDeleteSuccess';
  id: Scalars['ID'];
};

export type SpecialHoursNotice = {
  __typename?: 'SpecialHoursNotice';
  eventName: Scalars['String'];
  message: Scalars['String'];
};

export type SubscriptionHistoryOrderBy = {
  column: Scalars['String'];
  direction: SubscriptionHistorySortDirection;
};

export enum SubscriptionHistorySortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

/**
 * SubscriptionsProfile is a type that contains information about the subscriptions profile of a provider organization.
 * displayStatus is the current status including business logic for the front end.  The statuses can currently be of the
 * types: active, activeDowngraded, canceled, trialing, trialDowngraded, neverActive, pastDueActive, pastDueCanceled
 */
export type SubscriptionsProfile = {
  __typename?: 'SubscriptionsProfile';
  isValidStripeAddress: Scalars['Boolean'];
  isEligibleForTrial: Scalars['Boolean'];
  subscriptionHistory: PlatformSubscriptionHistoryConnection;
  displayStatus: Scalars['String'];
};

/**
 * SubscriptionsProfile is a type that contains information about the subscriptions profile of a provider organization.
 * displayStatus is the current status including business logic for the front end.  The statuses can currently be of the
 * types: active, activeDowngraded, canceled, trialing, trialDowngraded, neverActive, pastDueActive, pastDueCanceled
 */
export type SubscriptionsProfileSubscriptionHistoryArgs = {
  filter?: InputMaybe<PlatformSubscriptionHistoryFilter>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SubscriptionHistoryOrderBy>;
};

export type SweepstakesConfirmEntryFailure = {
  __typename?: 'SweepstakesConfirmEntryFailure';
  error: Error;
};

export type SweepstakesConfirmEntryInput = {
  id: Scalars['ID'];
  consumerId: Scalars['ID'];
  metadata?: InputMaybe<Array<GenericMetadataInput>>;
};

export type SweepstakesConfirmEntryPayload =
  | SweepstakesConfirmEntrySuccess
  | SweepstakesConfirmEntryFailure;

export type SweepstakesConfirmEntrySuccess = {
  __typename?: 'SweepstakesConfirmEntrySuccess';
  acceptedAt: Date;
};

export type SystemActor = ActorV2 & {
  __typename?: 'SystemActor';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SystemUserActor = ActorV2 & {
  __typename?: 'SystemUserActor';
  id: Scalars['ID'];
};

export enum Targeting_Mode {
  All = 'ALL',
  Organic = 'ORGANIC',
  Paid = 'PAID',
}

export enum Transaction_Transfer_Reason {
  Errant = 'ERRANT',
  Archive = 'ARCHIVE',
  Acquisition = 'ACQUISITION',
}

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Taggable = {
  tags?: Maybe<TagsConnection>;
};

export type TaggableTagsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type TagsConnection = {
  __typename?: 'TagsConnection';
  offsetPageInfo: OffsetPageInfo;
  edges?: Maybe<Array<TagsEdge>>;
  nodes?: Maybe<Array<Tag>>;
};

export type TagsEdge = {
  __typename?: 'TagsEdge';
  node: Tag;
};

export type TaskCompleteFailure = {
  __typename?: 'TaskCompleteFailure';
  error: Error;
};

export type TaskCompleteInput = {
  id: Scalars['ID'];
};

export type TaskCompletePayload = TaskCompleteSuccess | TaskCompleteFailure;

export type TaskCompleteSuccess = {
  __typename?: 'TaskCompleteSuccess';
  taskStatus: TaskStatus;
};

export type TaskStatus = {
  __typename?: 'TaskStatus';
  id: Scalars['ID'];
  slug: Scalars['String'];
  completedAt?: Maybe<DateTime>;
};

export enum TransactionStatus {
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Pending = 'PENDING',
}

export type TransactionalEmailInput = {
  email: Scalars['String'];
  templateId: Scalars['String'];
  personalizations?: InputMaybe<Scalars['String']>;
};

export type TransactionalEmailResponse = {
  __typename?: 'TransactionalEmailResponse';
  message: Scalars['String'];
  success?: Maybe<Scalars['Boolean']>;
};

export type TransactionsReimbursementsBusinessReport = {
  __typename?: 'TransactionsReimbursementsBusinessReport';
  rowsConnection: TransactionsReimbursementsBusinessReportRowsConnection;
};

export type TransactionsReimbursementsBusinessReportCreateInput = {
  range?: InputMaybe<DateRangeInput>;
  reportExportFormat?: InputMaybe<Report_Export_Format>;
  sort: Array<TransactionsReimbursementsBusinessReportSort>;
};

export type TransactionsReimbursementsBusinessReportLocationRow = {
  __typename?: 'TransactionsReimbursementsBusinessReportLocationRow';
  provider: ProviderOrganization;
  /** @deprecated No longer used */
  transactionsTotalInCents?: Maybe<Scalars['Int']>;
  transactionsPending: Scalars['Int'];
  transactionsComplete: Scalars['Int'];
  transactionsCanceled: Scalars['Int'];
  reimbursementsTotalInCents: Scalars['Int'];
  reimbursementsPendingInCents: Scalars['Int'];
  reimbursementsPaidInCents: Scalars['Int'];
};

export type TransactionsReimbursementsBusinessReportPageInput = {
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort: Array<TransactionsReimbursementsBusinessReportSort>;
};

export type TransactionsReimbursementsBusinessReportRowsConnection = {
  __typename?: 'TransactionsReimbursementsBusinessReportRowsConnection';
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  nodes: Array<TransactionsReimbursementsBusinessReportLocationRow>;
};

export type TransactionsReimbursementsBusinessReportSort = {
  column?: TransactionsReimbursementsBusinessReportSortColumn;
  order?: ReportSortOrder;
};

export enum TransactionsReimbursementsBusinessReportSortColumn {
  TransactionsTotalInCents = 'TRANSACTIONS_TOTAL_IN_CENTS',
  TransactionsPending = 'TRANSACTIONS_PENDING',
  TransactionsComplete = 'TRANSACTIONS_COMPLETE',
  TransactionsCanceled = 'TRANSACTIONS_CANCELED',
  ReimbursementsTotalInCents = 'REIMBURSEMENTS_TOTAL_IN_CENTS',
  ReimbursementsPendingInCents = 'REIMBURSEMENTS_PENDING_IN_CENTS',
  ReimbursementsPaidInCents = 'REIMBURSEMENTS_PAID_IN_CENTS',
}

export type TransactionsReimbursementsReport = {
  __typename?: 'TransactionsReimbursementsReport';
  rowsConnection: TransactionsReimbursementsReportRowsConnection;
};

export type TransactionsReimbursementsReportCreateInput = {
  reportExportFormat?: InputMaybe<Report_Export_Format>;
  sort: Array<TransactionsReimbursementsReportSort>;
};

export type TransactionsReimbursementsReportPageInput = {
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort: Array<TransactionsReimbursementsReportSort>;
};

export type TransactionsReimbursementsReportRow = {
  __typename?: 'TransactionsReimbursementsReportRow';
  canEdit: Scalars['Boolean'];
  canCancel: Scalars['Boolean'];
  canApplyPromotions: Scalars['Boolean'];
  consumerId: Scalars['ID'];
  consumerFullName: Scalars['String'];
  isEdited: Scalars['Boolean'];
  practice: Practice;
  reimbursementsTotalInCents: Scalars['Int'];
  reimbursements?: Maybe<Array<Maybe<ReimbursementLineItem>>>;
  transactionCreatedAt: DateTime;
  transactionId: Scalars['ID'];
  transactionType: Scalars['String'];
  transactionStatus: TransactionStatus;
};

export type TransactionsReimbursementsReportRowsConnection = {
  __typename?: 'TransactionsReimbursementsReportRowsConnection';
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  nodes: Array<TransactionsReimbursementsReportRow>;
};

export type TransactionsReimbursementsReportSort = {
  column?: TransactionsReimbursementsReportSortColumn;
  order?: ReportSortOrder;
};

export enum TransactionsReimbursementsReportSortColumn {
  TransactionCreatedAt = 'TRANSACTION_CREATED_AT',
}

export type TreatmentGuide = {
  __typename?: 'TreatmentGuide';
  meta: CmsMetadata;
  content: TreatmentGuideContent;
};

export type TreatmentGuideCardContent = {
  __typename?: 'TreatmentGuideCardContent';
  image: CmsImage;
  title: CmsContent;
  subTitle: CmsContent;
  description: CmsContent;
  fact: CmsFactContent;
};

export type TreatmentGuideContent = TreatmentGuideContentLongForm;

export type TreatmentGuideContentLongForm = {
  __typename?: 'TreatmentGuideContentLongForm';
  card: TreatmentGuideCardContent;
  treatmentHero: TreatmentHeroContent;
  factSheet: FactSheetContent;
  questionSheet: QuestionSheetContent;
  /** @deprecated Use isis instead */
  legal: CmsLegalContent;
  isis?: Maybe<TreatmentGuideIsIsConnection>;
};

export type TreatmentGuideIsIsConnection = {
  __typename?: 'TreatmentGuideISIsConnection';
  nodes: Array<CmsLegalContent>;
};

export type TreatmentGuidesConnection = {
  __typename?: 'TreatmentGuidesConnection';
  guides: Array<TreatmentGuide>;
  totalCount: Scalars['Int'];
};

export type TreatmentHeroContent = {
  __typename?: 'TreatmentHeroContent';
  breadcrumb: Scalars['String'];
  image: CmsImage;
  title: CmsContent;
  subHeading: CmsContent;
  description: CmsContent;
  facts: Array<CmsFactContent>;
};

export enum Us_State {
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  California = 'CALIFORNIA',
  Colorado = 'COLORADO',
  Connecticut = 'CONNECTICUT',
  Delaware = 'DELAWARE',
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Hawaii = 'HAWAII',
  Idaho = 'IDAHO',
  Illinois = 'ILLINOIS',
  Indiana = 'INDIANA',
  Iowa = 'IOWA',
  Kansas = 'KANSAS',
  Kentucky = 'KENTUCKY',
  Louisiana = 'LOUISIANA',
  Maine = 'MAINE',
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Montana = 'MONTANA',
  Nebraska = 'NEBRASKA',
  Nevada = 'NEVADA',
  NewHampshire = 'NEW_HAMPSHIRE',
  NewJersey = 'NEW_JERSEY',
  NewMexico = 'NEW_MEXICO',
  NewYork = 'NEW_YORK',
  NorthCarolina = 'NORTH_CAROLINA',
  NorthDakota = 'NORTH_DAKOTA',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Oregon = 'OREGON',
  Pennsylvania = 'PENNSYLVANIA',
  RhodeIsland = 'RHODE_ISLAND',
  SouthCarolina = 'SOUTH_CAROLINA',
  SouthDakota = 'SOUTH_DAKOTA',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  Utah = 'UTAH',
  Vermont = 'VERMONT',
  Virginia = 'VIRGINIA',
  Washington = 'WASHINGTON',
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING',
}

export enum Us_State_Abbreviation {
  Al = 'AL',
  Ak = 'AK',
  Az = 'AZ',
  Ar = 'AR',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ia = 'IA',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Me = 'ME',
  Md = 'MD',
  Ma = 'MA',
  Mi = 'MI',
  Mn = 'MN',
  Ms = 'MS',
  Mo = 'MO',
  Mt = 'MT',
  Ne = 'NE',
  Nv = 'NV',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Ny = 'NY',
  Nc = 'NC',
  Nd = 'ND',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Vt = 'VT',
  Va = 'VA',
  Wa = 'WA',
  Wv = 'WV',
  Wi = 'WI',
  Wy = 'WY',
}

export type UnknownLeadSource = LeadSource & {
  __typename?: 'UnknownLeadSource';
  id: Scalars['ID'];
  url?: Maybe<Scalars['URL']>;
};

export type UnusedGiftCard = {
  __typename?: 'UnusedGiftCard';
  id: Scalars['ID'];
  date: DateTime;
  displayName: Scalars['String'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  valueInCents: Scalars['Int'];
};

export type UnusedGiftCardsConnection = {
  __typename?: 'UnusedGiftCardsConnection';
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  nodes: Array<UnusedGiftCard>;
};

export type UnusedGiftCardsReport = {
  __typename?: 'UnusedGiftCardsReport';
  rows: UnusedGiftCardsConnection;
};

export type UnusedGiftCardsReportCreateInput = {
  range: DateRangeInput;
  sort: Array<UnusedGiftCardsReportSort>;
  reportExportFormat?: InputMaybe<Report_Export_Format>;
};

export type UnusedGiftCardsReportSort = {
  column?: UnusedGiftCardsSortColumn;
  order?: ReportSortOrder;
};

export type UnusedGiftCardsSimplePageInput = {
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort: Array<UnusedGiftCardsSort>;
};

export type UnusedGiftCardsSort = {
  column: UnusedGiftCardsSortColumn;
  order: ReportSortOrder;
};

export enum UnusedGiftCardsSortColumn {
  Date = 'DATE',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  DisplayName = 'DISPLAY_NAME',
  Value = 'VALUE',
}

export type UpdatePaymentIntentInput = {
  paymentIntentId: Scalars['String'];
  amountInCents: Scalars['Int'];
};

export type UserActionTrackError = {
  __typename?: 'UserActionTrackError';
  error: Error;
};

export type UserActionTrackInput = {
  eventName: Scalars['String'];
  sourceUrl: Scalars['URL'];
  campaignId: Scalars['ID'];
  metadata?: InputMaybe<Array<GenericMetadataInput>>;
};

export type UserActionTrackPayload =
  | UserActionTrackSuccess
  | UserActionTrackError;

export type UserActionTrackSuccess = {
  __typename?: 'UserActionTrackSuccess';
  success: Scalars['Boolean'];
};

export type UserBdLoginResponse = {
  __typename?: 'UserBDLoginResponse';
  status: Scalars['String'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<ValidationError>>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  isExistingMember?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  bdConsumerId?: Maybe<Scalars['String']>;
};

export type UserCheckInError = {
  __typename?: 'UserCheckInError';
  error: Error;
};

export type UserCheckInPayload = UserCheckInSuccess | UserCheckInError;

export type UserCheckInReward = {
  __typename?: 'UserCheckInReward';
  promotion?: Maybe<PatientPromotion>;
  status?: Maybe<Check_In_Reward_Status>;
};

export type UserCheckInSuccess = {
  __typename?: 'UserCheckInSuccess';
  /** @deprecated See UserCheckInSuccess.reward */
  promotions?: Maybe<Array<PatientPromotion>>;
  reward?: Maybe<UserCheckInReward>;
};

export type UserCheckInToEventClaim =
  | UserCheckInToEventClaimCompleted
  | UserCheckInToEventClaimFailed
  | UserCheckInToEventClaimPending;

export type UserCheckInToEventClaimCompleted = {
  __typename?: 'UserCheckInToEventClaimCompleted';
  id: Scalars['ID'];
  promotion: PatientPromotion;
};

export type UserCheckInToEventClaimFailed = {
  __typename?: 'UserCheckInToEventClaimFailed';
  id: Scalars['ID'];
};

export type UserCheckInToEventClaimPending = {
  __typename?: 'UserCheckInToEventClaimPending';
  id: Scalars['ID'];
};

export type UserCheckInToEventError = {
  __typename?: 'UserCheckInToEventError';
  error: Error;
};

export type UserCheckInToEventInput = {
  eventSlug: Scalars['String'];
};

export type UserCheckInToEventPayload =
  | UserCheckInToEventSuccess
  | UserCheckInToEventPending
  | UserCheckInToEventError;

/** Use optInPromotionClaimId as id in query optInPromotionClaimRequest */
export type UserCheckInToEventPending = {
  __typename?: 'UserCheckInToEventPending';
  optInPromotionClaimId: Scalars['ID'];
};

export type UserCheckInToEventSuccess = {
  __typename?: 'UserCheckInToEventSuccess';
  reward?: Maybe<UserCheckInReward>;
};

export type UserCheckInToPracticeClaim =
  | UserCheckInToPracticeClaimCompleted
  | UserCheckInToPracticeClaimFailed
  | UserCheckInToPracticeClaimPending;

export type UserCheckInToPracticeClaimCompleted = {
  __typename?: 'UserCheckInToPracticeClaimCompleted';
  id: Scalars['ID'];
  promotion: PatientPromotion;
};

export type UserCheckInToPracticeClaimFailed = {
  __typename?: 'UserCheckInToPracticeClaimFailed';
  id: Scalars['ID'];
};

export type UserCheckInToPracticeClaimPending = {
  __typename?: 'UserCheckInToPracticeClaimPending';
  id: Scalars['ID'];
};

export type UserCheckInToPracticeError = {
  __typename?: 'UserCheckInToPracticeError';
  error: Error;
};

export type UserCheckInToPracticeInput = {
  shipToAccountNumber: Scalars['String'];
};

export type UserCheckInToPracticePayload =
  | UserCheckInToPracticeSuccess
  | UserCheckInToPracticeError;

export type UserCheckInToPracticePayloadV2 =
  | UserCheckInToPracticeError
  | UserCheckInToPracticePending
  | UserCheckInToPracticeSuccess;

/** Use optInPromotionClaimId as id in query optInPromotionClaimRequest */
export type UserCheckInToPracticePending = {
  __typename?: 'UserCheckInToPracticePending';
  optInPromotionClaimId: Scalars['ID'];
};

export type UserCheckInToPracticeSuccess = {
  __typename?: 'UserCheckInToPracticeSuccess';
  reward?: Maybe<UserCheckInReward>;
};

export type UserCheckInToPracticeV2Input = {
  shipToAccountNumber: Scalars['String'];
};

export type UserLinkBdAccountError = {
  __typename?: 'UserLinkBDAccountError';
  error?: Maybe<Error>;
};

export type UserLinkBdAccountInput = {
  token: Scalars['String'];
};

export type UserLinkBdAccountPayload =
  | UserLinkBdAccountSuccess
  | UserLinkBdAccountError;

export type UserLinkBdAccountSuccess = {
  __typename?: 'UserLinkBDAccountSuccess';
  activity?: Maybe<BdMigrationActivity>;
};

export type UserLogoutFailure = {
  __typename?: 'UserLogoutFailure';
  error: Error;
};

export type UserLogoutPayload = UserLogoutSuccess | UserLogoutFailure;

export type UserLogoutSuccess = {
  __typename?: 'UserLogoutSuccess';
  message: Scalars['String'];
};

export type UserMigrationStatusResponse = {
  __typename?: 'UserMigrationStatusResponse';
  status: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  migrationActivity?: Maybe<BdMigrationActivity>;
};

export type UserStartMigrationResponse = {
  __typename?: 'UserStartMigrationResponse';
  status: Scalars['String'];
  message: Scalars['String'];
};

export type UserUpdateCommunicationMethodError = {
  __typename?: 'UserUpdateCommunicationMethodError';
  error: Error;
};

export type UserUpdateCommunicationMethodInput = {
  method: Communication_Method;
  enabled: Scalars['Boolean'];
};

export type UserUpdateCommunicationMethodResponse =
  | UserUpdateCommunicationMethodSuccess
  | UserUpdateCommunicationMethodError;

export type UserUpdateCommunicationMethodSuccess = {
  __typename?: 'UserUpdateCommunicationMethodSuccess';
  profile?: Maybe<ConsumerProfile>;
};

export type UserUpdateEmailAddressError = {
  __typename?: 'UserUpdateEmailAddressError';
  error?: Maybe<Error>;
};

export type UserUpdateEmailAddressInput = {
  baseUrl: Scalars['String'];
  emailAddress: Scalars['String'];
};

export type UserUpdateEmailAddressResponse =
  | UserUpdateEmailAddressSuccess
  | UserUpdateEmailAddressError;

export type UserUpdateEmailAddressSuccess = {
  __typename?: 'UserUpdateEmailAddressSuccess';
  profile?: Maybe<ConsumerProfile>;
};

export type UserUpdatePasswordError = {
  __typename?: 'UserUpdatePasswordError';
  error: Error;
};

export type UserUpdatePasswordInput = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type UserUpdatePasswordResponse =
  | UserUpdatePasswordSuccess
  | UserUpdatePasswordError;

export type UserUpdatePasswordSuccess = {
  __typename?: 'UserUpdatePasswordSuccess';
  profile?: Maybe<ConsumerProfile>;
};

export type UserUpdatePhoneNumberError = {
  __typename?: 'UserUpdatePhoneNumberError';
  error?: Maybe<Error>;
};

export type UserUpdatePhoneNumberInput = {
  baseUrl: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type UserUpdatePhoneNumberResponse =
  | UserUpdatePhoneNumberSuccess
  | UserUpdatePhoneNumberError;

export type UserUpdatePhoneNumberSuccess = {
  __typename?: 'UserUpdatePhoneNumberSuccess';
  profile?: Maybe<ConsumerProfile>;
};

export type UserUpdateProfileError = {
  __typename?: 'UserUpdateProfileError';
  error: Error;
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type UserUpdateProfileInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  birthDate?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<BirthdayInput>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UserUpdateProfileResponse =
  | UserUpdateProfileSuccess
  | UserUpdateProfileError;

export type UserUpdateProfileSuccess = {
  __typename?: 'UserUpdateProfileSuccess';
  profile?: Maybe<ConsumerProfile>;
};

export type UserVerifyEmailResponse = {
  __typename?: 'UserVerifyEmailResponse';
  status: Scalars['String'];
  message: Scalars['String'];
};

export type UserVerifyPhoneChangeError = {
  __typename?: 'UserVerifyPhoneChangeError';
  error?: Maybe<Error>;
};

export type UserVerifyPhoneChangeInput = {
  phoneNumber: Scalars['String'];
  token: Scalars['String'];
};

export type UserVerifyPhoneChangeResponse =
  | UserVerifyPhoneChangeSuccess
  | UserVerifyPhoneChangeError;

export type UserVerifyPhoneChangeSuccess = {
  __typename?: 'UserVerifyPhoneChangeSuccess';
  message?: Maybe<Scalars['String']>;
};

export type ValidationError = {
  __typename?: 'ValidationError';
  fieldName: Scalars['String'];
  message: Scalars['String'];
};

export type VerifyEmailResponse = {
  __typename?: 'VerifyEmailResponse';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type VerifyForgotPasswordResponse = {
  __typename?: 'VerifyForgotPasswordResponse';
  stateToken?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  isVerified: Scalars['Boolean'];
};

export type VerifySmsCodeResponse = {
  __typename?: 'VerifySMSCodeResponse';
  status: Scalars['String'];
  message: Scalars['String'];
  token?: Maybe<Scalars['String']>;
  mergeToken?: Maybe<Scalars['String']>;
  isSMSPreferenceSet?: Maybe<Scalars['Boolean']>;
};

export type Viewer = {
  __typename?: 'Viewer';
  id: Scalars['ID'];
  patient?: Maybe<Patient>;
  profile: ViewerProfile;
};

export type ViewerLeadsConnection = {
  __typename?: 'ViewerLeadsConnection';
  offsetPageInfo: OffsetPageInfo;
  nodes: Array<Lead>;
};

export type ViewerMarketingOfferAudienceConnection = {
  __typename?: 'ViewerMarketingOfferAudienceConnection';
  nodes: Array<MarketingOfferAudience>;
};

export type ViewerMarketingOfferProviderAudienceConnection = {
  __typename?: 'ViewerMarketingOfferProviderAudienceConnection';
  nodes: Array<MarketingOfferProviderAudience>;
};

export type ViewerPatientTransactionsConnection = {
  __typename?: 'ViewerPatientTransactionsConnection';
  simplePagination: SimplePagination;
  transactions: Array<Maybe<PatientTransaction>>;
};

export type ViewerPermissions = {
  __typename?: 'ViewerPermissions';
  permissions: Array<Scalars['String']>;
  expireAt: DateTime;
};

export type ViewerPracticesConnection = {
  __typename?: 'ViewerPracticesConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ViewerPracticesEdge>>>;
  totalCount: Scalars['Int'];
};

export type ViewerPracticesEdge = {
  __typename?: 'ViewerPracticesEdge';
  cursor: Scalars['String'];
  node: Practice;
};

export type ViewerPractitionersConnection = {
  __typename?: 'ViewerPractitionersConnection';
  nodes: Array<Practitioner>;
};

export type ViewerProfile = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
};

export type ViewerProviderLocationsConnection = {
  __typename?: 'ViewerProviderLocationsConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ViewerProviderLocationsEdge>;
};

export type ViewerProviderLocationsEdge = {
  __typename?: 'ViewerProviderLocationsEdge';
  node: ProviderOrganization;
};

export type ViewerProviderUsersConnection = {
  __typename?: 'ViewerProviderUsersConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ViewerProviderUsersEdge>;
};

export type ViewerProviderUsersEdge = {
  __typename?: 'ViewerProviderUsersEdge';
  node: ProviderUser;
};

export type ViewerProviderWorkspacesConnection = {
  __typename?: 'ViewerProviderWorkspacesConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ViewerProviderWorkspacesEdge>;
};

export type ViewerProviderWorkspacesEdge = {
  __typename?: 'ViewerProviderWorkspacesEdge';
  node: ProviderWorkspace;
};

export type ViewerProviderWorkspacesV2Connection = {
  __typename?: 'ViewerProviderWorkspacesV2Connection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ViewerProviderWorkspacesV2Edge>;
};

export type ViewerProviderWorkspacesV2Edge = {
  __typename?: 'ViewerProviderWorkspacesV2Edge';
  node: ProviderWorkspaceV2;
};

export type ViewerProviderWorkspacesV3Connection = {
  __typename?: 'ViewerProviderWorkspacesV3Connection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ViewerProviderWorkspacesV3Edge>;
};

export type ViewerProviderWorkspacesV3Edge = {
  __typename?: 'ViewerProviderWorkspacesV3Edge';
  node: ProviderBusinessWorkspace;
};

export type ViewerReportingPromotionsConnection = {
  __typename?: 'ViewerReportingPromotionsConnection';
  nodes: Array<ReportingPromotion>;
};

export type ViewerResendPhoneVerificationInput = {
  baseUrl: Scalars['String'];
};

export type ViewerSocialMediaCuratedPostsConnection = {
  __typename?: 'ViewerSocialMediaCuratedPostsConnection';
  offsetPageInfo: OffsetPageInfo;
  edges: Array<ViewerSocialMediaCuratedPostsEdge>;
};

export type ViewerSocialMediaCuratedPostsEdge = {
  __typename?: 'ViewerSocialMediaCuratedPostsEdge';
  node: SocialMediaCuratedPost;
};

export type ViewerSocialMediaCuratedPostsSort = {
  column?: ViewerSocialMediaCuratedPostsSortColumn;
  order?: ViewerSocialMediaCuratedPostsSortOrder;
};

export enum ViewerSocialMediaCuratedPostsSortColumn {
  Date = 'DATE',
  Recommandation = 'RECOMMANDATION',
  Popularity = 'POPULARITY',
}

export enum ViewerSocialMediaCuratedPostsSortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type ViewerSocialMediaCurratedPostsConnection = {
  __typename?: 'ViewerSocialMediaCurratedPostsConnection';
  count: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  edges: Array<ViewerSocialMediaCuratedPostsEdge>;
};

export type ViewerSocialMediaCurratedPostsPageInput = {
  resultsPerPage?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort?: ViewerSocialMediaCuratedPostsSort;
};

export type ZipCodeDetails = {
  __typename?: 'ZipCodeDetails';
  city: Scalars['String'];
  state: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  zipCode: Scalars['String'];
  population: Scalars['Int'];
};

export type ZipCodeSearchResultsConnection = {
  __typename?: 'ZipCodeSearchResultsConnection';
  nodes: Array<ZipCodeDetails>;
};

export enum Join__Graph {
  AdminsLambdaService = 'ADMINS_LAMBDA_SERVICE',
  AppointmentApi = 'APPOINTMENT_API',
  AuthV2Service = 'AUTH_V2_SERVICE',
  BookService = 'BOOK_SERVICE',
  CatalogService = 'CATALOG_SERVICE',
  CheckoutLambdaService = 'CHECKOUT_LAMBDA_SERVICE',
  CheckoutSalesService = 'CHECKOUT_SALES_SERVICE',
  CmsService = 'CMS_SERVICE',
  ConsumerPreferencesService = 'CONSUMER_PREFERENCES_SERVICE',
  DeviceLocationService = 'DEVICE_LOCATION_SERVICE',
  EmailService = 'EMAIL_SERVICE',
  FlashService = 'FLASH_SERVICE',
  GiftcardsService = 'GIFTCARDS_SERVICE',
  GrowService = 'GROW_SERVICE',
  IamService = 'IAM_SERVICE',
  IdentityService = 'IDENTITY_SERVICE',
  InsightsService = 'INSIGHTS_SERVICE',
  LeadsService = 'LEADS_SERVICE',
  LocationTypeaheadService = 'LOCATION_TYPEAHEAD_SERVICE',
  MarketingNotificationService = 'MARKETING_NOTIFICATION_SERVICE',
  MatthewTestApp = 'MATTHEW_TEST_APP',
  OffersService = 'OFFERS_SERVICE',
  PatientFinancingService = 'PATIENT_FINANCING_SERVICE',
  PaymentsApi = 'PAYMENTS_API',
  PaymentsMoveMoneyApi = 'PAYMENTS_MOVE_MONEY_API',
  PaymentsSubscriptionsApi = 'PAYMENTS_SUBSCRIPTIONS_API',
  PayoutsService = 'PAYOUTS_SERVICE',
  PointsBankingService = 'POINTS_BANKING_SERVICE',
  PointsService = 'POINTS_SERVICE',
  PricingPurchasingService = 'PRICING_PURCHASING_SERVICE',
  ProductsService = 'PRODUCTS_SERVICE',
  ProfileManagerService = 'PROFILE_MANAGER_SERVICE',
  ProviderOrgService = 'PROVIDER_ORG_SERVICE',
  ProviderRewardsService = 'PROVIDER_REWARDS_SERVICE',
  ProvidersLambdaService = 'PROVIDERS_LAMBDA_SERVICE',
  ReferralsService = 'REFERRALS_SERVICE',
  ReportsService = 'REPORTS_SERVICE',
  SapFulfillmentService = 'SAP_FULFILLMENT_SERVICE',
  SearchService = 'SEARCH_SERVICE',
  ServicePricing = 'SERVICE_PRICING',
  ServiceRewardsCheckout = 'SERVICE_REWARDS_CHECKOUT',
  SmsService = 'SMS_SERVICE',
  TaskCompletionService = 'TASK_COMPLETION_SERVICE',
  TransactionsService = 'TRANSACTIONS_SERVICE',
  UserActivityTrailService = 'USER_ACTIVITY_TRAIL_SERVICE',
  UsersService = 'USERS_SERVICE',
}

export enum Link__Purpose {
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY',
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
}

export type Point_PendingPointsResult = {
  __typename?: 'point_PendingPointsResult';
  consumerId?: Maybe<Scalars['String']>;
  pendingPoints?: Maybe<Scalars['Int']>;
};

export type Sms_LookupResponse = {
  __typename?: 'sms_LookupResponse';
  type: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type Sms_Response = {
  __typename?: 'sms_Response';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type Sms_SmsMessageInput = {
  message: Scalars['String'];
  to: Scalars['String'];
};
