export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'ActorV2',
        possibleTypes: [
          {
            name: 'AdminUserActor',
          },
          {
            name: 'ConsumerUserActor',
          },
          {
            name: 'LegacyPracticeUserActor',
          },
          {
            name: 'SystemActor',
          },
          {
            name: 'SystemUserActor',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdsCampaignBudgetValidatePayload',
        possibleTypes: [
          {
            name: 'AdsCampaignBudgetValidateSuccess',
          },
          {
            name: 'AdsCampaignBudgetValidateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingAudienceCreatePayload',
        possibleTypes: [
          {
            name: 'AdvertisingAudienceCreateSuccess',
          },
          {
            name: 'AdvertisingAudienceCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingAudienceEmailsUploadFormCreatePayload',
        possibleTypes: [
          {
            name: 'AdvertisingAudienceEmailsUploadFormCreateSuccess',
          },
          {
            name: 'AdvertisingAudienceEmailsUploadFormCreateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingAudienceReachEstimatePayload',
        possibleTypes: [
          {
            name: 'AdvertisingAudienceReachEstimateSuccess',
          },
          {
            name: 'AdvertisingAudienceReachEstimateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingContactAutoCallProfileUpdatePayload',
        possibleTypes: [
          {
            name: 'AdvertisingContactAutoCallProfileUpdateSuccess',
          },
          {
            name: 'AdvertisingContactAutoCallProfileUpdateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingContactUpdateEmailAddressPayload',
        possibleTypes: [
          {
            name: 'AdvertisingContactUpdateEmailAddressSuccess',
          },
          {
            name: 'AdvertisingContactUpdateEmailAddressFailure',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'AdvertisingLeadNotificationMethod',
        possibleTypes: [
          {
            name: 'AdvertisingLeadNotificationEmailAddress',
          },
          {
            name: 'AdvertisingLeadNotificationSMSPhoneNumber',
          },
          {
            name: 'AdvertisingLeadNotificationVoiceCallPhoneNumber',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingLeadNotificationMethodRemovePayload',
        possibleTypes: [
          {
            name: 'AdvertisingLeadNotificationMethodRemoveSuccess',
          },
          {
            name: 'AdvertisingLeadNotificationMethodRemoveFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingLeadNotificationMethodSetEnablePayload',
        possibleTypes: [
          {
            name: 'AdvertisingLeadNotificationMethodSetEnableSuccess',
          },
          {
            name: 'AdvertisingLeadNotificationMethodSetEnableFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingLeadNotificationPhoneNumberRemovePayload',
        possibleTypes: [
          {
            name: 'AdvertisingLeadNotificationPhoneNumberRemoveSuccess',
          },
          {
            name: 'AdvertisingLeadNotificationPhoneNumberRemoveFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingLeadNotificationSMSPhoneNumberAddPayload',
        possibleTypes: [
          {
            name: 'AdvertisingLeadNotificationSMSPhoneNumberAddSuccess',
          },
          {
            name: 'AdvertisingLeadNotificationSMSPhoneNumberAddFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingLeadNotificationSMSPhoneNumberVerifyPayload',
        possibleTypes: [
          {
            name: 'AdvertisingLeadNotificationSMSPhoneNumberVerifySuccess',
          },
          {
            name: 'AdvertisingLeadNotificationSMSPhoneNumberVerifyFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingLeadNotificationVoiceCallPhoneNumberAddPayload',
        possibleTypes: [
          {
            name: 'AdvertisingLeadNotificationVoiceCallPhoneNumberAddSuccess',
          },
          {
            name: 'AdvertisingLeadNotificationVoiceCallPhoneNumberAddFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingLeadNotificationVoiceCallPhoneNumberRemovePayload',
        possibleTypes: [
          {
            name: 'AdvertisingLeadNotificationVoiceCallPhoneNumberRemoveSuccess',
          },
          {
            name: 'AdvertisingLeadNotificationVoiceCallPhoneNumberRemoveFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingLeadNotificationVoiceCallPhoneNumberUpdatePayload',
        possibleTypes: [
          {
            name: 'AdvertisingLeadNotificationVoiceCallPhoneNumberUpdateSuccess',
          },
          {
            name: 'AdvertisingLeadNotificationVoiceCallPhoneNumberUpdateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingLeadNotificationsAddEmailAddressPayload',
        possibleTypes: [
          {
            name: 'AdvertisingLeadNotificationsAddEmailAddressSuccess',
          },
          {
            name: 'AdvertisingLeadNotificationsAddEmailAddressFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingLeadNotificationsAddSMSPhoneNumberPayload',
        possibleTypes: [
          {
            name: 'AdvertisingLeadNotificationsAddSMSPhoneNumberSuccess',
          },
          {
            name: 'AdvertisingLeadNotificationsAddSMSPhoneNumberFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingLeadNotificationsAddVoiceCallPhoneNumberPayload',
        possibleTypes: [
          {
            name: 'AdvertisingLeadNotificationsAddVoiceCallPhoneNumberSuccess',
          },
          {
            name: 'AdvertisingLeadNotificationsAddVoiceCallPhoneNumberFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingLeadNotificationsVerifyEmailAddressPayload',
        possibleTypes: [
          {
            name: 'AdvertisingLeadNotificationsVerifyEmailAddressSuccess',
          },
          {
            name: 'AdvertisingLeadNotificationsVerifyEmailAddressFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingLeadNotificationsVerifySMSPhoneNumberPayload',
        possibleTypes: [
          {
            name: 'AdvertisingLeadNotificationsVerifySMSPhoneNumberSuccess',
          },
          {
            name: 'AdvertisingLeadNotificationsVerifySMSPhoneNumberFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingNotificationMethodVerifyPayload',
        possibleTypes: [
          {
            name: 'AdvertisingNotificationMethodVerifySuccess',
          },
          {
            name: 'AdvertisingNotificationMethodVerifyFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingOfferActivatePayload',
        possibleTypes: [
          {
            name: 'AdvertisingOfferActivateSuccess',
          },
          {
            name: 'AdvertisingOfferActivateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingProfileAcceptTermsAndConditionsPayload',
        possibleTypes: [
          {
            name: 'AdvertisingProfileAcceptTermsAndConditionsSuccess',
          },
          {
            name: 'AdvertisingProfileAcceptTermsAndConditionsFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdvertisingProfileSetPhoneNotificationMethodPayload',
        possibleTypes: [
          {
            name: 'AdvertisingProfileSetPhoneNotificationMethodSuccess',
          },
          {
            name: 'AdvertisingProfileSetPhoneNotificationMethodFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganProductOffering',
        possibleTypes: [
          {
            name: 'AllerganProduct',
          },
          {
            name: 'AllerganStoreProductBundle',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreCreditCardAddPayload',
        possibleTypes: [
          {
            name: 'AllerganStoreCreditCardAddSuccess',
          },
          {
            name: 'AllerganStoreCreditCardAddFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreCreditCardDeletePayload',
        possibleTypes: [
          {
            name: 'AllerganStoreCreditCardDeleteSuccess',
          },
          {
            name: 'AllerganStoreCreditCardDeleteFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreCreditCardFormCreatePayload',
        possibleTypes: [
          {
            name: 'AllerganStoreCreditCardFormCreateSuccess',
          },
          {
            name: 'AllerganStoreCreditCardFormCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreCreditCardSetIsDefaultPayload',
        possibleTypes: [
          {
            name: 'AllerganStoreCreditCardSetIsDefaultSuccess',
          },
          {
            name: 'AllerganStoreCreditCardSetIsDefaultFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreInvoiceGenerateReceiptFilePayload',
        possibleTypes: [
          {
            name: 'AllerganStoreInvoiceGenerateReceiptFileSuccess',
          },
          {
            name: 'AllerganStoreInvoiceGenerateReceiptFileFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreInvoicesPayPayload',
        possibleTypes: [
          {
            name: 'AllerganStoreInvoicesPaySuccess',
          },
          {
            name: 'AllerganStoreInvoicesPayFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreOrderCreatePayload',
        possibleTypes: [
          {
            name: 'AllerganStoreOrderCreateSuccess',
          },
          {
            name: 'AllerganStoreOrderCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreOrderGenerateInvoiceFilePayload',
        possibleTypes: [
          {
            name: 'AllerganStoreOrderGenerateInvoiceFileSuccess',
          },
          {
            name: 'AllerganStoreOrderGenerateInvoiceFileFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreOrderPaymentMethod',
        possibleTypes: [
          {
            name: 'AllerganStoreCreditCard',
          },
          {
            name: 'AllerganStoreUnknownPaymentMethod',
          },
          {
            name: 'AllerganStoreBillMeLater',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreShoppingCartAddProductPayload',
        possibleTypes: [
          {
            name: 'AllerganStoreShoppingCartAddProductSuccess',
          },
          {
            name: 'AllerganStoreShoppingCartAddProductFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreShoppingCartAddProductsFromOrderPayload',
        possibleTypes: [
          {
            name: 'AllerganStoreShoppingCartAddProductsFromOrderSuccess',
          },
          {
            name: 'AllerganStoreShoppingCartAddProductsFromOrderFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreShoppingCartCreatePayload',
        possibleTypes: [
          {
            name: 'AllerganStoreShoppingCartCreateSuccess',
          },
          {
            name: 'AllerganStoreShoppingCartCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreShoppingCartDeletePayload',
        possibleTypes: [
          {
            name: 'AllerganStoreShoppingCartDeleteSuccess',
          },
          {
            name: 'AllerganStoreShoppingCartDeleteFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreShoppingCartPaymentMethod',
        possibleTypes: [
          {
            name: 'AllerganStoreCreditCard',
          },
          {
            name: 'AllerganStoreBillMeLater',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreShoppingCartReplaceProductsFromOrderPayload',
        possibleTypes: [
          {
            name: 'AllerganStoreShoppingCartReplaceProductsFromOrderSuccess',
          },
          {
            name: 'AllerganStoreShoppingCartReplaceProductsFromOrderFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreShoppingCartSetPaymentMethodToBillMeLaterPayload',
        possibleTypes: [
          {
            name: 'AllerganStoreShoppingCartSetPaymentMethodToBillMeLaterSuccess',
          },
          {
            name: 'AllerganStoreShoppingCartSetPaymentMethodToBillMeLaterFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreShoppingCartUpdateCreditCardPayload',
        possibleTypes: [
          {
            name: 'AllerganStoreShoppingCartUpdateCreditCardSuccess',
          },
          {
            name: 'AllerganStoreShoppingCartUpdateCreditCardFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreShoppingCartUpdateProductQuantityPayload',
        possibleTypes: [
          {
            name: 'AllerganStoreShoppingCartUpdateProductQuantitySuccess',
          },
          {
            name: 'AllerganStoreShoppingCartUpdateProductQuantityFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreShoppingCartUpdatePurchaseOrderNamePayload',
        possibleTypes: [
          {
            name: 'AllerganStoreShoppingCartUpdatePurchaseOrderNameSuccess',
          },
          {
            name: 'AllerganStoreShoppingCartUpdatePurchaseOrderNameFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AllerganStoreShoppingCartUpdateShippingMethodPayload',
        possibleTypes: [
          {
            name: 'AllerganStoreShoppingCartUpdateShippingMethodSuccess',
          },
          {
            name: 'AllerganStoreShoppingCartUpdateShippingMethodFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'BusinessOnboardByInvitationPayload',
        possibleTypes: [
          {
            name: 'BusinessOnboardByInvitationSuccess',
          },
          {
            name: 'BusinessOnboardByInvitationFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CMSArticleBodySection',
        possibleTypes: [
          {
            name: 'CMSArticleChecklistComparisonSection',
          },
          {
            name: 'CMSArticleHeroSection',
          },
          {
            name: 'CMSContentSection',
          },
          {
            name: 'CMSFootnoteSection',
          },
          {
            name: 'CMSImageSection',
          },
          {
            name: 'CMSJoinBannerSection',
          },
          {
            name: 'CMSListSection',
          },
          {
            name: 'CMSVideoSection',
          },
          {
            name: 'FactSheetContent',
          },
          {
            name: 'QuestionSheetSectionContentFaq',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CMSArticleContent',
        possibleTypes: [
          {
            name: 'CMSArticleContentLongForm',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CMSFeaturedItem',
        possibleTypes: [
          {
            name: 'CMSArticle',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CatalogCategory',
        possibleTypes: [
          {
            name: 'LocationCatalogCategory',
          },
          {
            name: 'BaseCatalogCategory',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ChangeRequestApprovePayload',
        possibleTypes: [
          {
            name: 'ChangeRequestApproveSuccess',
          },
          {
            name: 'ChangeRequestApproveError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ChangeRequestDeclinePayload',
        possibleTypes: [
          {
            name: 'ChangeRequestDeclineSuccess',
          },
          {
            name: 'ChangeRequestDeclineError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ChangeRequestDeletePayload',
        possibleTypes: [
          {
            name: 'ChangeRequestDeleteSuccess',
          },
          {
            name: 'ChangeRequestDeleteError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ChangeRequestIncreaseInventoryAllocationUpdatePayload',
        possibleTypes: [
          {
            name: 'ChangeRequestIncreaseInventoryAllocationUpdateSuccess',
          },
          {
            name: 'ChangeRequestIncreaseInventoryAllocationUpdateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ChangeRequestModifyEndDateUpdatePayload',
        possibleTypes: [
          {
            name: 'ChangeRequestModifyEndDateUpdateSuccess',
          },
          {
            name: 'ChangeRequestModifyEndDateUpdateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartAddLineItemPayload',
        possibleTypes: [
          {
            name: 'CheckoutCartAddLineItemSuccess',
          },
          {
            name: 'CheckoutCartAddLineItemFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartClearLineItemPriceAdjustmentPayload',
        possibleTypes: [
          {
            name: 'CheckoutCartClearLineItemPriceAdjustmentSuccess',
          },
          {
            name: 'CheckoutCartClearLineItemPriceAdjustmentFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartClearLineItemStaffMemberPayload',
        possibleTypes: [
          {
            name: 'CheckoutCartClearLineItemStaffMemberSuccess',
          },
          {
            name: 'CheckoutCartClearLineItemStaffMemberFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartCreatePayload',
        possibleTypes: [
          {
            name: 'CheckoutCartCreateSuccess',
          },
          {
            name: 'CheckoutCartCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartCreateTerminalReaderPaymentLineItemPayload',
        possibleTypes: [
          {
            name: 'CheckoutCartCreateTerminalReaderPaymentLineItemSuccess',
          },
          {
            name: 'CheckoutCartCreateTerminalReaderPaymentLineItemFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartDeletePayload',
        possibleTypes: [
          {
            name: 'CheckoutCartDeleteSuccess',
          },
          {
            name: 'CheckoutCartDeleteFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartLineItemPriceAdjustment',
        possibleTypes: [
          {
            name: 'CheckoutCartLineItemPriceAdjustmentPriceOverride',
          },
          {
            name: 'CheckoutCartLineItemPriceAdjustmentDiscount',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartPaymentLineItem',
        possibleTypes: [
          {
            name: 'CheckoutCartTerminalReaderPaymentLineItem',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartRemoveLineItemPayload',
        possibleTypes: [
          {
            name: 'CheckoutCartRemoveLineItemSuccess',
          },
          {
            name: 'CheckoutCartRemoveLineItemFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartRemoveRewardsLineItemPayload',
        possibleTypes: [
          {
            name: 'CheckoutCartRemoveRewardsLineItemSuccess',
          },
          {
            name: 'CheckoutCartRemoveRewardsLineItemFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartSetLineItemPriceAdjustmentDiscountPayload',
        possibleTypes: [
          {
            name: 'CheckoutCartSetLineItemPriceAdjustmentDiscountSuccess',
          },
          {
            name: 'CheckoutCartSetLineItemPriceAdjustmentDiscountFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartSetLineItemPriceAdjustmentPriceOverridePayload',
        possibleTypes: [
          {
            name: 'CheckoutCartSetLineItemPriceAdjustmentPriceOverrideSuccess',
          },
          {
            name: 'CheckoutCartSetLineItemPriceAdjustmentPriceOverrideFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartSetLineItemStaffMemberPayload',
        possibleTypes: [
          {
            name: 'CheckoutCartSetLineItemStaffMemberSuccess',
          },
          {
            name: 'CheckoutCartSetLineItemStaffMemberFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartSubmitOrderPayload',
        possibleTypes: [
          {
            name: 'CheckoutCartSubmitOrderSuccess',
          },
          {
            name: 'CheckoutCartSubmitOrderFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartUpdateLineItemPayload',
        possibleTypes: [
          {
            name: 'CheckoutCartUpdateLineItemSuccess',
          },
          {
            name: 'CheckoutCartUpdateLineItemFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartUpdatePatientPayload',
        possibleTypes: [
          {
            name: 'CheckoutCartUpdatePatientSuccess',
          },
          {
            name: 'CheckoutCartUpdatePatientFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutCartUpdateRewardsPayload',
        possibleTypes: [
          {
            name: 'CheckoutCartUpdateRewardsSuccess',
          },
          {
            name: 'CheckoutCartUpdateRewardsFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutOrderCreateRefundPayload',
        possibleTypes: [
          {
            name: 'CheckoutOrderCreateRefundSuccess',
          },
          {
            name: 'CheckoutOrderCreateRefundFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutOrderLineItemPriceAdjustment',
        possibleTypes: [
          {
            name: 'CheckoutOrderLineItemPriceAdjustmentPriceOverride',
          },
          {
            name: 'CheckoutOrderLineItemPriceAdjustmentDiscount',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutOrderRerunPayload',
        possibleTypes: [
          {
            name: 'CheckoutOrderRerunSuccess',
          },
          {
            name: 'CheckoutOrderRerunFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutOrderSendEmailReceiptPayload',
        possibleTypes: [
          {
            name: 'CheckoutOrderSendEmailReceiptSuccess',
          },
          {
            name: 'CheckoutOrderSendEmailReceiptFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CheckoutSalesReportCreatePayload',
        possibleTypes: [
          {
            name: 'CheckoutSalesReportCreateSuccess',
          },
          {
            name: 'CheckoutSalesReportCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CherryFinancingApplicationInitiatePayload',
        possibleTypes: [
          {
            name: 'CherryFinancingApplicationInitiateSuccess',
          },
          {
            name: 'CherryFinancingApplicationInitiateSuccessWithExisting',
          },
          {
            name: 'CherryFinancingApplicationInitiateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CherryFinancingApplicationRefreshPayload',
        possibleTypes: [
          {
            name: 'CherryFinancingApplicationRefreshAdditionalInformation',
          },
          {
            name: 'CherryFinancingApplicationRefreshSuccess',
          },
          {
            name: 'CherryFinancingApplicationRefreshFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CherryPaymentApprovalRequestCancelPayload',
        possibleTypes: [
          {
            name: 'CherryPaymentApprovalRequestCancelSuccess',
          },
          {
            name: 'CherryPaymentApprovalRequestCancelFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CherryPaymentApprovalRequestCreatePayload',
        possibleTypes: [
          {
            name: 'CherryPaymentApprovalRequestCreateSuccess',
          },
          {
            name: 'CherryPaymentApprovalRequestCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CherryProviderOrganizationOnboardCompletePayload',
        possibleTypes: [
          {
            name: 'CherryProviderOrganizationOnboardCompleteSuccess',
          },
          {
            name: 'CherryProviderOrganizationOnboardCompleteFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CherryProviderOrganizationOnboardInitiatePayload',
        possibleTypes: [
          {
            name: 'CherryProviderOrganizationOnboardInitiateSuccess',
          },
          {
            name: 'CherryProviderOrganizationOnboardInitiateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CherrySendSMSInvitationPayload',
        possibleTypes: [
          {
            name: 'CherrySendSMSInvitationSuccess',
          },
          {
            name: 'CherrySendSMSInvitationFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ConnectAccountRedirectUrlCreatePayload',
        possibleTypes: [
          {
            name: 'ConnectAccountRedirectUrlCreateSuccess',
          },
          {
            name: 'ConnectAccountRedirectUrlCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ConsultationRequestCreatePayload',
        possibleTypes: [
          {
            name: 'ConsultationRequestCreateError',
          },
          {
            name: 'ConsultationRequestCreateSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ConsumerConsentCommunicationPreferencesUpdatePayload',
        possibleTypes: [
          {
            name: 'ConsumerConsentCommunicationPreferencesUpdateSuccess',
          },
          {
            name: 'ConsumerConsentCommunicationPreferencesUpdateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ConsumerConsentCommunicationPreferencesUpdateUnsubscribeFromAllPayload',
        possibleTypes: [
          {
            name: 'ConsumerConsentCommunicationPreferencesUpdateUnsubscribeFromAllSuccess',
          },
          {
            name: 'ConsumerConsentCommunicationPreferencesUpdateUnsubscribeFromAllFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ConsumerConsentPrivacySettingsLimitSensitiveDataUsePayload',
        possibleTypes: [
          {
            name: 'ConsumerConsentPrivacySettingsLimitSensitiveDataUseSuccess',
          },
          {
            name: 'ConsumerConsentPrivacySettingsLimitSensitiveDataUseFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ConsumerConsentPrivacySettingsUpdatePayload',
        possibleTypes: [
          {
            name: 'ConsumerConsentPrivacySettingsUpdateSuccess',
          },
          {
            name: 'ConsumerConsentPrivacySettingsUpdateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ConsumerConsentPrivacyTermsAcceptUpdatedTermsPayload',
        possibleTypes: [
          {
            name: 'ConsumerConsentPrivacyTermsAcceptUpdatedTermsSuccess',
          },
          {
            name: 'ConsumerConsentPrivacyTermsAcceptUpdatedTermsFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ConsumerDeactivatePayload',
        possibleTypes: [
          {
            name: 'ConsumerDeactivateSuccess',
          },
          {
            name: 'ConsumerDeactivateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ConsumerDeletePayload',
        possibleTypes: [
          {
            name: 'ConsumerDeleteSuccess',
          },
          {
            name: 'ConsumerDeleteFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ConsumerUpdateEmailAddressResponse',
        possibleTypes: [
          {
            name: 'ConsumerUpdateEmailAddressSuccess',
          },
          {
            name: 'ConsumerUpdateEmailAddressError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ConsumerUpdateProfileResponse',
        possibleTypes: [
          {
            name: 'ConsumerUpdateProfileSuccess',
          },
          {
            name: 'ConsumerUpdateProfileError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ContactsFileValidatePayload',
        possibleTypes: [
          {
            name: 'ContactsFileValidateSuccess',
          },
          {
            name: 'ContactsFileValidateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ContactsUploadUrlCreatePayload',
        possibleTypes: [
          {
            name: 'ContactsUploadUrlCreateSuccess',
          },
          {
            name: 'ContactsUploadUrlCreateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'DirectoryConsultationRequestCreatePayload',
        possibleTypes: [
          {
            name: 'DirectoryConsultationRequestCreateSuccess',
          },
          {
            name: 'DirectoryConsultationRequestCreateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'FacebookPostCreatePayload',
        possibleTypes: [
          {
            name: 'FacebookPostCreateSuccess',
          },
          {
            name: 'FacebookPostCreateError',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'FieldValidationError',
        possibleTypes: [
          {
            name: 'ProviderSettingsInvalidFieldError',
          },
          {
            name: 'ProviderSettingsMissingFieldError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'GiftCardCreatePayload',
        possibleTypes: [
          {
            name: 'GiftCardCreateSuccess',
          },
          {
            name: 'GiftCardCreateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'GiftCardDeactivatePayload',
        possibleTypes: [
          {
            name: 'GiftCardDeactivateSuccess',
          },
          {
            name: 'GiftCardDeactivateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'GiftCardRedemptionRuleSet',
        possibleTypes: [
          {
            name: 'AtLeastOneBrandRedemptionRule',
          },
          {
            name: 'AtLeastOneProductRedemptionRule',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'GiftCardRemovePayload',
        possibleTypes: [
          {
            name: 'GiftCardRemoveSuccess',
          },
          {
            name: 'GiftCardRemoveError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'GiftCardSharePayload',
        possibleTypes: [
          {
            name: 'GiftCardShareSuccess',
          },
          {
            name: 'GiftCardShareError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'GrowActor',
        possibleTypes: [
          {
            name: 'GrowSystemUser',
          },
          {
            name: 'Actor',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'HumanActor',
        possibleTypes: [
          {
            name: 'AdminUserActor',
          },
          {
            name: 'ConsumerUserActor',
          },
          {
            name: 'LegacyPracticeUserActor',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LeadAddNotePayload',
        possibleTypes: [
          {
            name: 'LeadAddNoteSuccess',
          },
          {
            name: 'LeadAddNoteFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LeadContactedStatusUpdatePayload',
        possibleTypes: [
          {
            name: 'LeadContactedStatusUpdateSuccess',
          },
          {
            name: 'LeadContactedStatusUpdateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LeadNoteEditPayload',
        possibleTypes: [
          {
            name: 'LeadNoteEditSuccess',
          },
          {
            name: 'LeadNoteEditFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LeadNoteRemovePayload',
        possibleTypes: [
          {
            name: 'LeadNoteRemoveSuccess',
          },
          {
            name: 'LeadNoteRemoveFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LeadSetHasBookedAppointmentPayload',
        possibleTypes: [
          {
            name: 'LeadSetHasBookedAppointmentSuccess',
          },
          {
            name: 'LeadSetHasBookedAppointmentFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LeadSetIsViewedPayload',
        possibleTypes: [
          {
            name: 'LeadSetIsViewedSuccess',
          },
          {
            name: 'LeadSetIsViewedError',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'LeadSource',
        possibleTypes: [
          {
            name: 'AdvertisingLeadSource',
          },
          {
            name: 'ConsultationRequestLeadSource',
          },
          {
            name: 'FindAProviderLeadSource',
          },
          {
            name: 'UnknownLeadSource',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LeadsExportPayload',
        possibleTypes: [
          {
            name: 'LeadsExportSuccess',
          },
          {
            name: 'LeadsExportError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationCatalogCreatePayload',
        possibleTypes: [
          {
            name: 'LocationCatalogCreateFailure',
          },
          {
            name: 'LocationCatalogCreateSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationCatalogEnhancedCheckoutSetupStatusUpdatePayload',
        possibleTypes: [
          {
            name: 'LocationCatalogEnhancedCheckoutSetupStatusUpdateFailure',
          },
          {
            name: 'LocationCatalogEnhancedCheckoutSetupStatusUpdateSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationCatalogItemCreatePayload',
        possibleTypes: [
          {
            name: 'LocationCatalogItemCreateSuccess',
          },
          {
            name: 'LocationCatalogItemCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationCatalogItemEditPayload',
        possibleTypes: [
          {
            name: 'LocationCatalogItemEditSuccess',
          },
          {
            name: 'LocationCatalogItemEditFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationCatalogItemSetLocationFavoritePayload',
        possibleTypes: [
          {
            name: 'LocationCatalogItemSetLocationFavoriteFailure',
          },
          {
            name: 'LocationCatalogItemSetLocationFavoriteSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationCatalogItemSetManufacturerAvailabilityPayload',
        possibleTypes: [
          {
            name: 'LocationCatalogItemSetManufacturerAvailabilityFailure',
          },
          {
            name: 'LocationCatalogItemSetManufacturerAvailabilitySuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationCatalogItemSetStatusPayload',
        possibleTypes: [
          {
            name: 'LocationCatalogItemSetStatusFailure',
          },
          {
            name: 'LocationCatalogItemSetStatusSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationCatalogItemUpdatePayload',
        possibleTypes: [
          {
            name: 'LocationCatalogItemUpdateSuccess',
          },
          {
            name: 'LocationCatalogItemUpdateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationCatalogSetBaseCatalogItemsPayload',
        possibleTypes: [
          {
            name: 'LocationCatalogSetBaseCatalogItemsFailure',
          },
          {
            name: 'LocationCatalogSetBaseCatalogItemsSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationCatalogSetTaxRatesPayload',
        possibleTypes: [
          {
            name: 'LocationCatalogSetTaxRatesFailure',
          },
          {
            name: 'LocationCatalogSetTaxRatesSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileBatchDraftAddGalleryPhotosPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileBatchDraftAddGalleryPhotosError',
          },
          {
            name: 'LocationPublicProfileBatchDraftAddGalleryPhotosSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileBatchDraftAddProductsPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileBatchDraftAddProductsError',
          },
          {
            name: 'LocationPublicProfileBatchDraftAddProductsSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileBatchDraftApplyPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileBatchDraftApplyError',
          },
          {
            name: 'LocationPublicProfileBatchDraftApplySuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileBatchDraftGalleryPhotosDeletePayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileBatchDraftGalleryPhotosDeleteError',
          },
          {
            name: 'LocationPublicProfileBatchDraftGalleryPhotosDeleteSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileBatchDraftGalleryPhotosUpdatePayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileBatchDraftGalleryPhotosUpdateError',
          },
          {
            name: 'LocationPublicProfileBatchDraftGalleryPhotosUpdateSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileBatchDraftProductsDeletePayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileBatchDraftProductsDeleteError',
          },
          {
            name: 'LocationPublicProfileBatchDraftProductsDeleteSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileBatchDraftUpdateAvatarUrlPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileBatchDraftUpdateAvatarUrlError',
          },
          {
            name: 'LocationPublicProfileBatchDraftUpdateAvatarUrlSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileBatchDraftUpdateContactInformationPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileBatchDraftUpdateContactInformationError',
          },
          {
            name: 'LocationPublicProfileBatchDraftUpdateContactInformationSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileBatchDraftUpdateSettingsPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileBatchDraftUpdateSettingsError',
          },
          {
            name: 'LocationPublicProfileBatchDraftUpdateSettingsSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftAddGalleryPhotosPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftAddGalleryPhotosError',
          },
          {
            name: 'LocationPublicProfileDraftAddGalleryPhotosSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftAddLanguagesPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftAddLanguagesFailure',
          },
          {
            name: 'LocationPublicProfileDraftAddLanguagesSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftAddProductsPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftAddProductsError',
          },
          {
            name: 'LocationPublicProfileDraftAddProductsSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftGalleryPhotosDeletePayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftGalleryPhotosDeleteError',
          },
          {
            name: 'LocationPublicProfileDraftGalleryPhotosDeleteSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftGalleryPhotosUpdatePayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftGalleryPhotosUpdateError',
          },
          {
            name: 'LocationPublicProfileDraftGalleryPhotosUpdateSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftOptInMarketingEventsUpsertPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftOptInMarketingEventsUpsertError',
          },
          {
            name: 'LocationPublicProfileDraftOptInMarketingEventsUpsertSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftProductsDeletePayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftProductsDeleteError',
          },
          {
            name: 'LocationPublicProfileDraftProductsDeleteSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftPublishPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftPublishError',
          },
          {
            name: 'LocationPublicProfileDraftPublishSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftRemoveLanguagesPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftRemoveLanguagesFailure',
          },
          {
            name: 'LocationPublicProfileDraftRemoveLanguagesSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftUpdateAboutPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftUpdateAboutError',
          },
          {
            name: 'LocationPublicProfileDraftUpdateAboutSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftUpdateAddressPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftUpdateAddressError',
          },
          {
            name: 'LocationPublicProfileDraftUpdateAddressSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftUpdateBusinessHoursPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftUpdateBusinessHoursError',
          },
          {
            name: 'LocationPublicProfileDraftUpdateBusinessHoursSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftUpdateConsultationRequestSettingsPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftUpdateConsultationRequestSettingsError',
          },
          {
            name: 'LocationPublicProfileDraftUpdateConsultationRequestSettingsSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftUpdateContactInformationPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftUpdateContactInformationError',
          },
          {
            name: 'LocationPublicProfileDraftUpdateContactInformationSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftUpdateDetailsPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftUpdateDetailsError',
          },
          {
            name: 'LocationPublicProfileDraftUpdateDetailsSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftUpdateSettingsPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftUpdateSettingsError',
          },
          {
            name: 'LocationPublicProfileDraftUpdateSettingsSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LocationPublicProfileDraftUpsertGoogleDataPayload',
        possibleTypes: [
          {
            name: 'LocationPublicProfileDraftUpsertGoogleDataError',
          },
          {
            name: 'LocationPublicProfileDraftUpsertGoogleDataSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferAddChangeRequestIncreaseInventoryAllocationPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferAddChangeRequestIncreaseInventoryAllocationSuccess',
          },
          {
            name: 'MarketingOfferAddChangeRequestIncreaseInventoryAllocationError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferAddChangeRequestModifyEndDatePayload',
        possibleTypes: [
          {
            name: 'MarketingOfferAddChangeRequestModifyEndDateSuccess',
          },
          {
            name: 'MarketingOfferAddChangeRequestModifyEndDateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferApprovePayload',
        possibleTypes: [
          {
            name: 'MarketingOfferApproveSuccess',
          },
          {
            name: 'MarketingOfferApproveError',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'MarketingOfferChangeRequest',
        possibleTypes: [
          {
            name: 'ChangeRequestIncreaseInventoryAllocation',
          },
          {
            name: 'ChangeRequestModifyEndDate',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferChangeRequestApprovePayload',
        possibleTypes: [
          {
            name: 'MarketingOfferChangeRequestApproveSuccess',
          },
          {
            name: 'MarketingOfferChangeRequestApproveError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferChangeRequestDeclinePayload',
        possibleTypes: [
          {
            name: 'MarketingOfferChangeRequestDeclineSuccess',
          },
          {
            name: 'MarketingOfferChangeRequestDeclineError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferChangeRequestPublishPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferChangeRequestPublishSuccess',
          },
          {
            name: 'MarketingOfferChangeRequestPublishError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferCreatePayload',
        possibleTypes: [
          {
            name: 'MarketingOfferCreateSuccess',
          },
          {
            name: 'MarketingOfferCreateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferCreateWalletImageUploadURLPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferCreateWalletImageUploadURLError',
          },
          {
            name: 'MarketingOfferCreateWalletImageUploadURLSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferDeclinePayload',
        possibleTypes: [
          {
            name: 'MarketingOfferDeclineSuccess',
          },
          {
            name: 'MarketingOfferDeclineError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferDeletePayload',
        possibleTypes: [
          {
            name: 'MarketingOfferDeleteSuccess',
          },
          {
            name: 'MarketingOfferDeleteError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferDistributionChannel',
        possibleTypes: [
          {
            name: 'DollarsOffFlashDistributionChannel',
          },
          {
            name: 'DollarsOffOptInDistributionChannel',
          },
          {
            name: 'DollarsOffGivenDistributionChannel',
          },
          {
            name: 'DollarsOffPracticeGivenDistributionChannel',
          },
          {
            name: 'GiftCardGivenDistributionChannel',
          },
          {
            name: 'GiftCardPurchasedDistributionChannel',
          },
          {
            name: 'PointsEarnedDistributionChannel',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferDuplicatePayload',
        possibleTypes: [
          {
            name: 'MarketingOfferDuplicateSuccess',
          },
          {
            name: 'MarketingOfferDuplicateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferExpiration',
        possibleTypes: [
          {
            name: 'MarketingOfferFixedExpiration',
          },
          {
            name: 'MarketingOfferRelativeExpiration',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferPublishPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferPublishSuccess',
          },
          {
            name: 'MarketingOfferPublishError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferReward',
        possibleTypes: [
          {
            name: 'DollarsOffOfferReward',
          },
          {
            name: 'GiftCardOfferReward',
          },
          {
            name: 'PointsEarnedOfferReward',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferSubmitForApprovalPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferSubmitForApprovalSuccess',
          },
          {
            name: 'MarketingOfferSubmitForApprovalError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateActivationTypePayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateActivationTypeSuccess',
          },
          {
            name: 'MarketingOfferUpdateActivationTypeError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateAudiencesPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateAudiencesSuccess',
          },
          {
            name: 'MarketingOfferUpdateAudiencesError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateDistributionTypePayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateDistributionTypeSuccess',
          },
          {
            name: 'MarketingOfferUpdateDistributionTypeError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateDollarsOffRulesPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateDollarsOffRulesSuccess',
          },
          {
            name: 'MarketingOfferUpdateDollarsOffRulesError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateDurationPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateDurationSuccess',
          },
          {
            name: 'MarketingOfferUpdateDurationError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateEarnedPointsBrandsSelectionPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateEarnedPointsBrandsSelectionSuccess',
          },
          {
            name: 'MarketingOfferUpdateEarnedPointsBrandsSelectionError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateEarnedPointsDetailsPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateEarnedPointsDetailsSuccess',
          },
          {
            name: 'MarketingOfferUpdateEarnedPointsDetailsError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateEligibleAudiencesPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateEligibleAudiencesSuccess',
          },
          {
            name: 'MarketingOfferUpdateEligibleAudiencesError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateExpireInDaysPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateExpireInDaysSuccess',
          },
          {
            name: 'MarketingOfferUpdateExpireInDaysError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateFixedExpirationPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateFixedExpirationSuccess',
          },
          {
            name: 'MarketingOfferUpdateFixedExpirationError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateGiftCardDetailsPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateGiftCardDetailsSuccess',
          },
          {
            name: 'MarketingOfferUpdateGiftCardDetailsError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateGiftCardRulesPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateGiftCardRulesSuccess',
          },
          {
            name: 'MarketingOfferUpdateGiftCardRulesError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateInventoryPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateInventorySuccess',
          },
          {
            name: 'MarketingOfferUpdateInventoryError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateNamePayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateNameSuccess',
          },
          {
            name: 'MarketingOfferUpdateNameError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdatePracticeInventoryPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdatePracticeInventorySuccess',
          },
          {
            name: 'MarketingOfferUpdatePracticeInventoryError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateProductSelectionPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateProductSelectionSuccess',
          },
          {
            name: 'MarketingOfferUpdateProductSelectionError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateRelativeExpirationPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateRelativeExpirationSuccess',
          },
          {
            name: 'MarketingOfferUpdateRelativeExpirationError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateTermsPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateTermsSuccess',
          },
          {
            name: 'MarketingOfferUpdateTermsError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateValuePayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateValueSuccess',
          },
          {
            name: 'MarketingOfferUpdateValueError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateValueTypePayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateValueTypeSuccess',
          },
          {
            name: 'MarketingOfferUpdateValueTypeError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MarketingOfferUpdateWalletDetailsPayload',
        possibleTypes: [
          {
            name: 'MarketingOfferUpdateWalletDetailsSuccess',
          },
          {
            name: 'MarketingOfferUpdateWalletDetailsError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'OptInPromotionClaim',
        possibleTypes: [
          {
            name: 'OptInPromotionClaimCompleted',
          },
          {
            name: 'OptInPromotionClaimFailed',
          },
          {
            name: 'OptInPromotionClaimPending',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'OptInPromotionClaimPayload',
        possibleTypes: [
          {
            name: 'OptInPromotionClaimSuccess',
          },
          {
            name: 'OptInPromotionClaimError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PasswordResetCompletePayload',
        possibleTypes: [
          {
            name: 'PasswordResetCompleteSuccess',
          },
          {
            name: 'PasswordResetCompleteFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PasswordResetInitiatePayload',
        possibleTypes: [
          {
            name: 'PasswordResetInitiateSuccess',
          },
          {
            name: 'PasswordResetInitiateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PasswordResetVerifyPayload',
        possibleTypes: [
          {
            name: 'PasswordResetVerifySuccess',
          },
          {
            name: 'PasswordResetVerifyFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PatientActivity',
        possibleTypes: [
          {
            name: 'PatientTransaction',
          },
          {
            name: 'BDMigrationActivity',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PatientAssignOfferResponse',
        possibleTypes: [
          {
            name: 'PatientAssignOfferSuccess',
          },
          {
            name: 'PatientAssignOfferError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PatientFinancingApprovalRequest',
        possibleTypes: [
          {
            name: 'PatientFinancingCherryPaymentApprovalRequest',
          },
          {
            name: 'PatientFinancingRequest',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PatientFinancingProfileEnrollmentDetails',
        possibleTypes: [
          {
            name: 'CherryPatientFinancingProfileEnrollmentDetails',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PatientFinancingVendorApplication',
        possibleTypes: [
          {
            name: 'PatientFinancingCherryApplication',
          },
          {
            name: 'PatientFinancingApplication',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PatientTransactionCancelPayload',
        possibleTypes: [
          {
            name: 'PatientTransactionCancelSuccess',
          },
          {
            name: 'PatientTransactionCancelError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PatientTransactionCreateForPracticePayload',
        possibleTypes: [
          {
            name: 'PatientTransactionCreateForPracticeError',
          },
          {
            name: 'PatientTransactionCreateForPracticeSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PatientTransactionCreatePayload',
        possibleTypes: [
          {
            name: 'PatientTransactionCreateError',
          },
          {
            name: 'PatientTransactionCreateSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PatientTransactionFinalizePayload',
        possibleTypes: [
          {
            name: 'PatientTransactionFinalizeSuccess',
          },
          {
            name: 'PatientTransactionFinalizeError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PatientTransactionPayload',
        possibleTypes: [
          {
            name: 'PatientTransactionError',
          },
          {
            name: 'PatientTransactionSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PatientTransactionRefundRequestPayload',
        possibleTypes: [
          {
            name: 'PatientTransactionRefundRequestFailure',
          },
          {
            name: 'PatientTransactionRefundRequestSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PatientTransactionSimulatePayload',
        possibleTypes: [
          {
            name: 'PatientTransactionSimulateSuccess',
          },
          {
            name: 'PatientTransactionSimulateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PatientTransactionUpdatePayload',
        possibleTypes: [
          {
            name: 'PatientTransactionUpdateSuccess',
          },
          {
            name: 'PatientTransactionUpdateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentIntentPayload',
        possibleTypes: [
          {
            name: 'PaymentIntentSuccess',
          },
          {
            name: 'PaymentIntentError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentMethodChargePayload',
        possibleTypes: [
          {
            name: 'PaymentMethodChargeSuccess',
          },
          {
            name: 'PaymentMethodChargeFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentMethodDeletePayload',
        possibleTypes: [
          {
            name: 'PaymentMethodDeleteSuccess',
          },
          {
            name: 'PaymentMethodDeleteError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentMethodUpdatePayload',
        possibleTypes: [
          {
            name: 'PaymentMethodUpdateSuccess',
          },
          {
            name: 'PaymentMethodUpdateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentProfileDeactivatePayload',
        possibleTypes: [
          {
            name: 'PaymentProfileDeactivateSuccess',
          },
          {
            name: 'PaymentProfileDeactivateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentProfileReactivatePayload',
        possibleTypes: [
          {
            name: 'PaymentProfileReactivateSuccess',
          },
          {
            name: 'PaymentProfileReactivateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentProfileUpdateEmailPayload',
        possibleTypes: [
          {
            name: 'PaymentProfileUpdateEmailSuccess',
          },
          {
            name: 'PaymentProfileUpdateEmailError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentProfileUpdateFundingSourceForChildOrgPayload',
        possibleTypes: [
          {
            name: 'PaymentProfileUpdateFundingSourceForChildOrgSuccess',
          },
          {
            name: 'PaymentProfileUpdateFundingSourceForChildOrgError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentProfileUpdateFundingSourcePayload',
        possibleTypes: [
          {
            name: 'PaymentProfileUpdateFundingSourceSuccess',
          },
          {
            name: 'PaymentProfileUpdateFundingSourceError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentProfileUpdatePaymentFrequencyPayload',
        possibleTypes: [
          {
            name: 'PaymentProfileUpdatePaymentFrequencySuccess',
          },
          {
            name: 'PaymentProfileUpdatePaymentFrequencyError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentsActor',
        possibleTypes: [
          {
            name: 'PaymentsSystemUser',
          },
          {
            name: 'Actor',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentsBalanceAutomationParametersUpdatePayload',
        possibleTypes: [
          {
            name: 'PaymentsBalanceAutomationParametersUpdateSuccess',
          },
          {
            name: 'PaymentsBalanceAutomationParametersUpdateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentsCustomer',
        possibleTypes: [
          {
            name: 'PaymentsProviderCustomer',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentsSetupIntentPayload',
        possibleTypes: [
          {
            name: 'PaymentsSetupIntentSuccess',
          },
          {
            name: 'PaymentsSetupIntentError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentsTransactionReportCreatePayload',
        possibleTypes: [
          {
            name: 'PaymentsTransactionReportCreateSuccess',
          },
          {
            name: 'PaymentsTransactionReportCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentsTransferRequestApprovePayload',
        possibleTypes: [
          {
            name: 'PaymentsTransferRequestApproveSuccess',
          },
          {
            name: 'PaymentsTransferRequestApproveFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentsTransferRequestCancelPayload',
        possibleTypes: [
          {
            name: 'PaymentsTransferRequestCancelSuccess',
          },
          {
            name: 'PaymentsTransferRequestCancelFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentsTransferRequestCreatePayload',
        possibleTypes: [
          {
            name: 'PaymentsTransferRequestCreateSuccess',
          },
          {
            name: 'PaymentsTransferRequestCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentsTransferRequestDeclinePayload',
        possibleTypes: [
          {
            name: 'PaymentsTransferRequestDeclineSuccess',
          },
          {
            name: 'PaymentsTransferRequestDeclineFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentsWalletCreateForPracticePayload',
        possibleTypes: [
          {
            name: 'PaymentsWalletCreateForPracticeSuccess',
          },
          {
            name: 'PaymentsWalletCreateForPracticeFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PayoutDetailReportCreatePayload',
        possibleTypes: [
          {
            name: 'PayoutDetailReportCreateSuccess',
          },
          {
            name: 'PayoutDetailReportCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PayoutSummaryReportCreatePayload',
        possibleTypes: [
          {
            name: 'PayoutSummaryReportCreateSuccess',
          },
          {
            name: 'PayoutSummaryReportCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PlatformSubscriptionCancelPayload',
        possibleTypes: [
          {
            name: 'PlatformSubscriptionCancelSuccess',
          },
          {
            name: 'PlatformSubscriptionCancelFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PlatformSubscriptionPayload',
        possibleTypes: [
          {
            name: 'PlatformSubscriptionCreateSuccess',
          },
          {
            name: 'PlatformSubscriptionCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PlatformSubscriptionUpdatePaymentMethodPayload',
        possibleTypes: [
          {
            name: 'PlatformSubscriptionUpdatePaymentMethodSuccess',
          },
          {
            name: 'PlatformSubscriptionUpdatePaymentMethodFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PracticeActivateAdvertisingProfilePayload',
        possibleTypes: [
          {
            name: 'PracticeActivateAdvertisingProfileSuccess',
          },
          {
            name: 'PracticeActivateAdvertisingProfileFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PracticeAddStaffMemberPayload',
        possibleTypes: [
          {
            name: 'PracticeAddStaffMemberSuccess',
          },
          {
            name: 'PracticeAddStaffMemberError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PracticeAdvertisingProfileUpdateAdvertisementManagementStatusPayload',
        possibleTypes: [
          {
            name: 'PracticeAdvertisingProfileUpdateAdvertisementManagementStatusSuccess',
          },
          {
            name: 'PracticeAdvertisingProfileUpdateAdvertisementManagementStatusFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PracticeAdvertisingProfileUpdateEnrollmentStatusPayload',
        possibleTypes: [
          {
            name: 'PracticeAdvertisingProfileUpdateEnrollmentStatusSuccess',
          },
          {
            name: 'PracticeAdvertisingProfileUpdateEnrollmentStatusFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PracticeDeleteStaffMemberPayload',
        possibleTypes: [
          {
            name: 'PracticeDeleteStaffMemberSuccess',
          },
          {
            name: 'PracticeDeleteStaffMemberError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PracticeEnrollPayload',
        possibleTypes: [
          {
            name: 'PracticeEnrollSuccess',
          },
          {
            name: 'PracticeEnrollError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PracticeEnrollV2Payload',
        possibleTypes: [
          {
            name: 'PracticeEnrollV2Success',
          },
          {
            name: 'PracticeEnrollV2Failure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PracticeOptOutFlashRewardsPayload',
        possibleTypes: [
          {
            name: 'PracticeOptOutFlashRewardsSuccess',
          },
          {
            name: 'PracticeOptOutFlashRewardsFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PracticePaymentProfileUpdateEmailAddressPayload',
        possibleTypes: [
          {
            name: 'PracticePaymentProfileUpdateEmailAddressSuccess',
          },
          {
            name: 'PracticePaymentProfileUpdateEmailAddressFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PracticePaymentProfileUpdatePaymentFrequencyPayload',
        possibleTypes: [
          {
            name: 'PracticePaymentProfileUpdatePaymentFrequencySuccess',
          },
          {
            name: 'PracticePaymentProfileUpdatePaymentFrequencyFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PracticeReactivateFlashRewardsPayload',
        possibleTypes: [
          {
            name: 'PracticeReactivateFlashRewardsSuccess',
          },
          {
            name: 'PracticeReactivateFlashRewardsFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PracticeUpdateSettingsPayload',
        possibleTypes: [
          {
            name: 'PracticeUpdateSettingsSuccess',
          },
          {
            name: 'PracticeUpdateSettingsError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PracticeUpdateStaffMemberPayload',
        possibleTypes: [
          {
            name: 'PracticeUpdateStaffMemberSuccess',
          },
          {
            name: 'PracticeUpdateStaffMemberError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PricingProfileSendInvitePayload',
        possibleTypes: [
          {
            name: 'PricingProfileSendInviteSuccess',
          },
          {
            name: 'PricingProfileSendInviteFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PricingProfileUpdateEnrollmentStatusPayload',
        possibleTypes: [
          {
            name: 'PricingProfileUpdateEnrollmentStatusSuccess',
          },
          {
            name: 'PricingProfileUpdateEnrollmentStatusFailure',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Promotion',
        possibleTypes: [
          {
            name: 'OfficePromotion',
          },
          {
            name: 'PatientPromotion',
          },
          {
            name: 'PointPurchasablePromotion',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PromotionClaimWithPointsPayload',
        possibleTypes: [
          {
            name: 'PromotionClaimWithPointsSuccess',
          },
          {
            name: 'PromotionClaimWithPointsFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PromotionSelectPayload',
        possibleTypes: [
          {
            name: 'PromotionSelectSuccess',
          },
          {
            name: 'PromotionSelectError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderA4BUpgradeNotification',
        possibleTypes: [
          {
            name: 'ProviderA4BUpgradeInvitation',
          },
          {
            name: 'ProviderA4BUpgradeCompletedRequestInviteNotification',
          },
          {
            name: 'ProviderA4BUpgradePendingNotification',
          },
          {
            name: 'ProviderA4BUpgradeFreeFormNotification',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderAccessTokenGeneratePayload',
        possibleTypes: [
          {
            name: 'ProviderAccessTokenGenerateSuccess',
          },
          {
            name: 'ProviderAccessTokenGenerateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderBusinessAcceptTermsAndConditionsPayload',
        possibleTypes: [
          {
            name: 'ProviderBusinessAcceptTermsAndConditionsSuccess',
          },
          {
            name: 'ProviderBusinessAcceptTermsAndConditionsFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderEmailMarketingCampaignCreatePayload',
        possibleTypes: [
          {
            name: 'ProviderEmailMarketingCampaignCreateSuccess',
          },
          {
            name: 'ProviderEmailMarketingCampaignCreateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderEmailMarketingCampaignPackageAutomationSetEnabledPayload',
        possibleTypes: [
          {
            name: 'ProviderEmailMarketingCampaignPackageAutomationSetEnabledSuccess',
          },
          {
            name: 'ProviderEmailMarketingCampaignPackageAutomationSetEnabledFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderEmailMarketingCampaignPackageSendPreviewPayload',
        possibleTypes: [
          {
            name: 'ProviderEmailMarketingCampaignPackageSendPreviewSuccess',
          },
          {
            name: 'ProviderEmailMarketingCampaignPackageSendPreviewFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderLocationActivatePayload',
        possibleTypes: [
          {
            name: 'ProviderLocationActivateSuccess',
          },
          {
            name: 'ProviderLocationActivateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderLocationArchivePayload',
        possibleTypes: [
          {
            name: 'ProviderLocationArchiveSuccess',
          },
          {
            name: 'ProviderLocationArchiveFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderLocationUnarchivePayload',
        possibleTypes: [
          {
            name: 'ProviderLocationUnarchiveSuccess',
          },
          {
            name: 'ProviderLocationUnarchiveFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderMarketingCampaignAudience',
        possibleTypes: [
          {
            name: 'ProviderMarketingCampaignAdvertisingTargetingAudience',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderMarketingCampaignCreateContactLinkPayload',
        possibleTypes: [
          {
            name: 'ProviderMarketingCampaignCreateContactLinkSuccess',
          },
          {
            name: 'ProviderMarketingCampaignCreateContactLinkFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderMarketingCampaignCreatePayload',
        possibleTypes: [
          {
            name: 'ProviderMarketingCampaignCreateSuccess',
          },
          {
            name: 'ProviderMarketingCampaignCreateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderMarketingCampaignCreative',
        possibleTypes: [
          {
            name: 'ProviderMarketingCampaignAdvertisingCreative',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderMarketingCampaignDraftCreatePayload',
        possibleTypes: [
          {
            name: 'ProviderMarketingCampaignDraftCreateSuccess',
          },
          {
            name: 'ProviderMarketingCampaignDraftCreateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderMarketingCampaignGeneratePreviewPayload',
        possibleTypes: [
          {
            name: 'ProviderMarketingCampaignGeneratePreviewSuccess',
          },
          {
            name: 'ProviderMarketingCampaignGeneratePreviewFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderMarketingCampaignOffer',
        possibleTypes: [
          {
            name: 'ProviderMarketingCampaignOptInOffer',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderMarketingCampaignPausePayload',
        possibleTypes: [
          {
            name: 'ProviderMarketingCampaignPauseSuccess',
          },
          {
            name: 'ProviderMarketingCampaignPauseError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderMarketingCampaignPerformance',
        possibleTypes: [
          {
            name: 'AdvertisingPerformanceInfo',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderMarketingCampaignResumePayload',
        possibleTypes: [
          {
            name: 'ProviderMarketingCampaignResumeSuccess',
          },
          {
            name: 'ProviderMarketingCampaignResumeError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderMarketingCampaignStopPayload',
        possibleTypes: [
          {
            name: 'ProviderMarketingCampaignStopSuccess',
          },
          {
            name: 'ProviderMarketingCampaignStopError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderMarketingCampaignSubmitSurveyPayload',
        possibleTypes: [
          {
            name: 'ProviderMarketingCampaignSubmitSurveySuccess',
          },
          {
            name: 'ProviderMarketingCampaignSubmitSurveyFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderMarketingEmailOptOutPayload',
        possibleTypes: [
          {
            name: 'ProviderMarketingEmailOptOutSuccess',
          },
          {
            name: 'ProviderMarketingEmailOptOutError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderOrganizationAcceptAllerganStoreTermsAndConditionsPayload',
        possibleTypes: [
          {
            name: 'ProviderOrganizationAcceptAllerganStoreTermsAndConditionsSuccess',
          },
          {
            name: 'ProviderOrganizationAcceptAllerganStoreTermsAndConditionsFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderOrganizationAcceptPricingTermsAndConditionsPayload',
        possibleTypes: [
          {
            name: 'ProviderOrganizationAcceptPricingTermsAndConditionsSuccess',
          },
          {
            name: 'ProviderOrganizationAcceptPricingTermsAndConditionsFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderOrganizationTeamSetupCompletePayload',
        possibleTypes: [
          {
            name: 'ProviderOrganizationTeamSetupCompleteSuccess',
          },
          {
            name: 'ProviderOrganizationTeamSetupCompleteFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderOrganizationTransferPayload',
        possibleTypes: [
          {
            name: 'ProviderOrganizationTransferSuccess',
          },
          {
            name: 'ProviderOrganizationTransferFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderOrganizationUpdateCustomNamePayload',
        possibleTypes: [
          {
            name: 'ProviderOrganizationUpdateCustomNameSuccess',
          },
          {
            name: 'ProviderOrganizationUpdateCustomNameFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderReportGenerateFileResultPayload',
        possibleTypes: [
          {
            name: 'ProviderReportGenerateFileResultSuccess',
          },
          {
            name: 'ProviderReportResultFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderReportGenerateResultPayload',
        possibleTypes: [
          {
            name: 'ProviderReportGenerateResultSuccess',
          },
          {
            name: 'ProviderReportGenerateResultError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderReportResultAggregate',
        possibleTypes: [
          {
            name: 'ProviderReportResultAggregateInString',
          },
          {
            name: 'ProviderReportResultAggregateInInt',
          },
          {
            name: 'ProviderReportResultAggregateTotalRewards',
          },
          {
            name: 'ProviderReportResultAggregateNumberOfPatientsTimeSeries',
          },
          {
            name: 'ProviderReportResultAggregatePatientTreatmentStatus',
          },
          {
            name: 'ProviderReportResultAggregateTierRewardEligibility',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'ProviderReportResultAggregateInTimeSeries',
        possibleTypes: [
          {
            name: 'ProviderReportResultAggregateNumberOfPatientsTimeSeries',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderReportResultFilter',
        possibleTypes: [
          {
            name: 'ProviderReportResultFilterInString',
          },
          {
            name: 'ProviderReportResultFilterInInt',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderSessionTokenGeneratePayload',
        possibleTypes: [
          {
            name: 'ProviderSessionTokenGenerateSuccess',
          },
          {
            name: 'ProviderSessionTokenGenerateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderSettingsFieldError',
        possibleTypes: [
          {
            name: 'ProviderSettingsMissingFieldError',
          },
          {
            name: 'ProviderSettingsInvalidFieldError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderSettingsValidatePayload',
        possibleTypes: [
          {
            name: 'ProviderSettingsValidateSuccess',
          },
          {
            name: 'ProviderSettingsValidateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserAcceptInvitePayload',
        possibleTypes: [
          {
            name: 'ProviderUserAcceptInviteSuccess',
          },
          {
            name: 'ProviderUserAcceptInviteFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserAddRoleAssignmentPayload',
        possibleTypes: [
          {
            name: 'ProviderUserAddRoleAssignmentSuccess',
          },
          {
            name: 'ProviderUserAddRoleAssignmentFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserAlternateEmailAddressChangeRequestCreatePayload',
        possibleTypes: [
          {
            name: 'ProviderUserAlternateEmailAddressChangeRequestCreateSuccess',
          },
          {
            name: 'ProviderUserAlternateEmailAddressChangeRequestCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserDelegateInvitationPayload',
        possibleTypes: [
          {
            name: 'ProviderUserDelegateInvitationSuccess',
          },
          {
            name: 'ProviderUserDelegateInvitationFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserDeletePayload',
        possibleTypes: [
          {
            name: 'ProviderUserDeleteSuccess',
          },
          {
            name: 'ProviderUserDeleteFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserInvitationCheckAlternateEmailAddressAvailabilityPayload',
        possibleTypes: [
          {
            name: 'ProviderUserInvitationCheckAlternateEmailAddressAvailabilitySuccess',
          },
          {
            name: 'ProviderUserInvitationCheckAlternateEmailAddressAvailabilityFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserInvitationResendPayload',
        possibleTypes: [
          {
            name: 'ProviderUserInvitationResendSuccess',
          },
          {
            name: 'ProviderUserInvitationResendFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserInvitationSendAlternateEmailAddressVerificationPayload',
        possibleTypes: [
          {
            name: 'ProviderUserInvitationSendAlternateEmailAddressVerificationSuccess',
          },
          {
            name: 'ProviderUserInvitationSendAlternateEmailAddressVerificationFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserInvitationSendSMSVerificationPayload',
        possibleTypes: [
          {
            name: 'ProviderUserInvitationSendSMSVerificationSuccess',
          },
          {
            name: 'ProviderUserInvitationSendSMSVerificationFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserInvitationVerifySMSVerificationPayload',
        possibleTypes: [
          {
            name: 'ProviderUserInvitationVerifySMSVerificationSuccess',
          },
          {
            name: 'ProviderUserInvitationVerifySMSVerificationFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserInvitationVerifyTokenPayload',
        possibleTypes: [
          {
            name: 'ProviderUserInvitationVerifyTokenSuccess',
          },
          {
            name: 'ProviderUserInvitationVerifyTokenFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserInviteAllSuggestedPayload',
        possibleTypes: [
          {
            name: 'ProviderUserInviteAllSuggestedSuccess',
          },
          {
            name: 'ProviderUserInviteAllSuggestedFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserInvitePayload',
        possibleTypes: [
          {
            name: 'ProviderUserInviteSuccess',
          },
          {
            name: 'ProviderUserInviteFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserInviteValidation',
        possibleTypes: [
          {
            name: 'ProviderUserInviteValidationSuccess',
          },
          {
            name: 'ProviderUserInviteValidationFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserPhoneNumberChangeRequestCreatePayload',
        possibleTypes: [
          {
            name: 'ProviderUserPhoneNumberChangeRequestCreateSuccess',
          },
          {
            name: 'ProviderUserPhoneNumberChangeRequestCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserPhoneNumberReleasePayload',
        possibleTypes: [
          {
            name: 'ProviderUserPhoneNumberReleaseSuccess',
          },
          {
            name: 'ProviderUserPhoneNumberReleaseFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserPrimaryEmailAddressChangeRequestCreatePayload',
        possibleTypes: [
          {
            name: 'ProviderUserPrimaryEmailAddressChangeRequestCreateSuccess',
          },
          {
            name: 'ProviderUserPrimaryEmailAddressChangeRequestCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserRegisterByInvitationTokenPayload',
        possibleTypes: [
          {
            name: 'ProviderUserRegisterByInvitationTokenSuccess',
          },
          {
            name: 'ProviderUserRegisterByInvitationTokenFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserRoleAssignmentChangeRolePayload',
        possibleTypes: [
          {
            name: 'ProviderUserRoleAssignmentChangeRoleSuccess',
          },
          {
            name: 'ProviderUserRoleAssignmentChangeRoleFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserRoleAssignmentsDeactivatePayload',
        possibleTypes: [
          {
            name: 'ProviderUserRoleAssignmentsDeactivateSuccess',
          },
          {
            name: 'ProviderUserRoleAssignmentsDeactivateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserUpdateFirstNamePayload',
        possibleTypes: [
          {
            name: 'ProviderUserUpdateFirstNameSuccess',
          },
          {
            name: 'ProviderUserUpdateFirstNameFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserUpdateLastNamePayload',
        possibleTypes: [
          {
            name: 'ProviderUserUpdateLastNameSuccess',
          },
          {
            name: 'ProviderUserUpdateLastNameFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserUpdatePasswordPayload',
        possibleTypes: [
          {
            name: 'ProviderUserUpdatePasswordSuccess',
          },
          {
            name: 'ProviderUserUpdatePasswordFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserVerifyAlternateEmailAddressPayload',
        possibleTypes: [
          {
            name: 'ProviderUserVerifyAlternateEmailAddressSuccess',
          },
          {
            name: 'ProviderUserVerifyAlternateEmailAddressFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserVerifyPhoneNumberPayload',
        possibleTypes: [
          {
            name: 'ProviderUserVerifyPhoneNumberSuccess',
          },
          {
            name: 'ProviderUserVerifyPhoneNumberFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProviderUserVerifyPrimaryEmailAddressPayload',
        possibleTypes: [
          {
            name: 'ProviderUserVerifyPrimaryEmailAddressSuccess',
          },
          {
            name: 'ProviderUserVerifyPrimaryEmailAddressFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'QuestionSheetSectionContent',
        possibleTypes: [
          {
            name: 'QuestionSheetSectionContentIntro',
          },
          {
            name: 'QuestionSheetSectionContentFaq',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ReferralCreatePayload',
        possibleTypes: [
          {
            name: 'ReferralCreateSuccess',
          },
          {
            name: 'ReferralCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ReferringUserProfileCreatePayload',
        possibleTypes: [
          {
            name: 'ReferringUserProfileCreateSuccess',
          },
          {
            name: 'ReferringUserProfileCreateFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ReportExportPayload',
        possibleTypes: [
          {
            name: 'ReportExportSuccess',
          },
          {
            name: 'ReportExportError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'SetupIntentCompletePayload',
        possibleTypes: [
          {
            name: 'SetupIntentCompleteSuccess',
          },
          {
            name: 'SetupIntentCompleteFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'SignedUploadURLPayload',
        possibleTypes: [
          {
            name: 'SignedUploadURLError',
          },
          {
            name: 'SignedUploadURLSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'SocialNetworkIntegration',
        possibleTypes: [
          {
            name: 'FacebookIntegration',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'SocialNetworkIntegrationCreatePayload',
        possibleTypes: [
          {
            name: 'SocialNetworkIntegrationCreateSuccess',
          },
          {
            name: 'SocialNetworkIntegrationCreateError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'SocialNetworkIntegrationDeletePayload',
        possibleTypes: [
          {
            name: 'SocialNetworkIntegrationDeleteSuccess',
          },
          {
            name: 'SocialNetworkIntegrationDeleteError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'SweepstakesConfirmEntryPayload',
        possibleTypes: [
          {
            name: 'SweepstakesConfirmEntrySuccess',
          },
          {
            name: 'SweepstakesConfirmEntryFailure',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Taggable',
        possibleTypes: [
          {
            name: 'ProviderMarketingCampaignPackage',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'TaskCompletePayload',
        possibleTypes: [
          {
            name: 'TaskCompleteSuccess',
          },
          {
            name: 'TaskCompleteFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'TreatmentGuideContent',
        possibleTypes: [
          {
            name: 'TreatmentGuideContentLongForm',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserActionTrackPayload',
        possibleTypes: [
          {
            name: 'UserActionTrackSuccess',
          },
          {
            name: 'UserActionTrackError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserCheckInPayload',
        possibleTypes: [
          {
            name: 'UserCheckInSuccess',
          },
          {
            name: 'UserCheckInError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserCheckInToEventClaim',
        possibleTypes: [
          {
            name: 'UserCheckInToEventClaimCompleted',
          },
          {
            name: 'UserCheckInToEventClaimFailed',
          },
          {
            name: 'UserCheckInToEventClaimPending',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserCheckInToEventPayload',
        possibleTypes: [
          {
            name: 'UserCheckInToEventSuccess',
          },
          {
            name: 'UserCheckInToEventPending',
          },
          {
            name: 'UserCheckInToEventError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserCheckInToPracticeClaim',
        possibleTypes: [
          {
            name: 'UserCheckInToPracticeClaimCompleted',
          },
          {
            name: 'UserCheckInToPracticeClaimFailed',
          },
          {
            name: 'UserCheckInToPracticeClaimPending',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserCheckInToPracticePayload',
        possibleTypes: [
          {
            name: 'UserCheckInToPracticeSuccess',
          },
          {
            name: 'UserCheckInToPracticeError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserCheckInToPracticePayloadV2',
        possibleTypes: [
          {
            name: 'UserCheckInToPracticeError',
          },
          {
            name: 'UserCheckInToPracticePending',
          },
          {
            name: 'UserCheckInToPracticeSuccess',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserLinkBDAccountPayload',
        possibleTypes: [
          {
            name: 'UserLinkBDAccountSuccess',
          },
          {
            name: 'UserLinkBDAccountError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserLogoutPayload',
        possibleTypes: [
          {
            name: 'UserLogoutSuccess',
          },
          {
            name: 'UserLogoutFailure',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserUpdateCommunicationMethodResponse',
        possibleTypes: [
          {
            name: 'UserUpdateCommunicationMethodSuccess',
          },
          {
            name: 'UserUpdateCommunicationMethodError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserUpdateEmailAddressResponse',
        possibleTypes: [
          {
            name: 'UserUpdateEmailAddressSuccess',
          },
          {
            name: 'UserUpdateEmailAddressError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserUpdatePasswordResponse',
        possibleTypes: [
          {
            name: 'UserUpdatePasswordSuccess',
          },
          {
            name: 'UserUpdatePasswordError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserUpdatePhoneNumberResponse',
        possibleTypes: [
          {
            name: 'UserUpdatePhoneNumberSuccess',
          },
          {
            name: 'UserUpdatePhoneNumberError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserUpdateProfileResponse',
        possibleTypes: [
          {
            name: 'UserUpdateProfileSuccess',
          },
          {
            name: 'UserUpdateProfileError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UserVerifyPhoneChangeResponse',
        possibleTypes: [
          {
            name: 'UserVerifyPhoneChangeSuccess',
          },
          {
            name: 'UserVerifyPhoneChangeError',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'ViewerProfile',
        possibleTypes: [
          {
            name: 'AdminUserProfile',
          },
          {
            name: 'ConsumerProfile',
          },
          {
            name: 'PracticeEmployeeProfile',
          },
        ],
      },
    ],
  },
};
export default result;
