import React from 'react';
import {
  BlockFooter,
  Link,
  Maybe,
  StaticCode,
} from '@allergan-data-labs/contentful-graphql';
import {
  IBlockFooterFields,
  ILinkFields,
  IStaticCodeFields,
} from '@allergan-data-labs/contentful-rest';
import { RichText } from '@allergan-data-labs/component-library/src/components/richText/richText';

export type ConsumerFooterLinkType = Link | ILinkFields;
export type ConsumerFooterStaticCodeType = StaticCode | IStaticCodeFields;

export interface ConsumerFooterProps {
  copyright: React.ReactNode;
  PRT?: string;
  hideGlobalISILink?: boolean;
  hideAbout?: boolean;
  links?: Array<Maybe<ConsumerFooterLinkType | ConsumerFooterStaticCodeType>>;
}

// PD-4433: this should become the default type once we remove the consumer_footerRedesign_web FF (PD-4434)
export interface ConsumerFooterRedesignProps extends ConsumerFooterProps {
  isLoggedIn?: boolean;
  renderLink: (link: ConsumerFooterLinkType) => JSX.Element;
  renderStaticCodeComponent: (
    staticCode: ConsumerFooterStaticCodeType
  ) => JSX.Element | null;
}

const isGqlType = (
  footer?: Maybe<BlockFooter> | IBlockFooterFields
): footer is BlockFooter => {
  return (footer as Maybe<BlockFooter>)?.__typename === 'BlockFooter';
};

// PRT prop must be passed in to component seperately, depending on whether or not PRT is part of copyright
export const cmsConsumerFooterToProps = (
  contentfulFooter?: Maybe<BlockFooter> | IBlockFooterFields
): Omit<ConsumerFooterProps, 'PRT'> => {
  return {
    hideGlobalISILink: contentfulFooter?.showGlobalIsi === false || false,
    hideAbout: contentfulFooter?.showAboutLinks === false || false,
    copyright: (
      <RichText
        richText={
          isGqlType(contentfulFooter)
            ? contentfulFooter?.footerCopy?.json
            : contentfulFooter?.footerCopy
        }
        links={
          isGqlType(contentfulFooter)
            ? contentfulFooter.footerCopy?.links?.entries?.hyperlink
            : undefined
        }
        paragraphPreset={{
          s: 'body-S-regular',
          m: 'body-S-regular',
          l: 'body-M-regular',
        }}
      />
    ),
    links: isGqlType(contentfulFooter)
      ? contentfulFooter?.linksCollection?.items.map((l) => l)
      : contentfulFooter?.links?.map((l) => l.fields),
  };
};

export const isStaticCode = (
  link: ConsumerFooterLinkType | ConsumerFooterStaticCodeType | null
): link is ConsumerFooterStaticCodeType => {
  return (link as ConsumerFooterStaticCodeType)?.codeId !== undefined;
};
