import React from 'react';
import { Copyright2022 } from '@allergan-data-labs/component-library/src/constants/copyright';
import { cmsConsumerFooterToProps } from '@allergan-data-labs/consumer-component-library/src/footers/consumerFooterHelpers';
import { useCMSFooterQuery, FooterCodeID } from './useCMSFooterQuery';
import { ConsumerFooter } from './consumerFooter';

const DefaultConsumerFooter: React.FunctionComponent = () => {
  const { data, isLoading } = useCMSFooterQuery({
    codeId: FooterCodeID.defaultConsumerFooter,
  });

  if (!isLoading) {
    return <ConsumerFooter {...cmsConsumerFooterToProps(data)} />;
  } else {
    return <ConsumerFooter copyright={<Copyright2022 />} />;
  }
};

export { DefaultConsumerFooter };
