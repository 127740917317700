import { ApolloProvider } from '@apollo/react-hooks';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { useLocation } from 'react-router-dom';

// shared SDK
import { client } from '@allergan-data-labs/shared-sdk/src/client';
import { AlleApolloClientWeb } from '@allergan-data-labs/shared-sdk/src/client-web';

// shared components
import { LinkProvider } from '@allergan-data-labs/component-library/src/core/linkContext';
import { Datadog } from '@allergan-data-labs/component-library/src/datadog/dataDog';
import { LoadingProvider } from '@allergan-data-labs/component-library/src/loadingBar/loadingContext';
import { MarkdownDialogProvider } from '@allergan-data-labs/component-library/src/markdownDialogStateManagement';
import { NotificationProvider } from '@allergan-data-labs/component-library/src/notificationBar/notificationContext';
import { AlleFeatureProvider } from '@allergan-data-labs/component-library/src/optimizely/alleFeatureProvider';
import { StyledComponentsThemeProvider } from '@allergan-data-labs/component-library/src/styledComponents/themeProvider';

import { ContentViewProvider } from '@allergan-data-labs/consumer-component-library/src/content/contentViewContext';
import { SegmentProvider } from '@allergan-data-labs/consumer-component-library/src/segment/segmentContext';
import { EnvironmentContext } from '@allergan-data-labs/consumer-component-library/src/environment-context/environmentContext';
import { ReactRouterLink } from '@allergan-data-labs/consumer-component-library/src/environment-context/link/reactRouterLink';

// consumer components
import { AuthProvider } from './auth/AuthProvider';
import { AlleThemeProvider, ThemeProvider } from './components/themeProvider';

// utils
import { getAccessToken } from './auth/oktaClient';
import { routeConfig } from './routeConfig';

// TODO pull this up

import { __CMS_PREVIEW__ } from './application.config';
import { useResolveOptimizelyUser } from './utils/hooks/useResolveOptimizelyUser';
import { AlleMetadata } from './utils/alleMetadata';

// Initialize the global Apollo client
AlleApolloClientWeb({
  name: 'consumer-web',
  version: __APP_VERSION__,
  getAccessToken,
});

const queryClient = new QueryClient();

const Providers: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <>
      <AlleMetadata />
      <Datadog
        application={__CMS_PREVIEW__ ? 'cmsPreview' : 'consumer'}
        version={__APP_VERSION__}
      >
        <ApolloProvider client={client}>
          <QueryClientProvider client={queryClient}>
            <SegmentProvider>
              <AuthProvider>
                <NotificationProvider pathname={pathname}>
                  <AlleFeatureProvider
                    useResolveOptimizelyUser={useResolveOptimizelyUser}
                  >
                    <LinkProvider routes={routeConfig}>
                      <CookiesProvider>
                        <ThemeProvider>
                          <AlleThemeProvider>
                            <EnvironmentContext.Provider
                              value={{ Link: ReactRouterLink }}
                            >
                              <ContentViewProvider>
                                <LoadingProvider>
                                  <MarkdownDialogProvider>
                                    <StyledComponentsThemeProvider>
                                      {children}
                                    </StyledComponentsThemeProvider>
                                  </MarkdownDialogProvider>
                                </LoadingProvider>
                              </ContentViewProvider>
                            </EnvironmentContext.Provider>
                          </AlleThemeProvider>
                        </ThemeProvider>
                      </CookiesProvider>
                    </LinkProvider>
                  </AlleFeatureProvider>
                </NotificationProvider>
              </AuthProvider>
            </SegmentProvider>
          </QueryClientProvider>
        </ApolloProvider>
      </Datadog>
    </>
  );
};

export { Providers };
