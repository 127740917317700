import { useQuery as useReactQuery } from '@tanstack/react-query';
import { EntryCollection } from 'contentful';

import { IBlockFooterFields } from '@allergan-data-labs/contentful-rest';
import { logger } from '@allergan-data-labs/component-library/src/datadog/dataDog';
import contentfulRestClient from '@allergan-data-labs/contentful-rest/src/client/contentfulRestClient';

const FooterCodeID = {
  defaultConsumerFooter: 'defaultConsumerFooter',
  defaultProviderFooter: 'defaultProviderFooter',
  businessProviderFooter: 'businessProviderFooter',
  resortsWorldConsumerFooter: 'resortsWorldConsumerFooter',
} as const;

type UseCMSFooterQueryArgs = {
  codeId: string;
  skip?: boolean;
};

const useCMSFooterQuery = ({ codeId, skip }: UseCMSFooterQueryArgs) => {
  const contenfulFooterQuery = {
    'fields.codeId': codeId,
    include: 1,
    content_type: 'blockFooter',
  };

  const {
    data: contentfulFooter,
    isFetching: isLoadingContentfulFooter,
    error: contentfulFooterError,
  } = useReactQuery({
    queryKey: [JSON.stringify(contenfulFooterQuery)],
    queryFn: (): Promise<EntryCollection<IBlockFooterFields>> =>
      contentfulRestClient.getEntries(contenfulFooterQuery),
    enabled: !skip,
    refetchOnWindowFocus: false,
  });

  if (contentfulFooterError) {
    logger.error(`footer → profile → useCMSFooterQuery--error`, {
      error: `[Contentful error]: Message: ${contentfulFooterError}`,
    });
  }

  const contentfulFooterData = contentfulFooter?.items[0]?.fields ?? undefined;

  return {
    data: contentfulFooterData,
    isLoading: isLoadingContentfulFooter,
    hasError: !!contentfulFooterError,
  };
};

export { useCMSFooterQuery, FooterCodeID };
