import React, { useCallback } from 'react';
import { Flex, Box } from '@allergan-data-labs/alle-elements-layout';

import { useEnvironmentContext } from '../environment-context/environmentContext';
import {
  ConsumerFooterRedesignProps,
  isStaticCode,
} from './consumerFooterHelpers';
import { useColorMode } from '@allergan-data-labs/alle-elements-core';
import { AlleLogo } from '@allergan-data-labs/component-library/src/icons/alleLogo';
import { FacebookLogoCircular } from '@allergan-data-labs/component-library/src/icons/facebookLogoCircular';
import { InstagramLogo } from '@allergan-data-labs/component-library/src/icons/instagramLogo';
import { YoutubeLogo } from '@allergan-data-labs/component-library/src/icons/youtubeLogo';

const socialLinks = [
  {
    url: 'https://www.facebook.com/alle/',
    logo: FacebookLogoCircular,
  },
  {
    url: 'https://www.instagram.com/alle/',
    logo: InstagramLogo,
  },
  {
    url: 'https://www.youtube.com/@alle',
    logo: YoutubeLogo,
  },
];

const SocialLink = ({ url, Icon }: { url?: string; Icon: any }) => {
  const { Link } = useEnvironmentContext();

  return url ? (
    <Link target="_blank" href={url} display="flex" alignItems="center">
      <Icon />
    </Link>
  ) : null;
};

const ConsumerFooter = ({
  isLoggedIn,
  PRT,
  copyright,
  links,
  renderLink,
  renderStaticCodeComponent,
}: ConsumerFooterRedesignProps) => {
  const { colorMode } = useColorMode();

  const renderLinks = useCallback(() => {
    const Links: Array<JSX.Element> = [];

    links?.forEach((link, idx) => {
      if (link) {
        let LinkElement;
        if (isStaticCode(link)) {
          LinkElement = renderStaticCodeComponent(link);
        } else {
          LinkElement = renderLink(link);
        }

        // Separator
        if (idx !== 0 && LinkElement) {
          Links.push(
            <Box
              key={`footer-link-divider-${idx}`}
              width={2}
              color={`dark.Text/Neutral/Disabled/Default`}
            >
              |
            </Box>
          );
        }

        if (LinkElement) {
          Links.push(
            <Box
              key={`footer-link-${
                isStaticCode(link) ? link.codeId : link?.displayName
              }`}
            >
              {LinkElement}
            </Box>
          );
        }
      }
    });

    return Links;
  }, [links, renderLink, renderStaticCodeComponent]);

  return (
    <Box
      as="footer"
      data-testid="page-footer"
      position="absolute"
      backgroundColor={`${colorMode}.Section bg/Neutral/Inverted`}
      width="100%"
    >
      <Flex
        padding={{
          base: '32px 16px',
          sm: 56,
        }}
        rowGap={32}
        maxWidth={1440}
        flexDirection="column"
        margin="auto"
        boxSizing="border-box"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={32}
          flexDirection={{
            base: 'column',
            sm: 'row',
          }}
          sx={{
            '.MuiSvgIcon-root': {
              path: {
                fill: 'white',
              },
            },
            '.footer-logo': {
              '.MuiSvgIcon-root': {
                width: 83,
                height: 40,
                viewBox: '0 0 83 40',
              },
            },
          }}
        >
          <AlleLogo className="footer-logo" />
          <Flex
            gap={24}
            sx={{
              a: {
                marginBottom: 0,
              },
            }}
          >
            {socialLinks.map((socialLink) => (
              <SocialLink
                key={`social-link-${socialLink.url}`}
                url={socialLink.url}
                Icon={socialLink.logo}
              />
            ))}
          </Flex>
        </Flex>
        {links && (
          <Flex
            gap={isLoggedIn ? 8 : 6}
            alignItems="center"
            flexWrap="wrap"
            justifyContent={{
              base: 'center',
              sm: 'start',
            }}
            sx={{
              'a, p': {
                color: `${colorMode}.Text/Neutral/Interactive/Inverted enable`,
              },
              p: {
                marginBottom: 0,
              },
            }}
          >
            {renderLinks()}
          </Flex>
        )}

        <Box
          data-testid="consumer-footer-copyright"
          textStyle="Body/Small/Regular"
          color={`dark.Text/Neutral/Support`}
        >
          {copyright}
          {PRT && <span data-testid="consumer-footer-prt">{PRT}</span>}
        </Box>
      </Flex>
    </Box>
  );
};

export { ConsumerFooter };
