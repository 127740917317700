import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { alleColors } from '@allergan-data-labs/component-library/src/colorPalette';
import {
  Footer as BaseFooter,
  Logo,
  SectionsContainer,
  TopRow,
} from '@allergan-data-labs/component-library/src/core/footer';
import { Link } from '@allergan-data-labs/component-library/src/core/link';
import { CCPAOptOutIcon } from '@allergan-data-labs/component-library/src/icons/CCPAOptOut';
import { ConsumerFooterProps } from '@allergan-data-labs/consumer-component-library/src/footers/consumerFooterHelpers';
import { SiteNavigated } from '@allergan-data-labs/consumer-component-library/src/segment-tracking-plan/generated';
import { useSegment } from '@allergan-data-labs/consumer-component-library/src/segment/segmentContext';
import { Typography } from '@allergan-data-labs/universal-component-library/src/atoms/typography';
import {
  ALLERGAN_URLS,
  ABBVIE_URLS,
} from '@allergan-data-labs/universal-component-library/src/constants/urls';
import { above } from '@allergan-data-labs/universal-component-library/src/utils/mediaTemplate';
import { PrivacyDialog } from '@allergan-data-labs/consumer-component-library/src/components/privacyDialog';

// constants
import { useAuth } from '../../auth/AuthProvider';
import { AuthState } from '../../auth/authMachine';
import { Routes } from '../../constants/routes';
import { useAlleTreatments } from '@allergan-data-labs/split-feature-flag/src/useAlleTreatments';
import { caFeatureKeys } from '@allergan-data-labs/split-feature-flag/src/consumer';

const Label = styled(Typography).attrs({ preset: 'caption-L-regular' })`
  line-height: 1.82;
  margin-bottom: 24px;
  color: ${alleColors.nude[400]};
`;

/**
 * Negative margin-bottom covers the gap between the footer and the bottom of the page
 * caused by third party scripts adding blank img tags at the bottom of the page for
 * tracking purposes.
 *
 * This will not cause any visual side effects even if the white space is not present,
 * as negative margin-bottom will not pull the footer down past the end of the page.
 */
const Footer = styled(BaseFooter)`
  position: relative;
  margin-bottom: -50px;

  & ${TopRow} {
    justify-content: flex-start;
  }

  & ${Logo} {
    width: 50%;
  }

  & ${SectionsContainer} {
    width: 50%;
  }

  ${above.tablet`
    & ${SectionsContainer} {
      width: unset;
    }
  `}

  ${above.desktop`
    & ${Logo} {
      width: unset;
    }
  `}
`;

const CopyrightSection = styled.div`
  color: ${alleColors.gray[400]};

  p {
    line-height: 1.85;
  }
`;

const SectionTypography = styled(Typography)<{
  $isAbout?: boolean;
}>`
  && {
    margin-bottom: ${({ $isAbout }) => ($isAbout ? '24px' : '16px')};
  }
`;

const Button = styled.button`
  border: unset;
  padding: unset;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Text = styled(Typography)`
  text-align: left;

  ${above.tablet`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 4px;
  `}
`;

const ConsumerFooter = ({
  PRT,
  hideGlobalISILink = false,
  hideAbout = false,
  copyright,
}: ConsumerFooterProps) => {
  const { trackConsumer } = useSegment();
  const { authState } = useAuth();
  const [isPrivacyDialogOpen, setIsPrivacyDialogOpen] = useState(false);
  const isLoggedIn = authState?.matches(AuthState.isLoggedIn);

  const copyrightSection = (
    <CopyrightSection data-testid="consumer-footer-copyright">
      {copyright}
      {PRT && (
        <Typography
          preset={{
            s: 'body-S-regular',
            m: 'body-S-regular',
            l: 'body-M-regular',
          }}
          css={`
            color: inherit;
          `}
          data-testid="consumer-footer-prt"
        >
          {PRT}
        </Typography>
      )}
    </CopyrightSection>
  );

  const trackHelpLinkClick = () => {
    trackConsumer()?.helpRequested({
      event: {
        action_source: 'consumer web',
        activator: 'Footer',
        explainer: 'Tracking user engagement with footer',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: isLoggedIn ? 'logged_in' : 'logged_out',
      },
    });
  };

  const trackLinkClick = useCallback(
    (e: React.SyntheticEvent) => {
      const link = e.currentTarget as HTMLElement;
      const linkSegment: SiteNavigated = {
        event: {
          action_source: 'consumer web',
          activator: `Footer - ${link.textContent} Link`,
          explainer: 'Tracking user engagement with Footer',
          invoked_by: 'consumer',
          user_type: 'consumer',
          user_status: isLoggedIn ? 'logged_in' : 'logged_out',
          event_type: 'engagement',
        },
        site_type: 'consumer web',
        navigation_type: 'footer navigation',
        event_source_url: `${window.location.href}`,
        text: `${link.textContent}`,
        url: `${link.getAttribute('href')}`,
      };

      trackConsumer()?.siteNavigated(linkSegment);
    },
    [trackConsumer, isLoggedIn]
  );

  const trackOneTrustButtonClick = useCallback(() => {
    trackConsumer()?.siteNavigated({
      event: {
        action_source: 'consumer web',
        activator: 'Footer',
        explainer: 'Tracking if user interacts with footer',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: isLoggedIn ? 'logged_in' : 'logged_out',
        event_type: 'engagement',
      },
      navigation_type: 'footer navigation',
      site_type: 'consumer web',
      text: 'One Trust',
      event_source_url: `${window.location.href}`,
    });
  }, [trackConsumer, isLoggedIn]);

  const openCookieSettings = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    (window as any).OneTrust?.ToggleInfoDisplay();
  };

  const handleOneTrustButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    openCookieSettings(e);
    trackOneTrustButtonClick();
  };

  const handleYourPrivacyChoicesClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    const isLinkEvent = e.currentTarget instanceof HTMLAnchorElement;

    trackConsumer()?.siteNavigated({
      event: {
        action_source: 'consumer web',
        activator: 'Footer Link',
        explainer: 'Tracking user engagement with link on footer',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: isLoggedIn ? 'logged_in' : 'logged_out',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'footer navigation',
      event_source_url: window.location.href,
      text: 'Your Privacy Choices',
      url: isLinkEvent ? Routes.settingsPrivacy : undefined,
    });

    if (isLoggedIn) {
      return;
    }

    e.preventDefault();
    setIsPrivacyDialogOpen(true);
  };

  const isPocMultiAppEnabled =
    useAlleTreatments({
      splitNames: [caFeatureKeys.pocMultiApp],
    })[caFeatureKeys.pocMultiApp]?.treatment === 'on';

  return (
    <>
      <PrivacyDialog
        open={isPrivacyDialogOpen}
        isLoggedIn={isLoggedIn}
        loginRoute={Routes.login}
        onClose={() => setIsPrivacyDialogOpen(false)}
        openCookieSettings={openCookieSettings}
      />
      <Footer copyrightSection={copyrightSection}>
        {!hideAbout && (
          <BaseFooter.Section
            data-testid="consumer-footer-about"
            css={`
              max-width: 240px;
            `}
          >
            <BaseFooter.Section nested>
              <Label>Get treated</Label>
              <Link
                to={Routes.browseStates}
                onClick={trackLinkClick}
                external={isPocMultiAppEnabled}
                forceSameTab={isPocMultiAppEnabled}
              >
                <SectionTypography preset={'body-L-regular'}>
                  Search Providers
                </SectionTypography>
              </Link>
            </BaseFooter.Section>
            <Label>About</Label>
            <a
              css={`
                text-decoration: none;
              `}
              href={ALLERGAN_URLS.alleSupport}
              onClick={trackHelpLinkClick}
              rel="noopener noreferrer"
            >
              <SectionTypography $isAbout preset={'body-L-regular'}>
                Help
              </SectionTypography>
            </a>
            <Link to={Routes.about} onClick={trackLinkClick}>
              <SectionTypography $isAbout preset={'body-L-regular'}>
                About Us
              </SectionTypography>
            </Link>
            <Link to={Routes.howItWorks} onClick={trackLinkClick}>
              <SectionTypography $isAbout preset={'body-L-regular'}>
                How Allē Works
              </SectionTypography>
            </Link>
            <Link to={`${Routes.about}#faq`} onClick={trackLinkClick}>
              <SectionTypography $isAbout preset={'body-L-regular'}>
                FAQ
              </SectionTypography>
            </Link>
          </BaseFooter.Section>
        )}
        <BaseFooter.Section
          data-testid="consumer-footer-legal"
          css={`
            max-width: 240px;
          `}
        >
          <Label>Legal</Label>
          <Link to={Routes.termsAndConditions} onClick={trackLinkClick}>
            <SectionTypography preset={'body-L-regular'}>
              Terms and Conditions
            </SectionTypography>
          </Link>
          <Link to={ALLERGAN_URLS.termsOfUse} onClick={trackLinkClick} external>
            <SectionTypography preset={'body-L-regular'}>
              Terms of Use
            </SectionTypography>
          </Link>
          <Link
            to={ALLERGAN_URLS.unitedStatesPrivacyPolicy}
            onClick={trackLinkClick}
            external
          >
            <SectionTypography preset={'body-L-regular'}>
              Privacy Notice
            </SectionTypography>
          </Link>
          <Link
            to={ABBVIE_URLS.consumerDataHealthPrivacy}
            onClick={trackLinkClick}
            external
          >
            <SectionTypography preset={'body-L-regular'}>
              Consumer Health Data Privacy Notice
            </SectionTypography>
          </Link>
          {isLoggedIn ? (
            <Link
              to={Routes.settingsPrivacy}
              onClick={handleYourPrivacyChoicesClick}
            >
              <Text css="justify-content: left;" preset={'body-L-regular'}>
                Your Privacy{' '}
                <span css="display: inline-flex; flex-wrap: wrap; align-items:center;">
                  Choices
                  <CCPAOptOutIcon css="margin-left: 4px;" />
                </span>
              </Text>
            </Link>
          ) : (
            <>
              <Button id="ot-sdk-btn" onClick={handleOneTrustButtonClick}>
                <Text preset={'body-L-regular'}>Cookie Settings</Text>
              </Button>
              <Button onClick={handleYourPrivacyChoicesClick}>
                <Text preset={'body-L-regular'}>
                  Your Privacy{' '}
                  <span css="display: inline-flex; flex-wrap: wrap; align-items:center;">
                    Choices
                    <CCPAOptOutIcon css="margin-left: 4px;" />
                  </span>
                </Text>
              </Button>
            </>
          )}
          {!hideGlobalISILink && (
            <Link to={Routes.globalIsi} onClick={trackLinkClick}>
              <SectionTypography preset={'body-L-regular'}>
                Uses, Important Safety Info and Prescribing Info
              </SectionTypography>
            </Link>
          )}
        </BaseFooter.Section>
      </Footer>
    </>
  );
};

export { ConsumerFooter };
